import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

/**
 * Orchestrates the click away handlers for all currently opened typeaheads.
 * Each typeahead has to decide if the click was inside its popover and thus, as the popover may be mounted at
 * the document body level, visually out of place in the DOM hierarchy.
 * One popover may visually appear to be inside another popover, but in the DOM hierarchy both popovers are just
 * siblings in the document body.
 * Because of this, we call the handlers in reverse order of how they were opened because this allows one of
 * the inner typeaheads to "claim" the click by calling event.preventDefault, from which we interpret that the
 * click must also be part of the other popovers earlier on in the clickawayHandlers list, even though it is not
 * according to the DOM hierarchy.
 */
@Injectable({
	providedIn: 'root',
})
export class TypeaheadPopoverService {
	handlers: ClickHandler[] = [];

	constructor(@Inject(DOCUMENT) private document: Document) {}

	addBodyClickHandlerForInstance(handler: ClickHandler) {
		this.handlers = [...this.handlers, handler];
		this.document.body.addEventListener('click', this.onBodyClick.bind(this));
	}

	removeBodyClickHandlerForInstance(handler: ClickHandler) {
		this.handlers = this.handlers.filter((h) => h !== handler);
		if (this.handlers.length === 0) {
			this.document.body.removeEventListener('click', this.onBodyClick);
		}
	}

	onBodyClick(event: MouseEvent) {
		for (let i = this.handlers.length - 1; i >= 0 && !event.defaultPrevented; i--) {
			this.handlers[i](event);
		}
	}
}

type ClickHandler = (event: MouseEvent) => void;

import { computed, Injectable, signal } from '@angular/core';

@Injectable()
export class ReportProcessingService {
	public readonly isProcessingTemplate = signal(false);
	public readonly isProcessingUpdate = signal(false);

	public readonly isProcessing = computed(() =>
		[this.isProcessingTemplate, this.isProcessingUpdate].some((processing) => processing())
	);
}

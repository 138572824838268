export interface MeasureOptions {
	fontFamily: string;
	fontSize: number;
}

export const measureTexts = (texts: string[], ctx: CanvasRenderingContext2D, options: MeasureOptions): number[] => {
	ctx.font = `${options.fontSize}px ${options.fontFamily}`;
	return texts.map((text) => ctx.measureText(text).width);
};

export const measureText = (text: string, ctx: CanvasRenderingContext2D, options: MeasureOptions): number =>
	measureTexts([text], ctx, options)[0];

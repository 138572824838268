<evasys-top-menu [logo]="logo" [logoAlt]="'domain.landingPage' | transloco" (logoClickedAction)="onLogoClicked()">
	<evasys-product-menu
		[refreshMethod]="authEndpointService.refreshProductUrls"
		[renderOnOpen]="true"
		fixLeft
		class="h-100 mx-2 align-items-center d-flex"
	>
		<ng-container *ngFor="let product of productsObservable | async">
			<evasys-product-menu-item
				*ngIf="product.url"
				[id]="product.id"
				[name]="product.name | transloco"
				[url]="product.url | modify : asString"
			></evasys-product-menu-item>
		</ng-container>
	</evasys-product-menu>

	<div fixRight class="d-flex flex-row flex mx-3 h-100 align-items-center">
		<a
			[attr.href]="userManualUrl | async"
			[id]="'open-usermanual'"
			target="_blank"
			data-cy="open-usermanual"
			class="d-flex c-h-55px align-items-center"
		>
			<img
				evasysImage
				[src]="'assets/images/icons/help2.svg'"
				[id]="'img-open-userManual'"
				[isIcon]="true"
				[class]="'c-w-20px c-h-20px'"
			/>
			<span class="m-0 ms-1 ms-xl-2 h6 eva-topmenu-color">{{ 'domain.help' | transloco }}</span>
		</a>
		<evasys-usermenu
			(logoutResponse)="logoutResponse.emit()"
			(roleResponse)="roleResponse.emit($event)"
			[role]="role"
			[roles]="roleItems"
			[username]="username"
			class="ms-1 ms-sm-3 my-0 my-lg-0 order-lg-4 h-100 c-z-index-3"
		></evasys-usermenu>
	</div>

	<evasys-tabs
		menuItems
		(response)="menuResponse.emit($event)"
		[responsive]="true"
		[active]="activeMenuTabItemIndex | async"
		[tabitems]="menuItems"
		[underline]="false"
		class="col-12 col-lg-auto order-lg-2 align-self-start p-3 p-sm-5 p-lg-0 m-lg-0 c-h-lg-55px d-lg-flex align-items-lg-end"
	></evasys-tabs>
</evasys-top-menu>

import { Component, Input } from '@angular/core';
import { opacityAnimation } from '@evasys/globals/shared/animations/opacity.animation';
import { Required } from '@evasys/globals/shared/decorators/decorators';

@Component({
	selector: 'evasys-loading-overlay',
	templateUrl: './loading-overlay.component.html',
	animations: [opacityAnimation()],
})
export class LoadingOverlayComponent {
	@Input()
	@Required()
	loading!: boolean;

	@Input()
	backdropColorClass = 'c-bg-white';
}

<evasys-typeahead
	#typeahead
	(ngModelChange)="toggleChip($event)"
	[autoClosePopoverOnSelect]="false"
	[ngModel]="null"
	(blur)="_onTouched()"
	[disabled]="disabled"
	[errors]="errors"
	[formatter]="_formatter"
	[id]="id"
	[items]="items"
	[placement]="placement"
	[searchFailedText]="searchFailedText"
	[resultTemplate]="multiResultTemplate"
	[emptyResultsText]="emptyResultsText"
	[itemIdentifier]="itemIdentifier"
	[header]="headerTemplate"
	[autoFocus]="autoFocus"
	[popoverClass]="popoverClass"
	[popoverContainer]="popoverContainer"
	[childItemIdentifier]="childItemIdentifier"
	[inputValueReplaceStrategy]="inputValueReplaceStrategy.KEEP_INPUT_VALUE"
>
	<ng-content></ng-content>
</evasys-typeahead>
<div
	*ngIf="design === chipTypeaheadDesign.DEFAULT && chips.length > 0"
	class="chip d-flex mt-2 gap-1 mw-100"
	[attr.data-cy]="'chipTypeahead-selectedChips-' + id"
>
	<ng-container *ngFor="let chip of chips">
		<evasys-chip
			[id]="id + '-' + chip[itemIdentifier]"
			[text]="chip | modify : _formatter | async"
			[isDisabled]="disabled"
			(removeAction)="removeChip(chip)"
			[ngClass]="disabled ? 'disabled-class' : ''"
			data-cy="chip-selectedTypeaheadValue"
		></evasys-chip>
	</ng-container>
	<ng-container *ngIf="chips.length">
		<evasys-button
			class="d-flex"
			[id]="'filterTypeahead-' + id + '-removeAll'"
			[design]="buttonStyle.LINK"
			(click)="setChips([])"
			[title]="config?.translations?.chipTypeahead.removeAllTooltip | transloco"
		>
			<img
				evasysImage
				[id]="'resetFilter'"
				[alt]="'removeAll-icon'"
				[class]="'c-h-20px c-w-20px mx-1 align-self-center'"
				[src]="'./assets/images/icons/navigate_cross.svg'"
			/>{{ config?.translations?.chipTypeahead.removeAll | transloco }} ({{ chips.length }})
		</evasys-button>
	</ng-container>
</div>
<div *ngIf="design === chipTypeaheadDesign.LIST">
	<div
		*ngFor="let chip of chips | slice : 0 : chipVisibleSize ?? chips.length; let last = last"
		[ngClass]="[disabled ? 'disabled-class' : ' ', last ? '' : ' border-bottom border-light']"
		class="p-1 mt-1"
		[attr.data-cy]="'chip-selectedTypeaheadValue-' + id + '-' + chip[itemIdentifier]"
	>
		<div class="d-flex p-0 w-100">
			<span
				[title]="chip | modify : _formatter | async"
				class="text-truncate h4 m-0 justify-content-center align-self-center mw-90"
				data-toggle="tooltip"
				title="{{ _formatter(chip) | async }}"
			>
				{{ _formatter(chip) | async }}
			</span>
			<evasys-button
				class="ms-auto"
				[id]="'delete-' + id + '-' + chip[itemIdentifier]"
				[design]="iconStyle"
				(click)="removeChip(chip)"
			>
				<img
					evasysImage
					[id]="'delete'"
					[alt]="'delete-icon'"
					[class]="'c-w-20px c-h-20px'"
					[src]="'./assets/images/icons/navigate_cross.svg'"
				/>
			</evasys-button>
		</div>
		<ng-container *ngTemplateOutlet="chipContentTemplate; context: { $implicit: chip }"></ng-container>
	</div>
</div>

<ng-template #headerTemplate let-results>
	<ng-container *ngTemplateOutlet="header"></ng-container>
	<div
		class="d-flex justify-content-center pt-2"
		*ngIf="showSelectAll && results.latestResults?.entities.length > chips.length"
	>
		<evasys-button
			*ngLet="
				results.isLoadingNewQuery ||
				results.isLoadingMore ||
				(chips.length ? chips.length : 0) + results.latestResults?.totalElements >
					maxTotalSelectedElements as canSelectAll
			"
			class="w-100"
			[class]="'c-h-fit-content'"
			[id]="'filterTypeahead-' + id + '-selectAll'"
			[isDisabled]="canSelectAll"
			[design]="buttonStyle.LINK"
			[attr.title]="
				canSelectAll
					? ('common.selectAll.hover.error'
					  | transloco
							: {
									totalElements: results.latestResults?.totalElements,
									maxAllowedElements: maxTotalSelectedElements
							  })
					: null
			"
			(click)="selectAllResults($event, results.latestResults.text, !canSelectAll)"
			>{{ config.translations.chipTypeahead.selectAll | transloco }} ({{
				results.latestResults?.totalElements
			}})</evasys-button
		>
	</div>
</ng-template>
<ng-template #multiResultTemplate let-entity let-term="term" let-text="text">
	<evasys-checkbox
		class="py-1 c-pointer-events-none text-truncate w-100"
		[id]="'chipTypeahead-' + id + '-result-' + entity[itemIdentifier]"
		[checked]="entity | modify : isSelected : chips"
		[indeterminate]="entity | modify : isPartSelected : chips"
	>
		<ng-container
			*ngTemplateOutlet="
				resultTemplate || defaultResultTemplate;
				context: { $implicit: entity, term: term, text }
			"
		></ng-container>
	</evasys-checkbox>
</ng-template>
<ng-template #defaultResultTemplate let-text="text" let-term="term">
	<ngb-highlight [result]="text" [term]="term" class="w-100" evasysTextOverflow> </ngb-highlight>
</ng-template>

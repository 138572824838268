import { isEqual as lodashIsEqual } from 'lodash';

export function reduceObject(object: object, props: string[]) {
	return Object.keys(object)
		.filter((key) => props.includes(key))
		.reduce((obj: any, key: any) => {
			obj[key] = (object as any)[key];
			return obj;
		}, {});
}

/**
 * Same functionality as lodash.isEqual but with generic parameters, sometimes allowing for better typing
 *
 * ```typescript
 * const observable = new Observable<number>();
 *
 * observable.pipe(distinct(isEqual)); // yields an Observable<number>
 * observable.pipe(distinct(lodashIsEqual)); // yields an Observable<any>
 * ```
 */
export function isEqual<A, B>(a: A, b: B) {
	return lodashIsEqual(a, b);
}

export const chainIterables = <T>(iterables: Array<Iterable<T>>): Iterable<T> => {
	const remainingIterators = iterables.map((iterable) => iterable[Symbol.iterator]());

	return {
		[Symbol.iterator]: () => ({
			next: () => {
				while (remainingIterators.length > 0) {
					const result = remainingIterators[0].next();
					if (result.done) {
						remainingIterators.shift();
					} else {
						return result;
					}
				}

				return { done: true, value: undefined };
			},
		}),
	};
};

type ZipItem<T extends Array<Iterable<any>>> = T extends [Iterable<infer HeadItem>, ...infer TailIterables]
	? TailIterables extends Array<Iterable<any>>
		? [HeadItem, ...ZipItem<TailIterables>]
		: never
	: [];

export const zipIterableShortest = <T extends Array<Iterable<any>>>(...iterables: T): Iterable<ZipItem<T>> => {
	return {
		[Symbol.iterator]: () => {
			const iterators = iterables.map((iterable) => iterable[Symbol.iterator]());

			return {
				next: () => {
					const results = [];
					for (const iterator of iterators) {
						const result = iterator.next();
						if (result.done) {
							return { done: true, value: undefined };
						}

						results.push(result.value);
					}

					return { done: false, value: results as ZipItem<T> };
				},
			};
		},
	};
};

export const mapIterable = <S, T>(iterable: Iterable<S>, mapFn: (value: S) => T): Iterable<T> => {
	return {
		[Symbol.iterator]: () => {
			const iterator = iterable[Symbol.iterator]();
			return {
				next: () => {
					const result = iterator.next();
					return result.done ? result : { done: false, value: mapFn(result.value) };
				},
			};
		},
	};
};

/**
 * Builds a function that returns the next value output by the iterable on each call
 */
export const returnFromIterable = <T>(iterable: Iterable<T>) => {
	const iterator = iterable[Symbol.iterator]();
	return () => {
		const result = iterator.next();
		if (result.done) {
			throw Error('Iterator is exhausted');
		}

		return result.value;
	};
};

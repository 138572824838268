import { Language } from '../../constants/types';

export interface SystemMultiLangEntry<T> {
	lang: string;
	value: T;
}

export interface ReportMultiLangEntry<T> {
	reportLanguageId: number;
	value: T;
}

export interface FormMultiLangEntry<T> {
	formLanguageId: number;
	value: T;
	userLanguage?: Language;
	isMain?: true;
}

export type GeneralMultiLangEntry<T> = SystemMultiLangEntry<T> | ReportMultiLangEntry<T> | FormMultiLangEntry<T>;

export type SystemMultiLang<T> = Array<SystemMultiLangEntry<T>>;
export type ReportMultiLang<T> = Array<ReportMultiLangEntry<T>>;
export type FormMultiLang<T> = Array<FormMultiLangEntry<T>>;

export type GeneralMultiLang<T> = SystemMultiLang<T> | ReportMultiLang<T> | FormMultiLang<T>;

export const isSystemMultiLang = <T>(value: GeneralMultiLang<T>): value is SystemMultiLang<T> =>
	value.length === 0 || 'lang' in value[0];

export const isFormMultiLang = <T>(value: GeneralMultiLang<T>): value is FormMultiLang<T> =>
	value.length === 0 || 'formLanguageId' in value[0];

export const isReportMultiLang = <T>(value: GeneralMultiLang<T>): value is ReportMultiLang<T> =>
	value.length === 0 || 'reportLanguageId' in value[0];

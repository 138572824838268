import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class StepFilterConfig {
	translation: StepFilterTranslations;
}

export interface StepFilterTranslations {
	clear: string;
	next: string;
	back: string;
}

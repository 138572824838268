import { AfterViewInit, Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { Required } from '@evasys/globals/shared/decorators/decorators';

@Directive({
	selector: 'img[evasysImage]',
})
export class ImageDirective implements OnInit, AfterViewInit {
	//region Input & Output
	@Input()
	isIcon?: boolean = false;

	@Input()
	@Required()
	set id(id: string) {
		this._id = id;
		this.host?.nativeElement.setAttribute('data-cy', 'image-' + id);
	}

	@Input()
	addTimestampToSrc = false;

	@Input()
	@Required()
	set src(src: string) {
		this._src = !this.addTimestampToSrc ? src : src + '?t=' + new Date().getTime();
		this.renderer2?.setAttribute(this.host.nativeElement, 'src', this._src);
	}

	@Input()
	set alt(alt: string) {
		if (alt && this.host) {
			this.renderer2.setAttribute(this.host.nativeElement, 'alt', alt);
			if (this.altTextAsLabel) {
				this.renderer2?.setAttribute(this.host.nativeElement, 'title', alt);
			}
		}
		this._alt = alt;
	}

	@Input()
	altTextAsLabel = false;

	//endregion

	//region Variables
	_src?: string;
	_alt?: string;
	_id?: string;
	//endregion

	//region Events

	constructor(private host: ElementRef<HTMLImageElement>, private renderer2: Renderer2) {}

	ngOnInit(): void {
		this.checkAltText();
	}
	ngAfterViewInit() {
		this.checkSettedAttributes();
	}

	//endregion

	//region Methods
	private checkSettedAttributes() {
		if (!this.host.nativeElement.getAttribute('data-cy')) {
			this.renderer2?.setAttribute(this.host.nativeElement, 'id', this.id);
		}
		if (this.addTimestampToSrc && this.host.nativeElement.getAttribute('src') !== this._src) {
			this.src = this._src;
		}
		if (this._alt && !this.host.nativeElement.getAttribute('alt')) {
			this.renderer2?.setAttribute(this.host.nativeElement, 'alt', this._alt);
		}
		if (this._alt && this.altTextAsLabel) {
			this.renderer2?.setAttribute(this.host.nativeElement, 'title', this._alt);
		}
	}

	private checkAltText(): void {
		if (!this.isIcon && !this._alt) {
			throw new Error(`Attribute alt for ${this._id} is required if isIcon is not true.`);
		}
	}
	//endregion
}

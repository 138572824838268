<div class="position-fixed w-100 custom-zindex">
	<ng-container *ngFor="let notification of notifications">
		<ngb-alert
			[attr.data-cy]="'dynamicNotification-' + notification.id"
			class="m-3 shadow-sm eva-topmenu-bg-color d-flex"
			@popAnimation
			type="custom"
			(closed)="onClosed(notification)"
		>
			<img
				evasysImage
				*ngIf="notification.severity"
				[class]="'c-w-23px c-w-23px'"
				[ngClass]="asCssClass(notification.severity)"
				class="me-3 align-self-center"
				[isIcon]="true"
				[id]="'severityIcon'"
				[src]="'./assets/images/icons/' + notification.severity + '.svg'"
			/>

			<div class="d-flex flex-column align-items-start ps-5">
				<div class="col justify-content-center p-1" [innerHTML]="notification.message"></div>
				<evasys-button
					*ngIf="notification.action"
					class="btn"
					[id]="'api-call'"
					(evasysRegulationClick)="onApiCallAction(notification)"
				>
					{{ notification.action?.text }}
				</evasys-button>
			</div>

			<evasys-button
				class="ms-auto align-self-center"
				[id]="'dynamicNotification-closeButton-' + notification.id"
				[design]="buttonDesign.LINK"
				(click)="onClosed(notification)"
			>
				<img
					evasysImage
					[class]="'c-w-20px c-h-20px'"
					[id]="'dynamicNotification-' + notification.id"
					[isIcon]="true"
					[src]="'assets/images/icons/window-close.svg'"
				/>
			</evasys-button>
		</ngb-alert>
	</ng-container>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbCollapseModule, NgbHighlight, NgbPaginationModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { DropdownModule, SharedUiModule } from '@evasys/shared/ui';
import { TranslocoModule } from '@ngneat/transloco';
import { TopMenuComponent } from './components/top-menu/top-menu.component';
import { PageControlComponent } from './components/page-control/page-control.component';
import { WordcloudResponsesWidgetComponent } from './components/widgets/wordcloud-responses-widget/wordcloud-responses-widget.component';
import { TopicResponsesComponent } from './components/widgets/topic-responses/topic-responses.component';
import { WordcloudContentComponent } from './components/widgets/wordcloud-content/wordcloud-content.component';
import { OverviewTableComponent } from './components/overview-table/overview-table.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TextOverlayComponent } from './components/overlays/text-overlay/text-overlay.component';
import { SlateModule } from 'slate-angular';
import { TextMarkComponent } from './components/rich-text/text-mark/text-mark.component';
import { ElementStyleDirective } from './components/rich-text/text-mark/element-style.directive';
import { EditableTextComponent } from './components/editable-text/editable-text.component';
import { RichTextEditableComponent } from './components/rich-text/rich-text-editable/rich-text-editable.component';
import { RichTextEditorToolbarComponent } from './components/rich-text/rich-text-editor-toolbar/rich-text-editor-toolbar.component';
import { RichTextComponent } from './components/widgets/rich-text/rich-text.component';
import { LineChartContentComponent } from './components/widgets/line-chart-content/line-chart-content.component';
import { ReportItemTitleComponent } from './components/report-item-title/report-item-title.component';
import { ProfileLineChartContentComponent } from './components/widgets/profile-line-chart-content/profile-line-chart-content.component';
import { WordcloudInfoPopoverComponent } from './components/wordcloud-info-popover/wordcloud-info-popover.component';
import { ReportItemWrapperComponent } from './components/widgets/report-item-wrapper/report-item-wrapper.component';
import { BarChartWidgetComponent } from './components/widgets/bar-chart-widget/bar-chart-widget.component';
import { LineChartWidgetComponent } from './components/widgets/line-chart-widget/line-chart-widget.component';
import { WordcloudWidgetComponent } from './components/widgets/wordcloud-widget/wordcloud-widget.component';
import { ProfileLineChartWidgetComponent } from './components/widgets/profile-line-chart-widget/profile-line-chart-widget.component';
import { AxisChartComponent } from './components/widgets/axis-chart/axis-chart.component';
import { BarChartContentComponent } from './components/widgets/bar-chart-content/bar-chart-content.component';
import { A11yModule } from '@angular/cdk/a11y';
import { OnlyValuesFromItemsDirective } from './directives/only-values-from-items.directive';
import { SharedUtilModule } from '@evasys/shared/util';
import { OnlyValuesFromTypeaheadItemsDirective } from './directives/only-values-from-typeahead-items.directive';
import { ClickableWordsDirective } from './components/widgets/wordcloud-content/clickable-words.directive';
import { ChartExportMenuComponent } from './components/chart-export-menu/chart-export-menu.component';
import { ReportItemComponent } from './components/widgets/report-item/report-item.component';
import { KeepMapOrderModule } from '../../../../../shared/util/src/lib/pipes/keep-map-order/keep-map-order.module';
import { ResponsesWidgetComponent } from './components/widgets/responses-widget/responses-widget.component';
import { ResponsesContentComponent } from './components/widgets/responses-content/responses-content.component';
import { TypeaheadFilterAreaComponent } from './components/typeahead-filter-area/typeahead-filter-area.component';
import { TypeaheadFilterResultComponent } from './components/typeahead-filter-result/typeahead-filter-result.component';
import { InlineTranslocoModule } from '../../../../../shared/util/src/lib/pipes/inline-transloco/inline-transloco.module';
import { ReportLanguageBoundaryDirective } from './directives/report-language-boundary.directive';
import { EvainsightsSharedUtilModule } from '@evasys/evainsights/shared/util';
import { NgLetModule } from 'ng-let';
import { DividerWidgetComponent } from './components/widgets/divider-widget/divider-widget.component';

@NgModule({
	imports: [
		CommonModule,
		NgbCollapseModule,
		SharedUiModule,
		TranslocoModule,
		NgbPaginationModule,
		FormsModule,
		ReactiveFormsModule,
		SlateModule,
		RouterModule,
		NgbPopoverModule,
		A11yModule,
		SharedUtilModule,
		NgbHighlight,
		DropdownModule,
		KeepMapOrderModule,
		InlineTranslocoModule,
		EvainsightsSharedUtilModule,
		NgLetModule,
	],
	declarations: [
		TopMenuComponent,
		PageControlComponent,
		OverviewTableComponent,
		TopicResponsesComponent,
		WordcloudContentComponent,
		BarChartContentComponent,
		ProfileLineChartContentComponent,
		TextOverlayComponent,
		TextMarkComponent,
		ElementStyleDirective,
		EditableTextComponent,
		RichTextEditableComponent,
		RichTextEditorToolbarComponent,
		RichTextComponent,
		LineChartContentComponent,
		ReportItemTitleComponent,
		WordcloudInfoPopoverComponent,
		AxisChartComponent,
		ReportItemWrapperComponent,
		BarChartWidgetComponent,
		LineChartWidgetComponent,
		WordcloudWidgetComponent,
		WordcloudResponsesWidgetComponent,
		ProfileLineChartWidgetComponent,
		OnlyValuesFromItemsDirective,
		OnlyValuesFromTypeaheadItemsDirective,
		ClickableWordsDirective,
		ChartExportMenuComponent,
		ReportItemComponent,
		ResponsesWidgetComponent,
		ResponsesContentComponent,
		TypeaheadFilterAreaComponent,
		TypeaheadFilterResultComponent,
		ReportLanguageBoundaryDirective,
		DividerWidgetComponent,
	],
	exports: [
		TopMenuComponent,
		PageControlComponent,
		OverviewTableComponent,
		TopicResponsesComponent,
		WordcloudContentComponent,
		BarChartContentComponent,
		ProfileLineChartContentComponent,
		RichTextEditableComponent,
		RichTextEditorToolbarComponent,
		TextOverlayComponent,
		EditableTextComponent,
		LineChartContentComponent,
		WordcloudInfoPopoverComponent,
		BarChartWidgetComponent,
		LineChartWidgetComponent,
		WordcloudWidgetComponent,
		WordcloudResponsesWidgetComponent,
		ProfileLineChartWidgetComponent,
		OnlyValuesFromItemsDirective,
		OnlyValuesFromTypeaheadItemsDirective,
		ClickableWordsDirective,
		ChartExportMenuComponent,
		ReportItemComponent,
		ResponsesWidgetComponent,
		ResponsesContentComponent,
		TypeaheadFilterAreaComponent,
		TypeaheadFilterResultComponent,
		ReportLanguageBoundaryDirective,
	],
})
export class EvainsightsSharedUiModule {}

import { Injectable } from '@angular/core';
import { EvasysEffects } from '../essential/redux/effects/evasys.effects';
import { Actions } from '@ngrx/effects';
import { ConfigModel } from '@evasys/globals/evasys/models/business/config.model';
import { EvasysRequestMethodEnum } from '@evasys/globals/shared/enums/general/evasys-request-method.enum';
import { EvasysApiRequestModel } from '@evasys/globals/shared/models/general/evasys-api-request.model';
import { sharedActions } from '../actions/index';
import { SharedCoreConfiguration } from '../shared-core.configuration';
import { ApiService } from '../essential/api/services/api.service';

@Injectable()
export class ConfigEffects extends EvasysEffects<ConfigModel> {
	private API_PATH = this.sharedCoreConfiguration.api.config;

	constructor(
		protected override actions$: Actions,
		protected readonly apiService: ApiService,
		private readonly sharedCoreConfiguration: SharedCoreConfiguration
	) {
		super(sharedActions.fromConfigs, sharedCoreConfiguration.api.config, actions$);
	}

	loadPage$ = this.createEffect(
		sharedActions.fromConfigs.LoadPageActionSet,
		(action: any) =>
			({
				apiPath: this.API_PATH,
				many: true,
				requestMethod: EvasysRequestMethodEnum.GET,
				page: action.payload,
			} as EvasysApiRequestModel)
	);

	editOne$ = this.createEffect(sharedActions.fromConfigs.EditOneActionSet, (action: any) => ({
		apiPath: [this.API_PATH, action.payload.id].join('/'),
		many: false,
		requestMethod: EvasysRequestMethodEnum.PATCH,
		body: action.payload.changes,
	}));

	loadMany$ = this.createEffect(
		sharedActions.fromConfigs.LoadManyActionSet,
		(action: any) =>
			({
				apiPath: this.API_PATH,
				many: true,
				requestMethod: EvasysRequestMethodEnum.GET,
				page: action.payload.page,
				pageSize: action.payload.pageSize,
				filter: 'name in ' + action.payload.ids,
			} as EvasysApiRequestModel)
	);

	loadOneWithConfigName$ = this.createEffect(
		sharedActions.fromConfigs.LoadOneWithConfigName,
		(action: any) =>
			({
				apiPath: this.API_PATH,
				many: true,
				requestMethod: EvasysRequestMethodEnum.GET,
				filter: 'name eq ' + action.payload,
			} as EvasysApiRequestModel)
	);

	protected mapToFrontend(data: any): ConfigModel {
		return {
			...data,
			id: data.configid,
		} as ConfigModel;
	}

	protected mapToBackend(config: ConfigModel): any {
		const newConfig = {
			...config,
		};

		delete newConfig.oldValue;

		return newConfig;
	}
}

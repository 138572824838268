import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { loadData } from '@evasys/shared/util';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { EvasysState } from '../states/evasys.state';
import { EvasysPageConfigModel } from '@evasys/globals/shared/models/general/evasys-page-config.model';
import { Dictionary, Update } from '@ngrx/entity';
import { EvasysLoadingStrategiesEnum } from '@evasys/globals/shared/enums/general/evasys-loadingStrategies.enum';
import { HttpErrorResponse } from '@angular/common/http';
import { catchFailure, dispatchResult, load } from '../../../../../../util/src/lib/rxjs/rxjs.functions';
import { Actions } from '@ngrx/effects';
import { EvasysFacadeGetModel } from '@evasys/globals/evasys/models/general/evasys-facade-get.model';

@Injectable({
	providedIn: 'root',
})
export abstract class FacadeService<T, S = void> {
	protected requestCounter = 0;

	protected constructor(
		protected readonly store: Store<EvasysState<T, S>>,
		private readonly fromActions: any,
		private readonly fromSelectors: any,
		protected readonly actions: Actions
	) {
		// This is intentional
	}

	//#region read

	public get<OUT>(options: EvasysFacadeGetModel<T>): Observable<OUT> {
		let entityCount = undefined;
		if (options.id !== undefined) {
			entityCount = Array.isArray(options.id) ? options.id.length : 1;
		}
		this.requestCounter += 1;
		return load<unknown, OUT>(
			this.actions,
			this.fromActions.LoadActionSet,
			this.store,
			this.fromActions.LoadActionSet.DEFAULT({ payload: options, requestId: this.requestCounter }),
			() => {
				if (entityCount === 1) {
					return this.store.select(this.fromSelectors.selectEntity, {
						id: Array.isArray(options.id) ? options.id[0] : options.id,
					});
				} else {
					return this.store.select(this.fromSelectors.selectWithFilter(options.filterState));
				}
			},
			options.loadingStrategy,
			entityCount,
			options.asEvasysCollectionModel
		);
	}

	/**
	 * @deprecated use the simple get-Method
	 */
	public getAllLoaded(): Observable<T[]> {
		return this.store.select(this.fromSelectors.selectAll);
	}
	/**
	 * @deprecated use the simple get-Method
	 */
	public getAll(loadingStrategy: EvasysLoadingStrategiesEnum): Observable<T[]> {
		return catchFailure(
			this.store.select(this.fromSelectors.selectAll),
			this.store.select(this.fromSelectors.selectError)
		).pipe(
			loadData(
				() => this.store.dispatch(this.fromActions.LoadAllActionSet.DEFAULT({ payload: undefined })),
				() => this.store.select(this.fromSelectors.selectLoading),
				loadingStrategy
			)
		);
	}
	/**
	 * @deprecated use the simple get-Method
	 */
	public getOne(id: string | number, loadingStrategy: EvasysLoadingStrategiesEnum): Observable<T> {
		return catchFailure(
			this.store.select(this.fromSelectors.selectEntity, { id }),
			this.store.select(this.fromSelectors.selectError)
		).pipe(
			loadData(
				() =>
					this.store.dispatch(
						this.fromActions.LoadOneActionSet.DEFAULT({
							payload: id,
						})
					),
				() => this.store.select(this.fromSelectors.selectLoading),
				loadingStrategy,
				false
			),
			map((entity) => entity as T)
		);
	}
	/**
	 * @deprecated use the simple get-Method
	 */
	public getOneWithParams(
		id: string | number,
		params: [string, string | number][],
		loadingStrategy: EvasysLoadingStrategiesEnum
	) {
		return catchFailure(
			this.store.select(this.fromSelectors.selectEntity, { id }),
			this.store.select(this.fromSelectors.selectError)
		).pipe(
			loadData<T>(
				() =>
					this.store.dispatch(
						this.fromActions.LoadOneWithParamsActionSet.DEFAULT({
							payload: {
								id,
								params,
							},
						})
					),
				() => this.store.select(this.fromSelectors.selectLoading),
				loadingStrategy,
				false
			),
			map((entities) => entities as T)
		);
	}
	/**
	 * @deprecated use the simple get-Method
	 */
	public getMany(
		ids: string[] | number[],
		page: number,
		loadingStrategy: EvasysLoadingStrategiesEnum,
		pageSize = -1
	): Observable<T[]> {
		return load<any, T[]>(
			this.actions,
			this.fromActions.LoadManyActionSet,
			this.store,
			this.fromActions.LoadManyActionSet.DEFAULT({
				payload: {
					ids,
					page,
					pageSize,
				},
			}),
			() =>
				this.store.select<T[]>(this.fromSelectors.selectMany, {
					ids,
					page,
					pageSize,
				}),
			loadingStrategy,
			ids.length
		);
	}
	/**
	 * @deprecated use the simple get-Method
	 */
	public getPage(page: number, loadingStrategy: EvasysLoadingStrategiesEnum): Observable<T[]> {
		return this.store.select(this.fromSelectors.selectPage, { page }).pipe(
			loadData(
				() =>
					this.store.dispatch(
						this.fromActions.LoadPageActionSet.DEFAULT({
							payload: page,
						})
					),
				() => this.store.select(this.fromSelectors.selectLoading),
				loadingStrategy
			),
			map((entities) => entities as T[])
		);
	}
	/**
	 * @deprecated use the simple get-Method
	 */
	public getAllUntilPage(page: number, loadingStrategy: EvasysLoadingStrategiesEnum): Observable<T[]> {
		return catchFailure(
			this.store.select(this.fromSelectors.selectEntities),
			this.store.select(this.fromSelectors.selectError)
		).pipe(
			loadData(
				() =>
					this.store.dispatch(
						this.fromActions.LoadPageActionSet.DEFAULT({
							payload: page,
						})
					),
				() => this.store.select(this.fromSelectors.selectLoading),
				loadingStrategy
			),
			map((entities) => (Array.isArray(entities) ? entities : Object.values(entities)))
		);
	}

	public getPageConfigs(): Observable<EvasysPageConfigModel> {
		return this.store.select(this.fromSelectors.selectPageConfig);
	}

	public isLoading(): Observable<boolean> {
		return this.store.select(this.fromSelectors.selectLoading);
	}

	public getError(): Observable<HttpErrorResponse> {
		return this.store.select(this.fromSelectors.selectError);
	}

	public getResponse(): Observable<{
		error: HttpErrorResponse;
		entities: Dictionary<T>;
	}> {
		return this.store.select(this.fromSelectors.selectResponse);
	}

	//#endregion read

	//#region write
	public onlyLoadPage(page: number): void {
		this.store.dispatch(this.fromActions.LoadPageActionSet.DEFAULT({ payload: page }));
	}

	public deleteOne(id: string | number): Promise<void> {
		this.store.dispatch(this.fromActions.DeleteOneActionSet.DEFAULT({ payload: id }));
		return dispatchResult(this.actions, this.fromActions.DeleteOneActionSet);
	}

	public deleteOneLocal(id: string | number): void {
		this.store.dispatch(this.fromActions.DeleteOneLocalActionSet.DEFAULT({ payload: id }));
	}

	public deleteMany(ids: number[] | string[]): Promise<void> {
		this.store.dispatch(this.fromActions.DeleteManyActionSet.DEFAULT({ payload: ids }));
		return dispatchResult(this.actions, this.fromActions.DeleteManyActionSet);
	}

	public createOne(obj: T): Promise<T> {
		this.store.dispatch(this.fromActions.CreateOneActionSet.DEFAULT({ payload: obj }));
		return dispatchResult(this.actions, this.fromActions.CreateOneActionSet);
	}

	public createOneLocal(obj: T): void {
		this.store.dispatch(this.fromActions.CreateOneLocalActionSet.DEFAULT({ payload: obj }));
	}

	public updateOne(update: Update<T>): Promise<T> {
		this.store.dispatch(this.fromActions.EditOneActionSet.DEFAULT({ payload: update }));
		return dispatchResult(this.actions, this.fromActions.EditOneActionSet);
	}

	public updateOneLocal(update: Update<T>) {
		this.store.dispatch(this.fromActions.EditOneLocalActionSet.DEFAULT({ payload: update }));
	}

	public updateMany(updates: Update<T>[]): Promise<T> {
		this.store.dispatch(this.fromActions.EditManyActionSet.DEFAULT({ payload: updates }));
		return dispatchResult(this.actions, this.fromActions.EditManyActionSet);
	}

	public clearAll() {
		this.store.dispatch(this.fromActions.ClearActionSet.DEFAULT({ payload: undefined }));
	}

	//#endregion write
}

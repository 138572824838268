import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicNotificationComponent } from './notification/components/dynamic-notification/dynamic-notification.component';
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthGuard } from './auth/guards/auth.guard';
import { Oauth2Interceptor } from './auth/interceptors/oauth2.interceptor';
import { SharedFeatureConfiguration } from './shared-feature.configuration';
import { SharedCoreModule } from '@evasys/shared/core';
import { SharedUtilModule } from '@evasys/shared/util';
import { SharedUiModule } from '@evasys/shared/ui';

@NgModule({
	imports: [CommonModule, NgbAlertModule, SharedCoreModule, SharedUtilModule, SharedUiModule],
	declarations: [DynamicNotificationComponent],
	exports: [DynamicNotificationComponent],
	providers: [
		AuthGuard,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: Oauth2Interceptor,
			multi: true,
		},
	],
})
export class SharedFeatureModule {
	static forRoot(config: SharedFeatureConfiguration): ModuleWithProviders<any> {
		return {
			ngModule: SharedFeatureModule,
			providers: [
				{
					provide: SharedFeatureConfiguration,
					useValue: config,
				},
			],
		};
	}
}

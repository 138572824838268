import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EvasysNotificationModel } from '@evasys/globals/shared/models/evasys-notification.model';
import { EvasysNotificationActionModel } from '@evasys/globals/shared/models/evasys-notification-action.model';
import { ProductMenuItem, Tabitem } from '@evasys/shared/ui';
import { Observable } from 'rxjs';

@Component({
	selector: 'evasys-topmenu',
	templateUrl: './topmenu.component.html',
})
export class TopmenuComponent {
	//#region Input & Output
	@Input()
	public logo?: string;

	@Input()
	public logoAlt?: string;

	@Input()
	products: ProductMenuItem[];

	@Input()
	public username?: string;

	@Input()
	public role?: string;

	@Input()
	public menuItems: Tabitem<string>[] = [];

	@Input()
	notifications: EvasysNotificationModel[] = [];

	@Input()
	public roleItems: Map<any, string> = new Map<any, string>();

	@Input()
	public canSwitchUser: boolean;

	@Input()
	public showSystemUsage: boolean;

	@Input()
	public searchText: string;

	@Input()
	public system: 'evasys' | 'evaexam';

	@Input()
	public activeSystemUser: number;

	@Input()
	public activeOnlineUser: number;

	@Input()
	productRefreshMethod: () => Promise<void>;

	@Output()
	public menuResponse = new EventEmitter<string>();

	@Output()
	public roleResponse = new EventEmitter<any>();

	@Output()
	public logoutResponse = new EventEmitter<void>();

	@Output()
	public searchAction: EventEmitter<string> = new EventEmitter<string>();

	@Output()
	public helpAction = new EventEmitter<string>();

	@Output()
	public deleteNotificationAction: EventEmitter<string> = new EventEmitter<string>();

	@Output()
	public apiCallAction: EventEmitter<EvasysNotificationActionModel> =
		new EventEmitter<EvasysNotificationActionModel>();

	@Output()
	public openUserSwitch = new EventEmitter<void>();

	@Output()
	public systemUsageCall = new EventEmitter<void>();

	@Output()
	public userMenuOpened = new EventEmitter<void>();

	@Output()
	public logoClickedAction = new EventEmitter<void>();
	//#endregion Input & Output

	//#region Variables
	public isMobileMenuCollapsed = true;

	//#endregion Variables

	//region Events
	onDeleteNotification($event: string) {
		this.deleteNotificationAction.emit($event);
	}

	onApiCallAction($event: EvasysNotificationActionModel) {
		this.apiCallAction.emit($event);
	}
	//endregion

	//region methods
	connected = (result: boolean, currentProduct: ProductMenuItem) => {
		if (result) {
			return result;
		} else {
			return !(currentProduct.url instanceof Observable) && !!currentProduct.url;
		}
	};

	//endregion
}

import { environment } from './environment';
import { Deployment } from './deployment.enum';

export const getDeploymentFromLocation = (): Deployment => {
	if (isLocationSubdomainOf('staging.evainsights.de')) {
		return Deployment.STAGING_DE;
	} else if (isLocationSubdomainOf('dev.evainsights.de')) {
		return Deployment.DEV_DE;
	} else if (isLocationSubdomainOf('evainsights.de')) {
		return Deployment.DE;
	} else if (location.hostname === 'localhost' || isLocationSubdomainOf('localhost')) {
		return Deployment.LOCAL;
	}

	throw Error('Current hostname is unknown. Cannot determine deployment');
};

const isLocationSubdomainOf = (domain: string) => location.hostname.endsWith('.' + domain);

export const runtimeEnvironment = {
	tenantSubdomain:
		location.hostname === 'localhost' ? environment.fallbackTenantSubdomain : location.hostname.split('.')[0],
	get applicationInsightsConnectionString() {
		return environment.applicationInsightsConnectionString?.[getDeploymentFromLocation()] ?? null;
	},
};

<div class="card p-3 my-3 overflow-hidden">
	<div class="row mb-2">
		<button
			[attr.data-cy]="'selectArea-toggle-' + id"
			[class.c-cursor-def]="!canFocus"
			(click)="onClick()"
			class="btn fw-bold text-start col col-lg-5"
		>
			{{ name }}
		</button>
		<p
			*ngIf="input.value?.length > 0"
			[attr.data-cy]="'selectArea-value-' + id"
			[@fadeInLeftAnimation]
			class="border-0 c-lg-border-left-only col-12 col-lg-7 text-primary text-truncate fw-bold mb-0"
		>
			{{ input.value | namesByIds : items | commaSeparated }}
		</p>
	</div>
	<evasys-selector
		*ngIf="focused"
		[id]="'selectArea-' + id"
		[formControl]="input"
		[@yGrowAnimation]
		[allowMultiSelect]="multiple"
		(pageChange)="pageChange.emit($event)"
		(selectionChange)="writeValue($event)"
		class="c-h-200px"
		ngDefaultControl
	>
		<evasys-selector-item *ngFor="let selectItem of selectItems" [value]="selectItem.id">{{
			selectItem.name
		}}</evasys-selector-item>
		<evasys-loading [loading]="loading" [id]="'selectArea-' + id"></evasys-loading>
	</evasys-selector>
</div>

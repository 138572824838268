import { Component, Input } from '@angular/core';
import { Required } from '@evasys/globals/shared/decorators/decorators';

@Component({
	selector: 'evasys-mark',
	templateUrl: './mark.component.html',
})
export class MarkComponent {
	//region Input

	@Input()
	@Required()
	id: string;

	@Input()
	marked: boolean;
	//endregion
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { endpoints } from '@evasys/globals/evainsights/api/endpoints';
import { ReportItem, ReportItemWithoutData } from '@evasys/globals/evainsights/models/report-item';
import { ReportItemPlacementUpdate } from '@evasys/globals/evainsights/models/report/report-item-placement.model';

@Injectable({
	providedIn: 'root',
})
export class ReportItemService {
	constructor(private http: HttpClient) {
		// This is intentional;
	}

	getReportItem(reportItemId: number): Observable<ReportItem> {
		return this.http.get<ReportItem>(endpoints.reportItem.url({ reportItemId }));
	}

	saveNewReportItemsForReport(reportId: number, reportItems: ReportItemWithoutData[]): Observable<ReportItem> {
		return this.http.post<ReportItem>(endpoints.postReportItemsForReport.url({ reportId }), reportItems);
	}

	saveNewReportItemsForReportTemplate(
		reportTemplateId: number,
		reportItems: ReportItemWithoutData[]
	): Observable<ReportItem> {
		return this.http.post<ReportItem>(
			endpoints.postReportItemsForReportTemplate.url({ reportTemplateId }),
			reportItems
		);
	}

	patchReportItemPlacementsForReport(
		reportId: number,
		reportItemPlacements: ReportItemPlacementUpdate[]
	): Observable<HttpResponse<unknown>> {
		return this.http.patch(endpoints.patchReportItemPlacementsForReport.url({ reportId }), reportItemPlacements, {
			observe: 'response',
		});
	}

	patchReportTemplateItemPlacementsForReportTemplate(
		reportTemplateId: number,
		reportItemPlacements: ReportItemPlacementUpdate[]
	): Observable<HttpResponse<unknown>> {
		return this.http.patch(
			endpoints.patchReportTemplateItemPlacementsForReportTemplate.url({ reportTemplateId }),
			reportItemPlacements,
			{
				observe: 'response',
			}
		);
	}

	updateReportItem(reportItem: ReportItemWithoutData): Observable<ReportItem> {
		return this.http.put<ReportItem>(endpoints.reportItem.url({ reportItemId: reportItem.id }), reportItem);
	}

	deleteReportItem(reportItemId: number): Observable<HttpResponse<unknown>> {
		return this.http.delete(endpoints.reportItem.url({ reportItemId: reportItemId }), {
			observe: 'response',
		});
	}
}

import { range, sum } from 'lodash';

export function fitTexts(textLengths: number[], availableWidth: number): number[] {
	// fit texts of given lengths into a container with a certain available width by ellipsizing the texts.
	// Uses a longest-first strategy.
	let overflow = sum(textLengths) - availableWidth;
	if (overflow <= 0) {
		return textLengths;
	}

	const sortOrder = range(0, textLengths.length).sort((i, j) => textLengths[j] - textLengths[i]);

	for (let i = 0; i < textLengths.length; i++) {
		const diff = textLengths[sortOrder[i]] - (i >= textLengths.length - 1 ? 0 : textLengths[sortOrder[i + 1]]);
		const requiredDiff = overflow / (i + 1);

		if (diff === 0) {
			continue;
		}

		if (diff >= requiredDiff) {
			const upper = textLengths[sortOrder[i]] - requiredDiff;
			return textLengths.map((s) => Math.min(s, upper));
		}

		overflow -= diff * (i + 1);
	}

	return textLengths;
}

import { createFeatureSelector } from '@ngrx/store';
import { EvasysState } from '@evasys/shared/core';
import { EvasysStatusModel } from '@evasys/globals/evasys/models/general/evasys-status.model';
import { statusAdapter } from '../reducers/status.reducer';

export const getStatusState = createFeatureSelector<EvasysState<EvasysStatusModel>>('status');

export const {
	selectLoading,
	selectPageConfig,
	selectPage,
	selectLoadedPages,
	selectEntity,
	selectMany,
	selectError,
	selectHasError,
} = statusAdapter.getSelectors(getStatusState);

export const { selectEntities, selectAll, selectIds, selectTotal } = statusAdapter.entity.getSelectors(getStatusState);

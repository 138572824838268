import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'modify',
})
export class ModifyPipe implements PipeTransform {
	transform<T, U, X>(value: T, modification?: (item?: T, arg?: U) => X, arg?: U): undefined | null | X;
	transform<T, U, X>(value: T[], modification?: (item: T, arg?: U) => X, arg?: U): X[];
	transform<T, U, X>(
		value: T | T[],
		modification?: (item?: T | T[], arg?: U) => X,
		arg?: U
	): undefined | null | X | X[] {
		if (value === undefined) {
			return undefined;
		} else if (value === null) {
			return null;
		} else if (Array.isArray(value)) {
			return value.map((item) => modification(item, arg));
		} else {
			return modification(value, arg);
		}
	}
}

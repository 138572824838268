import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { ReportTemplateService } from '@evasys/evainsights/shared/core';
import { reportTemplateActions } from '../actions';
import { ReportTemplate } from '@evasys/globals/evainsights/models/report/report-reportTemplate.model';
import { AbstractReportEffects } from './abstract-report.effects';

@Injectable()
export class ReportTemplateEffects extends AbstractReportEffects<ReportTemplate> {
	constructor(protected readonly actions$: Actions, readonly apiService: ReportTemplateService) {
		super();
	}

	load$ = this.createEffectGetOne(reportTemplateActions.fromReportTemplates.LoadActionSet);
	createOne$ = this.createEffectCreateOne(reportTemplateActions.fromReportTemplates.CreateOneActionSet);
	createOneLocal$ = this.createEffectCreateOneLocal(
		reportTemplateActions.fromReportTemplates.CreateOneLocalActionSet
	);
	editOne$ = this.createEffectEditOne(reportTemplateActions.fromReportTemplates.EditOneActionSet);
	editOneLocal$ = this.createEffectEditOneLocal(reportTemplateActions.fromReportTemplates.EditOneLocalActionSet);
	deleteOneLocal$ = this.createEffectDeleteOneLocal(
		reportTemplateActions.fromReportTemplates.DeleteOneLocalActionSet
	);
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MetaInfoComponent } from './meta-info.component';

@NgModule({
	declarations: [MetaInfoComponent],
	imports: [CommonModule],
	exports: [MetaInfoComponent],
})
export class MetaInfoModule {}

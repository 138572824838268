import { Component, Input } from '@angular/core';
import { SharedUiConfiguration } from '../../../../../shared-ui.configuration';

@Component({
	selector: 'evasys-product-menu',
	templateUrl: './product-menu.component.html',
})
export class ProductMenuComponent {
	@Input()
	refreshMethod?: () => Promise<void>;

	@Input()
	productstranslation?: string;
	@Input()
	switchtotranslation?: string;

	@Input()
	switchlogo?: string;

	@Input()
	renderOnOpen = false;

	isOpen = false;

	private expireTime = 15 * 60 * 1000;
	lastRefresh?: number;

	constructor(public readonly config: SharedUiConfiguration) {}

	onOpenStateChange(isOpen: boolean) {
		if (this.refreshMethod && isOpen && (!this.lastRefresh || Date.now() - this.lastRefresh >= this.expireTime)) {
			this.refreshMethod()
				.then(() => {
					this.lastRefresh = Date.now();
					this.isOpen = isOpen;
				})
				.catch((err) => {
					console.error('Can not refresh the product urls: ', err);
				});
		} else {
			this.isOpen = isOpen;
		}
	}
}

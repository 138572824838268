import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateMultiLangPipe } from './pipe/translate-multi-lang.pipe';
import { ReplaceIfEmptyDirective } from './directives/replace-if-empty.directive';
import { GetLanguageControlPipe } from './pipe/get-control.pipe';
import { ReportLanguageCodePipe } from './pipe/report-language-code.pipe';

@NgModule({
	imports: [CommonModule],
	declarations: [TranslateMultiLangPipe, ReplaceIfEmptyDirective, GetLanguageControlPipe, ReportLanguageCodePipe],
	exports: [TranslateMultiLangPipe, ReplaceIfEmptyDirective, GetLanguageControlPipe, ReportLanguageCodePipe],
})
export class EvainsightsSharedUtilModule {}

import { EvasysActionsFactory } from '../essential/redux/actions/evasys-actions.factory';
import { EvasysTokenModel } from '@evasys/globals/shared/models/general/evasys-token.model';
import { LoginModel } from '@evasys/globals/evasys/models/business/login.model';
import { EvasysRevocationRequestModel } from '@evasys/globals/shared/models/general/evasys-revocation-request.model';

const actionsFactory = new EvasysActionsFactory<EvasysTokenModel>('AUTH');

export const { LoadOneActionSet, ClearActionSet, LoadActionSet } = actionsFactory.createActions();

export const LoginActionSet = actionsFactory.createCustomActionSet<LoginModel, EvasysTokenModel>('Login');
export const LogoutActionSet = actionsFactory.createCustomActionSet<EvasysRevocationRequestModel, void>('Logout');
export const RefreshActionSet = actionsFactory.createCustomActionSet<string, EvasysTokenModel>('Refresh');

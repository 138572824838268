import { InputLogProperties, LogProperties } from '@evasys/globals/evainsights/logging/log-properties.model';
import { formatCode } from '@evasys/globals/evainsights/logging/code.model';
import { consoleLoggerByLevel, Level } from '@evasys/globals/evainsights/logging/level.enum';
import { getErrorCode } from '@evasys/globals/evainsights/logging/coded-error';

export abstract class Logger {
	debug = this.getLevelLogger(Level.DEBUG);
	info = this.getLevelLogger(Level.INFO);
	warn = this.getLevelLogger(Level.WARN);
	error = this.getLevelLogger(Level.ERROR);
	fatal = this.getLevelLogger(Level.FATAL);

	private getLevelLogger(level: Level): LevelLogger {
		return (data, props) => this.log(level, data, props);
	}

	log(level: Level, data: string | Error, props?: InputLogProperties) {
		const preparedProps = this.prepareLogProperties(data, props);

		this.consoleLog(level, data, ...(preparedProps ? [preparedProps] : []));
		this.logPreparedProperties(level, data, preparedProps);
	}

	protected abstract logPreparedProperties(level: Level, data: string | Error, props?: LogProperties): void;

	private prepareLogProperties(data: string | Error, props?: InputLogProperties): LogProperties | undefined {
		const code = props && props.code ? props.code : getErrorCode(data);
		return code === null ? props : { ...props, code: formatCode(code) };
	}

	private consoleLog(level: Level, ...data: any[]) {
		consoleLoggerByLevel[level](...data);
	}
}

export type LevelLogger = (data: string | Error, props?: InputLogProperties) => void;

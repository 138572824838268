import { range } from 'lodash';
import { fullBy } from '@evasys/globals/shared/helper/array';
import { Matrix } from '@evasys/globals/evainsights/models/report-item';

export const zeros = (shape: number[]): Matrix =>
	shape.length === 0 ? 0 : range(shape[0]).map(() => zeros(shape.slice(1)));

export const matrixFullBy = (shape: number[], value: (position: number[]) => number): Matrix => {
	const inner = (restShape: number[], positionPrefix: number[]): Matrix =>
		restShape.length === 0
			? value(positionPrefix)
			: fullBy(restShape[0], (index) => inner(restShape.slice(1), [...positionPrefix, index]));

	return inner(shape, []);
};

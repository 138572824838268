import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ValidationErrorModel } from '@evasys/globals/evasys/models/component/validation-error.model';
import { Subscription } from 'rxjs';
import { Required } from '@evasys/globals/shared/decorators/decorators';

@Component({
	selector: 'evasys-textbox',
	templateUrl: './textbox.component.html',
	styleUrls: ['./textbox.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: TextboxComponent,
			multi: true,
		},
	],
})
export class TextboxComponent implements OnInit, ControlValueAccessor, OnDestroy {
	//region ViewChild
	@ViewChild('inputElement')
	inputElement: ElementRef<HTMLInputElement> | undefined;

	//#endregion ViewChild

	//#region Input & Output
	@Input()
	@Required()
	id: string | undefined;

	@Input()
	additionalText: string | undefined;

	@Input()
	type = 'text';

	@Input()
	readonly = false;

	@Input()
	thinStyle = false;

	@Input()
	set value(value: string) {
		this.labelInside = !value;
		this.input.setValue(value);
	}

	@Input()
	description: string | null = null;

	@Input()
	descriptionAsInnerHTML = false;

	@Input()
	required = false;

	@Input()
	clearButton = false;

	@Input()
	maxLength?: number;

	@Input()
	minLength?: number;

	@Input()
	max?: number;

	@Input()
	min?: number;

	@Input()
	errors: ValidationErrorModel[] = [];

	@Input()
	formControlName?: string;

	@Input()
	set isDisabled(disabled: boolean) {
		this.setDisabledState(disabled);
	}

	@Output()
	focusAction: EventEmitter<void> = new EventEmitter<void>();

	@Output()
	blurAction: EventEmitter<void> = new EventEmitter<void>();

	@Output()
	clearAction: EventEmitter<void> = new EventEmitter<void>();

	//#endregion Input & Output

	//#region Variables
	private focused = false;

	public subscriptions: Subscription[] = [];
	public labelInside = true;
	public _isDisabled = false;
	public input = new FormControl();

	//#endregion Variables

	//#region Getter & Setter
	get height(): string {
		return document.documentElement.style.getPropertyValue('--textbox-height');
	}

	//#endregion Getter & Setter

	//#region Events
	_onChange: any = () => {
		//default
	};
	_onTouched: any = () => {
		//default
	};

	ngOnInit(): void {
		this.subscriptions.push(this.input.valueChanges.subscribe((val) => this.determineLabel(val)));
	}

	ngOnDestroy() {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	onFocus(): void {
		this.focusAction.emit();
		if (!this.readonly) {
			this.labelInside = false;
			this.focused = true;
		}
	}

	onBlur(): void {
		this.focused = false;
		this.determineLabel(this.input.value);
		this.blurAction.emit();
	}

	onInput(): void {
		this._onChange(this.input.value);
	}

	onClear() {
		this.clear();
		this.onInput();
		this.clearAction.emit();
	}

	/**
	 * stop input of 'e' for inputs of type number
	 * @param event
	 */
	keydownAction(event): boolean {
		return !(this.type === 'number' && event.keyCode === 69);
	}
	//#endregion Events

	//#region Methods
	isInvalid(): boolean {
		return this.errors.find((error) => error.trigger) !== undefined;
	}

	clear(): void {
		this.input.setValue('');
	}

	private determineLabel(value: any): void {
		this.labelInside = value === undefined || value === null || (value?.length === 0 && !this.focused);
	}

	//#endregion Methods

	//#region ControlValueAccessor

	registerOnChange(fn: (_: any) => void): void {
		this._onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this._onTouched = fn;
	}

	setDisabledState(isDisabled: boolean): void {
		this._isDisabled = isDisabled;
		isDisabled ? this.input.disable() : this.input.enable();
	}

	writeValue(value: any): void {
		if (value === null) {
			this.input.reset();
		}
		this.input.setValue(value);
	}

	//#endregion ControlValueAccessor
}

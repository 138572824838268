import { UrlService } from '@evasys/shared/core';
import { LanguageService } from '@evasys/evainsights/shared/core';

export function initUrlService(urlService: UrlService) {
	return () => urlService.initialize();
}

export function initLanguage(languageService: LanguageService) {
	return () => {
		languageService.initialize();
	};
}

import { Injectable } from '@angular/core';
import { UserRightsEnum } from '@evasys/globals/shared/enums/business/user-rights.enum';
import { Router } from '@angular/router';
import { LevelEnum } from '@evasys/globals/shared/enums/business/level.enum';
import { AuthService } from '@evasys/shared/core';
import { map } from 'rxjs/operators';
import { firstValueFrom, Observable } from 'rxjs';
import { EvasysSharedFeatureConfiguration } from '../../evasys-shared-feature.configuration';
import { EvasysTokenModel } from '@evasys/globals/shared/models/general/evasys-token.model';
import { KeyValue, Location } from '@angular/common';

@Injectable({
	providedIn: 'root',
})
export class NavigateService {
	private CSRF_ID = 'TOKEN=';

	constructor(
		private readonly router: Router,
		private readonly authService: AuthService,
		private readonly evasysSharedFeatureConfiguration: EvasysSharedFeatureConfiguration,
		private readonly location: Location
	) {}

	public navigateTo(url: string, addToken: boolean = true): Promise<void> {
		return firstValueFrom(this.transformUrlObservable(url, addToken)).then((transformedUrl) => {
			window.location.href = transformedUrl;
		});
	}

	public navigateBack() {
		this.location.back();
	}

	public transformUrlObservable(url: string, addToken: boolean = true): Observable<string> {
		return this.authService.token.pipe(
			map((token) => {
				const target = this.targetURL(url);
				return addToken ? this.addToken(target, token) : target;
			})
		);
	}

	private targetURL(url: string) {
		const multiPageUrl = this.evasysSharedFeatureConfiguration.multiPageUrl;
		const startSlash = !url.startsWith('/') && !multiPageUrl.endsWith('/') ? '/' : '';
		return multiPageUrl + startSlash + url;
	}

	private addToken(url: string, token: EvasysTokenModel) {
		const separator = url.includes('?') ? '&' : '?';
		const csrf = token?.csrf;
		return url + separator + this.CSRF_ID + csrf;
	}

	/**
	 * navigate user to start page
	 *
	 * @param right
	 * @param level
	 */
	public async navigateWithUserRightAndLevel(
		right: UserRightsEnum,
		level: LevelEnum,
		urlParams?: KeyValue<string, string>[]
	) {
		const urlParamsString = urlParams.reduce((urlParamsString, param) => {
			const addChar = urlParamsString.length === 0 ? '' : '&';
			return urlParamsString + addChar + param.key + '=' + param.value;
		}, '');
		if (!this.navigateWithUserRights(right, urlParamsString)) {
			this.navigateWithUserLevel(level, urlParamsString);
		}
	}

	private navigateWithUserLevel(userLevel: LevelEnum, urlParamsString: string) {
		switch (userLevel) {
			case LevelEnum.INSTRUCTOR:
				this.navigateTo(
					'public/userAccess/switchUserAccess/handleAccess?desiredRole=' +
						LevelEnum.INSTRUCTOR +
						'&' +
						urlParamsString
				);
				break;
			case LevelEnum.DEAN_OF_STUDIES:
				this.navigateTo(
					'public/userAccess/switchUserAccess/handleAccess?desiredRole=' +
						LevelEnum.DEAN_OF_STUDIES +
						'&' +
						urlParamsString
				);
				break;
			case LevelEnum.DEAN:
				this.navigateTo(
					'public/userAccess/switchUserAccess/handleAccess?desiredRole=' +
						LevelEnum.DEAN +
						'&' +
						urlParamsString
				);
				break;
		}
	}

	private navigateWithUserRights(userRight: UserRightsEnum, urlParamsString: string) {
		switch (userRight) {
			case UserRightsEnum.ADMINISTRATOR:
			case UserRightsEnum.SUBSECTION_ADMINISTRATOR:
				this.router.navigate(['subunits']);
				break;
			case UserRightsEnum.REPORT_CREATOR:
				this.navigateTo(
					'public/userAccess/switchUserAccess/handleAccess?desiredRole=' +
						LevelEnum.REPORT_CREATOR +
						'&' +
						urlParamsString
				);
				break;
			case UserRightsEnum.WEB_VERIFIER:
				this.navigateTo(
					'public/userAccess/switchUserAccess/handleAccess?desiredRole=' +
						LevelEnum.WEB_VERIFIER +
						'&' +
						urlParamsString
				);
				break;
			case UserRightsEnum.DATA_COLLECTOR:
				this.navigateTo(
					'public/userAccess/switchUserAccess/handleAccess?desiredRole=' +
						LevelEnum.DATA_COLLECTOR +
						'&' +
						urlParamsString
				);
				break;
			case UserRightsEnum.INSTRUCTOR:
				this.navigateTo(
					'public/userAccess/switchUserAccess/handleAccess?desiredRole=' +
						LevelEnum.INSTRUCTOR +
						'&' +
						urlParamsString
				);
				break;
			case UserRightsEnum.DEAN_OF_STUDIES:
				this.navigateTo(
					'public/userAccess/switchUserAccess/handleAccess?desiredRole=' +
						LevelEnum.DEAN_OF_STUDIES +
						'&' +
						urlParamsString
				);
				break;
			case UserRightsEnum.DEAN:
				this.navigateTo(
					'public/userAccess/switchUserAccess/handleAccess?desiredRole=' +
						LevelEnum.DEAN +
						'&' +
						urlParamsString
				);
				break;
			default:
				return false;
		}
		return true;
	}

	public navigateToStartPage() {
		firstValueFrom(this.authService.getCompleteStartPage())
			.then(async (startPage) => {
				//On new world: We want to user angular routing navigation
				if (startPage.includes('/ui/')) {
					firstValueFrom(this.authService.getStartPage())
						.then((startPage) => {
							const route = [startPage.split('/ui/')[1] ?? ''];
							if (this.router.url === '/' + route.join('/')) {
								window.location.reload();
							} else {
								this.router.navigate(route);
							}
						})
						.catch((err) => console.error('Can not navigate to start page (ui): ', err));
				} else {
					this.navigateTo(startPage, false);
				}
			})
			.catch((err) => console.error('Can not navigate to start page: ', err));
	}

	public navigateToExternWebsite(url: string) {
		window.location.href = url;
	}
}

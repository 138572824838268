<ng-template #innerHTMLNote>
	<p [innerHTML]="note"></p>
</ng-template>
<img
	*ngIf="note"
	alt="note"
	[src]="'./assets/images/icons/' + icon + '.svg'"
	[class]="class"
	[ngbPopover]="asInnerHTML ? innerHTMLNote : note"
	[openDelay]="300"
	[closeDelay]="500"
	[placement]="placement"
	[attr.data-cy]="'note-' + icon"
	triggers="mouseenter:mouseleave"
/>

import { firstValueFrom, from, merge, Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { Actions } from '@ngrx/effects';
import { EvasysActionSet } from '@evasys/globals/shared/models/general/evasys-action-set.models';
import { EvasysLoadingStrategiesEnum } from '@evasys/globals/shared/enums/general/evasys-loadingStrategies.enum';
import { Store } from '@ngrx/store';
import { EvasysActionModel } from '@evasys/globals/shared/models/general/evasys-action.model';

export function catchFailure<T>(
	dataObservable: Observable<T>,
	errorObservable: Observable<HttpErrorResponse>
): Observable<T> {
	return merge(dataObservable, errorObservable.pipe(filter((error) => !!error))).pipe(
		tap((emit) => {
			if (emit instanceof Error || emit instanceof HttpErrorResponse) {
				throw emit;
			}
		}),
		filter((emit) => !(emit instanceof Error || emit instanceof HttpErrorResponse)),
		map((emit) => emit as T)
	);
}

/**
 * Gives the ability to get a callback from a ngrx store action dispatch.
 * @param actions: Get actions via constructor injection
 * @param actionSet: Action set which is dispatched
 * @returns a Promise (success => promise resolves, failure => promise rejects)
 */

export function dispatchResult<IN, OUT>(
	actions: Actions,
	actionSet: EvasysActionSet<IN, OUT>,
	defaultAction?: EvasysActionModel
) {
	return firstValueFrom(
		actions.pipe(
			filter(
				(action: EvasysActionModel) =>
					(actionSet.SUCCESS.type === action.type || actionSet.FAILURE.type === action.type) &&
					(defaultAction?.requestId ? defaultAction.requestId === action.requestId : true)
			),
			tap((action) => {
				if ((action as any).error) {
					throw (action as any).error;
				}
				if ((action as any).payload?.error) {
					throw (action as any).payload;
				}
			}),
			map((action) => (action as any).payload)
		)
	);
}

export function load<IN, OUT>(
	actions: Actions,
	actionSet: EvasysActionSet<IN, OUT>,
	store: Store,
	dispatchAction: EvasysActionModel,
	select: () => Observable<any>,
	loadingStrategy: EvasysLoadingStrategiesEnum,
	entityCount?: number,
	pageConfig?: boolean
): Observable<OUT> {
	const apiResult = dispatchResult<IN, OUT>(actions, actionSet, dispatchAction);
	if (loadingStrategy !== EvasysLoadingStrategiesEnum.APIONLY && pageConfig) {
		throw Error('pageConfig is only implemented for APIONLY yet');
	}
	switch (loadingStrategy) {
		case EvasysLoadingStrategiesEnum.APIONLY:
			store.dispatch(dispatchAction);
			return from(apiResult).pipe(map((response) => (pageConfig ? response : response.entities ?? response)));
		case EvasysLoadingStrategiesEnum.APITHENSTATE:
			store.dispatch(dispatchAction);
			return from(apiResult).pipe(
				switchMap((response) =>
					select().pipe(
						map((stateChange) =>
							Array.isArray(stateChange)
								? [
										...stateChange,
										...response.entities.filter(
											(entity) =>
												!stateChange.find((searchEntity) => entity.id === searchEntity.id)
										),
								  ]
								: { ...response, ...stateChange }
						)
					)
				)
			);
		case EvasysLoadingStrategiesEnum.STATEONLY:
			return select();
		case EvasysLoadingStrategiesEnum.STATETHENAPI:
			store.dispatch(dispatchAction);
			return select();
		case EvasysLoadingStrategiesEnum.STATEFALLBACKAPI:
			if (entityCount) {
				return from(
					firstValueFrom(select()).then((entities) => {
						if (entityCount === 1 && (entities === undefined || entities === null)) {
							store.dispatch(dispatchAction);
							return apiResult;
						} else if (entityCount > 1 && (entities?.length ?? 0) < entityCount) {
							store.dispatch(dispatchAction);
							return apiResult.then((response) => response.entities);
						} else {
							return entities;
						}
					})
				);
			} else {
				throw new Error(
					'Can not get entity count: If you use state fallback api, you have to pass an entity count.'
				);
			}
	}
}

<nav
	class="navbar navbar-expand-lg navbar-light w-100 p-0 position-fixed fixed-top c-mh-51px c-z-index-4 eva-topmenu-bg-color d-flex justify-content-between border-bottom"
>
	<div class="d-flex align-items-center order-lg-1 h-100">
		<button
			class="btn btn-link navbar-toggler border-0 mx-2 mx-sm-4 c-w-30px c-h-30px"
			type="button"
			data-toggle="collapse"
			[attr.aria-expanded]="!isMobileMenuCollapsed"
			[attr.data-cy]="'topmenu-button-hamburger'"
			(click)="collapse.toggle()"
		>
			<img
				evasysImage
				[id]="'hamburger-menu'"
				[src]="'./assets/images/icons/menu.svg'"
				[isIcon]="true"
				[class]="'c-h-20px'"
			/>
		</button>
		<div class="h-100"><ng-content select="[fixLeft]"></ng-content></div>
	</div>

	<button class="h-100 border-0 m-0 p-0 align-self-center order-lg-2" (click)="logoClickedAction.emit()">
		<img
			evasysImage
			[src]="logo"
			[id]="'topMenu'"
			[alt]="logoAlt ?? ''"
			[attr.class]="'eva-topmenu-logo-bg-color mt-1 c-w-150px c-h-42px'"
			[isIcon]="true"
			[altTextAsLabel]="true"
		/>
	</button>

	<div class="order-lg-4 h-100"><ng-content select="[fixRight]"></ng-content></div>

	<div
		class="collapse navbar-collapse justify-content-lg-between align-items-end eva-topmenu-bg-color order-lg-3 c-h-lg-100"
		#collapse="ngbCollapse"
		[(ngbCollapse)]="isMobileMenuCollapsed"
	>
		<div class="h-lg-100"><ng-content select="[responsiveStart]"></ng-content></div>
		<div class="ms-lg-4 flex-grow-1 h-lg-100">
			<ng-content select="[menuItems]"></ng-content>
		</div>
		<div class="h-lg-100"><ng-content select="[responsiveEnd]"></ng-content></div>
	</div>
</nav>

<div class="input-group position-relative m-0 flex-nowrap" (click)="inputElement.focus()">
	<div class="input-group-prepend" *ngIf="additionalText">
		<div class="input-group-text py-0 px-1 text-primary bg-light">
			{{ additionalText }}
		</div>
	</div>

	<div class="position-relative flex-fill p-0">
		<input
			#inputElement
			[formControl]="input"
			class="form-control align-self-stretch"
			[ngClass]="
				(description !== null || (clearButton && input.value?.length > 0) ? 'pe-4' : 'pe-1') && thinStyle
					? 'pt-0  pb-0'
					: ''
			"
			[class.pe-4]="clearButton"
			(focus)="onFocus()"
			(blur)="onBlur()"
			(input)="onInput()"
			(keydown)="keydownAction($event)"
			[type]="type"
			[attr.id]="id"
			[readOnly]="readonly"
			[value]="input.value"
			[class.is-invalid]="isInvalid()"
			autocomplete="off"
			[attr.data-cy]="'textbox-' + id"
			[attr.maxlength]="maxLength"
			[attr.minlength]="minLength"
			[attr.max]="max"
			[attr.min]="min"
			[attr.name]="formControlName"
			[evasysNumbersOnly]="type === 'number'"
		/>

		<div
			[style.height.px]="inputElement?.offsetHeight"
			class="form-label-wrapper overflow-hidden c-l-1 c-r-2 form-label-inside d-flex align-items-center ps-1"
			[class.form-label-inside]="labelInside"
		>
			<label
				[attr.data-cy]="'textbox-label-' + id"
				[for]="id"
				class="text-nowrap form-label m-0 position-relative"
			>
				<ng-content></ng-content>
				<span *ngIf="required">*</span>
			</label>
		</div>

		<button
			class="position-absolute c-h-20px c-w-20px c-absolute-top c-absolute-right h-100 btn me-1 mx-0 my-auto p-0"
			(click)="onClear()"
			*ngIf="clearButton && input.value?.length > 0"
		>
			<img
				evasysImage
				[isIcon]="true"
				[src]="'./assets/images/icons/delete2.svg'"
				[class]="'c-w-20px c-h-20px'"
				[alt]="'delete-icon'"
				[id]="'clearTextbox'"
			/>
		</button>
		<evasys-note
			*ngIf="!(input.value?.length > 0 && clearButton) && description"
			[style.height.px]="inputElement?.offsetHeight"
			[note]="description"
			[class]="'position-absolute c-h-20px c-w-20px c-absolute-top c-absolute-right h-100 me-1'"
			[asInnerHTML]="descriptionAsInnerHTML"
		></evasys-note>
	</div>
</div>
<evasys-error *ngIf="isInvalid()" [id]="'textbox-' + id" [errors]="errors" class="col-12"></evasys-error>

import {
	Directive,
	effect,
	EnvironmentInjector,
	inject,
	OnInit,
	runInInjectionContext,
	TemplateRef,
	ViewContainerRef,
} from '@angular/core';
import { DropdownComponent } from '../dropdown/dropdown.component';

@Directive({
	selector: '[evasysDropdownMenu]',
})
export class DropdownMenuDirective implements OnInit {
	//region Variables
	PUFFER_HEIGHT = 70;
	//endregion

	//region ViewChild
	private templateRef = inject(TemplateRef<any>);
	private dropdownComponent = inject(DropdownComponent);
	private viewContainerRef = inject(ViewContainerRef);
	private environmentInjector = inject(EnvironmentInjector);

	//endregion

	//region Events
	ngOnInit() {
		runInInjectionContext(this.environmentInjector, () => {
			effect(this.onOpenStateChanged, { allowSignalWrites: true });
		});
	}

	onOpenStateChanged = () => {
		if (this.dropdownComponent.isOpen()) {
			const embeddedViewRef = this.viewContainerRef.createEmbeddedView(this.templateRef);
			embeddedViewRef.rootNodes[0].setAttribute('evasysDropdownMenu', '');
			embeddedViewRef.rootNodes[0].classList.add('w-max-content', 'overflow-auto', 'minw-100');
			this.determineMenuHeight(embeddedViewRef.rootNodes[0]);
			this.determineMenuWidth(embeddedViewRef.rootNodes[0]);
			this.dropdownComponent.dropdownMenuComponent.set(embeddedViewRef.rootNodes[0]);
		} else {
			this.viewContainerRef.clear();
		}
	};

	determineMenuHeight(node: HTMLElement) {
		// const menuHeight =
		// 	window.innerHeight -
		// 	node.getBoundingClientRect().y -
		// 	node.getBoundingClientRect().height -
		// 	this.PUFFER_HEIGHT;
		// node.style.maxHeight = menuHeight + 'px';
	}

	determineMenuWidth(node: HTMLElement) {
		const menuWidth = window.innerWidth * 0.5;
		node.style.maxWidth = menuWidth + 'px';
	}

	//endregion
}

import { animate, group, query, style, transition, trigger } from '@angular/animations';

export const pageAnimation = () => {
	const direction = 'left';
	const optional = { optional: true };
	return trigger('pageAnimation', [
		transition('* <=> *', [
			query(
				':enter, :leave',
				[
					style({
						position: 'absolute',
						top: 0,
						[direction]: 0,
						width: '100%',
					}),
				],
				optional
			),
			query(':enter', [style({ [direction]: '-100%' })], optional),
			group([
				query(':leave', [animate('600ms ease', style({ [direction]: '100%' }))], optional),
				query(':enter', [animate('600ms ease', style({ [direction]: '0%' }))], optional),
			]),
		]),
	]);
};

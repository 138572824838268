import {
	PATH_VARIABLE_PREFIX,
	replacePathVariables,
	VariableReplacer,
	variableValueReplacer,
	VariableValues,
} from '../api/path-variables';

const PATH_SEPARATOR = '/';

class Path<T extends VariableValues | void = void> {
	constructor(public readonly path: string) {}

	build = (variableValues: T): string => {
		return (
			PATH_SEPARATOR + this.replacePathVariables(variableValueReplacer(variableValues || undefined)).toString()
		);
	};

	match = (otherPath: string, variableValues?: T): boolean => {
		if (otherPath.indexOf('?') !== -1) {
			otherPath = otherPath.substring(0, otherPath.indexOf('?'));
		}
		const thisPath = variableValues === undefined ? PATH_SEPARATOR + this.path : this.build(variableValues);
		const otherParts = otherPath.split(PATH_SEPARATOR);
		const parts = thisPath.split(PATH_SEPARATOR);
		return (
			parts.length === otherParts.length &&
			parts.every((part, index) => part.startsWith(PATH_VARIABLE_PREFIX) || part === otherParts[index])
		);
	};

	replacePathVariables = (replacer: VariableReplacer): string => {
		return replacePathVariables(this.path, replacer);
	};
}

enum PathEnum {
	DEFAULT = '',
	REPORT = 'dashboard/reports/:reportId',
	REPORT_READER = 'reader/:reportId',

	REPORTS = 'dashboard/reports',
	REPORT_CREATOR = 'dashboard/reports-creator',
	REPORT_CREATOR_REPORT = 'dashboard/reports-creator/:reportId',
	REPORT_CREATOR_REPORT_ITEM = 'dashboard/reports-creator/:reportId/items',
	REPORT_CREATOR_REPORT_ITEMS_BATCH_CREATOR = 'dashboard/reports-creator/:reportId/items-batch',
	REPORT_CREATOR_REPORT_ITEM_CREATOR = 'dashboard/reports-creator/:reportId/items/:reportItemId',

	REPORT_TEMPLATE = 'dashboard/report-templates/:reportTemplateId',
	REPORT_TEMPLATES = 'dashboard/report-templates',
	REPORT_TEMPLATE_CREATOR = 'dashboard/report-templates-creator',
	REPORT_TEMPLATE_CREATOR_REPORT_TEMPLATE = 'dashboard/report-templates-creator/:reportTemplateId',
	REPORT_TEMPLATE_CREATOR_REPORT_TEMPLATE_ITEM = 'dashboard/report-templates-creator/:reportId/items',
	REPORT_TEMPLATE_CREATOR_REPORT_TEMPLATE_ITEMS_BATCH_CREATOR = 'dashboard/report-templates-creator/:reportId/items-batch',
	REPORT_TEMPLATE_CREATOR_REPORT_TEMPLATE_ITEM_CREATOR = 'dashboard/report-templates-creator/:reportId/items/:reportItemId',

	TEXT_ANALYSIS = 'dashboard/text-analysis',
	TEXT_ANALYSIS_CREATOR = 'create',
	TEXT_ANALYSIS_CREATOR_TEXT_ANALYSIS = ':topicModelId/edit',

	TOPIC = 'dashboard/text-analysis/:topicModelId/edit/topics',
	TOPIC_CREATOR_TOPIC = ':topicId/edit',

	FORM_SELECTOR = 'dashboard/form-selector',
	SURVEY_SELECTOR = 'dashboard/survey-selector',
	SETTINGS = 'settings',
	HEALTH = 'health',
	CATCH_REST = '**',
}

export const paths = {
	getDefault: new Path(PathEnum.DEFAULT),
	getReport: new Path<{ reportId: number }>(PathEnum.REPORT),
	getReportReader: new Path<{ reportId: string }>(PathEnum.REPORT_READER),

	getReports: new Path(PathEnum.REPORTS),
	getReportCreator: new Path(PathEnum.REPORT_CREATOR),
	getReportCreatorReport: new Path<{ reportId: number }>(PathEnum.REPORT_CREATOR_REPORT),
	getReportCreatorReportItem: new Path<{ reportId: number }>(PathEnum.REPORT_CREATOR_REPORT_ITEM),
	getReportCreatorReportItemsBatchCreator: new Path<{ reportId: number }>(
		PathEnum.REPORT_CREATOR_REPORT_ITEMS_BATCH_CREATOR
	),
	getReportCreatorReportItemCreator: new Path<{ reportId: number; reportItemId: number | string }>(
		PathEnum.REPORT_CREATOR_REPORT_ITEM_CREATOR
	),

	getReportTemplate: new Path<{ reportTemplateId: number }>(PathEnum.REPORT_TEMPLATE),
	getReportTemplates: new Path(PathEnum.REPORT_TEMPLATES),
	getReportTemplateCreator: new Path(PathEnum.REPORT_TEMPLATE_CREATOR),
	getReportTemplateCreatorReportTemplate: new Path<{ reportTemplateId: number }>(
		PathEnum.REPORT_TEMPLATE_CREATOR_REPORT_TEMPLATE
	),
	getReportTemplateCreatorReportTemplateItem: new Path<{ reportId: number }>(
		PathEnum.REPORT_TEMPLATE_CREATOR_REPORT_TEMPLATE_ITEM
	),
	getReportTemplateCreatorReportTemplateItemsBatchCreator: new Path<{ reportId: number }>(
		PathEnum.REPORT_TEMPLATE_CREATOR_REPORT_TEMPLATE_ITEMS_BATCH_CREATOR
	),
	getReportTemplateCreatorReportTemplateItemCreator: new Path<{ reportId: number; reportItemId: number | string }>(
		PathEnum.REPORT_TEMPLATE_CREATOR_REPORT_TEMPLATE_ITEM_CREATOR
	),

	getTextAnalysis: new Path(PathEnum.TEXT_ANALYSIS),
	getTextAnalysisCreator: new Path(PathEnum.TEXT_ANALYSIS_CREATOR),
	getTextAnalysisCreatorTextAnalysis: new Path<{ topicModelId: number }>(
		PathEnum.TEXT_ANALYSIS_CREATOR_TEXT_ANALYSIS
	),

	getTopic: new Path<{ topicModelId: number }>(PathEnum.TOPIC),
	getTopicCreatorTopic: new Path<{ topicId: string }>(PathEnum.TOPIC_CREATOR_TOPIC),

	getFormSelector: new Path(PathEnum.FORM_SELECTOR),
	getSurveySelector: new Path(PathEnum.SURVEY_SELECTOR),
	getSettings: new Path(PathEnum.SETTINGS),
	getHealth: new Path(PathEnum.HEALTH),
	getCatchRest: new Path(PathEnum.CATCH_REST),
};

export enum ExternalLink {
	EVASYS_DE = 'https://www.evasys.de',
	EVASYS_EN = 'https://www.evasys.de/en',
}

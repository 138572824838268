import { Injectable } from '@angular/core';
import { EvasysState, FacadeService } from '@evasys/shared/core';
import { Store } from '@ngrx/store';
import { reportTemplateActions } from '../actions';
import { reportTemplateSelectors } from '../selectors';
import { ReportTemplate } from '@evasys/globals/evainsights/models/report/report-reportTemplate.model';
import { Actions } from '@ngrx/effects';

@Injectable({
	providedIn: 'root',
})
export class ReportTemplateFacadeService extends FacadeService<ReportTemplate> {
	constructor(
		private readonly reportTemplateStore: Store<EvasysState<ReportTemplate>>,
		protected override readonly actions: Actions
	) {
		super(
			reportTemplateStore,
			reportTemplateActions.fromReportTemplates,
			reportTemplateSelectors.fromReportTemplates,
			actions
		);
	}
}

import { BehaviorSubject } from 'rxjs';

export interface LocalStorageSubjectOptions {
	id?: string;
	sync?: boolean;
}

export class LocalStorageSubject<T> extends BehaviorSubject<T> {
	private static readonly LOCAL_STORAGE_TOKEN = 'localStorageSubject';
	private localStorageId: string;
	private _sync: boolean;

	set sync(sync: boolean) {
		this._sync = sync;
	}

	constructor(value: T, options?: LocalStorageSubjectOptions) {
		if (options?.id) {
			const localStorageId = `${LocalStorageSubject.LOCAL_STORAGE_TOKEN}-${options.id}`;
			const localStorageValue = JSON.parse(localStorage.getItem(localStorageId));
			super(localStorageValue !== null ? localStorageValue : value);
			this.localStorageId = localStorageId;
		} else {
			super(value);
		}
		this._sync = options?.sync !== undefined ? options?.sync : true;
	}
	override next(value: T) {
		if (this.localStorageId && this._sync) {
			localStorage.setItem(this.localStorageId, JSON.stringify(value));
		}
		super.next(value);
	}

	public init(id: string) {
		this.localStorageId = `${LocalStorageSubject.LOCAL_STORAGE_TOKEN}-${id}`;
		const localStorageValue = JSON.parse(localStorage.getItem(this.localStorageId));
		if (localStorageValue !== null) {
			super.next(localStorageValue);
		} else if (this._sync) {
			localStorage.setItem(this.localStorageId, JSON.stringify(super.value));
		}
	}

	public clear() {
		if (this.localStorageId) {
			localStorage.removeItem(this.localStorageId);
		}
	}
}

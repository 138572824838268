import { SelectorItemModel } from '@evasys/globals/evasys/models/component/order-selector-item.model';

export function isSelectorItem(object: any): object is SelectorItemModel {
	return (
		typeof object === 'object' &&
		'name' in object &&
		typeof object.name === 'string' &&
		(!('id' in object) || object.id == null || typeof object.id === 'string' || typeof object.id === 'number')
	);
}

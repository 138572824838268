export class Counter {
	private count: number;

	constructor() {
		this.count = 0;
	}

	get Count(): number {
		return this.count;
	}

	countUp(increment: number = 1): void {
		this.count = this.count + increment;
	}

	countDown(decrement: number = 1): void {
		this.count = this.count - decrement;
	}
}

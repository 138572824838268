import { Matrix } from '@evasys/globals/evainsights/models/report-item';

export const matrixShape = (matrix: Matrix): number[] =>
	typeof matrix === 'number' ? [] : [matrix.length, ...matrixShape(matrix[0])];

export const getMatrixNdims = (matrix: Matrix): number =>
	typeof matrix === 'number' ? 0 : 1 + getMatrixNdims(matrix[0]);

export function isMatrixNdimensional<N extends number>(matrix: Matrix, num: N): matrix is Matrix<N> {
	return getMatrixNdims(matrix) === num;
}

import { TypeaheadIdentifierValue, TypeaheadItemIdentifier } from '../models/component/typeahead/typeahead.model';

export function getTypeaheadIdentifierValue<T>(
	item: T,
	itemIdentifier: TypeaheadItemIdentifier<T>
): TypeaheadIdentifierValue {
	if (typeof itemIdentifier === 'function') {
		return itemIdentifier(item);
	} else {
		return item[itemIdentifier] as TypeaheadIdentifierValue;
	}
}

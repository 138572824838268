import { ReportItemContentBase } from '../common';
import {
	AxisChartConfig,
	AxisChartDataBase,
	AxisChartIndexDimension,
	AxisChartVisualDimension,
	ConditionalEvidence,
	CountAxisChartConfig,
	ResponseCountAxisChartData,
} from './axis-chart-report-item-content.model';
import { VisualizationType } from '../../../../constants/types';
import { DimensionMappingConfig } from './dimensions';
import { ReportMultiLang } from '../../../common/multi-lang';
import { Matrix } from './matrix';

export type LineChartContent = CountLineChartContent | CentralTendencyLineChartContent;

// Count Line Charts

export type CountLineChartContent = ReportItemContentBase<CountLineChartConfig, CountLineChartData>;

export interface CountLineChartConfig extends CountAxisChartConfig {
	visualizationType: VisualizationType.LINE_CHART;
	dimensionMappings: Array<LineChartDimensionMapping>;
	aggregation: ResponseCountAggregation;
}

export type CountLineChartData = ResponseCountAxisChartData;

// Central Tendency Aggregated Line Charts

export type CentralTendencyLineChartContent = ReportItemContentBase<
	CentralTendencyLineChartConfig,
	CentralTendencyLineChartData
>;

export interface CentralTendencyLineChartConfig extends AxisChartConfig {
	visualizationType: VisualizationType.LINE_CHART;
	dimensionMappings: Array<LineChartDimensionMapping>;
	aggregation: CentralTendencyAggregation;
}

export interface CentralTendencyLineChartData extends AxisChartDataBase {
	centralTendency: Matrix;
	conditionalEvidences: ConditionalEvidence[];

	statistics: {
		numElements: number;
	};
}

// Dimension mappings

export type LineChartDimensionMapping = DimensionMappingConfig<LineChartIndexDimension, LineChartVisualDimension>;
export type LineChartIndexDimension = AxisChartIndexDimension;
export type LineChartVisualDimension = AxisChartVisualDimension;

// Aggregation Config

export enum AggregationType {
	RESPONSE_COUNT = 'RESPONSE_COUNT',
	CENTRAL_TENDENCY = 'CENTRAL_TENDENCY',
}

export interface ResponseCountAggregation {
	type: AggregationType.RESPONSE_COUNT;
}

export enum CentralTendencyMetric {
	MEAN = 'MEAN',
	MEDIAN = 'MEDIAN',
}

export interface CentralTendencyAggregation {
	type: AggregationType.CENTRAL_TENDENCY;
	metric: CentralTendencyMetric;
	sources: Array<{
		name: ReportMultiLang<string>;
		itemOptionIds: number[];
	}>;
	// temporary solution to reverse the value axis order without having to do another backend request
	reverseSources: boolean;
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InPipe } from './pipe/in.pipe';

@NgModule({
	declarations: [InPipe],
	imports: [CommonModule],
	exports: [InPipe],
})
export class InModule {}

export const PATH_VARIABLE_PREFIX = ':';
export type VariableReplacer = (variable: string) => string;

export const replaceUrlPathVariables = (url: URL, replacer: VariableReplacer): URL => {
	const result = new URL(url);
	result.pathname = replacePathVariables(url.pathname, replacer);
	return result;
};
export const replacePathVariables = (path: string, replacer: VariableReplacer): string => {
	return path
		.split('/')
		.map((segment) => (isSegmentAVariable(segment) ? replacer(getSegmentVariableName(segment)) : segment))
		.join('/');
};

const isSegmentAVariable = (segment: string): boolean => segment.startsWith(PATH_VARIABLE_PREFIX);
const getSegmentVariableName = (segment: string): string => segment.slice(PATH_VARIABLE_PREFIX.length);

export type VariableValues = { [variable: string]: string | number };

export const variableValueReplacer = (values?: VariableValues): VariableReplacer => {
	return (variable: string) => {
		if (!values || !values.hasOwnProperty(variable)) {
			throw Error(`Missing value for path variable ${variable}`);
		}
		return values[variable].toString();
	};
};

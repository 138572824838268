/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
	AxisChart,
	AxisSpecification,
	ChartDatum,
	ColorDimensionSpecification,
	DimensionIndex,
	Labels,
	NumberValueFormatterTarget,
	PreparedSegmentStatistics,
} from './axis';
import { RenderConfig } from '../components/widgets/d3-base/d3-base.component';
import { group, line, range, ScaleBand, scaleBand, ScaleLinear, scaleLinear, select } from 'd3';
import { translateReportMultiLangString } from '@evasys/evainsights/shared/util';
import { avoid, AvoidDirection, isBounds, isMatrixNdimensional, matrixMax } from './util';
import { dataFontSize, roundingTolerance } from '@evasys/globals/evainsights/helper/charts/defaults';
import {
	AggregationType,
	CentralTendencyLineChartContent,
	LineChartContent,
	LineChartVisualDimension,
	Matrix,
	VisualizationDimensionType,
} from '@evasys/globals/evainsights/models/report-item';
import { assertNotNullish, getPropertyNotNullishGuard } from '@evasys/globals/evainsights/typeguards/common';
import { full } from '@evasys/globals/shared/helper/array';

export class LineChart extends AxisChart<LineChartContent> {
	constructor(host: HTMLElement, ctx: CanvasRenderingContext2D, content: LineChartContent) {
		super(host, ctx, content, lineVisualDimensions);
	}

	// prettier-ignore
	render({ size, reportLanguageId, decimalFormat }: RenderConfig) {
		const dimensionMapping = this.getDimensionMapping();
		const {labels: indexLabels, domains: indexDomains} = this.getIndexDimensionDomainsAndLabels(reportLanguageId);
		const visualLabels = this.mapIndexToVisual(indexLabels, dimensionMapping, undefined);
		assertNotNullish(visualLabels[VisualizationDimensionType.GROUP]);
		const visualDomains = this.mapIndexToVisual(indexDomains, dimensionMapping, [0]);
		const color: ColorDimensionSpecification = {
			scale: this.getColorScale(visualDomains[VisualizationDimensionType.COLOR]),
			labels: visualLabels[VisualizationDimensionType.COLOR],
		}

		const segmentStatistics = this.getPreparedSegmentStatistics();
		const textualStatisticsSegments = segmentStatistics.filter(getPropertyNotNullishGuard('textual'));

		const svg = this.createSvg({size});
		const isValueAxisNumeric = !isCentralTendencyLineChartContent(this.content);
		const textualStatisticsSize = this.hasTextualStatistics ? 0 : undefined;
		const frame = this.buildFrame({svg, size, reportLanguageId, color, isValueAxisNumeric, visualStatisticsSize: undefined, textualStatisticsSize });

		const {index} = this.getAxesSpecifications({size, body: frame.body});
		const {indexScale, indexAxis} = this.buildIndexAxisScale({index, labels: visualLabels[VisualizationDimensionType.GROUP]});

		if (this.hasTextualStatistics) {
			assertNotNullish(frame.textualStatistics);
			const statsContainer = svg.append('g').datum(textualStatisticsSegments);
			const statsBbox = this.drawVerticalTextualStatistics(statsContainer, {index, groupScale: indexScale, top: frame.textualStatistics.top}, decimalFormat);
			frame.config.textualStatisticsSize = statsBbox.height;
		}

		this.placeFrameElements({svg, frame});
		const {value} = this.getAxesSpecifications({size, body: frame.body});
		const axisSpecifications = { index, value };
		const {valueScale, valueAxis, valueFormatter} = this.buildValueAxisScale({value, reportLanguageId, decimalFormat});

		this.drawAxes({svg, frame, axisSpecifications, indexAxis, valueAxis});

		const lineData = this.getChartData(indexDomains);

		const dataContent = svg.append('g');
		const lines = dataContent.append('g');
		// draw lines with circles at each data point
		lines.selectAll('g')
			.data(group(lineData, (d) => d.visual[VisualizationDimensionType.COLOR]))
			.join('g')
			.call(linesGroup => {
				linesGroup.append('path')
					.attr('d', ([, lineData]) => line()(this.buildPath(lineData, indexScale, valueScale)))
					.attr('data-cy', d => 'lineChart-line-'+d[0])
					.attr('stroke', ([lineColor]) => color.scale(lineColor))
					.attr('fill', 'none')
					.attr('stroke-width', '2px');
			})
			.call((linesGroup) => {
				linesGroup
					.attr('stroke', ([lineColor]) =>  color.scale(lineColor))
					.attr('stroke-width', '2px')
					.selectAll('circle')
					.data(([, lineData]) => lineData)
					.join('circle')
					.attr('data-cy', d => 'lineChart-dots-dot-'+d.index)
					.attr('fill', 'white')
					.attr('r', 2)
					.attr('cx', d => indexScale(d.visual[VisualizationDimensionType.GROUP])! + indexScale.bandwidth() / 2)
					.attr('cy', d => valueScale(d.value))
		});

		// draw the text labels for each line
		dataContent.append('g')
			.selectAll('g')
			.data(group(lineData, (d) => d.visual[VisualizationDimensionType.GROUP]))
			.join('g')
			.call(lineLabelGroup => lineLabelGroup
				.selectAll('text')
				.data(([, indexData]) => indexData)
				.join('text')
					.attr('data-cy', d => 'lineChart-dots-dot-label-'+d.index)
					.attr('fill', (datum) => color.scale(datum.visual[VisualizationDimensionType.COLOR]))
					.attr('stroke', 'white')
					.attr('stroke-width', 4)
					.attr('text-anchor', 'middle')
					.attr('x', (d) => indexScale(d.visual[VisualizationDimensionType.GROUP])! + indexScale.bandwidth() / 2)
					.attr('y', (d) => valueScale(d.value) - 10)
					.attr('paint-order','stroke')
					.attr('font-size', dataFontSize)
					.attr('font-weight', 'bold')
					.text((d) => valueFormatter(d.value))
			).each(function () {
				const bounds = valueScale.range().reverse();
				if (isBounds(bounds)) {
					avoid(select(this).selectAll<SVGTextElement, null>('text'), {bounds, direction: AvoidDirection.VERTICAL});
				}
			});
	}

	get isVertical(): boolean {
		return true;
	}

	private buildPath(
		data: LineDatum[],
		indexScale: ScaleBand<number>,
		valueScale: ScaleLinear<number, number>
	): [number, number][] {
		return [...data]
			.sort((a, b) => a.visual[VisualizationDimensionType.GROUP] - b.visual[VisualizationDimensionType.GROUP])
			.map((datum) => [
				indexScale(datum.visual[VisualizationDimensionType.GROUP])! + indexScale.bandwidth() / 2,
				valueScale(datum.value),
			]);
	}

	protected override getValues(): Matrix {
		if (isCentralTendencyLineChartContent(this.content)) {
			return this.content.data.centralTendency;
		} else {
			return this.content.data.responseCounts;
		}
	}

	protected getNormalizationFn(): (value: number, index: DimensionIndex[]) => number {
		if (isCentralTendencyLineChartContent(this.content)) {
			return this.getNoNormalizationFn();
		} else {
			return this.getCountNormalizationFn(
				this.content.config.axes.value.normalization?.evidenceDimensionIndices,
				this.content.data.conditionalEvidences
			);
		}
	}

	protected get isNormalized(): boolean {
		if (isCentralTendencyLineChartContent(this.content)) return false;
		return this.content.config.axes.value.normalization !== null;
	}

	private buildIndexAxisScale({ index, labels }: { index: AxisSpecification; labels: Labels }) {
		const indexScale = scaleBand(range(labels.length), index.range).padding(0.1);
		const indexAxis = () => index.axis.painter(indexScale).tickFormat((i) => labels[i]);
		return { indexScale, indexAxis };
	}

	private buildValueAxisScale({
		value,
		reportLanguageId,
		decimalFormat,
	}: {
		value: AxisSpecification;
		reportLanguageId: number;
		decimalFormat: string;
	}) {
		if (isCentralTendencyLineChartContent(this.content)) {
			const aggregationConfig = this.content.config.aggregation;
			const scalaLength = aggregationConfig.sources.length;

			const domain = aggregationConfig.reverseSources ? [scalaLength - 1, 0] : [0, scalaLength - 1];
			const valueScale = scaleLinear(domain, value.range);
			const valueAxis = () =>
				value.axis
					.painter(valueScale)
					.tickValues(range(scalaLength))
					.tickFormat((i) =>
						translateReportMultiLangString(aggregationConfig.sources[i.valueOf()].name, reportLanguageId)
					);
			const valueFormatter = () => null; // do not show point texts

			return { valueScale, valueAxis, valueFormatter };
		} else {
			const maxValue = !this.content.config.axes.value.normalization
				? matrixMax(this.content.data.responseCounts) * 1.15
				: 1;

			const valueNice = this.determineNice(value);
			const valueScale = scaleLinear([0, Math.max(0, maxValue - roundingTolerance)], value.range).nice(valueNice);
			const valueAxisTickFormatter = this.getNumberValueFormatter(NumberValueFormatterTarget.AXIS, decimalFormat);
			const valueAxis = () => value.axis.painter(valueScale).ticks(valueNice).tickFormat(valueAxisTickFormatter);

			const valueFormatter = this.getNumberValueFormatter(
				NumberValueFormatterTarget.POINT,
				decimalFormat,
				'hideZeroCounts' in this.content.config && this.content.config.hideZeroCounts
			);
			return { valueScale, valueAxis, valueFormatter };
		}
	}

	protected override getPreparedSegmentStatistics(): PreparedSegmentStatistics[] {
		if (isCentralTendencyLineChartContent(this.content)) {
			const globalEvidence = this.content.data.conditionalEvidences.find(
				(ev) => ev.evidenceDimensionIndices.length === 0
			);
			assertNotNullish(globalEvidence);
			if (!isMatrixNdimensional(globalEvidence.evidence, 0)) {
				throw Error('Expected global evidence to be a 0-dimensional matrix');
			}

			return [
				{
					position: full(this.content.config.dimensionMappings.length, null),
					textual: {
						abstentions: 0,
						evidence: globalEvidence.evidence,
						values: { sampleSize: this.content.data.statistics.numElements },
					},
				},
			];
		} else {
			return super.getPreparedSegmentStatistics(this.content);
		}
	}
}

const lineVisualDimensions: Array<LineChartVisualDimension['type']> = [
	VisualizationDimensionType.COLOR,
	VisualizationDimensionType.GROUP,
	VisualizationDimensionType.PATTERN,
	VisualizationDimensionType.COLOR,
	VisualizationDimensionType.COLUMN,
];

const isCentralTendencyLineChartContent = (content: LineChartContent): content is CentralTendencyLineChartContent => {
	return content.config.aggregation.type === AggregationType.CENTRAL_TENDENCY;
};

type LineDatum = ChartDatum<LineChartVisualDimension['type']>;

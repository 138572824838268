import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StatusCardComponent } from './status-card.component';
import { LoadingOverlayModule } from '../loading-overlay/loading-overlay.module';

@NgModule({
	declarations: [StatusCardComponent],
	imports: [CommonModule, LoadingOverlayModule],
	exports: [StatusCardComponent],
})
export class StatusCardModule {}

import { inject, Injectable, signal } from '@angular/core';
import { ReportLanguage } from '@evasys/globals/evainsights/models/report/report-reportTemplate.model';
import { TranslocoService } from '@ngneat/transloco';
import { isEqual } from '@evasys/globals/shared/helper/object';
import { UiConfigService } from '../common/ui-config.service';
import { getUserLanguageCode } from '@evasys/evainsights/shared/util';

@Injectable({
	providedIn: 'root',
})
export class ReportLanguageService {
	//region Injections
	uiConfigService = inject(UiConfigService);
	private readonly translocoService = inject(TranslocoService);
	//endregion

	//region variables
	private readonly _activeReportLanguage = signal<ReportLanguage | undefined>(undefined, { equal: isEqual });

	public readonly activeReportLanguage = this._activeReportLanguage.asReadonly();
	//endregion

	//region methods
	changeActiveReportLanguage(reportLanguage: ReportLanguage) {
		this._activeReportLanguage.set(reportLanguage);
	}

	getPreferredReportLanguage(availableReportLanguages: ReportLanguage[]) {
		return this.findClosestReportLanguage(this.activeReportLanguage(), availableReportLanguages);
	}

	findClosestReportLanguage(target: ReportLanguage | undefined, availableReportLanguages: ReportLanguage[]) {
		// if the target language is available, use that one
		const equalAvailable = availableReportLanguages.find((av) => isEqual(av, target));
		if (equalAvailable !== undefined) {
			return equalAvailable;
		}

		// The report language id is shared between all reports, e.g. 1 for german and 2 for english.
		// Check if there is some report language available with the same ID and thus same UI language.
		const sameIdAvailable = availableReportLanguages.find((av) => av.id === target?.id);
		if (sameIdAvailable !== undefined) {
			return sameIdAvailable;
		}

		// Alternatively try to fall back to the users current UI language.
		const userLanguage = getUserLanguageCode(this.translocoService.getActiveLang());
		const currentUiLanguage = availableReportLanguages.find((av) => av.primaryUserLanguage === userLanguage);
		if (currentUiLanguage !== undefined) {
			return currentUiLanguage;
		}

		// Or use some other arbitrary report language.
		return availableReportLanguages[0];
	}

	//endregion
}

import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from './modules/translate.module';
import { ConfigsModule } from './modules/config.module';
import { AuthModule } from './modules/auth.module';
import { AuthUserModule } from './modules/auth-user.module';
import { SharedCoreConfiguration } from './shared-core.configuration';
import { NotificationModule } from './modules/notification.module';

@NgModule({
	imports: [
		CommonModule,
		RouterModule,

		AuthUserModule,
		ConfigsModule,
		TranslateModule,
		AuthModule,
		NotificationModule,
	],
})
export class SharedCoreModule {
	static forRoot(config: SharedCoreConfiguration): ModuleWithProviders<any> {
		return {
			ngModule: SharedCoreModule,
			providers: [
				{
					provide: SharedCoreConfiguration,
					useValue: config,
				},
			],
		};
	}
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IfContentDirective } from './if-content/if-content.directive';

@NgModule({
	declarations: [IfContentDirective],
	imports: [CommonModule],
	exports: [IfContentDirective],
})
export class IfContentModule {}

import { Directive, EventEmitter, HostListener, Input, OnDestroy, Output } from '@angular/core';
import { isObservable, Observable, Subscription } from 'rxjs';
import { isPromise } from 'rxjs/internal/util/isPromise';

@Directive({
	selector: '[evasysRegulationClick]',
})
export class RegulationClickDirective<T, U> implements OnDestroy {
	//@region Input & Output
	@Input()
	asyncClickCallbackFunction: (mouseEvent?: MouseEvent) => Promise<T> | Observable<T>;

	@Input()
	clickTimeoutMilliseconds = 500;

	@Input()
	ignoreClicks = false;

	@Output()
	evasysRegulationClick: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

	@Output()
	callbackSuccessfully = new EventEmitter<T>();

	@Output()
	callbackFailed = new EventEmitter<U>();
	//endregion

	//region Variables
	private clickTimestamp: number;
	private subscription: Subscription;
	//endregion

	//region Events
	@HostListener('click', ['$event'])
	onClick(mouseEvent: MouseEvent) {
		const currentTimestamp = Date.now();
		if (
			(currentTimestamp - this.clickTimeoutMilliseconds >= this.clickTimestamp || !this.clickTimestamp) &&
			!this.ignoreClicks
		) {
			this.clickTimestamp = currentTimestamp;
			if (this.asyncClickCallbackFunction) {
				this.handleCallback(mouseEvent);
			} else {
				this.evasysRegulationClick.emit(mouseEvent);
			}
		}
	}

	ngOnDestroy() {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}
	//endregion

	//region Methods
	private handleCallback(mouseEvent: MouseEvent) {
		this.ignoreClicks = true;
		const callback = this.asyncClickCallbackFunction(mouseEvent);
		if (isObservable(callback)) {
			if (this.subscription) {
				this.subscription.unsubscribe();
			}
			this.subscription = callback.subscribe({
				next: (data: T) => {
					this.ignoreClicks = false;
					this.subscription.unsubscribe();
					this.callbackSuccessfully.emit(data);
				},
				error: (error: U) => {
					this.ignoreClicks = false;
					this.subscription.unsubscribe();
					this.callbackFailed.emit(error);
				},
			});
		} else if (isPromise(callback)) {
			callback
				.then((data: T) => {
					this.callbackSuccessfully.emit(data);
				})
				.catch((error: U) => {
					this.callbackFailed.emit(error);
				})
				.finally(() => (this.ignoreClicks = false));
		}
	}
	//endregion
}

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { BaseType, path, ScaleLinear, Selection } from 'd3';

export interface VisualizationStatistics {
	arithmetic?: ArithmeticVisualizationStatistics;
	quantile?: QuantileVisualizationStatistics;
}

export interface ArithmeticVisualizationStatistics {
	mean: number;
	standardDeviation?: number;
}

export interface QuantileVisualizationStatistics {
	median?: number;
	extrema?: {
		min: number;
		max: number;
	};
	range?: {
		lower: number;
		upper: number;
	};
}

/**
 * One single line of a statistics visualization.
 * Its properties specify which visual elements like the `medianArrow` should be displayed on that line and at which position.
 * A track without any elements does not render anything to the chart.
 */
export interface StatisticsTrack {
	whisker?: Range;
	meanBar?: number;
	medianArrow?: number;
	quantileBox?: {
		median?: number;
		range: Range;
	};
}

/**
 * Represents the size of a statistics track orthogonal to the tracks placement direction, relative to the tracks visual center.
 * The visual center is where the horizontal whiskers line would be.
 * The top and bottom values denote how many pixel above and below that line the track extends (including the line if enabled).
 * A median arrow sits above the whiskers line and might thus increase the track's size only in the top direction.
 * The quantile box, in contrast, extends symmetrically in both directions and might thus increase both the top and bottom values.
 * The values denote only the largest extent of the enabled elements, not their sum.
 */
export interface TrackExtent {
	top: number;
	bottom: number;
}

/**
 * Multiple StatisticsTracks stacked on top of each other such that they do not overlap.
 */
export interface TrackStack {
	height: number;
	placedTracks: PlacedTrack[];
}

export interface PlacedTrack {
	track: StatisticsTrack;
	offset: number;
}

export type Range = [start: number, end: number];
export type Position = [x: number, y: number];

const style = {
	layout: {
		trackStackSpacing: 5,
	},
	whisker: {
		color: '#444',
		strokeWidth: 2,
		height: 10,
	},
	meanBar: {
		color: '#B16868',
		strokeWidth: 2,
		width: 4,
		height: 16,
	},
	medianArrow: {
		color: '#0038A3',
		width: 9,
		height: 9,
		borderRadius: 1,
		borderWidth: 2,
	},
	quantileBox: {
		borderColor: '#444',
		strokeWidth: 2,
		height: 10,
		medianWidth: 4,
		medianColor: '#0038A3',
	},
};

export const addStatisticsDefinitions = (defs: Selection<SVGDefsElement, any, BaseType, any>) => {
	defs.append('rect')
		.attr('id', 'mean-bar')
		.attr('x', -style.meanBar.width / 2)
		.attr('y', -style.meanBar.height / 2)
		.attr('width', style.meanBar.width)
		.attr('height', style.meanBar.height)
		.attr('fill', style.meanBar.color)
		.attr('rx', style.meanBar.width / 2)
		.attr('stroke', 'white')
		.attr('stroke-width', 2 * style.meanBar.strokeWidth)
		.attr('paint-order', 'stroke');

	defs.append('path')
		.attr('id', 'median-arrow')
		.attr('d', getMedianArrowPath())
		.attr('fill', style.medianArrow.color)
		.attr('stroke', 'white')
		.attr('stroke-width', 2 * style.medianArrow.borderWidth)
		.attr('paint-order', 'stroke')
		.attr(
			'transform',
			`translate(${-style.medianArrow.width / 2} ${
				-style.medianArrow.height - style.medianArrow.borderWidth + 1
			})`
		);

	defs.append('rect')
		.attr('id', 'quantile-box-median-bar')
		.attr('x', -style.quantileBox.medianWidth / 2)
		.attr('y', -style.quantileBox.height / 2)
		.attr('width', style.quantileBox.medianWidth)
		.attr('height', style.quantileBox.height)
		.attr('fill', style.quantileBox.medianColor);
};

export const getStackedStatistics = (statistics: VisualizationStatistics): TrackStack =>
	stackTracks(getStatisticsTracks(statistics));

const getStatisticsTracks = ({ arithmetic, quantile }: VisualizationStatistics): StatisticsTrack[] => {
	if (!arithmetic && !quantile) {
		return [];
	} else if (arithmetic?.standardDeviation !== undefined && (quantile?.range || quantile?.extrema)) {
		// separate tracks for quantile and arithmetic
		const arithmeticTrack: StatisticsTrack = {
			meanBar: arithmetic.mean,
			whisker: [arithmetic.mean - arithmetic.standardDeviation, arithmetic.mean + arithmetic.standardDeviation],
		};

		const quantileTrack: StatisticsTrack = {
			whisker: quantile.extrema ? [quantile.extrema.min, quantile.extrema.max] : undefined,
			medianArrow: quantile.range ? undefined : quantile.median,
			quantileBox: quantile.range
				? {
						median: quantile.median,
						range: [quantile.range.lower, quantile.range.upper],
				  }
				: undefined,
		};

		return [arithmeticTrack, quantileTrack];
	}
	{
		// combined track
		return [
			{
				whisker:
					arithmetic?.standardDeviation !== undefined
						? [
								arithmetic.mean - arithmetic.standardDeviation,
								arithmetic.mean + arithmetic.standardDeviation,
						  ]
						: quantile?.extrema
						? [quantile.extrema.min, quantile.extrema.max]
						: undefined,
				meanBar: arithmetic?.mean,
				medianArrow: quantile?.range ? undefined : quantile?.median,
				quantileBox: quantile?.range
					? {
							median: quantile.median,
							range: [quantile.range.lower, quantile.range.upper],
					  }
					: undefined,
			},
		];
	}
};

const stackTracks = (tracks: StatisticsTrack[]): TrackStack => {
	const placedTracks: PlacedTrack[] = [];
	let offset: number | null = null;

	for (const track of tracks) {
		const extent = measureStatisticsTrack(track);
		offset = offset === null ? extent.top : offset + style.layout.trackStackSpacing + extent.top;

		placedTracks.push({ offset, track });

		offset += extent.bottom;
	}

	return { height: offset ?? 0, placedTracks };
};

const measureStatisticsTrack = (elt: StatisticsTrack): TrackExtent => {
	const extents: TrackExtent[] = [];
	const symmetric = (value: number) => ({ top: value, bottom: value });

	if (elt.meanBar !== undefined) {
		if (elt.whisker !== undefined || elt.quantileBox !== undefined) {
			extents.push(symmetric(style.meanBar.height / 2));
		} else {
			extents.push({ top: style.meanBar.height, bottom: 0 });
		}
	}

	if (elt.medianArrow !== undefined) {
		const whiskerOffset = elt.whisker === undefined ? 0 : style.whisker.strokeWidth / 2;

		extents.push({
			top: whiskerOffset + style.medianArrow.height,
			bottom: 0,
		});
	}

	if (elt.quantileBox !== undefined) {
		extents.push(symmetric(style.quantileBox.height / 2 + style.quantileBox.strokeWidth));
	}

	if (elt.whisker !== undefined) {
		extents.push(symmetric(style.whisker.height / 2 + style.whisker.strokeWidth / 2));
	}

	return {
		top: Math.max(...extents.map((extent) => extent.top)),
		bottom: Math.max(...extents.map((extent) => extent.bottom)),
	};
};

export const drawStatisticsTrack =
	(scale: ScaleLinear<number, number>) => (selection: Selection<BaseType, StatisticsTrack, BaseType, any>) => {
		// whisker
		selection
			.filter((d) => d.whisker !== undefined)
			.append('path')
			.attr('stroke', style.whisker.color)
			.attr('stroke-width', style.whisker.strokeWidth)
			.attr('stroke-linejoin', 'round')
			.attr('stroke-linecap', 'round')
			.attr('fill', 'white')
			.attr('d', (d) => getWhiskerPath(style.whisker.height, scale(d.whisker![1]) - scale(d.whisker![0])))
			.attr('transform', (d) => `translate(${scale(d.whisker![0])})`)
			.attr('data-cy', 'barChart-visual-whisker');

		// box
		selection
			.filter((d) => d.quantileBox !== undefined && d.quantileBox.range[0] !== d.quantileBox.range[1]) // hide if zero-width
			.append('rect')
			.attr('x', (d) => scale(d.quantileBox!.range[0]))
			.attr('y', -style.quantileBox.height / 2)
			.attr('width', (d) => scale(d.quantileBox!.range[1]) - scale(d.quantileBox!.range[0]))
			.attr('height', style.quantileBox.height)
			.attr('fill', 'white')
			.attr('stroke', style.quantileBox.borderColor)
			.attr('stroke-width', 2 * style.quantileBox.strokeWidth)
			.attr('paint-order', 'stroke')
			.attr('rx', 1.5)
			.attr('data-cy', 'barChart-visual-quartile');

		// mean bar
		selection
			.filter((d) => d.meanBar !== undefined)
			.append('use')
			.attr('xlink:href', '#mean-bar')
			.attr(
				'transform',
				(d) => `translate(${scale(d.meanBar!)} ${d.whisker || d.quantileBox ? 0 : -style.meanBar.height / 2})`
			)
			.attr('data-cy', 'barChart-visual-mean');

		// median
		selection
			.filter((d) => d.medianArrow !== undefined)
			.append('use')
			.attr('xlink:href', '#median-arrow')
			.attr(
				'transform',
				(d) =>
					`translate(${scale(d.medianArrow!)} ${
						d.whisker === undefined ? 0 : -style.whisker.strokeWidth / 2
					})`
			)
			.attr('data-cy', 'barChart-visual-medianArrow');

		// box median
		selection
			.filter((d) => d.quantileBox?.median !== undefined)
			.append('use')
			.attr('xlink:href', '#quantile-box-median-bar')
			.attr('transform', (d) => `translate(${scale(d.quantileBox!.median!)} 0)`)
			.attr('data-cy', 'barChart-visual-median');
	};

const getMedianArrowPath = (): string => {
	const ctx = path();

	const start: Position = [style.medianArrow.width / 2, 0];

	const arcPoints: Position[] = [
		start,
		[style.medianArrow.width, 0],
		[style.medianArrow.width / 2, style.medianArrow.height],
		[0, 0],
		start,
	];

	ctx.moveTo(...start);
	for (let i = 0; i < arcPoints.length - 1; i++) {
		ctx.arcTo(...arcPoints[i], ...arcPoints[i + 1], style.medianArrow.borderRadius);
	}
	ctx.closePath();

	return ctx.toString();
};

const getWhiskerPath = (height: number, length: number) => {
	const ctx = path();

	// left end
	ctx.moveTo(0, -height / 2);
	ctx.lineTo(0, height / 2);

	// center line
	ctx.moveTo(0, 0);
	ctx.lineTo(length, 0);

	// right end
	ctx.moveTo(length, -height / 2);
	ctx.lineTo(length, height / 2);

	return ctx.toString();
};

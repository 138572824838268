import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { endpoints } from '@evasys/globals/evainsights/api/endpoints';
import { runtimeEnvironment } from '@evasys/globals/evainsights/environments/runtime-environment';

@Injectable({
	providedIn: 'root',
})
export class EndpointsService {
	//region injections
	private httpClient = inject(HttpClient);
	//endregion

	getEvasysEndpoint() {
		return this.httpClient.get<{ value: string }>(
			endpoints.evasysEndpoint.url({ tenantSubdomain: runtimeEnvironment.tenantSubdomain })
		);
	}
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { endpoints } from '@evasys/globals/evainsights/api/endpoints';
import { ParticipationEventSearchRequest } from '@evasys/globals/evainsights/models/search/ParticipationEventSearchRequest';
import { KeyValueModel } from '@evasys/globals/evainsights/models/common/key-value.model';
import { Page } from '@evasys/globals/evainsights/models/pagination/page.model';

@Injectable({
	providedIn: 'root',
})
export class ParticipationEventService {
	constructor(private http: HttpClient) {}

	getSearchResult(filter: ParticipationEventSearchRequest = {}): Observable<Page<KeyValueModel>> {
		return this.http.get<Page<KeyValueModel>>(endpoints.getParticipationEvents.url(), {
			params: { ...filter },
		});
	}
}

import { animate, state, style, transition, trigger } from '@angular/animations';

export const xGrowAnimation = (from: string, to: string) => {
	return trigger('xGrowAnimation', [
		state(
			'false',
			style({
				width: from,
				overflow: 'hidden',
			})
		),
		state(
			'true',
			style({
				width: to,
				opacity: '*',
				overflow: 'hidden',
			})
		),
		state(
			':leave',
			style({
				width: from,
				overflow: 'hidden',
			})
		),
		state(
			':enter',
			style({
				width: to,
				opacity: '*',
				overflow: 'hidden',
			})
		),
		transition('true <=> false', [animate('400ms ease-in-out')]),
		transition(':enter', [
			style({
				width: from,
				overflow: 'hidden',
			}),
			animate(
				'250ms',
				style({
					width: to,
					opacity: '*',
				})
			),
		]),
		transition(':leave', [
			style({
				width: to,
				overflow: 'hidden',
			}),
			animate(
				'250ms',
				style({
					width: from,
				})
			),
		]),
	]);
};

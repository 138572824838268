import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { ReportService } from '@evasys/evainsights/shared/core';
import { reportActions } from '../actions';
import { Report } from '@evasys/globals/evainsights/models/report/report-reportTemplate.model';
import { AbstractReportEffects } from './abstract-report.effects';

@Injectable()
export class ReportEffects extends AbstractReportEffects<Report> {
	constructor(protected readonly actions$: Actions, readonly apiService: ReportService) {
		super();
	}

	load$ = this.createEffectGetOne(reportActions.fromReports.LoadActionSet);
	createOne$ = this.createEffectCreateOne(reportActions.fromReports.CreateOneActionSet);
	createOneLocal$ = this.createEffectCreateOneLocal(reportActions.fromReports.CreateOneLocalActionSet);
	editOne$ = this.createEffectEditOne(reportActions.fromReports.EditOneActionSet);
	editOneLocal$ = this.createEffectEditOneLocal(reportActions.fromReports.EditOneLocalActionSet);
	deleteOneLocal$ = this.createEffectDeleteOneLocal(reportActions.fromReports.DeleteOneLocalActionSet);
}

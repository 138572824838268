import { Injectable, signal } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/*
	necessary to Work:
	add directive to app.component.ts to a Tag.
	For Example: <router-outlet evasysScreen>...
 */

export enum ScreenSizes {
	NONE = -1,
	XS = 0,
	S = 576,
	M = 768,
	L = 992,
	XL = 1200,
	XXL = 1400,
}

@Injectable({
	providedIn: 'root',
})
export class ScreenService {
	//#region Variables
	private screenSize: ScreenSizes = ScreenSizes.NONE;
	public readonly screenWidth = signal<number | null>(null);
	public readonly screenHeight = signal<number | null>(null);
	private screenSizeSubject: BehaviorSubject<ScreenSizes> = new BehaviorSubject<ScreenSizes>(this.screenSize);
	//#endregion Variables

	//#region Getter/Setter
	set size(size: ScreenSizes) {
		this.screenSize = size;
		this.screenSizeSubject.next(size);
	}

	get size(): ScreenSizes {
		return this.screenSize;
	}

	get sizeObservable(): Observable<ScreenSizes> {
		return this.screenSizeSubject.asObservable();
	}

	//#endregion Getter/Setter

	//#region methods

	isSize(size: ScreenSizes): boolean {
		return this.screenSize === size;
	}

	isSizeSmallerThan(size: ScreenSizes): boolean {
		return this.screenSize < size;
	}

	isSizeBiggerThan(size: ScreenSizes): boolean {
		return this.screenSize > size;
	}

	isSizeObservable(screenSize: ScreenSizes): Observable<boolean> {
		return this.screenSizeSubject.asObservable().pipe(map((size) => size === screenSize));
	}

	isSizeSmallerThanObservable(screenSize: ScreenSizes): Observable<boolean> {
		return this.screenSizeSubject.asObservable().pipe(map((size) => screenSize > size));
	}

	isSizeBiggerThanObservable(screenSize: ScreenSizes): Observable<boolean> {
		return this.screenSizeSubject.asObservable().pipe(map((size) => screenSize < size));
	}

	//#endregion methods
}

import { Store } from '@ngrx/store';
import { EvasysUserModel } from '@evasys/globals/shared/models/general/evasys-user.model';
import { UserRightsEnum } from '@evasys/globals/shared/enums/business/user-rights.enum';
import { LevelEnum } from '@evasys/globals/shared/enums/business/level.enum';
import { sharedActions } from '../actions';
import { EvasysState } from '../essential/redux/states/evasys.state';
import { OnlineTemplatesAccessEnum } from '@evasys/globals/evasys/enums/online-templates-access.enum';

export class UserModelClass implements EvasysUserModel {
	id: number;
	activeRight: UserRightsEnum;
	userRights: UserRightsEnum[];
	level: LevelEnum;
	firstname: string;
	fullName: string;
	fullUnitName: string;
	surname: string;
	active?: boolean;
	lang?: string;
	flavor?: string;
	onlineTemplatesAccess: OnlineTemplatesAccessEnum;

	constructor(userModel: EvasysUserModel, private readonly userStore: Store<EvasysState<EvasysUserModel>>) {
		this.setModel(userModel);
		this.activeRight = userModel.activeRight ?? this.userRights[0];
	}

	public isAuthorized(role: UserRightsEnum[]): boolean {
		return role.includes(this.activeRight);
	}

	public changeActiveRole(role: UserRightsEnum): void {
		return this.userStore.dispatch(
			sharedActions.fromAuthUser.ChangeActiveRoleActionSet.DEFAULT({
				payload: {
					id: this.id,
					activeRole: role,
				},
			})
		);
	}

	private setModel(userModel: EvasysUserModel) {
		for (const key of Object.keys(userModel)) {
			this[key] = userModel[key];
		}
	}
}

<div
	*ngIf="loading"
	[@opacityAnimation]
	[attr.data-cy]="'loadingOverlay'"
	class="d-flex c-user-select-none justify-content-center align-items-center position-absolute c-t-0px c-b-0px c-l-0px c-r-0px c-z-index-1000"
>
	<div [class]="'c-opacity-90 position-absolute c-t-0px c-b-0px c-l-0px c-r-0px ' + backdropColorClass"></div>
	<div class="spinner-grow text-primary me-2"></div>
	<ng-content></ng-content>
</div>

import { reportTemplateActions } from '../actions';
import { Action, createReducer } from '@ngrx/store';
import { defaultEvasysState, EvasysEntityAdapter, EvasysReducerCaseFactory, EvasysState } from '@evasys/shared/core';
import { ReportTemplate } from '@evasys/globals/evainsights/models/report/report-reportTemplate.model';

export const reportTemplateAdapter = new EvasysEntityAdapter<ReportTemplate>();
export const reportTemplateInitialState: EvasysState<ReportTemplate> = reportTemplateAdapter.entity.getInitialState(
	defaultEvasysState()
);
const reportTemplateReducerCaseFactory = new EvasysReducerCaseFactory<ReportTemplate>(
	reportTemplateAdapter,
	reportTemplateActions.fromReportTemplates
);

const {
	reduceCreateOneActionSet,
	reduceCreateOneLocalActionSet,
	reduceEditOneActionSet,
	reduceEditOneLocalActionSet,
	reduceDeleteOneLocalActionSet,
	reduceLoadActionSet,
} = reportTemplateReducerCaseFactory.reduceEvasysActionSets();

export const reportTemplateReducer = createReducer(
	reportTemplateInitialState,
	...reduceLoadActionSet,
	...reduceLoadActionSet,
	...reduceCreateOneActionSet,
	...reduceCreateOneLocalActionSet,
	...reduceEditOneActionSet,
	...reduceEditOneLocalActionSet,
	...reduceDeleteOneLocalActionSet
);

export function reducer(state: EvasysState<ReportTemplate> | undefined, action: Action) {
	return reportTemplateReducer(state, action);
}

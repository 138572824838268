import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectorComponent } from './selector/selector.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SelectorItemComponent } from './selector-item/selector-item.component';
import { SelectorGroupComponent } from './selector-group/selector-group.component';
import { ImageModule } from '../../../directives/image/image.module';
import { SelectorPrimaryComponent } from './selector-primary/selector-primary.component';

@NgModule({
	declarations: [SelectorComponent, SelectorItemComponent, SelectorGroupComponent, SelectorPrimaryComponent],
	imports: [CommonModule, ReactiveFormsModule, ImageModule],
	exports: [SelectorComponent, SelectorItemComponent, SelectorGroupComponent, SelectorPrimaryComponent],
})
export class SelectorModule {}

import { Action, ActionReducer, createReducer, MetaReducer, on } from '@ngrx/store';
import { ConfigModel } from '@evasys/globals/evasys/models/business/config.model';
import { localStorageSync } from 'ngrx-store-localstorage';
import { sharedActions } from '../actions/index';
import { EvasysEntityAdapter } from '../essential/redux/adapter/evasys-entity.adapter';
import { defaultEvasysState, EvasysState } from '../essential/redux/states/evasys.state';
import { EvasysReducerCaseFactory } from '../essential/redux/reducers/evasys-reducer-case.factory';
import { getWindowVariable } from '@evasys/globals/shared/helper/window';

export const configAdapter = new EvasysEntityAdapter<ConfigModel>();
export const configInitialState: EvasysState<ConfigModel> = configAdapter.entity.getInitialState(defaultEvasysState());
const configReducerCaseFactory = new EvasysReducerCaseFactory<ConfigModel>(configAdapter, sharedActions.fromConfigs);

const { reduceLoadPageActionSet, reduceLoadManyActionSet, reduceEditOneActionSet, reduceLoadActionSet } =
	configReducerCaseFactory.reduceEvasysActionSets();

export const configReducer = createReducer(
	configInitialState,
	...reduceLoadPageActionSet,
	...reduceLoadManyActionSet,
	...reduceEditOneActionSet,
	...reduceLoadActionSet,
	on(sharedActions.fromConfigs.LoadOneWithConfigName.DEFAULT, (state: EvasysState<ConfigModel>) =>
		configAdapter.load(state)
	),
	on(sharedActions.fromConfigs.LoadOneWithConfigName.SUCCESS, (state: EvasysState<ConfigModel>, { payload }) =>
		configAdapter.loadManySucceed(payload, state)
	),
	on(sharedActions.fromConfigs.LoadOneWithConfigName.FAILURE, (state: EvasysState<ConfigModel>, { error }) => ({
		...state,
		error,
		loading: false,
	}))
);

export function reducer(state: EvasysState<ConfigModel> | undefined, action: Action) {
	return configReducer(state, action);
}

//MetaReducer to store the config in localStorage

function localStorageSyncReducer(
	reducer: ActionReducer<EvasysState<ConfigModel>>
): ActionReducer<EvasysState<ConfigModel>> {
	return localStorageSync({
		keys: ['ids', 'entities', 'loadedPages', 'pageConfig'],
		rehydrate: true,
		storageKeySerializer: (key) =>
			`${
				getWindowVariable<string>('base-href') ? getWindowVariable<string>('base-href') + '_' : ''
			}config_${key}`,
	})(reducer);
}

export const metaReducers: Array<MetaReducer<EvasysState<ConfigModel>>> = [localStorageSyncReducer];

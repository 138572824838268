import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { metaReducers, notificationInitialState, notificationReducer } from '../reducers/notification.reducer';

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		StoreModule.forFeature('notification', notificationReducer, {
			metaReducers,
			initialState: notificationInitialState,
		}),
	],
})
export class NotificationModule {}

import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, from, Observable, of, throwError } from 'rxjs';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';
import { AuthService } from '@evasys/shared/core';
import { EvasysTokenModel } from '@evasys/globals/shared/models/general/evasys-token.model';
import { EvasysGrantEnum } from '@evasys/globals/shared/enums/general/evasys-grant.enum';
import { SharedFeatureConfiguration } from '../../shared-feature.configuration';

@Injectable({
	providedIn: 'root',
})
export class Oauth2Interceptor implements HttpInterceptor {
	private isRefreshing = false;
	private refreshTokenSubject = new BehaviorSubject(null);

	constructor(private readonly config: SharedFeatureConfiguration, private readonly authService: AuthService) {}

	handle401Error(req: HttpRequest<any>, next: HttpHandler, error: HttpErrorResponse): Observable<HttpEvent<any>> {
		if (!this.isRefreshing) {
			this.isRefreshing = true;
			this.refreshTokenSubject.next(null);

			return this.authService.refreshLogin().pipe(
				switchMap((newToken: EvasysTokenModel | null) => {
					if (newToken) {
						this.refreshTokenSubject.next(newToken.access_token);

						return next.handle(this.addHeader(req, newToken.access_token)).pipe(
							catchError((error) => {
								return of(error);
							})
						);
					} else {
						return from(
							this.authService.logout().then((logout) => {
								console.info('user logout on refresh the token - no token', logout);
								window.location.reload();
								return logout;
							})
						);
					}
				}),
				catchError((error) => {
					return from(
						this.authService
							.logout()
							.then((logout) => {
								console.info('user logout - token error response: 401', logout);
								window.location.reload();
								return throwError(() => error);
							})
							.catch((err) => {
								console.error('user logout failed - ', err);
								window.location.reload();
								return throwError(() => error);
							})
					);
				}),
				finalize(() => {
					this.isRefreshing = false;
				})
			);
		} else {
			//The error response for the logout after failing refresh must pass.
			//Otherwise the logout never get a response and we get a white page
			if (req.url.includes('revoke')) {
				throw error;
			}
			return this.refreshTokenSubject.pipe(
				filter((token) => token != null),
				take(1),
				switchMap((accessToken) => {
					return next.handle(this.addHeader(req, accessToken));
				})
			);
		}
	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (
			(!request.body || request.body.grant_type !== EvasysGrantEnum.PASSWORD) &&
			this.config.isApiUrl(request.url)
		) {
			return this.authService.token.pipe(
				take(1),
				switchMap((token) => next.handle(this.addHeader(request, token?.access_token))),
				catchError((error) => {
					if (error instanceof HttpErrorResponse && error.status === 401) {
						return this.handle401Error(request, next, error);
					}

					return this.config.catchAllHttpErrors ? of(error) : throwError(() => error);
				})
			);
		} else {
			return next.handle(request);
		}
	}

	private addHeader(request: HttpRequest<unknown>, accessToken): HttpRequest<any> {
		const headers = {};
		if (accessToken) {
			if ((request?.body as any)?.grant_type !== 'refresh_token') {
				Object.assign(headers, { Authorization: `Bearer ${accessToken}` });
			}
		} else if (this.config.clientSecret != null) {
			Object.assign(headers, { Eva: this.config.clientSecret });
		}

		if (!(request?.body instanceof FormData)) {
			Object.assign(headers, { 'Content-Type': 'application/json' });
		}

		return request.clone({
			setHeaders: headers,
		});
	}
}

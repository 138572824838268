import { animate, state, style, transition, trigger } from '@angular/animations';

export const z3DRotateAnimation = () => {
	return trigger('z3DRotateAnimation', [
		// ...
		state(
			'true',
			style({
				transform: 'rotate3d(0, 0, 1, -180deg)',
			})
		),
		state(
			'false',
			style({
				transform: 'rotate3d(0, 0, 1, 0deg)',
			})
		),
		transition('true <=> false', [animate('0.2s')]),
	]);
};

import { SystemMultiLang } from '../common/multi-lang';

export enum ColorSchemeType {
	QUALITATIVE = 'QUALITATIVE',
	QUANTITATIVE = 'QUANTITATIVE',
}

export enum QuantitativeColorSchemeSymmetry {
	// What can the color scheme be used for?
	SEQUENTIAL, // e.g. to visualize counts in wordclouds or for scala items like very good vs. very poor
	DIVERGING, // e.g. for scala item like too little vs. too much
}

export enum ThemeVariant {
	LIGHT = 'LIGHT',
	DARK = 'DARK',
}

export interface Theme {
	id: number;
	name: string;
	parent: Theme | null;
	forcedVariant: ThemeVariant | null;
}

export interface ScaleBase {
	themeId: number;
	variant: ThemeVariant;
}

export interface QualitativeScale extends ScaleBase {
	// Color palette for cases where the order is not important.
	// Another data series added? Just use next color from the colors array.
	colors: Color[];
}

export interface ContinuousQuantitativeScale extends ScaleBase {
	stops: ColorScaleStop[];
}

export interface DiscreteQuantitativeScale extends ScaleBase {
	// Color palette for cases where the order is important, e.g., primarily for scala questions.
	// The colors property effectively is a list of color palettes of different lengths.
	// Example: If we use an ordinal color palette for a pie chart depicting the 5 options of a scala question,
	// we search through the outer array colors property to find an inner array of length 5.
	// This array of length 5 contains the 5 colors we use for the options, respecting the order given by the item value positions.
	// The chart may use the colors in reverse order if configured for it.
	colors: Color[][];
}

export type QuantitativeScale = ContinuousQuantitativeScale | DiscreteQuantitativeScale;
export type ColorScale = QualitativeScale | QuantitativeScale;

export interface ColorScaleStop {
	color: Color;
	position: number; // 0 to 1
}

interface SchemeBase {
	id: number;
	name: SystemMultiLang<string>;
}

export interface QualitativeScheme extends SchemeBase {
	type: ColorSchemeType.QUALITATIVE;
	scales: QualitativeScale[];
}

export interface QuantitativeSchemeBase extends SchemeBase {
	type: ColorSchemeType.QUANTITATIVE;
	symmetry: QuantitativeColorSchemeSymmetry;
}

export interface ContinuousQuantitativeScheme extends QuantitativeSchemeBase {
	scales: ContinuousQuantitativeScale[];
}

export interface DiscreteQuantitativeScheme extends QuantitativeSchemeBase {
	scales: DiscreteQuantitativeScale[];
}

export type QuantitativeScheme = ContinuousQuantitativeScheme | DiscreteQuantitativeScheme;
export type ColorScheme = QualitativeScheme | QuantitativeScheme;

export type Color = string;

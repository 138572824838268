import { on, ReducerTypes } from '@ngrx/store';
import { EvasysCollectionModel } from '@evasys/globals/shared/models/general/evasys-collection.model';
import { EvasysState } from '../states/evasys.state';
import { EvasysEntityAdapter } from '../adapter/evasys-entity.adapter';
import { ActionCreator } from '@ngrx/store/src/models';

export class EvasysReducerCaseFactory<M> {
	constructor(private adapter: EvasysEntityAdapter<M>, private from: any) {
		// This is intentional
	}

	// region Special handlers

	private createOneSuccessStateChange<S extends EvasysState<M, unknown>>(state: S, { payload }): S {
		return this.adapter.entity.addOne(payload as M, { ...state, loading: false });
	}

	private deleteOneSuccessStateChange<S extends EvasysState<M, unknown>>(state: S, { payload }): S {
		return this.adapter.entity.removeOne(payload as string, {
			...state,
			loading: false,
		});
	}

	private editOneSuccessStateChange<S extends EvasysState<M, unknown>>(state: S, { payload }): S {
		return this.adapter.entity.updateOne({ id: payload.id, changes: payload }, { ...state, loading: false });
	}

	// endregion Special handlers

	//#region ReduceCases

	private reduceLoadActionSet<S extends EvasysState<M, unknown>>(): ReducerTypes<S, readonly ActionCreator[]>[] {
		if (this.from.LoadActionSet) {
			return [
				on(this.from.LoadActionSet.DEFAULT, (state) => {
					return this.adapter.load(state);
				}),
				on(this.from.LoadActionSet.SUCCESS, (state, { payload }) => {
					if (payload?.entities) {
						return this.adapter.loadManySucceed(payload as EvasysCollectionModel<M>, state);
					} else {
						return this.adapter.loadOneSucceed(payload as M, state);
					}
				}),
				on(this.from.LoadActionSet.FAILURE, this.adapter.loadFailed.bind(this)),
			];
		}
		return [];
	}

	private reduceLoadPageActionSet<S extends EvasysState<M, unknown>>(): ReducerTypes<S, readonly ActionCreator[]>[] {
		if (this.from.LoadPageActionSet) {
			return [
				on(this.from.LoadPageActionSet.DEFAULT, (state) => {
					return this.adapter.load(state);
				}),
				on(this.from.LoadPageActionSet.SUCCESS, (state, { payload }) =>
					this.adapter.loadManySucceed(payload as EvasysCollectionModel<M>, state)
				),
				on(this.from.LoadPageActionSet.FAILURE, this.adapter.loadFailed.bind(this)),
			];
		}
		return [];
	}

	private reduceUploadFileActionSet<S extends EvasysState<M, unknown>>(): ReducerTypes<
		S,
		readonly ActionCreator[]
	>[] {
		if (this.from.UploadFileActionSet) {
			return [
				on(this.from.UploadFileActionSet.DEFAULT, (state) => {
					return this.adapter.load(state);
				}),
				on(this.from.UploadFileActionSet.SUCCESS, (state, { payload }) => {
					return this.adapter.entity.updateOne(
						{
							id: payload.id,
							changes: payload,
						},
						{ ...state, loading: false }
					);
				}),
				on(this.from.UploadFileActionSet.FAILURE, (state, { error }) => ({
					...state,
					error,
					loading: false,
				})),
			];
		}
		return [];
	}

	private reduceLoadManyActionSet<S extends EvasysState<M, unknown>>(): ReducerTypes<S, readonly ActionCreator[]>[] {
		if (this.from.LoadManyActionSet) {
			return [
				on(this.from.LoadManyActionSet.DEFAULT, (state) => this.adapter.load(state)),
				on(this.from.LoadManyActionSet.SUCCESS, (state, { payload }) => {
					return this.adapter.loadManySucceed(payload as EvasysCollectionModel<M>, state);
				}),
				on(this.from.LoadManyActionSet.FAILURE, this.adapter.loadFailed.bind(this)),
			];
		}
		return [];
	}

	private reduceLoadPageWithFilterActionSet<S extends EvasysState<M, unknown>>(): ReducerTypes<
		S,
		readonly ActionCreator[]
	>[] {
		if (this.from.LoadPageWithFilterActionSet) {
			return [
				on(this.from.LoadPageWithFilterActionSet.DEFAULT, (state) => this.adapter.load(state)),
				on(this.from.LoadPageWithFilterActionSet.SUCCESS, (state, { payload }) => {
					return this.adapter.loadManySucceed(payload as EvasysCollectionModel<M>, state);
				}),
				on(this.from.LoadPageWithFilterActionSet.FAILURE, this.adapter.loadFailed.bind(this)),
			];
		}
		return [];
	}

	private reduceLoadAllActionSet<S extends EvasysState<M, unknown>>(): ReducerTypes<S, readonly ActionCreator[]>[] {
		if (this.from.LoadAllActionSet) {
			return [
				on(this.from.LoadAllActionSet.DEFAULT, (state) => this.adapter.load(state)),
				on(this.from.LoadAllActionSet.SUCCESS, (state, { payload }) => {
					return this.adapter.loadManySucceed(payload as EvasysCollectionModel<M>, state);
				}),
				on(this.from.LoadAllActionSet.FAILURE, (state, { error }) => ({
					...state,
					error,
					loading: false,
				})),
			];
		}
		return [];
	}

	private reduceLoadWithParamsActionSet<S extends EvasysState<M, unknown>>(): ReducerTypes<
		S,
		readonly ActionCreator[]
	>[] {
		if (this.from.LoadWithParamsActionSet) {
			return [
				on(this.from.LoadWithParamsActionSet.DEFAULT, (state) => this.adapter.load(state)),
				on(this.from.LoadWithParamsActionSet.SUCCESS, (state, { payload }) => {
					return this.adapter.loadManySucceed(payload as EvasysCollectionModel<M>, state);
				}),
				on(this.from.LoadWithParamsActionSet.FAILURE, this.adapter.loadFailed.bind(this)),
			];
		}
		return [];
	}

	private reduceLoadOneActionSet<S extends EvasysState<M, unknown>>(): ReducerTypes<S, readonly ActionCreator[]>[] {
		if (this.from.LoadOneActionSet) {
			return [
				on(this.from.LoadOneActionSet.DEFAULT, (state) => this.adapter.load(state)),
				on(this.from.LoadOneActionSet.SUCCESS, (state, { payload }) =>
					this.adapter.loadOneSucceed(payload as M, state)
				),
				on(this.from.LoadOneActionSet.FAILURE, this.adapter.loadFailed.bind(this)),
			];
		}
		return [];
	}

	private reduceLoadOneWithParamsActionSet<S extends EvasysState<M, unknown>>(): ReducerTypes<
		S,
		readonly ActionCreator[]
	>[] {
		if (this.from.LoadOneWithParamsActionSet) {
			return [
				on(this.from.LoadOneWithParamsActionSet.DEFAULT, (state) => this.adapter.load(state)),
				on(this.from.LoadOneWithParamsActionSet.SUCCESS, (state, { payload }) =>
					this.adapter.loadOneSucceed(payload as M, state)
				),
				on(this.from.LoadOneWithParamsActionSet.FAILURE, this.adapter.loadFailed.bind(this)),
			];
		}
		return [];
	}

	private reduceCreateOneActionSet<S extends EvasysState<M, unknown>>(): ReducerTypes<S, readonly ActionCreator[]>[] {
		if (this.from.CreateOneActionSet) {
			return [
				on(this.from.CreateOneActionSet.DEFAULT, this.adapter.load.bind(this)),
				on(this.from.CreateOneActionSet.SUCCESS, this.createOneSuccessStateChange.bind(this)),
				on(this.from.CreateOneActionSet.FAILURE, this.adapter.loadFailed.bind(this)),
			];
		}
		return [];
	}

	private reduceCreateOneLocalActionSet<S extends EvasysState<M, unknown>>(): ReducerTypes<
		S,
		readonly ActionCreator[]
	>[] {
		if (this.from.CreateOneLocalActionSet) {
			return [on(this.from.CreateOneLocalActionSet.SUCCESS, this.createOneSuccessStateChange.bind(this))];
		}
		return [];
	}

	private reduceDeleteOneActionSet<S extends EvasysState<M, unknown>>(): ReducerTypes<S, readonly ActionCreator[]>[] {
		if (this.from.DeleteOneActionSet) {
			return [
				on(this.from.DeleteOneActionSet.DEFAULT, this.adapter.load.bind(this)),
				on(this.from.DeleteOneActionSet.SUCCESS, this.deleteOneSuccessStateChange.bind(this)),
				on(this.from.DeleteOneActionSet.FAILURE, this.adapter.loadFailed.bind(this)),
			];
		}
		return [];
	}

	private reduceDeleteOneLocalActionSet<S extends EvasysState<M, unknown>>(): ReducerTypes<
		S,
		readonly ActionCreator[]
	>[] {
		if (this.from.DeleteOneLocalActionSet) {
			return [on(this.from.DeleteOneLocalActionSet.SUCCESS, this.deleteOneSuccessStateChange.bind(this))];
		}
		return [];
	}

	private reduceDeleteManyActionSet<S extends EvasysState<M, unknown>>(): ReducerTypes<
		S,
		readonly ActionCreator[]
	>[] {
		if (this.from.DeleteManyActionSet) {
			return [
				on(this.from.DeleteManyActionSet.DEFAULT, this.adapter.load.bind(this)),
				on(this.from.DeleteManyActionSet.SUCCESS, (state, { payload }) =>
					this.adapter.entity.removeMany(payload as string[], {
						...state,
						loading: false,
					})
				),
				on(this.from.DeleteManyActionSet.FAILURE, this.adapter.loadFailed.bind(this)),
			];
		}
		return [];
	}

	private reduceEditOneActionSet<S extends EvasysState<M, unknown>>(): ReducerTypes<S, readonly ActionCreator[]>[] {
		if (this.from.EditOneActionSet) {
			return [
				on(this.from.EditOneActionSet.DEFAULT, this.adapter.load.bind(this)),
				on(this.from.EditOneActionSet.SUCCESS, this.editOneSuccessStateChange.bind(this)),
				on(this.from.EditOneActionSet.FAILURE, this.adapter.loadFailed.bind(this)),
			];
		}
		return [];
	}

	private reduceEditOneLocalActionSet<S extends EvasysState<M, unknown>>(): ReducerTypes<
		S,
		readonly ActionCreator[]
	>[] {
		if (this.from.EditOneLocalActionSet) {
			return [on(this.from.EditOneLocalActionSet.SUCCESS, this.editOneSuccessStateChange.bind(this))];
		}
		return [];
	}

	private reduceEditManyActionSet<S extends EvasysState<M, unknown>>(): ReducerTypes<S, readonly ActionCreator[]>[] {
		if (this.from.EditManyActionSet) {
			return [
				on(this.from.EditManyActionSet.DEFAULT, this.adapter.load.bind(this)),
				on(this.from.EditManyActionSet.SUCCESS, (state, { payload }) =>
					this.adapter.entity.updateMany(payload, { ...state, loading: false })
				),
				on(this.from.EditManyActionSet.FAILURE, this.adapter.loadFailed.bind(this)),
			];
		}
		return [];
	}

	private reduceClearActionSet<S extends EvasysState<M, unknown>>(): ReducerTypes<S, readonly ActionCreator[]>[] {
		if (this.from.ClearActionSet) {
			return [on(this.from.ClearActionSet.DEFAULT, (state) => this.adapter.entity.removeAll(state))];
		}
		return [];
	}

	//#endregion ReduceCases

	public reduceEvasysActionSets<S extends EvasysState<M, unknown>>() {
		return {
			reduceLoadActionSet: this.reduceLoadActionSet<S>(),
			reduceLoadPageActionSet: this.reduceLoadPageActionSet<S>(),
			reduceLoadManyActionSet: this.reduceLoadManyActionSet<S>(),
			reduceLoadAllActionSet: this.reduceLoadAllActionSet<S>(),
			reduceLoadOneActionSet: this.reduceLoadOneActionSet<S>(),
			reduceLoadOneWithParamsActionSet: this.reduceLoadOneWithParamsActionSet<S>(),
			reduceLoadWithParamsActionSet: this.reduceLoadWithParamsActionSet<S>(),
			reduceLoadPageWithFilterActionSet: this.reduceLoadPageWithFilterActionSet<S>(),

			reduceCreateOneActionSet: this.reduceCreateOneActionSet<S>(),
			reduceCreateOneLocalActionSet: this.reduceCreateOneLocalActionSet<S>(),
			reduceUploadFileActionSet: this.reduceUploadFileActionSet<S>(),

			reduceDeleteOneActionSet: this.reduceDeleteOneActionSet<S>(),
			reduceDeleteOneLocalActionSet: this.reduceDeleteOneLocalActionSet<S>(),
			reduceDeleteManyActionSet: this.reduceDeleteManyActionSet<S>(),

			reduceEditOneActionSet: this.reduceEditOneActionSet<S>(),
			reduceEditOneLocalActionSet: this.reduceEditOneLocalActionSet<S>(),
			reduceEditManyActionSet: this.reduceEditManyActionSet<S>(),

			reduceClearActionSet: this.reduceClearActionSet<S>(),
		};
	}
}

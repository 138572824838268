import { EvasysActionsFactory } from '../essential/redux/actions/evasys-actions.factory';
import { EvasysUserModel } from '@evasys/globals/shared/models/general/evasys-user.model';
import { UserRightsEnum } from '@evasys/globals/shared/enums/business/user-rights.enum';
import { ExternalIdUserModel } from '@evasys/globals/evasys/models/business/external-id-user.model';

const actionsFactory = new EvasysActionsFactory<EvasysUserModel>('AUTHUSER');

export const { LoadActionSet } = actionsFactory.createActions();

export const AuthenticateUserActionSet = actionsFactory.createCustomActionSet<string, EvasysUserModel>(
	'AuthenticateUser'
);

export const UnauthenticateUserActionSet = actionsFactory.createCustomActionSet<void, void>('UnauthenticateUser');

export const ChangeActiveRoleActionSet = actionsFactory.createCustomActionSet<
	{ id: number; activeRole: UserRightsEnum },
	EvasysUserModel
>('ChangeActiveUserRight', false);

export const GetUserSwitchDataActionSet = actionsFactory.createCustomActionSet<number, ExternalIdUserModel[]>(
	'GetUserSwitchData'
);

import { environment } from '@evasys/globals/evainsights/environments/environment';
import { replaceUrlPathVariables, VariableReplacer, variableValueReplacer, VariableValues } from './path-variables';
import { escapeRegExp } from 'lodash';

function getFullUrl(path: string): string {
	return new URL(path, window.location.href).toString();
}

export const PATH_DATA_SERVICE = getFullUrl(environment.dataServiceUrl);
export const PATH_REPORT_SERVICE = getFullUrl(environment.reportServiceUrl);
export const PATH_AUTH_SERVICE = getFullUrl(environment.authServiceUrl);

const SERVICE_PATHS = [PATH_DATA_SERVICE, PATH_REPORT_SERVICE, PATH_AUTH_SERVICE];

export const getIsNotPrefixPattern = (prefixes: string[]) => new RegExp(`^(?!${prefixes.map(escapeRegExp).join('|')})`);
export const isNotServicePathPattern = getIsNotPrefixPattern(SERVICE_PATHS);

export function isApiUrl(url: string): boolean {
	return SERVICE_PATHS.some((urlPrefix) => url.startsWith(urlPrefix));
}

export class Endpoint<T extends VariableValues | void = void> {
	constructor(public readonly baseUrl: string, public readonly path: string) {}

	url = (variableValues: T): string => {
		return this.replacePathVariables(variableValueReplacer(variableValues || undefined)).toString();
	};

	replacePathVariables = (replacer: VariableReplacer): URL => {
		return replaceUrlPathVariables(new URL(this.path, this.baseUrl), replacer);
	};
}

export const endpoints = {
	getUserUiConfig: new Endpoint(PATH_DATA_SERVICE, 'config/user'),
	getSystemUiConfig: new Endpoint<{ tenantSubdomain?: string }>(PATH_DATA_SERVICE, 'config/system/:tenantSubdomain'),

	//#region Unit
	getUnits: new Endpoint(PATH_DATA_SERVICE, 'units'),
	//#endregion Unit

	//#region Staff
	getStaffs: new Endpoint(PATH_DATA_SERVICE, 'staffs'),
	getStaff: new Endpoint<{ loginName: string }>(PATH_DATA_SERVICE, 'staffs/:loginName'),
	//#endregion Staff

	//#region ParticipationEvent
	getParticipationEvents: new Endpoint(PATH_DATA_SERVICE, 'participationEvents'),

	//#endregion ParticipationEvent

	//#region Item
	getItems: new Endpoint(PATH_DATA_SERVICE, 'items'),
	postItemsSearch: new Endpoint(PATH_DATA_SERVICE, 'items/search'),
	postItemCollectionsSearch: new Endpoint(PATH_DATA_SERVICE, 'itemCollections/search'),

	//#endregion Item

	//#region Programmes
	getProgrammes: new Endpoint(PATH_DATA_SERVICE, 'programmes'),

	//#endregion Programm

	//#region Periods
	getPeriods: new Endpoint(PATH_DATA_SERVICE, 'periods'),

	//#endregion Periods

	//#region Forms
	getForms: new Endpoint(PATH_DATA_SERVICE, 'forms'),
	getForm: new Endpoint<{ formId: number }>(PATH_DATA_SERVICE, 'forms/:formId'),

	//#endregion Forms

	//#region Surveys
	getSurveys: new Endpoint(PATH_DATA_SERVICE, 'surveys'),
	getSurveyPeriodName: new Endpoint(PATH_DATA_SERVICE, 'surveyPeriodName'),

	//#endregion Surveys

	//#region Leaders
	getLeaders: new Endpoint(PATH_DATA_SERVICE, 'leaders'),

	//#endregion Leaders

	//#region Reports
	reports: new Endpoint(PATH_REPORT_SERVICE, 'reports'),

	deleteReport: new Endpoint<{ reportId: number }>(PATH_REPORT_SERVICE, 'reports/:reportId'),

	createNewRespondentFilterItemForReportIdAndItemOptionIds: new Endpoint<{ reportId: number }>(
		PATH_REPORT_SERVICE,
		'reports/:reportId/respondentFilter/respondentFilterItems'
	),

	deleteRespondentFilterItemOptionsForReportIdAndItemId: new Endpoint<{ reportId: number; itemId: number }>(
		PATH_REPORT_SERVICE,
		'reports/:reportId/respondentFilter/respondentFilterItems/:itemId'
	),

	deleteReportTemplate: new Endpoint<{ reportTemplateId: number }>(
		PATH_REPORT_SERVICE,
		'reportTemplates/:reportTemplateId'
	),

	reportTemplates: new Endpoint(PATH_REPORT_SERVICE, 'reportTemplates'),

	report: new Endpoint<{ reportId: number }>(PATH_REPORT_SERVICE, 'reports/:reportId'),

	reportByToken: new Endpoint<{ token: string }>(PATH_REPORT_SERVICE, 'reports/token/:token'),

	reportPublish: new Endpoint<{ reportId: number }>(PATH_REPORT_SERVICE, 'reports/:reportId/publish'),

	reportCalculate: new Endpoint<{ reportId: number }>(PATH_REPORT_SERVICE, 'reports/:reportId/calculate'),

	reportTemplate: new Endpoint<{ reportTemplateId: number }>(
		PATH_REPORT_SERVICE,
		'reportTemplates/:reportTemplateId'
	),

	getReportTemplatesForReport: new Endpoint<{ reportId: number }>(
		PATH_REPORT_SERVICE,
		'reports/:reportId/reportTemplates'
	),

	postReportItemsForReport: new Endpoint<{ reportId: number }>(PATH_REPORT_SERVICE, 'reports/:reportId/reportitems'),

	patchReportItemPlacementsForReport: new Endpoint<{ reportId: number }>(
		PATH_REPORT_SERVICE,
		'reports/:reportId/reportitems'
	),

	deleteReportItem: new Endpoint<{ reportId: number }>(PATH_REPORT_SERVICE, 'reportitems/:reportItemId'),

	postReportItemsForReportTemplate: new Endpoint<{ reportTemplateId: number }>(
		PATH_REPORT_SERVICE,
		'reportTemplates/:reportTemplateId/reportitems'
	),

	patchReportTemplateItemPlacementsForReportTemplate: new Endpoint<{ reportTemplateId: number }>(
		PATH_REPORT_SERVICE,
		'reportTemplates/:reportTemplateId/reportitems'
	),

	reportReportTemplate: new Endpoint<{ reportId: number; reportTemplateId: number }>(
		PATH_REPORT_SERVICE,
		'reports/:reportId/reportTemplates/:reportTemplateId'
	),

	reportItem: new Endpoint<{ reportItemId: number }>(PATH_REPORT_SERVICE, 'reportitems/:reportItemId'),

	reportSurveys: new Endpoint<{ reportId: number }>(PATH_REPORT_SERVICE, 'reports/:reportId/surveys'),

	getReportTemplateForms: new Endpoint<{ reportTemplateId: number }>(
		PATH_REPORT_SERVICE,
		'reportTemplates/:reportTemplateId/forms'
	),
	//#endregion Reports

	//#region Topic Model
	topicModel: new Endpoint<{ topicModelId: number }>(PATH_REPORT_SERVICE, 'topicmodels/:topicModelId'),
	topicsByTopicModelId: new Endpoint<{ topicModelId: number }>(
		PATH_REPORT_SERVICE,
		'topicmodels/:topicModelId/topics'
	),
	getItemIdSurveyPeriodNames: new Endpoint<{ topicModelId: number }>(
		PATH_REPORT_SERVICE,
		'topicmodels/:topicModelId/itemIdSurveyPeriodNames'
	),
	topics: new Endpoint(PATH_REPORT_SERVICE, 'topics'),
	topic: new Endpoint<{ topicId: string }>(PATH_REPORT_SERVICE, 'topics/:topicId'),
	topicResponses: new Endpoint<{ topicId: string }>(PATH_REPORT_SERVICE, 'topics/:topicId/responses'),
	assignTopicModel: new Endpoint<{ topicModelId: number }>(PATH_REPORT_SERVICE, 'topicmodels/:topicModelId/assign'),
	checkRemainingTopicAssignments: new Endpoint<{ topicModelId: number }>(
		PATH_REPORT_SERVICE,
		'topicmodels/:topicModelId/check'
	),
	getTopicModelInfos: new Endpoint(PATH_REPORT_SERVICE, 'topicmodelinfos'),
	getTopicModelInfo: new Endpoint<{ topicModelId: number }>(PATH_REPORT_SERVICE, 'topicmodelinfos/:topicModelId'),
	//#endregion Topic Model

	//#region Calculation
	calculateTopicModels: new Endpoint(PATH_REPORT_SERVICE, 'calculate/topicmodels'),
	//#endregion Calculation

	//#region Charts
	postAxisChartData: new Endpoint(PATH_REPORT_SERVICE, 'reportitemdata/axischart'),
	postResponsesData: new Endpoint(PATH_REPORT_SERVICE, 'reportitemdata/responses'),
	postWordFrequencyWordcloudData: new Endpoint(PATH_REPORT_SERVICE, 'reportitemdata/wordfrequencywordcloud'),
	postTopicWordcloudData: new Endpoint(PATH_REPORT_SERVICE, 'reportitemdata/topicwordcloud'),
	postTopicWordcloudResponsesData: new Endpoint(PATH_REPORT_SERVICE, 'reportitemdata/topicwordcloudresponses'),
	getWordcloudResponseDataByTopicId: new Endpoint<{ topicId: string }>(
		PATH_REPORT_SERVICE,
		'topics/:topicId/wordcloudresponsedata'
	),
	getWordcloudDataByTopicId: new Endpoint<{ topicId: string }>(PATH_REPORT_SERVICE, 'topics/:topicId/wordclouddata'),
	//#endregion Charts

	//#region Endpoints
	endpoints: new Endpoint(PATH_AUTH_SERVICE, 'endpoints'),
	evasysEndpoint: new Endpoint<{ tenantSubdomain?: string }>(PATH_AUTH_SERVICE, 'endpoints/evasys/:tenantSubdomain'),
	//#endregion Endpoints

	//#region tenant
	getTenantRegisteredBySubDomainName: new Endpoint<{ tenantSubdomain?: string }>(
		PATH_AUTH_SERVICE,
		'tenant/registered/:tenantSubdomain'
	),
	//#endregion tenant
};

export enum VisualizationDimensionType {
	COLOR = 'COLOR',
	PATTERN = 'PATTERN',
	GROUP = 'GROUP',
	STACK = 'STACK',
	ROW = 'ROW',
	COLUMN = 'COLUMN',
}

export interface ColorVisualDimension {
	type: VisualizationDimensionType.COLOR;
	showLegend: boolean;
}

export interface PatternVisualDimension {
	type: VisualizationDimensionType.PATTERN;
}

export interface RowVisualDimension {
	type: VisualizationDimensionType.ROW;
}

export interface ColumnVisualDimension {
	type: VisualizationDimensionType.COLUMN;
	wrap: number | null;
}

export interface GroupVisualDimension {
	type: VisualizationDimensionType.GROUP;
}

export interface StackVisualDimension {
	type: VisualizationDimensionType.STACK;
}

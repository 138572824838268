import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EvasysNotificationModel } from '@evasys/globals/shared/models/evasys-notification.model';
import { EvasysNotificationActionModel } from '@evasys/globals/shared/models/evasys-notification-action.model';
import { SharedUiConfiguration } from '../../../shared-ui.configuration';
import { Animations } from '@evasys/globals/shared/animations/animations';

@Component({
	selector: 'evasys-notification-collection',
	templateUrl: './notification-collection.component.html',
	animations: [Animations.yGrowAnimation('0px', '*'), Animations.nestedAnimations],
})
export class NotificationCollectionComponent {
	//#region Input & Output

	@Input()
	id: string;

	@Input()
	notifications: EvasysNotificationModel[] = [];

	@Output()
	public deleteAction: EventEmitter<string> = new EventEmitter<string>();

	@Output()
	public apiCallAction: EventEmitter<EvasysNotificationActionModel> =
		new EventEmitter<EvasysNotificationActionModel>();

	//#endregion

	//#region Variables

	public collapsed = true;

	//#endregion

	public constructor(public readonly config: SharedUiConfiguration) {}

	//#region Events

	public onCollapseToggle(): void {
		this.collapsed = !this.collapsed;
	}

	onDeleteAction(notificationId: string): void {
		this.deleteAction.emit(notificationId);
	}

	onApiCallAction(action: EvasysNotificationActionModel): void {
		this.apiCallAction.emit(action);
	}

	//#endregion
}

import { animate, style, transition, trigger } from '@angular/animations';

export const opacityAnimation = () => {
	return trigger('opacityAnimation', [
		transition('void => *', [
			style({
				opacity: 0,
			}),
			animate(
				'250ms',
				style({
					opacity: 1,
				})
			),
		]),

		transition('* => void', [
			animate(
				250,
				style({
					opacity: 0,
				})
			),
		]),
	]);
};

import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { UserRightsEnum } from '@evasys/globals/shared/enums/business/user-rights.enum';
import { AuthUserService, UserModelClass } from '@evasys/shared/core';
import { Subscription } from 'rxjs';

@Directive({
	selector: '[evasysPermission]',
})
export class PermissionDirective implements OnInit, OnDestroy {
	//region Input & Output
	@Input()
	evasysPermission?: UserRightsEnum[];
	//endregion

	//region Variables
	private currentUser?: UserModelClass;
	private subscriptions: Subscription[] = [];
	//endregion

	//region constructor
	constructor(
		private readonly authUserService: AuthUserService,
		private templateRef: TemplateRef<any>,
		private viewContainer: ViewContainerRef
	) {}
	//endregion

	//region Events
	ngOnInit() {
		this.subscriptions.push(
			this.authUserService.authenticatedUserObservable.subscribe((user) => {
				this.currentUser = user;
				this.updateView();
			})
		);
	}

	ngOnDestroy() {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	//endregion

	//region Methods
	private updateView() {
		if (this.checkPermission()) {
			if (!this.viewContainer.get(0)) {
				this.viewContainer.createEmbeddedView(this.templateRef);
			}
		} else {
			this.viewContainer.clear();
		}
	}

	private checkPermission(): boolean {
		if (this.currentUser?.activeRight) {
			for (const checkPermission of this.evasysPermission) {
				if (this.currentUser.activeRight === checkPermission) {
					return true;
				}
			}
		}
		return false;
	}
	//endregion
}

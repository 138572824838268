import { inject, Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { GeneralMultiLang } from '@evasys/globals/evainsights/models/common/multi-lang';
import { MultiLangService } from '../language/multi-lang.service';

@Pipe({
	name: 'translateMultiLang',
})
export class TranslateMultiLangPipe implements PipeTransform {
	multiLangService = inject(MultiLangService);

	transform(texts: GeneralMultiLang<string> | null | undefined): Observable<string> {
		return this.multiLangService.translate(texts, '');
	}
}

import { Component, inject, TemplateRef, ViewChild } from '@angular/core';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'evainsights-page-developer-tools',
	templateUrl: './page-developer-tools.component.html',
})
export class PageDeveloperToolsComponent {
	private offcanvasService = inject(NgbOffcanvas);

	@ViewChild('content')
	content!: TemplateRef<unknown>;

	toggle() {
		if (this.offcanvasService.hasOpenOffcanvas()) {
			this.offcanvasService.dismiss();
		} else {
			this.offcanvasService.open(this.content, { position: 'start' });
		}
	}
}

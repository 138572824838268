import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IfContentModule } from '../../../../../../util/src/lib/directives/if-content/if-content.module';
import { ButtonModule } from '../../static-components/button/button.module';
import { ModalComponent } from './modal/modal.component';

@NgModule({
	declarations: [ModalComponent],
	imports: [CommonModule, IfContentModule, ButtonModule],
	exports: [ModalComponent],
})
export class ModalModule {}

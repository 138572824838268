import { createEntityAdapter } from '@ngrx/entity';
import { EvasysCollectionModel } from '@evasys/globals/shared/models/general/evasys-collection.model';
import { cloneDeep } from 'lodash';
import {
	entitySelector,
	errorSelector,
	hasErrorSelector,
	loadedPagesSelector,
	loadingSelector,
	manyEntitiesSelector,
	manyEntitiesWithFilterSelector,
	pageConfigSelector,
	pageSelector,
	responseSelector,
	selectWithFilterSelector,
} from '../selectors/evasys.selectors';
import { EvasysState } from '../states/evasys.state';
import { HttpErrorResponse } from '@angular/common/http';

export class EvasysEntityAdapter<T> {
	public entity = createEntityAdapter<T>();

	load<S extends EvasysState<T, unknown>>(state: S): S {
		return { ...state, error: undefined, loading: true };
	}

	loadFailed<S extends EvasysState<T, unknown>>(state: S, error: HttpErrorResponse): S {
		return { ...state, error: error, loading: false };
	}

	loadManySucceed<S extends EvasysState<T, unknown>>(collection: EvasysCollectionModel<T>, state: S): S {
		if (!collection?.pageConfig) {
			throw Error('call on loadManySucceed but no collection! Try loadOneSucceed!');
		}
		const newState = cloneDeep(state);
		if (collection?.pageConfig?.page && !state.loadedPages?.includes(collection.pageConfig?.page)) {
			newState.loadedPages?.push(collection.pageConfig?.page);
		}
		const upsertedState = this.entity.upsertMany(collection.entities, {
			...newState,
			loading: false,
			pageConfig: collection.pageConfig,
		});

		return upsertedState;
	}

	loadOneSucceed<S extends EvasysState<T, unknown>>(item: T, state: S): S {
		return this.entity.upsertOne(item, {
			...state,
			loading: false,
		});
	}

	getSelectors(state: any) {
		return {
			selectLoading: loadingSelector(state),
			selectPageConfig: pageConfigSelector(state),
			selectPage: pageSelector(state),
			selectLoadedPages: loadedPagesSelector(state),
			selectEntity: entitySelector(state),
			selectMany: manyEntitiesSelector(state),
			selectManyWithFilter: manyEntitiesWithFilterSelector<T>(state),
			selectError: errorSelector(state),
			selectResponse: responseSelector(state),
			selectHasError: hasErrorSelector(state),
			selectWithFilter: (filter: (entity: T) => boolean) => selectWithFilterSelector(state, filter),
		};
	}
}

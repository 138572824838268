import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, first, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { EvasysActionSet } from '@evasys/globals/shared/models/general/evasys-action-set.models';
import { ReportBaseService } from '@evasys/evainsights/shared/core';
import { ReportSearchRequest } from '@evasys/globals/evainsights/models/search/ReportSearchRequest';
import { Update } from '@ngrx/entity';

export abstract class AbstractReportEffects<T> {
	protected abstract readonly actions$: Actions;
	protected abstract readonly apiService: ReportBaseService<T, ReportSearchRequest>;

	protected createEffectGetOne(actionSet: EvasysActionSet<number, T>) {
		return createEffect(() => {
			return this.actions$.pipe(
				ofType(actionSet.DEFAULT),
				mergeMap((action) => {
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					return this.apiService.getById(action.payload.id).pipe(
						first(),
						map((response) => {
							// eslint-disable-next-line @typescript-eslint/ban-ts-comment
							// @ts-ignore
							return actionSet.SUCCESS({ payload: response, requestId: action.requestId });
						}),
						catchError((error) => {
							// eslint-disable-next-line @typescript-eslint/ban-ts-comment
							// @ts-ignore
							return of(actionSet.FAILURE({ error, requestId: action.requestId }));
						})
					);
				})
			);
		});
	}

	protected createEffectCreateOne(actionSet: EvasysActionSet<T, T>) {
		return createEffect(() => {
			return this.actions$.pipe(
				ofType(actionSet.DEFAULT),
				mergeMap((action) => {
					return this.apiService.saveNew(action.payload).pipe(
						first(),
						map((response) => {
							// eslint-disable-next-line @typescript-eslint/ban-ts-comment
							// @ts-ignore
							return actionSet.SUCCESS({ payload: response, requestId: action.requestId });
						}),
						catchError((error) => {
							// eslint-disable-next-line @typescript-eslint/ban-ts-comment
							// @ts-ignore
							return of(actionSet.FAILURE({ error, requestId: action.requestId }));
						})
					);
				})
			);
		});
	}

	protected createEffectCreateOneLocal(actionSet: EvasysActionSet<T, void>) {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		return this.createLocalEffect(actionSet);
	}

	protected createEffectDeleteOneLocal(actionSet: EvasysActionSet<number, void>) {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		return this.createLocalEffect(actionSet);
	}

	protected createLocalEffect(actionSet: EvasysActionSet<number | T, void>) {
		return createEffect(() => {
			return this.actions$.pipe(
				ofType(actionSet.DEFAULT),
				map((action) => {
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					return actionSet.SUCCESS({ payload: action.payload, requestId: action.requestId });
				})
			);
		});
	}

	protected createEffectEditOne(actionSet: EvasysActionSet<Update<T>, T>) {
		return createEffect(() => {
			return this.actions$.pipe(
				ofType(actionSet.DEFAULT),
				mergeMap((action) => {
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					return this.apiService.update(action.payload.changes).pipe(
						first(),
						map((response) => {
							// eslint-disable-next-line @typescript-eslint/ban-ts-comment
							// @ts-ignore
							return actionSet.SUCCESS({ payload: response, requestId: action.requestId });
						}),
						catchError((error) => {
							// eslint-disable-next-line @typescript-eslint/ban-ts-comment
							// @ts-ignore
							return of(actionSet.FAILURE({ error, requestId: action.requestId }));
						})
					);
				})
			);
		});
	}

	protected createEffectEditOneLocal(actionSet: EvasysActionSet<Update<T>, void>) {
		return createEffect(() => {
			return this.actions$.pipe(
				ofType(actionSet.DEFAULT),
				map((action) => {
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					return actionSet.SUCCESS({ payload: action.payload.changes, requestId: action.requestId });
				})
			);
		});
	}
}

import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';

@Pipe({
	name: 'toObservable',
})
export class ToObservablePipe<T> implements PipeTransform {
	transform(value: T | Observable<T>): Observable<T> {
		return value instanceof Observable ? value : of(value);
	}
}

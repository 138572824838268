import { animate, state, style, transition, trigger } from '@angular/animations';

export const x3DRotateAnimation = () => {
	return trigger('x3DRotateAnimation', [
		// ...
		state(
			'true',
			style({
				transform: 'rotate3d(0, 1, 0, 180deg)',
			})
		),
		state(
			'false',
			style({
				transform: 'rotate3d(0, 1, 0, 0deg)',
			})
		),
		transition('true <=> false', [animate('0.2s')]),
	]);
};

import * as fromTranslate from './translate.selectors';
import * as fromConfigs from './config.selectors';
import * as fromAuth from './auth.selectors';
import * as fromAuthUser from './auth-user.selectors';
import * as fromNotifications from './notification.selectors';

export const sharedSelectors = {
	fromTranslate,
	fromConfigs,
	fromAuth,
	fromAuthUser,
	fromNotifications,
};

import { Component, computed, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { SelectorComponent } from '../selector/selector.component';
import { isArray } from 'lodash';
import { Animations } from '@evasys/globals/shared/animations/animations';

@Component({
	selector: 'evasys-selector-item',
	templateUrl: './selector-item.component.html',
	animations: [Animations.xGrowAnimation('0', '20px'), Animations.noInitialAnimation, Animations.nestedAnimations],
})
export class SelectorItemComponent<T> implements OnInit {
	//region Injections

	public readonly selectorComponent = inject(SelectorComponent);

	//endregion

	//region Input & Output
	@Input({ required: true })
	value: T;

	@Input()
	allowDeselection = true;

	@Input()
	checkIcon = 'assets/images/icons/check2.svg';

	@Input()
	stretchItem = false;

	@Output()
	forbiddenDeselectionAction = new EventEmitter<void>();

	@Output()
	selectStateChanged = new EventEmitter<boolean>();

	//endregion

	//region Variables
	isSelected = computed(() =>
		isArray(this.selectorComponent.value())
			? this.selectorComponent.value().includes(this.value)
			: this.selectorComponent.value() === this.value
	);
	//endregion

	//region Events

	ngOnInit() {
		this.selectorComponent.options.set([...this.selectorComponent.options(), this.value]);
	}

	onSelect(mouseEvent: MouseEvent) {
		if (!this.selectorComponent.isDisabled) {
			if (
				mouseEvent.shiftKey &&
				this.selectorComponent.allowMultiSelect &&
				Array.isArray(this.selectorComponent.value()) &&
				(this.selectorComponent.value() as []).length > 0
			) {
				this.selectorComponent.selectBetween(this.value);
			} else if (this.isSelected()) {
				this.handleDeselection();
			} else {
				this.handleSelection();
			}
		}
	}

	onMouseEnter() {
		if (
			this.selectorComponent.isMouseDown() &&
			!this.isSelected() &&
			!this.selectorComponent.isDisabled &&
			this.selectorComponent.allowMultiSelect
		) {
			this.selectorComponent.onValueChange([...(this.selectorComponent.value() ?? []), this.value]);
		}
	}

	//endregion

	//region Methods
	private handleDeselection() {
		if (
			this.allowDeselection &&
			(!this.selectorComponent.forceSelection ||
				(this.selectorComponent.forceSelection &&
					Array.isArray(this.selectorComponent.value()) &&
					this.selectorComponent.value().length > 1))
		) {
			if (this.selectorComponent.allowMultiSelect) {
				this.selectorComponent.onValueChange(
					(this.selectorComponent.value() satisfies []).filter((item) => item !== this.value)
				);
			} else {
				this.selectorComponent.onValueChange(null);
			}
			this.selectStateChanged.emit(false);
		} else if (!this.allowDeselection) {
			this.forbiddenDeselectionAction.emit();
		}
	}

	private handleSelection() {
		if (this.selectorComponent.allowMultiSelect) {
			this.selectorComponent.onlyNewSelectionChange.emit(this.value);
			this.selectorComponent.onValueChange([...(this.selectorComponent.value() ?? []), this.value]);
		} else {
			this.selectorComponent.onValueChange(this.value);
		}
		this.selectStateChanged.emit(true);
	}

	//endregion
}

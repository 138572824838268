import { matrixShape } from '../common';
import { matrixFullBy } from '../creation';
import { matrixAt } from './at';
import { Matrix } from '@evasys/globals/evainsights/models/report-item';

export const matrixTranspose = (matrix: Matrix, axes: number[]): Matrix => {
	const inputShape = matrixShape(matrix);
	const outputShape = axes.map((axis) => inputShape[axis]);
	const reverseAxes = inverseTranspose(axes);

	return matrixFullBy(outputShape, (outputPosition) =>
		matrixAt(matrix, transposePosition(outputPosition, reverseAxes))
	);
};

const inverseTranspose = (axes: number[]): number[] => {
	const result = new Array(axes.length);
	axes.forEach((ax, index) => (result[ax] = index));
	return result;
};

const transposePosition = (position: number[], axes: number[]): number[] => axes.map((axis) => position[axis]);

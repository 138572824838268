import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportsModule } from './modules/reports.module';
import { ReportTemplatesModule } from './modules/reporttemplates.module';
import {
	menuAdapter,
	menuMetaReducers,
	menuReducer,
} from '../../../../../evasys/shared/core/src/lib/reducers/menu.reducer';
import { defaultEvasysState, EvasysState } from '@evasys/shared/core';
import { EvasysMenuModel } from '@evasys/globals/evasys/models/general/evasys-menu.model';
import { MenuState } from '@evasys/globals/evasys/models/states/menu.state';
import { EffectsModule } from '@ngrx/effects';
import { MenuEffects } from '../../../../../evasys/shared/core/src/lib/effects/menu.effects';
import { StoreModule } from '@ngrx/store';

const menuInitialState: EvasysState<EvasysMenuModel, MenuState> = menuAdapter.entity.getInitialState({
	...defaultEvasysState(),
	supplement: {
		permanentlyOpen: true,
		searchText: '',
	},
});

@NgModule({
	imports: [
		CommonModule,
		ReportsModule,
		ReportTemplatesModule,
		EffectsModule.forFeature([MenuEffects]),
		// @ts-ignore
		StoreModule.forFeature('menu', menuReducer, {
			metaReducers: menuMetaReducers,
			initialState: menuInitialState,
		}),
	],
	exports: [ReportsModule, ReportTemplatesModule],
})
export class EvainsightsStoresCoreModule {}

import { Dictionary } from '@ngrx/entity';

export enum UserRightsEnum {
	INSTRUCTOR = 0x00000001,
	DEAN = 0x00000002,
	ADMINISTRATOR = 0x00000004,
	DEAN_OF_STUDIES = 0x00000008,
	DATA_COLLECTOR = 0x00000010,
	SERIES_PRINT = 0x00000020,
	ARCHIVED = 0x00000040,
	REPORT_CREATOR = 0x00000080,
	WASTE_PAPER_BIN = 0x00000100,
	WEB_VERIFIER = 0x00000200,
	REPORTING_MASK = 0x00000400,
	SUBSECTION_ADMINISTRATOR = 0x00000800,
	MODUL = 0x00001000,
	BAHN_KAM_AND_ABT = 0x00002000,
	BAHN_F_AND_E = 0x00004000,
	BAHN_SCANKRAFT = 0x00008000,
	LINKED_USER = 0x00010000,
	FIRSTADMIN = 0x00020000,
	EXAM_ACCOUNT = 0x00040000,
	SURVEY_ACCOUNT = 0x00080000,
	CATEGORIZATION = 0x00100000,
}

//TODO: UNKNOWN mit strings ersetzen
export const UserRightDesignation: Dictionary<string> = {
	[UserRightsEnum.INSTRUCTOR]: 'S_USER_INSTRUCTOR',
	[UserRightsEnum.DEAN]: 'S_USER_DEAN',
	[UserRightsEnum.ADMINISTRATOR]: 'S_ADMINISTRATOR',
	[UserRightsEnum.DEAN_OF_STUDIES]: 'S_USER_DEANOFSTUDY',
	[UserRightsEnum.DATA_COLLECTOR]: 'S_USER_DATAENTRYASSISTANT',
	[UserRightsEnum.SERIES_PRINT]: 'S_MERGE_PRINT',
	[UserRightsEnum.ARCHIVED]: 'S_ARCHIVE',
	[UserRightsEnum.REPORT_CREATOR]: 'S_USER_REPORTCREATOR',
	[UserRightsEnum.WASTE_PAPER_BIN]: 'UNKNOWN',
	[UserRightsEnum.WEB_VERIFIER]: 'S_NUTZER_VERIFICATOR',
	[UserRightsEnum.REPORTING_MASK]: 'S_REPORT_MASK',
	[UserRightsEnum.SUBSECTION_ADMINISTRATOR]: 'S_NUTZER_SUBUNITADMIN',
	[UserRightsEnum.MODUL]: 'S_MODULE',
	[UserRightsEnum.BAHN_KAM_AND_ABT]: 'UNKNOWN',
	[UserRightsEnum.BAHN_F_AND_E]: 'UNKNOWN',
	[UserRightsEnum.BAHN_SCANKRAFT]: 'UNKNOWN',
	[UserRightsEnum.LINKED_USER]: 'UNKNOWN',
	[UserRightsEnum.FIRSTADMIN]: 'S_NUTZER_ADMIN',
	[UserRightsEnum.EXAM_ACCOUNT]: 'UNKNOWN',
	[UserRightsEnum.SURVEY_ACCOUNT]: 'UNKNOWN',
	[UserRightsEnum.CATEGORIZATION]: 'UNKNOWN',
};

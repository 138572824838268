import { EvasysActionsFactory } from '../essential/redux/actions/evasys-actions.factory';
import { EvasysNotificationModel } from '@evasys/globals/shared/models/evasys-notification.model';
import { Update } from '@ngrx/entity';

const actionsFactory = new EvasysActionsFactory<EvasysNotificationModel>('NOTIFICATION');

export const { ClearActionSet, LoadActionSet } = actionsFactory.createActions();

export const CreateOneActionSet = actionsFactory.createCustomActionSet<EvasysNotificationModel, void>(
	'createOne',
	false
);
export const CreateManyActionSet = actionsFactory.createCustomActionSet<EvasysNotificationModel[], void>(
	'createMany',
	false
);
export const DeleteOneActionSet = actionsFactory.createCustomActionSet<string, void>('deleteOne', false);
export const UpdateOneActionSet = actionsFactory.createCustomActionSet<Update<EvasysNotificationModel>, void>(
	'updateOne',
	false
);

import { createFeatureSelector } from '@ngrx/store';
import { EvasysState } from '@evasys/shared/core';
import { ReportTemplate } from '@evasys/globals/evainsights/models/report/report-reportTemplate.model';
import { reportTemplateAdapter } from '../reducers/reporttemplate.reducer';

export const getReportTemplateState = createFeatureSelector<EvasysState<ReportTemplate>>('reportTemplates');

export const { selectLoading, selectEntity, selectError, selectWithFilter } =
	reportTemplateAdapter.getSelectors(getReportTemplateState);

export const { selectIds, selectEntities } = reportTemplateAdapter.entity.getSelectors(getReportTemplateState);

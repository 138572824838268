import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { metaReducers, userInitialState, authUserReducer } from '../reducers/auth-user.reducer';
import { AuthUserEffects } from '../effects/auth-user.effects';

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		EffectsModule.forFeature([AuthUserEffects]),
		StoreModule.forFeature('authUser', authUserReducer, { metaReducers, initialState: userInitialState }),
	],
})
export class AuthUserModule {}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { endpoints } from '@evasys/globals/evainsights/api/endpoints';
import { KeyValueModel } from '@evasys/globals/evainsights/models/common/key-value.model';
import { Page } from '@evasys/globals/evainsights/models/pagination/page.model';
import { UnitSearchRequest } from '@evasys/globals/evainsights/models/search/UnitSearchRequest';

@Injectable({
	providedIn: 'root',
})
export class UnitService {
	constructor(private http: HttpClient) {}

	getUnits(filter: UnitSearchRequest = {}): Observable<Page<KeyValueModel>> {
		return this.http.get<Page<KeyValueModel>>(endpoints.getUnits.url(), {
			params: { ...filter },
		});
	}
}

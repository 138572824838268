import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const equalValidator = (firstControlName: string, secondControlName: string): ValidatorFn => {
	return (control: AbstractControl): ValidationErrors | null => {
		const firstControl = control.get(firstControlName);
		const secondControl = control.get(secondControlName);

		if (firstControl.value != secondControl.value && !(firstControl.disabled || secondControl.disabled)) {
			return { equal: [firstControlName, secondControlName] };
		}

		return null;
	};
};

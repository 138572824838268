import { Injectable } from '@angular/core';
import { ConfigModel } from '@evasys/globals/evasys/models/business/config.model';
import { Store } from '@ngrx/store';
import { catchError, map, tap } from 'rxjs/operators';
import { firstValueFrom, Observable, throwError } from 'rxjs';
import { sharedSelectors } from '../selectors';
import { sharedActions } from '../actions';
import { loadData } from '@evasys/shared/util';
import { EvasysLoadingStrategiesEnum } from '@evasys/globals/shared/enums/general/evasys-loadingStrategies.enum';
import { FacadeService } from '../essential/redux/services/facade.service';
import { EvasysState } from '../essential/redux/states/evasys.state';
import { catchFailure } from '../../../../util/src/lib/rxjs/rxjs.functions';
import { Actions } from '@ngrx/effects';

@Injectable({
	providedIn: 'root',
})
export class ConfigService extends FacadeService<ConfigModel> {
	constructor(private configStore: Store<EvasysState<ConfigModel>>, protected override actions: Actions) {
		super(configStore, sharedActions.fromConfigs, sharedSelectors.fromConfigs, actions);
	}

	/**
	 * @deprecated use the simple get-Method
	 */
	public getOneConfigObservable<T>(
		id: string,
		loadingStrategy = EvasysLoadingStrategiesEnum.APIONLY
	): Observable<T | null> {
		return this.configStore.select(sharedSelectors.fromConfigs.selectConfigWithName(id)).pipe(
			loadData(
				() =>
					this.configStore.dispatch(sharedActions.fromConfigs.LoadOneWithConfigName.DEFAULT({ payload: id })),
				() => this.configStore.select(sharedSelectors.fromConfigs.selectLoading),
				loadingStrategy,
				false
			),
			map((config: ConfigModel) => (config?.value as T) || null),
			tap((configValue) => {
				if (configValue === null) {
					console.error('Can not get configuration with name: ', id);
				}
			}),
			catchError((err) => throwError('Can not load Evasys-Config: ' + err))
		);
	}

	/**
	 * @deprecated use the simple get-Method
	 */
	public getOneConfigPromise<T>(id: string, loadingStrategy = EvasysLoadingStrategiesEnum.APIONLY) {
		return firstValueFrom(this.getOneConfigObservable<T>(id, loadingStrategy));
	}

	/**
	 * @deprecated use the simple get-Method
	 */
	getManyConfigs(names: string[], loadingStrategy: EvasysLoadingStrategiesEnum) {
		return catchFailure(
			this.store.select(sharedSelectors.fromConfigs.selectConfigsWithName(names)),
			this.store.select(sharedSelectors.fromConfigs.selectError)
		).pipe(
			loadData(
				() =>
					this.store.dispatch(
						sharedActions.fromConfigs.LoadManyActionSet.DEFAULT({
							payload: {
								ids: names,
								page: 1,
								pageSize: -1,
							},
						})
					),
				() => this.store.select(sharedSelectors.fromConfigs.selectLoading),
				loadingStrategy,
				true,
				names.length
			),
			map(
				(entities: ConfigModel[]) =>
					entities.reduce((result, entity) => {
						const key = entity.name;
						result[key] = entity?.value || null;
						return result;
					}, {}) as any
			)
		);
	}
}

import { Modifier } from '@popperjs/core';

// ensures that the popover is at least as wide as the reference
export const minWidthReference: Partial<Modifier<'minWidthReference', Record<string, never>>> = {
	name: 'minWidthReference',
	enabled: true,
	phase: 'beforeWrite',
	requires: ['computeStyles'],
	fn: ({ state }) => {
		state.styles.popper.minWidth = `${state.rects.reference.width}px`;
	},
	effect: ({ state }) => {
		state.elements.popper.style.minWidth = `${(state.elements.reference as HTMLElement).offsetWidth}px`;
	},
};

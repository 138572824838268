import { Injectable } from '@angular/core';
import { EvasysState, FacadeService } from '@evasys/shared/core';
import { Store } from '@ngrx/store';
import { reportActions } from '../actions';
import { reportSelectors } from '../selectors';
import { Actions } from '@ngrx/effects';
import { Report } from '@evasys/globals/evainsights/models/report/report-reportTemplate.model';

@Injectable({
	providedIn: 'root',
})
export class ReportFacadeService extends FacadeService<Report> {
	constructor(private readonly reportStore: Store<EvasysState<Report>>, override readonly actions: Actions) {
		super(reportStore, reportActions.fromReports, reportSelectors.fromReports, actions);
	}
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ValueByMapPipe } from './pipe/value-by-map.pipe';

@NgModule({
	declarations: [ValueByMapPipe],
	imports: [CommonModule],
	exports: [ValueByMapPipe],
})
export class ValueByMapModule {}

import { defaultEvasysState, EvasysEntityAdapter, EvasysReducerCaseFactory, EvasysState } from '@evasys/shared/core';
import { SystemInfoModel } from '@evasys/globals/evasys/models/business/system-info.model';
import { evasysActions } from '../actions';
import { Action, ActionReducer, createReducer, MetaReducer } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { environment } from '@evasys/globals/evasys/environments/environment';

export const systemInfoAdapter = new EvasysEntityAdapter<SystemInfoModel>();
export const systemInfoInitialState: EvasysState<SystemInfoModel> = systemInfoAdapter.entity.getInitialState(
	defaultEvasysState()
);
const systemInfoReducerCaseFactory = new EvasysReducerCaseFactory<SystemInfoModel>(
	systemInfoAdapter,
	evasysActions.fromSystemInfo
);

const { reduceLoadPageActionSet, reduceLoadOneActionSet, reduceLoadManyActionSet, reduceLoadActionSet } =
	systemInfoReducerCaseFactory.reduceEvasysActionSets();

export const systemInfoReducer = createReducer(
	systemInfoInitialState,
	...reduceLoadPageActionSet,
	...reduceLoadOneActionSet,
	...reduceLoadManyActionSet,
	...reduceLoadActionSet
);

export function reducer(state: EvasysState<SystemInfoModel> | undefined, action: Action) {
	return systemInfoReducer(state, action);
}

//MetaReducer to store the system info in localStorage

function localStorageSyncReducer(
	actionReducer: ActionReducer<EvasysState<SystemInfoModel>>
): ActionReducer<EvasysState<SystemInfoModel>> {
	return localStorageSync({
		keys: ['ids', 'entities', 'loadedPages', 'pageConfig'],
		rehydrate: true,
		storageKeySerializer: (key) => `${environment.baseHref}_systemInfo_${key}`,
	})(actionReducer);
}

export const metaReducers: MetaReducer<any>[] = [localStorageSyncReducer];

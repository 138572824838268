import { Injectable } from '@angular/core';
import { EvasysCollectionModel } from '@evasys/globals/shared/models/general/evasys-collection.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, first, map } from 'rxjs/operators';
import { EvasysApiRequestModel } from '@evasys/globals/shared/models/general/evasys-api-request.model';
import { EvasysRequestMethodEnum } from '@evasys/globals/shared/enums/general/evasys-request-method.enum';
import { SharedCoreConfiguration } from '../../../shared-core.configuration';

@Injectable({
	providedIn: 'root',
})
export class ApiService {
	constructor(private readonly http: HttpClient, private readonly sharedCoreConfiguration: SharedCoreConfiguration) {}

	private getDynamicParams(requestParams: any, httpParams: any) {
		requestParams.forEach(([key, value]: any) => {
			httpParams = httpParams.append(key, value);
		});
		return httpParams;
	}

	public request<M>(
		request: EvasysApiRequestModel,
		mapResponse: (data: any) => M = (data: any) => data,
		mapRequest: (data: M) => any = (data: any) => data
	) {
		const params = this.getParams(request);

		request = this.handleFileUpload(this.handleRequestBodyMapping<M>(request, mapRequest));

		return this.http
			.request(
				request.requestMethod,
				(request.baseUrl ?? this.sharedCoreConfiguration.api.baseUrl) + request.apiPath,
				{
					body: request.body,
					params,
				}
			)
			.pipe(
				map((response: any) => {
					if (
						request.requestMethod === EvasysRequestMethodEnum.GET ||
						request.requestMethod === EvasysRequestMethodEnum.POST ||
						request.requestMethod === EvasysRequestMethodEnum.PATCH
					) {
						return request.many
							? this.mapMany(response, request.apiPath, mapResponse)
							: mapResponse(response);
					} else {
						return response;
					}
				}),
				catchError((err) => {
					console.error('An error occurred in api request/response: ', err);
					throw err;
				}),
				first()
			);
	}

	private mapMany<M>(response: any, apiPath: string, mapFunction: (data: any) => M) {
		return {
			entities: (Array.isArray(response) ? (response as M[]) : (response.results as M[])).map((entity) =>
				mapFunction(entity)
			),
			pageConfig: {
				pageCount: response.page_count,
				pageSize: response.page_size,
				totalItems: response.total_items,
				page: response.page,
			},
		} as EvasysCollectionModel<M>;
	}

	private handleRequestBodyMapping<M>(request: EvasysApiRequestModel, mapRequest: (data: M) => any) {
		if (
			request.body &&
			Object.keys(request.body).length > 0 &&
			(request.requestMethod === EvasysRequestMethodEnum.POST ||
				request.requestMethod === EvasysRequestMethodEnum.PUT ||
				request.requestMethod === EvasysRequestMethodEnum.DELETE ||
				request.requestMethod === EvasysRequestMethodEnum.PATCH)
		) {
			request.body = mapRequest(request.body);
		}
		return request;
	}

	private getFile(request: EvasysApiRequestModel): File | null {
		if (request?.fileData?.file instanceof File) {
			//passing file directly
			return request.fileData.file;
		} else if (request.body && request.body[request?.fileData?.file] instanceof File) {
			//passing file as body part
			return request.body[request.fileData.file];
		}
		return null;
	}

	private replaceFilePropertyWithName(request: EvasysApiRequestModel, file: File) {
		if (!(request.fileData.file instanceof File)) {
			const bodyUpdate = {};
			Object.defineProperty(bodyUpdate, request.fileData.file, {
				value: file.name,
				writable: true,
				enumerable: true,
				configurable: true,
			});
			return { ...request.body, ...bodyUpdate };
		}
		return request.body;
	}

	private checkRequestMethod(request: EvasysApiRequestModel) {
		if (request.requestMethod !== EvasysRequestMethodEnum.POST) {
			console.warn(
				'Can not use "' +
					request.requestMethod +
					'" for a file upload. Only "POST" is allowed. (automatically changed to "POST"'
			);
			request.requestMethod = EvasysRequestMethodEnum.POST;
		}
		return request;
	}

	private handleFileUpload(request: EvasysApiRequestModel) {
		const file = this.getFile(request);
		//handle file upload
		if (file) {
			if (request.fileData.formName) {
				//Files (binary) can only passed with the content type multipart/form-data
				const formData = new FormData();
				formData.append(request.fileData.formName, file, file.name);
				//stringify body if exists
				if (request.body) {
					formData.append('data', JSON.stringify(this.replaceFilePropertyWithName(request, file)));
				}

				request.body = formData;

				//the backend only can get the file through POST
				request = this.checkRequestMethod(request);
			} else {
				console.error('Can not create a request with a file: No form name is passed.');
			}
		}
		return request;
	}

	private getParams(request: EvasysApiRequestModel) {
		let params = new HttpParams();
		if (request.requestMethod === EvasysRequestMethodEnum.GET) {
			if (request.fields) {
				params = params.append('fields', request.fields);
			}
			if (request.filter) {
				params = params.append('filter', request.filter);
			}
			if (request.page) {
				params = params.append('page', request.page.toString());
			}
			if (request.order) {
				params = params.append('order_by', request.order);
			}
			if (request.view) {
				params = params.append('view', request.view);
			}
			if (request.pageSize) {
				params = params.append('page_size', request.pageSize);
			}
			if (request.params && request.params.length > 0) {
				params = this.getDynamicParams(request.params, params);
			}
			return params;
		}
		return null;
	}
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Form } from '@evasys/globals/evainsights/models/form/form.model';
import { Observable } from 'rxjs';
import { endpoints } from '@evasys/globals/evainsights/api/endpoints';
import { Page } from '@evasys/globals/evainsights/models/pagination/page.model';
import { RestService } from '../rest-service';
import { FormSearchRequest } from '@evasys/globals/evainsights/models/search/FormSearchRequest';

@Injectable({
	providedIn: 'root',
})
export class FormService implements RestService<Form, FormSearchRequest> {
	constructor(private http: HttpClient) {
		// This is intentional;
	}

	getSearchResult(filter: FormSearchRequest = {}): Observable<Page<Form>> {
		return this.http.get<Page<Form>>(endpoints.getForms.url(), {
			params: { ...filter },
		});
	}

	getById(formId: number): Observable<Form> {
		return this.http.get<Form>(endpoints.getForm.url({ formId }));
	}
}

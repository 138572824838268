import { EvasysActionsFactory } from '@evasys/shared/core';
import { EvasysMenuModel } from '@evasys/globals/evasys/models/general/evasys-menu.model';
import { KeyValue } from '@angular/common';

const actionsFactory = new EvasysActionsFactory<EvasysMenuModel>('MENU');

export const { LoadAllActionSet, ClearActionSet, LoadActionSet } = actionsFactory.createActions();

export const OpenPermanentlyActionSet = actionsFactory.createCustomActionSet<boolean, void>(
	'openPermanentlyAction',
	false
);

export const SetSearchTextActionSet = actionsFactory.createCustomActionSet<string, void>('setSearchTextAction', false);

export const SetMenuMarkActionSet = actionsFactory.createCustomActionSet<KeyValue<string, boolean>, void>(
	'setExtrasInfoAction',
	false
);

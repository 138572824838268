import { range } from 'lodash';
import { getMatrixNdims } from './common';
import { Matrix } from '@evasys/globals/evainsights/models/report-item';

export type MatrixAxisSpecification = number | number[] | { exclude?: number | number[] };

export const getMatrixAxes = (matrix: Matrix, spec?: MatrixAxisSpecification): number[] => {
	const ndims = getMatrixNdims(matrix);
	if (typeof spec === 'number') {
		spec = [spec];
	}

	if (Array.isArray(spec)) {
		if (spec.length > new Set(spec).size) {
			throw Error('Duplicate axes');
		}

		spec = [...spec].sort((a, b) => a - b);
		if (spec.some((ax) => ax < 0 || ax >= ndims)) {
			throw Error('Invalid axis that is either negative or beyond the matrix dimensionality');
		}

		return spec;
	}

	const allAxes = range(ndims);
	if (spec?.exclude !== undefined) {
		const excludeSet = new Set(asArray(spec.exclude));
		return allAxes.filter((ax) => !excludeSet.has(ax));
	} else {
		return allAxes;
	}
};

export const invertAxes = (matrix: Matrix, axis?: MatrixAxisSpecification): number[] => {
	const includedAxes = new Set(getMatrixAxes(matrix, axis));
	return range(getMatrixNdims(matrix)).filter((ax) => !includedAxes.has(ax));
};

const asArray = <T>(values: T | T[]): T[] => (Array.isArray(values) ? values : [values]);

import { Component, inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Survey } from '@evasys/globals/evainsights/models/survey/survey.entity';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { hasNoFilterCriteria } from '@evasys/globals/evainsights/helper/respondentFilter';
import { nonNullish } from '@evasys/globals/evainsights/typeguards/common';
import { RespondentFilter } from '@evasys/globals/evainsights/models/report/item.model';
import { NgIf } from '@angular/common';
import {
	CountConfig,
	RespondentCountParams,
	RespondentCountTranslocoKeyParts,
} from '@evasys/globals/evainsights/models/respondent/respondent.model';

@Component({
	selector: 'evainsights-survey-filtered-respondent-count',
	templateUrl: './survey-filtered-respondent-count.component.html',
	standalone: true,
	imports: [TranslocoModule, NgIf],
})
export class SurveyFilteredRespondentCountComponent implements OnChanges {
	translocoService = inject(TranslocoService);

	@Input()
	survey!: Survey;

	@Input({ required: true })
	reportRespondentFilter!: RespondentFilter;

	countConfig: CountConfig | undefined;

	ngOnChanges(changes: SimpleChanges) {
		if (changes['surveys'] || changes['reportRespondentFilter']) {
			const params: RespondentCountParams = {
				total: this.survey.responseCount,
				min: this.survey.minRespondentCount,
				filtered: this.survey.filteredRespondentCount,
			};

			this.countConfig = {
				keyParts: this.getRespondentCountTranslocoKeyParts(params),
				params,
			};
		}
	}

	getRespondentCountTranslocoKeyParts({
		total,
		min,
		filtered,
	}: RespondentCountParams): RespondentCountTranslocoKeyParts {
		if (filtered === undefined) {
			return { coarse: 'loading', fine: 'unknown' };
		} else if (filtered === null) {
			return {
				coarse: 'belowThreshold',
				fine: total < min ? 'dueToTotalRespondents' : 'dueToFilter',
			};
		} else {
			const coarse = 'meetsThreshold';

			if (filtered < total) {
				return { coarse, fine: 'partial' };
			} else if (hasNoFilterCriteria(nonNullish(this.reportRespondentFilter))) {
				return { coarse, fine: 'unfiltered' };
			} else {
				return { coarse, fine: 'complete' };
			}
		}
	}
}

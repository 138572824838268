import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Translation } from '../translation.interface';

@Injectable({
  providedIn: 'platform',
})
export class CalenderTranslationService {
  private CalendarTranlation: BehaviorSubject<any> = new BehaviorSubject<any>({});

  setTranslation(value: Translation) {
    this.CalendarTranlation.next(value);
  }

  get listTranslation(): any {
    return this.CalendarTranlation.value;
  }
}

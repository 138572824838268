import { defaultEvasysState, EvasysEntityAdapter, EvasysReducerCaseFactory, EvasysState } from '@evasys/shared/core';
import { Action, ActionReducer, createReducer, MetaReducer, on } from '@ngrx/store';
import { evasysActions } from '../actions';
import { MenuState } from '@evasys/globals/evasys/models/states/menu.state';
import { EvasysMenuModel } from '@evasys/globals/evasys/models/general/evasys-menu.model';
import { localStorageSync } from 'ngrx-store-localstorage';
import { Update } from '@ngrx/entity';
import { getWindowVariable } from '@evasys/globals/shared/helper/window';

export const menuAdapter = new EvasysEntityAdapter<EvasysMenuModel>();
export const menuInitialState: EvasysState<EvasysMenuModel, MenuState> = menuAdapter.entity.getInitialState({
	...defaultEvasysState(),
	supplement: {
		permanentlyOpen: false,
		searchText: '',
	},
});

const menuReducerCaseFactory = new EvasysReducerCaseFactory<EvasysMenuModel>(menuAdapter, evasysActions.fromMenu);

const { reduceLoadAllActionSet, reduceClearActionSet } =
	menuReducerCaseFactory.reduceEvasysActionSets<EvasysState<EvasysMenuModel, MenuState>>();

export const menuReducer = createReducer(
	menuInitialState,
	...reduceLoadAllActionSet,
	...reduceClearActionSet,
	on(
		evasysActions.fromMenu.OpenPermanentlyActionSet.DEFAULT,
		(state: EvasysState<EvasysMenuModel, MenuState>, { payload }) => ({
			...state,
			supplement: { ...state.supplement, permanentlyOpen: payload },
		})
	),
	on(
		evasysActions.fromMenu.SetSearchTextActionSet.DEFAULT,
		(state: EvasysState<EvasysMenuModel, MenuState>, { payload }) => ({
			...state,
			supplement: { ...state.supplement, searchText: payload },
		})
	),
	on(
		evasysActions.fromMenu.SetMenuMarkActionSet.DEFAULT,
		(state: EvasysState<EvasysMenuModel, MenuState>, { payload }) =>
			menuAdapter.entity.updateOne(
				{ id: payload.key, changes: { mark: payload.value } } as Update<EvasysMenuModel>,
				state
			)
	)
);

export function reducer(state: EvasysState<EvasysMenuModel, MenuState> | undefined, action: Action) {
	return menuReducer(state, action);
}

//MetaReducer to store the system info in localStorage

function localStorageSyncReducer(
	reducer: ActionReducer<EvasysState<EvasysMenuModel, MenuState>>
): ActionReducer<EvasysState<EvasysMenuModel, MenuState>> {
	return localStorageSync({
		keys: ['supplement'],
		rehydrate: true,
		storageKeySerializer: (key) =>
			`${getWindowVariable<string>('base-href') ? getWindowVariable<string>('base-href') + '_' : ''}menu_${key}`,
	})(reducer);
}

export const menuMetaReducers: Array<MetaReducer<EvasysState<EvasysMenuModel, MenuState>>> = [localStorageSyncReducer];

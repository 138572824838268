import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
	SelectorGroupModel,
	SelectorItemModel,
} from '@evasys/globals/evasys/models/component/order-selector-item.model';
import { Animations } from '@evasys/globals/shared/animations/animations';
import { isSelectorGroup } from '@evasys/shared/util';
import { ChangeEventFunc, SelectId } from '@evasys/globals/shared/types/select.types';
import { Required } from '@evasys/globals/shared/decorators/decorators';

@Component({
	selector: 'evasys-select-area',
	templateUrl: './select-area.component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: SelectAreaComponent,
			multi: true,
		},
	],
	animations: [Animations.yGrowAnimation(), Animations.fadeInLeftAnimation],
})
export class SelectAreaComponent implements ControlValueAccessor {
	//#region Input & Output
	@Input()
	@Required()
	id?: string;

	public get canFocus(): boolean {
		return !this.focused && !this.input.disabled && this._canFocus;
	}

	@Input()
	public set canFocus(focusable: boolean) {
		this._canFocus = focusable;
	}

	@Input()
	name?: string;

	public get selectItems(): SelectItems {
		return this._selectItems;
	}

	@Input()
	public set selectItems(items: SelectItems) {
		this._selectItems = items;
		this.itemsChange.emit(items);
	}

	@Input()
	loading?: boolean;

	public get focused(): boolean {
		return !this.input.disabled && this._focused;
	}

	@Input()
	public set focused(toggle: boolean) {
		if (this._focused !== toggle) {
			this._focused = toggle;
			this.focusedChange.emit(this.focused);
		}
	}

	@Input()
	public multiple = false;

	@Output()
	itemsChange = new EventEmitter<SelectItems>();

	@Output()
	focusedChange = new EventEmitter<boolean>();

	@Output()
	selectionChange = new EventEmitter<SelectId | SelectId[] | null>();

	@Output()
	pageChange = new EventEmitter<number>();

	//#endergion Input & Output

	//#region Variables
	public input = new FormControl();

	private _canFocus = false;

	private _selectItems: SelectItems = [];

	private _focused = false;
	//#endregion Variables

	//#region Events
	public onClick(): void {
		if (this.canFocus) {
			this.focused = true;
		}
	}

	_onChange: ChangeEventFunc = () => {
		//default
	};
	_onTouched: any = () => {
		//default
	};

	//#endregion Events

	//#region ControlValueAccessor

	registerOnChange(fn: ChangeEventFunc): void {
		this._onChange = fn;
		this.input.registerOnChange(this._onChange);
	}

	registerOnTouched(fn: any): void {
		this._onTouched = fn;
	}

	setDisabledState(isDisabled: boolean): void {
		isDisabled ? this.input.disable() : this.input.enable();
	}

	writeValue(selected: SelectId | SelectId[] | null): void {
		if (selected != null) {
			if (!Array.isArray(selected)) {
				selected = [selected];
			}
			for (const [index, id] of selected.entries()) {
				if (typeof id === 'number') {
					selected[index] = id.toString();
				}
			}
		}

		this.input.setValue(selected);
		this.selectionChange.emit(selected);
	}

	//#endregion ControlValueAccessor

	public get items(): SelectorItemModel[] {
		const items = [];
		for (const selectItem of this.selectItems ?? []) {
			if (isSelectorGroup(selectItem)) {
				items.push(...selectItem.items);
			} else {
				items.push(selectItem);
			}
		}
		items.forEach((item) => {
			item.id = typeof item.id === 'number' ? item.id.toString() : item.id;
		});
		return items;
	}
}

type SelectItems = (SelectorItemModel | SelectorGroupModel)[];

import { minBy, range, sum } from 'lodash';
import { assertNotNullish } from '@evasys/globals/evainsights/typeguards/common';

type Row = number[];
type Split = Row[];

export const distributeEvenly = (lengths: number[], maxWidth: number, paddingBetween: number): number[][] => {
	const getRowWidth = (rowLengths: number[]) =>
		rowLengths.length > 0 ? sum(rowLengths) + rowLengths.length * paddingBetween : 0;

	const possibleSplits = getPossibleSplits(lengths);
	const allowedSplits = possibleSplits.filter((split) =>
		split.every((row) => row.length === 1 || getRowWidth(row) <= maxWidth)
	);
	const minRowCount = Math.min(...allowedSplits.map((split) => split.length));
	const minRowSplits = allowedSplits.filter((split) => split.length === minRowCount);
	const bestSplit = minBy(minRowSplits, (split) => Math.max(...split.map(getRowWidth)));
	assertNotNullish(bestSplit);
	return getSplitIndices(bestSplit);
};

const getPossibleSplits = (values: number[]): Split[] => {
	if (values.length === 0) {
		return [[]];
	}

	const [head, ...rest] = values;
	const restSplits = getPossibleSplits(rest);

	return [
		...restSplits
			.filter((restSplit) => restSplit.length > 0)
			.map(([firstRestRow, ...remainingRestRows]) => [[head, ...firstRestRow], ...remainingRestRows]),
		...restSplits.map((restSplit) => [[head], ...restSplit]),
	];
};

const getSplitIndices = (split: Split) => {
	let idx = 0;
	const rowIndices: number[][] = [];
	for (const row of split) {
		rowIndices.push(range(idx, idx + row.length));
		idx += row.length;
	}
	return rowIndices;
};

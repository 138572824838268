import { Component, EventEmitter, Output } from '@angular/core';
import { ExternalIdUserModel } from '@evasys/globals/evasys/models/business/external-id-user.model';
import { sortBy } from 'lodash';

@Component({
	selector: 'evasys-modal-multiple-users',
	templateUrl: './modal-multiple-users.component.html',
})
export class ModalMultipleUsersComponent {
	//region Input & Output

	@Output()
	openChange: EventEmitter<boolean> = new EventEmitter<boolean>();

	@Output()
	submitChange: EventEmitter<string> = new EventEmitter<string>();

	//endregion

	//region Variables
	public name?: string;
	public externalIdUsers?: ExternalIdUserModel[];

	public opened = false;
	//endregion

	//region Events

	onSubmit(id: string) {
		this.close();
		this.submitChange.emit(id);
	}

	//endregion

	//region Methods

	public close(): Promise<void> {
		return new Promise<void>((resolve) => {
			this.opened = false;

			setTimeout(() => {
				this.name = null;
				this.externalIdUsers = null;

				resolve();
			}, 100);
		});
	}

	public open(externalIdUsers: ExternalIdUserModel[]) {
		this.name = externalIdUsers[0].fullName;
		let userList: ExternalIdUserModel[] = sortBy(externalIdUsers, ['subunitName', 'orderId'], ['asc', 'asc']);
		userList = userList.reduce((list: ExternalIdUserModel[], user: ExternalIdUserModel) => {
			// orderId 0 is Administrator
			if (user.orderId === 0) {
				return [user, ...list];
			} else {
				return [...list, user];
			}
		}, []);

		this.externalIdUsers = userList;
		this.opened = true;
	}
}

import {
	ApplicationRef,
	ComponentRef,
	createComponent,
	EmbeddedViewRef,
	inject,
	Injectable,
	runInInjectionContext,
} from '@angular/core';
import { fromEvent } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { PageDeveloperToolsComponent } from '../components/page-developer-tools/page-developer-tools.component';

@Injectable()
export class DomInjectorService {
	//region Injections
	private appRef = inject(ApplicationRef);
	//endregion

	//region Variables
	private developerToolsRef?: ComponentRef<PageDeveloperToolsComponent>;
	//endregion

	constructor() {
		this.developerToolsRef = this.mountComponent();
		runInInjectionContext(this.appRef.injector, () => {
			fromEvent(window, 'keydown').pipe(takeUntilDestroyed()).subscribe(this.onKeyDown);
		});
	}

	//region events
	private onKeyDown = (event: Event) => {
		const keyboardEvent = event as KeyboardEvent;
		if (keyboardEvent.shiftKey && keyboardEvent.altKey && keyboardEvent.key === 'Enter') {
			this.toggle();
		}
	};
	//endregion

	//region methods
	mountComponent() {
		const componentRef = createComponent(PageDeveloperToolsComponent, {
			environmentInjector: this.appRef.injector,
		});
		this.appRef.attachView(componentRef.hostView);
		document.body.appendChild((componentRef.hostView as EmbeddedViewRef<unknown>).rootNodes[0] as HTMLElement);
		return componentRef;
	}

	toggle() {
		this.developerToolsRef?.instance.toggle();
	}
	//endregion
}

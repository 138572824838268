import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { endpoints } from '@evasys/globals/evainsights/api/endpoints';
import { map } from 'rxjs/operators';
import { runtimeEnvironment } from '@evasys/globals/evainsights/environments/runtime-environment';

@Injectable({
	providedIn: 'root',
})
export class TenantService {
	constructor(private http: HttpClient) {
		// This is intentional;
	}

	getTenantRegistered(): Observable<boolean> {
		return this.http
			.get<{ value: boolean }>(
				endpoints.getTenantRegisteredBySubDomainName.url({
					tenantSubdomain: runtimeEnvironment.tenantSubdomain,
				})
			)
			.pipe(map((response) => response.value));
	}
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownComponent } from './dropdown/dropdown.component';
import { DropdownPrimaryComponent } from './styles/dropdown-primary/dropdown-primary.component';
import { DropdownFilterComponent } from './styles/dropdown-filter/dropdown-filter.component';
import { DropdownMenuDirective } from './dropdown-menu/dropdown-menu.directive';
import { NgxPopperjsModule } from 'ngx-popperjs';
import { DropdownToggleDirective } from './dropdown-toggle/dropdown-toggle.directive';
import { DropdownValueDirective } from './dropdown-value/dropdown-value.directive';
import { TextOverflowModule } from '../../../directives/text-overflow/text-overflow.module';
import { ClickAwayModule } from '../../../directives/click-away/click-away.module';
import { ImageModule } from '../../../directives/image/image.module';

@NgModule({
	declarations: [
		DropdownComponent,
		DropdownPrimaryComponent,
		DropdownFilterComponent,
		DropdownMenuDirective,
		DropdownToggleDirective,
		DropdownValueDirective,
	],
	imports: [CommonModule, NgxPopperjsModule, TextOverflowModule, ClickAwayModule, ImageModule],
	exports: [
		DropdownComponent,
		DropdownPrimaryComponent,
		DropdownFilterComponent,
		DropdownMenuDirective,
		DropdownToggleDirective,
		DropdownValueDirective,
	],
})
export class DropdownModule {}

import { AbstractControl, FormGroup, ValidatorFn } from '@angular/forms';
import { asArray } from '../../general/as-array';

export function requiresControl(controls: string | string[]): ValidatorFn {
	return (abstractControl: AbstractControl) => {
		const formGroup = abstractControl as FormGroup;
		const valid = asArray(controls).every((control) => control in formGroup.controls);
		return valid ? null : { requiresControl: true };
	};
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextOverflowDirective } from './text-overflow/text-overflow.directive';

@NgModule({
	declarations: [TextOverflowDirective],
	imports: [CommonModule],
	exports: [TextOverflowDirective],
})
export class TextOverflowModule {}

import { EvasysStatusModel } from '@evasys/globals/evasys/models/general/evasys-status.model';
import { EvasysActionsFactory } from '@evasys/shared/core';
import { EvasysNotificationActionModel } from '@evasys/globals/shared/models/evasys-notification-action.model';

const actionsFactory = new EvasysActionsFactory<EvasysStatusModel>('STATUS');

export const { LoadAllActionSet, LoadOneActionSet, LoadActionSet } = actionsFactory.createActions();

export const EulaAcceptedActionSet = actionsFactory.createCustomActionSet<EvasysNotificationActionModel, void>(
	'AcceptEula'
);

export const transmitEvasysUpdateStatus = actionsFactory.createCustomActionSet<void, void>(
	'transmitEvasysUpdateStatus'
);

export const transmitEvasysUpdateRentalStatus = actionsFactory.createCustomActionSet<string, void>(
	'transmitEvasysUpdateRentalStatus'
);

export const transmitExtrasUpdateStatus = actionsFactory.createCustomActionSet<number, void>(
	'transmitExtrasUpdateStatus'
);

export const deleteOneStatusActionSet = actionsFactory.createCustomActionSet<string, void>('deleteOneStatus', false);

export const createOneStatusActionSet = actionsFactory.createCustomActionSet<EvasysStatusModel, void>(
	'createOneStatus',
	false
);

export enum EndpointsEnum {
	CONFIGS = 'configs',
	INFOS = 'infos',
	LOGOS = 'logos',
	MENUS = 'menus',
	PERIODS = 'periods',
	STATUS = 'status',
	SUBUNITS = 'subunits',
	USERS = 'users',
	WIZARDS = 'wizards',
}

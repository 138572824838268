export function calculateDateTimeForSystemTimezone(date: Date) {
	const targetTime = new Date(date);
	//UTC+2 === -120 and UTC-2 === +120, because of that * -1
	const tzDifference = -1 * targetTime.getTimezoneOffset();
	return new Date(targetTime.getTime() + tzDifference * 60 * 1000);
}

export function getDateTimeInSystemTimezone(date: Date | undefined) {
	return date ? calculateDateTimeForSystemTimezone(date) : '';
}

<nav
	class="navbar navbar-expand-lg navbar-light w-100 p-0 pe-lg-3 position-fixed fixed-top c-h-55px c-z-index-4 eva-topmenu-bg-color"
>
	<div class="d-flex flex-row flex-nowrap">
		<button
			class="btn btn-link navbar-toggler mx-1 mx-sm-4 my-0 border-0"
			type="button"
			data-toggle="collapse"
			[attr.aria-expanded]="!isMobileMenuCollapsed"
			[attr.data-cy]="'topmenu-button-hamburger'"
			(click)="collapse.toggle()"
		>
			<img
				evasysImage
				[id]="'hamburger-menu'"
				[src]="'./assets/images/icons/menu.svg'"
				[isIcon]="true"
				[class]="'c-h-20px'"
			/>
		</button>
		<div class="mx-2">
			<evasys-product-menu
				[renderOnOpen]="true"
				*ngIf="!!products && (products | reduce : connected : false)"
				[refreshMethod]="productRefreshMethod"
			>
				<ng-container *ngFor="let product of products">
					<ng-container *ngIf="product.isActive && product.url">
						<evasys-product-menu-item
							*evasysPermission="product.rights"
							[id]="product.id"
							[name]="product.name | transloco"
							[url]="product.url"
						></evasys-product-menu-item>
					</ng-container>
				</ng-container>
			</evasys-product-menu>
		</div>
	</div>
	<button class="h-100 border-0 m-0 p-0" (click)="logoClickedAction.emit()">
		<img
			evasysImage
			[src]="logo"
			[id]="'topMenu'"
			[alt]="logoAlt ?? ''"
			[attr.class]="'eva-topmenu-logo-bg-color mt-1 c-w-150px c-h-42px'"
			[isIcon]="true"
		/>
	</button>

	<div
		class="mx-1 mx-sm-4 my-0 my-lg-0 mx-lg-0 order-lg-4 h-100 c-w-lg-75px c-w-lg-max-110px c-z-index-3 d-flex flex-nowrap flex-row"
	>
		<evasys-usermenu
			(roleResponse)="roleResponse.emit($event)"
			[roles]="roleItems"
			(logoutResponse)="logoutResponse.emit()"
			(systemUsageCall)="systemUsageCall.emit()"
			(userMenuOpened)="userMenuOpened.emit()"
			(openUserSwitch)="openUserSwitch.emit()"
			[username]="username"
			[role]="role"
			[system]="system"
			[canSwitchUser]="canSwitchUser"
			[showSystemUsage]="showSystemUsage"
			[activeOnlineUser]="activeOnlineUser"
			[activeSystemUser]="activeSystemUser"
			class="mx-1 mx-sm-4 my-0 my-lg-0 mx-lg-0 order-lg-4 h-100 c-z-index-3"
		></evasys-usermenu>
		<evasys-notification-collection
			[notifications]="notifications"
			(deleteAction)="onDeleteNotification($event)"
			(apiCallAction)="onApiCallAction($event)"
		></evasys-notification-collection>
	</div>
	<div
		class="collapse navbar-collapse row m-0 justify-content-lg-between mx-0 mx-lg-4 eva-topmenu-bg-color"
		#collapse="ngbCollapse"
		[(ngbCollapse)]="isMobileMenuCollapsed"
	>
		<evasys-tabs
			[responsive]="true"
			[tabitems]="menuItems"
			(response)="menuResponse.emit($event)"
			[underline]="false"
			class="col-12 col-lg-auto order-lg-2 align-self-start p-3 p-sm-5 p-lg-0 m-lg-0 c-h-lg-55px d-lg-flex align-items-lg-end"
		></evasys-tabs>

		<div
			class="col-12 col-lg-2 order-lg-3 px-2 py-0 px-lg-0 m-0 ms-1 ms-md-0 ms-sm-0 d-flex border-0 align-self-start justify-content-end"
		>
			<div class="c-position-lg-absolute">
				<evasys-search-help
					[searchText]="searchText"
					(helpAction)="helpAction.emit($event)"
					(searchAction)="searchAction.emit($event)"
				></evasys-search-help>
			</div>
		</div>
	</div>
</nav>

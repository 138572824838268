import { SelectorGroupModel } from '@evasys/globals/evasys/models/component/order-selector-item.model';
import { isSelectorItem } from './selector-item.type-guard';

export function isSelectorGroup(object: any): object is SelectorGroupModel {
	return (
		typeof object === 'object' &&
		'name' in object &&
		typeof object.name === 'string' &&
		'items' in object &&
		Array.isArray(object.items) &&
		(object.items.length === 0 ||
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			object.items.every((item) => isSelectorItem(item)))
	);
}

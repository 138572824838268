import {
	FormElementDescriptorService,
	FormElementTranslate,
	getContextLanguageCode,
	getLanguageCode,
	selectByReportLanguage,
} from '@evasys/evainsights/shared/util';
import { inject, Injectable } from '@angular/core';
import { Item } from '@evasys/globals/evainsights/models/item/item.model';
import { ReportLanguage } from '@evasys/globals/evainsights/models/report/report-reportTemplate.model';
import { maxLengthTitle } from '@evasys/globals/evainsights/validations/validations';
import { FormMultiLang, ReportMultiLang } from '@evasys/globals/evainsights/models/common/multi-lang';
import { ItemOption } from '@evasys/globals/evainsights/models/item/item-value-position.model';
import { TranslocoService } from '@ngneat/transloco';
import { hasText } from '@evasys/globals/shared/helper/string';

@Injectable({
	providedIn: 'root',
})
export class ReportMultiLangConfigFieldService {
	protected translocoService = inject(TranslocoService);
	protected descriptorService = inject(FormElementDescriptorService);

	forItemOption = (reportLanguages: ReportLanguage[], itemOption: ItemOption): ReportMultiLangConfigField => {
		return {
			value: reportLanguages.map((reportLanguage) => ({
				reportLanguageId: reportLanguage.id,
				value: selectByReportLanguage(itemOption.text, reportLanguage),
			})),
			constraints: { required: false },
		};
	};

	forItem = (reportLanguages: ReportLanguage[], item: Item): ReportMultiLangConfigField => {
		return {
			value: reportLanguages.map((reportLanguage) => ({
				reportLanguageId: reportLanguage.id,
				value: this.descriptorService.getItemDescriptor(item, this.getTranslate(reportLanguage)),
			})),
			constraints: { required: true },
		};
	};

	forItems = (reportLanguages: ReportLanguage[], items: Item[]): ReportMultiLangConfigField => {
		return {
			value: reportLanguages.map((reportLanguage) => ({
				reportLanguageId: reportLanguage.id,
				value: items
					.map((item) => this.descriptorService.getItemDescriptor(item, this.getTranslate(reportLanguage)))
					.join(' / ')
					.substring(0, maxLengthTitle),
			})),
			constraints: { required: true, maxLength: maxLengthTitle },
		};
	};

	protected getTranslate = (reportLanguage: ReportLanguage): FormElementTranslate => ({
		key: (key: string) => {
			const contextLanguageCode = getContextLanguageCode(this.translocoService.getActiveLang());
			const languageCode = getLanguageCode(reportLanguage.primaryUserLanguage, contextLanguageCode);
			return this.translocoService.translate(key, undefined, languageCode);
		},
		form: (formMultiLang: FormMultiLang<string>) => {
			const text = selectByReportLanguage(formMultiLang, reportLanguage);
			return hasText(text) ? text : null;
		},
	});
}

export interface ReportMultiLangConfigField {
	value: ReportMultiLang<string>;
	constraints: TextConstraints;
}

export interface TextConstraints {
	required: boolean;
	maxLength?: number;
}

import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TabsComponent } from './components/dynamic-components/tabs/tabs.component';
import { RadioButtonComponent } from './components/control-components/radio-button/radio-button.component';
import { MenuComponent } from './components/app-shell-components/menu/menu.component';
import { ListComponent } from './components/static-components/list/list.component';
import { TopmenuComponent } from './components/app-shell-components/topmenu/topmenu.component';
import { UsermenuComponent } from './components/app-shell-components/usermenu/usermenu.component';
import { SidemenuComponent } from './components/app-shell-components/sidemenu/sidemenu.component';
import { SidebarComponent } from './components/app-shell-components/sidebar/sidebar.component';
import { PaginationComponent } from './components/dynamic-components/pagination/pagination.component';
import { StaticNotificationComponent } from './components/static-components/static-notification/static-notification.component';
import { OrderSelectorComponent } from './components/control-components/order-selector/order-selector.component';
import { IsMenuActivePipe } from './pipes/sidemenu/of-sub-menu.pipe';
import { UploadBoxComponent } from './components/control-components/upload-box/upload-box.component';
import { RouterModule } from '@angular/router';
import { SharedUtilModule } from '@evasys/shared/util';
import { SearchHelpComponent } from './components/app-shell-components/search-help/search-help.component';
import { SelectAreaComponent } from './components/control-components/select-area/select-area.component';
import { LoadComponentComponent } from './components/dynamic-components/load-component/load-component.component';
import { DropdownOptionalComponent } from './components/control-components/dropdown/old/dropdown-optional/dropdown-optional.component';
import { ProgressBarComponent } from './components/dynamic-components/progress-bar/progress-bar.component';
import { SelectAreaPipeComponent } from './components/dynamic-components/select-area-pipe/select-area-pipe.component';
import { FooterComponent } from './components/app-shell-components/footer/footer.component';
import { ChipTypeaheadComponent } from './components/control-components/dropdown/old/chip-typeahead/chip-typeahead.component';
import { TypeaheadComponent } from './components/control-components/dropdown/old/typeahead/typeahead.component';
import { WizardComponent } from './components/dynamic-components/wizard/wizard.component';
import { KeyValueToMapPipe } from './pipes/wizard/key-value-to-map.pipe';
import { KeyValueToSelectItemsPipe } from './pipes/wizard/key-value-to-select-items.pipe';
import { ConstraintsToTextboxTypePipe } from './pipes/wizard/constraints-to-textbox-type.pipe';
import { SharedUiConfiguration } from './shared-ui.configuration';
import { TranslocoModule } from '@ngneat/transloco';
import { ProgressStepsComponent } from './components/dynamic-components/progress-steps/progress-steps.component';
import { BreadcrumbComponent } from './components/dynamic-components/breadcrumb/breadcrumb.component';
import { CollapseButtonComponent } from './components/static-components/collapse-button/collapse-button.component';
import { TableCellClickableComponent } from './components/static-components/table-cell-clickable/table-cell-clickable.component';
import { TableCellTextComponent } from './components/static-components/table-cell-text/table-cell-text.component';
import { TableCellImageComponent } from './components/static-components/table-cell-image/table-cell-image.component';
import { MarkComponent } from './components/static-components/mark/mark.component';
import { SystemUsageComponent } from './components/app-shell-components/system-usage/system-usage.component';
import { SidebarContainerComponent } from './components/app-shell-components/sidebar-container/sidebar-container.component';
import { OverlayComponent } from './components/static-components/overlay/overlay.component';
import { DisabledDirective } from './directives/is-disabled.directive';
import { HesitateDirective } from './directives/hesitate.directive';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { TableStickyDirective } from './directives/table-sticky.directive';
import { TextAreaComponent } from './components/control-components/text-area/text-area.component';
import { ChipComponent } from './components/static-components/chip/chip.component';
import { TopMenuComponent } from './components/app-shell-components/top-menu/top-menu.component';
import { LegalNoticeComponent } from './components/app-shell-components/legal-notice/legal-notice.component';
import { ShowMoreComponent } from './components/dynamic-components/show-more/show-more.component';
import { ShowMoreContainerDirective } from './components/dynamic-components/show-more/show-more-container.directive';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ImageModule } from './directives/image/image.module';
import { MoreMenuComponent } from './components/dynamic-components/more-menu/more-menu.component';
import { MoreMenuItemComponent } from './components/dynamic-components/more-menu/more-menu-item/more-menu-item.component';
import { NgxPopperjsModule } from 'ngx-popperjs';
import { MetaInfoModule } from './components/static-components/meta-info/meta-info.module';
import { StatusCardModule } from './components/dynamic-components/status-card/status-card.module';
import { LoadingOverlayModule } from './components/dynamic-components/loading-overlay/loading-overlay.module';
import { SearchboxComponent } from './components/app-shell-components/searchbox/searchbox.component';
import { EmptyContentComponent } from './components/static-components/empty-content/empty-content.component';
import { ProductMenuModule } from './components/app-shell-components/product-menu/src/product-menu/product-menu.module';
import { TableAreaModule } from './components/static-components/table-area/table-area.module';
import { NotificationCollectionComponent } from './components/app-shell-components/notification-collection/notification-collection.component';
import { PopperMenuComponent } from './components/dynamic-components/popper-menu/popper-menu.component';
import { ButtonToggleComponent } from './components/control-components/button-toggle/button-toggle.component';
import { AccordionComponent } from './components/dynamic-components/accordion/accordion.component';
import { AccordionItemComponent } from './components/dynamic-components/accordion/accordion-item/accordion-item.component';
import { ToggleButtonDirective } from './components/control-components/button-toggle/toggle-button.directive';
import { StepFilterModule } from './components/dynamic-components/step-filter/step-filter.module';
import { PageModule } from './components/template-components/page/page.module';
import { HeaderModule } from './components/static-components/header/header.module';
import { SelectorModule } from './components/control-components/selector/selector.module';
import { ButtonModule } from './components/static-components/button/button.module';
import { TextOverflowModule } from './directives/text-overflow/text-overflow.module';
import { DropdownModule } from './components/control-components/dropdown/dropdown.module';
import { KeepMapOrderModule } from '../../../util/src/lib/pipes/keep-map-order/keep-map-order.module';
import { ClickAwayModule } from './directives/click-away/click-away.module';
import { ClickAwayDirective } from './directives/click-away/click-away/click-away.directive';
import { TableModule } from './components/static-components/table/table.module';
import { CheckboxModule } from './components/control-components/checkbox/checkbox.module';
import { PermissionsModule } from '../../../../evasys/shared/feature/src/lib/permissions/permissions.module';
import { TextboxModule } from './components/control-components/textbox/textbox.module';
import { NumbersOnlyModule } from './directives/numbers-only/numbers-only.module';
import { NoteModule } from './components/static-components/note/note.module';
import { ErrorModule } from './components/dynamic-components/error/error.module';
import { FoldableModule } from './directives/foldable/foldable.module';
import { InModule } from './pipes/wizard/in/in.module';
import { ModalModule } from './components/dynamic-components/modal/modal.module';
import { InlineTranslocoModule } from '../../../util/src/lib/pipes/inline-transloco/inline-transloco.module';
import { InputAutoFillOffDirective } from '../../../../evainsights/shared/util/src/lib/directives/input-auto-fill-off.directive';
import { NgLetModule } from 'ng-let';
import { TooltipModule } from './directives/tooltip/tooltip.module';

@NgModule({
	imports: [
		CommonModule,
		NgbModule,
		ReactiveFormsModule,
		RouterModule,
		SharedUtilModule,
		FormsModule,
		TranslocoModule,
		ScrollingModule,
		InfiniteScrollModule,
		ImageModule,
		ProductMenuModule,
		NgxPopperjsModule,
		MetaInfoModule,
		StatusCardModule,
		LoadingOverlayModule,
		TableAreaModule,
		StepFilterModule,
		PageModule,
		HeaderModule,
		SelectorModule,
		ButtonModule,
		TextOverflowModule,
		DropdownModule,
		KeepMapOrderModule,
		ClickAwayModule,
		CheckboxModule,
		TextboxModule,
		NumbersOnlyModule,
		NoteModule,
		ErrorModule,
		FoldableModule,
		InModule,
		PermissionsModule,
		ModalModule,
		InlineTranslocoModule,
		InputAutoFillOffDirective,
		NgLetModule,
		TooltipModule,
	],
	declarations: [
		TabsComponent,
		RadioButtonComponent,
		MenuComponent,
		ListComponent,
		TopmenuComponent,
		UsermenuComponent,
		SidebarComponent,
		SidemenuComponent,
		SidebarContainerComponent,
		PaginationComponent,
		StaticNotificationComponent,
		OrderSelectorComponent,
		IsMenuActivePipe,
		UploadBoxComponent,
		SearchHelpComponent,
		SelectAreaComponent,
		LoadComponentComponent,
		DropdownOptionalComponent,
		ProgressBarComponent,
		SelectAreaPipeComponent,
		FooterComponent,
		TypeaheadComponent,
		ChipTypeaheadComponent,
		WizardComponent,
		KeyValueToMapPipe,
		KeyValueToSelectItemsPipe,
		ConstraintsToTextboxTypePipe,
		ProgressStepsComponent,
		NotificationCollectionComponent,
		BreadcrumbComponent,
		CollapseButtonComponent,
		TableCellClickableComponent,
		TableCellTextComponent,
		TableCellImageComponent,
		MarkComponent,
		SystemUsageComponent,
		DisabledDirective,
		OverlayComponent,
		HesitateDirective,
		TableStickyDirective,
		TextAreaComponent,
		PopperMenuComponent,
		ChipComponent,
		LegalNoticeComponent,
		ButtonToggleComponent,
		ToggleButtonDirective,
		TopMenuComponent,
		ShowMoreComponent,
		ShowMoreContainerDirective,
		AccordionComponent,
		AccordionItemComponent,
		MoreMenuComponent,
		MoreMenuItemComponent,
		SearchboxComponent,
		EmptyContentComponent,
	],
	exports: [
		TabsComponent,
		RadioButtonComponent,
		MenuComponent,
		ListComponent,
		TopmenuComponent,
		UsermenuComponent,
		SidebarComponent,
		SidemenuComponent,
		SidebarContainerComponent,
		PaginationComponent,
		ModalModule,
		StaticNotificationComponent,
		OrderSelectorComponent,
		UploadBoxComponent,
		SelectAreaComponent,
		SearchHelpComponent,
		LoadComponentComponent,
		DropdownOptionalComponent,
		ProgressBarComponent,
		SelectAreaPipeComponent,
		FooterComponent,
		TypeaheadComponent,
		ChipTypeaheadComponent,
		WizardComponent,
		NotificationCollectionComponent,
		BreadcrumbComponent,
		CollapseButtonComponent,
		TableCellClickableComponent,
		TableCellTextComponent,
		TableCellImageComponent,
		MarkComponent,
		InModule,
		SystemUsageComponent,
		OverlayComponent,
		HesitateDirective,
		DisabledDirective,
		TableStickyDirective,
		TextAreaComponent,
		PopperMenuComponent,
		ChipComponent,
		TopMenuComponent,
		LegalNoticeComponent,
		ToggleButtonDirective,
		ButtonToggleComponent,
		ShowMoreComponent,
		ShowMoreContainerDirective,
		AccordionComponent,
		AccordionItemComponent,
		ImageModule,
		ProductMenuModule,
		MoreMenuComponent,
		MoreMenuItemComponent,
		MetaInfoModule,
		StatusCardModule,
		LoadingOverlayModule,
		TableAreaModule,
		StepFilterModule,
		PageModule,
		SearchboxComponent,
		EmptyContentComponent,
		HeaderModule,
		SelectorModule,
		ButtonModule,
		ClickAwayDirective,
		TableModule,
		TextOverflowModule,
		CheckboxModule,
		TextboxModule,
		NumbersOnlyModule,
		ErrorModule,
		NoteModule,
		FoldableModule,
		TooltipModule,
	],
})
export class SharedUiModule {
	static forRoot(config: SharedUiConfiguration): ModuleWithProviders<any> {
		return {
			ngModule: SharedUiModule,
			providers: [
				{
					provide: SharedUiConfiguration,
					useValue: config,
				},
			],
		};
	}
}

import { Pipe, PipeTransform } from '@angular/core';
import { UserRightDesignation, UserRightsEnum } from '@evasys/globals/shared/enums/business/user-rights.enum';
import { TranslocoService } from '@ngneat/transloco';

@Pipe({
	name: 'userRightsDesignation',
})
export class UserRightsDesignationPipe implements PipeTransform {
	public constructor(private readonly translocoService: TranslocoService) {}

	transform(value: UserRightsEnum[], activeRole?: UserRightsEnum): Map<UserRightsEnum, string> | null {
		if (activeRole) {
			value = value.filter((role) => role !== activeRole && role !== UserRightsEnum.FIRSTADMIN);
		}

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		return value
			? new Map<UserRightsEnum, string>(
					Object.values(value).map((val) => [val, this.translocoService.translate(UserRightDesignation[val])])
			  )
			: null;
	}
}

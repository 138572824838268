import { EntityState } from '@ngrx/entity';
import { EvasysPageConfigModel } from '@evasys/globals/shared/models/general/evasys-page-config.model';
import { HttpErrorResponse } from '@angular/common/http';

export interface EvasysState<T, S = void> extends EntityState<T> {
	error?: HttpErrorResponse;
	loading: boolean;
	loadedPages: number[];
	pageConfig: EvasysPageConfigModel;
	supplement: S;
}

export function defaultEvasysState() {
	return {
		ids: [],
		entities: {},
		error: undefined,
		loading: false,
		loadedPages: [],
		supplement: undefined,
		pageConfig: {
			pageCount: 0,
			pageSize: 0,
			page: 0,
			totalItems: 0,
		},
	};
}

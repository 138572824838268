import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'commaSeparated',
})
export class CommaSeparatedPipe implements PipeTransform {
	transform(value: string[], separator = ', '): string {
		return value.join(separator);
	}
}

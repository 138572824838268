import { WithNonNullishProperties } from '../../shared/types/with-required-properties.type';
import { enumValues } from '../../shared/helper/enum';

export const isNullish = (v: unknown): v is null | undefined => v === null || v === undefined;

export const isNotNullish = <T>(v: T | null | undefined): v is T => !isNullish(v);

export function assertNotNullish<T>(v: T | undefined | null): asserts v is T {
	if (isNullish(v)) {
		throw Error('Value should not be undefined');
	}
}

export function nonNullish<T>(v: T | null | undefined): T {
	assertNotNullish(v);
	return v;
}

export const getPropertyNotNullishGuard =
	<P extends PropertyKey>(property: P) =>
	<T extends { [p in P]?: unknown }>(object: T): object is WithNonNullishProperties<T, P> =>
		isNotNullish(object[property]);

export const isEnumValue = <O extends object>(value: unknown, obj: O): value is O[keyof O] => {
	const values: unknown[] = enumValues(obj);
	return values.includes(value);
};

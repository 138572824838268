import { inject, Injectable } from '@angular/core';
import { EvasysState } from '@evasys/shared/core';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { EvasysUserModel } from '@evasys/globals/shared/models/general/evasys-user.model';
import { sharedSelectors } from '../../../../../../../shared/core/src/lib/selectors';
import { Observable } from 'rxjs';
import { EvasysTokenModel } from '@evasys/globals/shared/models/general/evasys-token.model';

@Injectable({
	providedIn: 'root',
})
export class AuthUserServiceHelperService {
	private readonly userStore = inject(Store<EvasysState<EvasysUserModel>>);
	private readonly tokenStore = inject(Store<EvasysState<EvasysTokenModel>>);

	public getUserIdFromAuthUser(): Observable<number> {
		return this.userStore.select(sharedSelectors.fromAuthUser.selectAuthenticatedUser).pipe(
			map((user) => {
				return Number(user?.id);
			})
		);
	}

	public getAuthenticatedUser(): Observable<EvasysUserModel | null> {
		return this.userStore.select(sharedSelectors.fromAuthUser.selectAuthenticatedUser);
	}

	public getToken(): Observable<EvasysTokenModel | undefined> {
		// token is undefined before logging in
		return this.tokenStore.select(sharedSelectors.fromAuth.selectToken);
	}
}

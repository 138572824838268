import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
	selector: 'evasys-footer',
	templateUrl: './footer.component.html',
	// We don't use this to set global style, for some reason without this
	// angular filters the style-attributes in our footer-text, making
	// things like DEV-version not marked in red.
	encapsulation: ViewEncapsulation.None,
})
export class FooterComponent {
	//region Input & Output

	@Input()
	public footerText?: string;

	//endregion
}

import { MessageformatConfig, TRANSLOCO_MESSAGE_FORMAT_CONFIG } from '@ngneat/transloco-messageformat';
import { makeEnvironmentProviders } from '@angular/core';
import { TRANSLOCO_TRANSPILER } from '@ngneat/transloco';
import { EvasysMessageformatTranspiler } from './evasys-messageformat.transpiler';

export function provideTranslocoEvasysMessageformat(config?: MessageformatConfig) {
	return makeEnvironmentProviders([
		{
			provide: TRANSLOCO_MESSAGE_FORMAT_CONFIG,
			useValue: config,
		},
		{
			provide: TRANSLOCO_TRANSPILER,
			useClass: EvasysMessageformatTranspiler,
		},
	]);
}

<div class="h5 mt-3">
	<strong>{{ sharedUiConfigs.translations?.systemUsage.headlineTranslation | transloco }}</strong>
	<div class="d-flex align-items-center mt-2">
		<a class="c-cursor-pointer" (click)="onSystemUsageCall()">
			<img
				evasysImage
				[id]="'system-user'"
				[src]="'./assets/images/icons/users3.svg'"
				[alt]="'Icon'"
				[isIcon]="true"
				[class]="' me-2 c-w-16px c-h-16px'"
			/>
			<span>{{ sharedUiConfigs.translations?.systemUsage.systemTranslation[system] | transloco }}</span>
		</a>
		<a (click)="onSystemUsageCall()" data-cy="text-activeUserSessionCount" class="ms-auto c-cursor-pointer">{{
			activeSystemUser
		}}</a>
	</div>
	<div class="d-flex align-items-center mt-1">
		<a class="c-cursor-pointer" (click)="onSystemUsageCall()">
			<img
				evasysImage
				[id]="'online-user'"
				[src]="'./assets/images/icons/users3.svg'"
				[alt]="'Icon'"
				[isIcon]="true"
				[class]="' me-2 c-w-16px c-h-16px'"
			/>
			<span>{{ sharedUiConfigs.translations?.systemUsage.onlineUserTranslation[system] | transloco }}</span>
		</a>
		<a (click)="onSystemUsageCall()" data-cy="text-onlineSessionCount" class="ms-auto c-cursor-pointer">{{
			activeOnlineUser
		}}</a>
	</div>
</div>

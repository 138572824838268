import { Component, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '@evasys/shared/core';
import { DomInjectorService } from '../../services/dom-injector.service';
import { runtimeEnvironment } from '@evasys/globals/evainsights/environments/runtime-environment';

@Component({
	selector: 'evainsights-area-developer-login',
	templateUrl: './area-developer-login.component.html',
})
export class AreaDeveloperLoginComponent {
	//region injections
	private formBuilder = inject(FormBuilder);
	private authService = inject(AuthService);
	private domInjectorService = inject(DomInjectorService);
	//endregion

	//region form
	public loginform: FormGroup = this.formBuilder.group({
		username: [undefined, [Validators.required]],
		password: [undefined, [Validators.required]],
	});
	//endregion

	//region variables
	submitted = false;
	//endregion

	//region events

	onSubmit() {
		this.submitted = true;
		if (this.loginform.valid) {
			this.loginWithCredentials(this.loginform.value).then(() => {
				this.domInjectorService.toggle();
			});
		}
	}
	//endregion

	//region methods

	private loginWithCredentials(credentials: { username: string; password: string }) {
		return this.authService
			.login({
				username: credentials.username,
				password: credentials.password,
				userId: undefined,
				captcha: undefined,
				tenantSubdomain: runtimeEnvironment.tenantSubdomain,
			})
			.catch((error) => {
				console.error(error);
			});
	}
	//endregion
}

import { createFeatureSelector } from '@ngrx/store';
import { EvasysState } from '@evasys/shared/core';
import { Report } from '@evasys/globals/evainsights/models/report/report-reportTemplate.model';
import { reportAdapter } from '../reducers/report.reducer';

export const getReportState = createFeatureSelector<EvasysState<Report>>('reports');

export const { selectLoading, selectEntity, selectError, selectWithFilter } =
	reportAdapter.getSelectors(getReportState);

export const { selectIds, selectEntities } = reportAdapter.entity.getSelectors(getReportState);

import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class EvasysSharedFeatureConfiguration {
	multiPageUrl: string;
	prodMode: boolean;
	baseHref: string;
}

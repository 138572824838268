import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { AuthService } from '@evasys/shared/core';
import { from, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { paths } from '@evasys/globals/evainsights/constants/paths';

@Injectable({
	providedIn: 'root',
})
export class IsNotAuthenticatedGuard {
	public constructor(private readonly authService: AuthService, private readonly router: Router) {}

	public canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
		return this.authService.hasToken().pipe(
			switchMap((isAuthenticated) => {
				const newSession = route.queryParams['forwardinfo'];
				if (newSession && isAuthenticated) {
					return from(this.authService.logout().then(() => isAuthenticated));
				}
				return of(isAuthenticated);
			}),
			map((isAuthenticated) => {
				//User has a valid auth token
				if (isAuthenticated) {
					return this.router.createUrlTree([paths.getReports.build()]);
				} else {
					return true;
				}
			})
		);
	}
}

import { EvasysActionsEnum } from '@evasys/globals/shared/enums/general/evasys-actions.enum';
import { ReportTemplate } from '@evasys/globals/evainsights/models/report/report-reportTemplate.model';
import { Update } from '@ngrx/entity';
import { createActionSet } from './createActionSet';

const storeKey = '[REPORTTEMPLATES]';

export const LoadActionSet = createActionSet<number, ReportTemplate>(storeKey, EvasysActionsEnum.LOAD);
export const CreateOneActionSet = createActionSet<ReportTemplate, ReportTemplate>(
	storeKey,
	EvasysActionsEnum.CREATEONE
);
export const CreateOneLocalActionSet = createActionSet<ReportTemplate, void>(
	storeKey,
	EvasysActionsEnum.CREATEONELOCAL
);
export const EditOneActionSet = createActionSet<Update<ReportTemplate>, ReportTemplate>(
	storeKey,
	EvasysActionsEnum.EDITONE
);
export const EditOneLocalActionSet = createActionSet<Update<ReportTemplate>, void>(
	storeKey,
	EvasysActionsEnum.EDITONELOCAL
);
export const DeleteOneLocalActionSet = createActionSet<number, void>(storeKey, EvasysActionsEnum.DELETEONELOCAL);

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextboxComponent } from './textbox/textbox.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NumbersOnlyModule } from '../../../directives/numbers-only/numbers-only.module';
import { ErrorModule } from '../../dynamic-components/error/error.module';
import { NoteModule } from '../../static-components/note/note.module';
import { ImageModule } from '../../../directives/image/image.module';

@NgModule({
	declarations: [TextboxComponent],
	imports: [CommonModule, ReactiveFormsModule, NumbersOnlyModule, NoteModule, ErrorModule, ImageModule],
	exports: [TextboxComponent],
})
export class TextboxModule {}

<div
	class="form-check"
	[ngClass]="labelPosition === _labelPosition.LEFT ? 'd-inline-flex align-items-center flex-row-reverse' : ''"
	[class.form-switch]="role === 'switch'"
	[class.align-items-center]="arrange === 'center'"
	[class.form-check-alone]="hiddenLabel"
>
	<input
		#input
		class="form-check-input"
		type="checkbox"
		(change)="onChecked()"
		[attr.value]="value"
		[attr.name]="name"
		[attr.id]="id"
		[disabled]="_isDisabled"
		[class.is-invalid]="isInvalid()"
		[checked]="_checked === true"
		[ngClass]="labelPosition === _labelPosition.LEFT ? 'ms-3' : ''"
		[attr.data-cy]="'checkbox-' + id"
		[attr.role]="role"
	/>
	<label #labelElement [for]="id" [attr.hidden]="hiddenLabel" class="form-check-label d-inline">
		<ng-content></ng-content>
	</label>

	<evasys-error *ngIf="isInvalid()" [id]="'checkbox-' + id" [errors]="errors"></evasys-error>
</div>

import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

export const emailValidator = (): ValidatorFn => {
	return (control: AbstractControl): ValidationErrors | null => {
		let validationResult = null;

		const value: string = control.value;
		if (!value || value === '') {
			return null;
		}

		//angular email validation
		validationResult = Validators.email(control);

		//custom validation
		if (validationResult === null) {
			//regex copied from backend: module/ep-legacy/src/legacycode/classes/email_validation.php
			const emailRegex = new RegExp(
				"^['_a-zA-Z0-9-]+(\\.['_a-zA-Z0-9-]+)*@[a-zA-Z0-9-]+(\\.[a-zA-Z0-9-]{1,})*\\.([a-zA-Z]{2,}){1}"
			);
			validationResult = emailRegex.test(value) ? null : { email: true };
		}

		return validationResult;
	};
};

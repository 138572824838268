<div (evasysClickAway)="onOpenStateChange(false)" class="dropdown h-100">
	<button class="border-0 h-100 m-0 d-block" (click)="onOpenStateChange(!isOpen)" data-cy="productsMenu-toggle">
		<img
			evasysImage
			[src]="switchlogo ?? './assets/images/icons/subunit.svg'"
			[id]="'productsMenu'"
			[alt]="productstranslation ?? (config.translations.productsMenu.products | transloco)"
			[class]="'c-w-22px c-h-22px'"
			[altTextAsLabel]="true"
		/>
	</button>

	<div
		[style.display]="!renderOnOpen && !isOpen ? 'none' : 'flex'"
		*ngIf="!renderOnOpen || (renderOnOpen && isOpen)"
		class="dropdown-menu card c-z-index-100 shadow position-absolute mt-1 py-3"
	>
		<span class="d-block mb-2 px-4 fw-bold">{{
			switchtotranslation ?? (config.translations.productsMenu.switchTo | transloco)
		}}</span>
		<ng-content></ng-content>
	</div>
</div>

export function enumValues<O extends object>(obj: O): O[keyof O][] {
	return Object.entries(obj)
		.filter(([key]) => Number.isNaN(Number(key)))
		.map(([, value]) => value);
}

export function enumKeys<O extends object>(obj: O): (keyof O)[] {
	return Object.keys(obj).filter((key) => Number.isNaN(Number(key))) as (keyof O)[];
}

export function getEnumValue<O extends object>(obj: O, name: unknown): O[keyof O] | undefined {
	return enumValues(obj).find((enumValue) => enumValue === name);
}

export const enumKey = <O extends object>(obj: O, id: unknown) => Object.keys(obj)[Object.values(obj).indexOf(id)];

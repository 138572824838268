import { DataIndexDimensionType, DimensionMappingConfig, VisualizationDimensionType } from '../models/report-item';
import { isMatch as isLodashMatch } from 'lodash';

const buildFindMappingByDataType =
	<Type>(type: Type) =>
	<Mapping extends DimensionMappingConfig<object, object>>(mappings: Mapping[]) => {
		for (const mapping of mappings) {
			if (isMatch(mapping, { data: { type: type } })) {
				return mapping;
			}
		}
		return undefined;
	};

const buildFindMappingByVisualizationType =
	<Type>(type: Type) =>
	<Data, Visualization extends object>(mappings: DimensionMappingConfig<Data, Visualization>[]) => {
		for (const mapping of mappings) {
			for (const visualization of mapping.visualizations) {
				if (isMatch(visualization, { type })) {
					return { mapping, visualization };
				}
			}
		}
		return undefined;
	};

const isMatch = <Value extends object, Pattern extends object>(
	value: Value,
	pattern: Pattern
): value is Value & Pattern => isLodashMatch(value, pattern);

// find by mapping data type
export const findItemMapping = buildFindMappingByDataType(DataIndexDimensionType.ITEM as const);
export const findItemOptionMapping = buildFindMappingByDataType(DataIndexDimensionType.ITEM_OPTION as const);
export const findPeriodMapping = buildFindMappingByDataType(DataIndexDimensionType.PERIOD as const);
export const findTopicMapping = buildFindMappingByDataType(DataIndexDimensionType.TOPIC as const);

// find by mapping visualization type
export const findColorMapping = buildFindMappingByVisualizationType(VisualizationDimensionType.COLOR as const);
export const findPatternMapping = buildFindMappingByVisualizationType(VisualizationDimensionType.PATTERN as const);
export const findGroupMapping = buildFindMappingByVisualizationType(VisualizationDimensionType.GROUP as const);
export const findStackMapping = buildFindMappingByVisualizationType(VisualizationDimensionType.STACK as const);
export const findRowMapping = buildFindMappingByVisualizationType(VisualizationDimensionType.ROW as const);
export const findColumnMapping = buildFindMappingByVisualizationType(VisualizationDimensionType.COLUMN as const);

//Enum zum Mappen der Bootstrap Klassen
export enum ButtonDesignEnum {
	PRIMARY = 'primary',
	OUTLINE_PRIMARY = 'outline-primary',
	SECONDARY = 'secondary',
	OUTLINE_SECONDARY = 'outline-secondary',
	LINK = 'link',
	OUTLINE_LINK = 'outline-link',
	DANGER = 'danger',
	OUTLINE_DANGER = 'outline-danger',
}

import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Required } from '@evasys/globals/shared/decorators/decorators';
import { ButtonDesignEnum } from '@evasys/globals/shared/enums/component/button-design.enum';
import { sizesType } from '@evasys/globals/shared/types/sizes.type';

@Component({
	selector: 'evasys-modal',
	templateUrl: './modal.component.html',
})
export class ModalComponent {
	//region ViewChild
	@ViewChild('content')
	content?: ElementRef;

	@ViewChild('footerRef')
	footerRef?: ElementRef;

	//endregion

	//region Input & Output
	@Input()
	@Required()
	id: string;

	@Input()
	public closeable = true;

	@Input()
	size: sizesType = 'lg';

	@Input()
	scrollable = true;

	@Input()
	public set opened(open: boolean) {
		if (open && !this._open) {
			this.modal = this.modalService.open(this.content, {
				size: this.size,
				scrollable: this.scrollable,
				centered: true,
				backdrop: this.closeable ? true : 'static',
				beforeDismiss: () => this.onDismiss(),
			});
			this._open = true;
		} else if (!open && this._open) {
			this.modal?.dismiss();
		}
	}

	@Output()
	public openedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

	//#endregion Input & Output

	//#region Variables
	private _open = false;
	private modal?: NgbModalRef;
	public buttonDesign = ButtonDesignEnum;

	//#endregion Variables

	constructor(private modalService: NgbModal) {
		//init
	}

	//#region Events

	onDismiss() {
		this._open = false;
		this.openedChange.emit(false);
		return true;
	}

	//#endregion Events

	public open(): void {
		this.opened = true;
	}

	public close(): void {
		this.opened = false;
	}
}

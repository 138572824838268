import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ModalComponent } from '@evasys/shared/ui';
import { Required } from '@evasys/globals/shared/decorators/decorators';
import { ButtonDesignEnum } from '@evasys/globals/shared/enums/component/button-design.enum';

@Component({
	selector: 'evasys-modal-confirm',
	templateUrl: './modal-confirm.component.html',
})
export class ModalConfirmComponent {
	@ViewChild('modal')
	public modal!: ModalComponent;

	@Input()
	@Required()
	public id!: string;

	@Output()
	public accept = new EventEmitter<void>();

	@Output()
	public reject = new EventEmitter<void>();

	public readonly designs = ButtonDesignEnum;

	public confirm(): void {
		this.modal.open();
	}
}

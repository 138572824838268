<div class="d-flex align-items-center" [attr.data-cy]="'chip-' + id">
	<img
		*ngIf="imageSrc"
		evasysImage
		[id]="'chip-' + id + '-info'"
		[alt]="imageAlt"
		[class]="'c-h-20px c-w-20px mx-1 align-self-center'"
		[src]="imageSrc"
	/>
	<div [title]="text" class="text-truncate h4 m-0 text-light px-1" data-toggle="tooltip" title="{{ text }}">
		{{ text }}
	</div>
	<evasys-button
		class="ms-auto d-flex"
		[id]="'chip-' + id + '-delete'"
		[isDisabled]="isDisabled"
		(click)="_removeAction(); $event.stopPropagation()"
		><img
			evasysImage
			[id]="'chip-' + id + '-delete'"
			[alt]="'delete-icon'"
			[class]="'c-w-20px c-h-20px'"
			[src]="'./assets/images/icons/delete2_deactivated.svg'"
		/>
	</evasys-button>
</div>

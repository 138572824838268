import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class AppCheckerService {
	public hasPopupBlocker(): boolean {
		try {
			const test = window.open('', '', 'width=10,height=10');
			if (test != undefined) test.close();
			return false;
		} catch (e) {
			return true;
		}
	}
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SharedUiConfiguration } from '../../../shared-ui.configuration';

@Component({
	selector: 'evasys-system-usage',
	templateUrl: './system-usage.component.html',
})
export class SystemUsageComponent {
	// region Inputs & Outputs
	@Input()
	system: 'evasys' | 'evaexam';

	@Input()
	activeSystemUser: number;

	@Input()
	activeOnlineUser: number;

	@Output()
	systemUsageCall = new EventEmitter<void>();
	//endregion

	constructor(public readonly sharedUiConfigs: SharedUiConfiguration) {}

	// region Events
	public onSystemUsageCall(): void {
		this.systemUsageCall.emit();
	}
	// endregion
}

import { Options } from '@popperjs/core';
import { Padding } from '@evasys/globals/shared/types/padding';
import { maxSize } from './modifier';

export interface MaxSizePopperOptionsParams {
	padding?: Padding;
	minWidth: number;
	minHeight: number;
}

export const maxSizePopperOptions = (params: MaxSizePopperOptionsParams) => (options: Partial<Options>) => {
	const padding: Padding = params.padding ?? { top: 75, bottom: 20, left: 20, right: 20 };

	options.modifiers?.splice(5, 1);
	for (const modifier of options.modifiers || []) {
		if (modifier.name === 'offset' && modifier.options) {
			modifier.options['offset'] = [0, 10];
		}
		if (modifier.name === 'preventOverflow') {
			modifier.options = { padding };
		}
	}
	options.modifiers?.push(
		{
			...maxSize,
			options: {
				padding,
			},
		},
		{
			name: 'applyMaxSize',
			enabled: true,
			phase: 'beforeWrite',
			requires: ['maxSize'],
			fn({ state }) {
				const { width, height } = state.modifiersData['maxSize'];
				state.styles['popper'] = {
					...state.styles['popper'],
					maxWidth: `${Math.max(width, params.minWidth)}px`,
					maxHeight: `${Math.max(height, params.minHeight)}px`,
				};
			},
		}
	);
	return options;
};

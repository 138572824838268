import { NgModule } from '@angular/core';
import { AnchorComponent } from './anchor/anchor.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';

@NgModule({
	imports: [NgbModule, CommonModule, RouterLink],
	declarations: [AnchorComponent],
	exports: [AnchorComponent],
})
export class AnchorModule {}

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import get = Reflect.get;
import { sharedActions } from '../actions';

@Injectable({
	providedIn: 'root',
})
export class GlobalService {
	constructor(private readonly globalStore: Store<any>) {}

	public isLoading(): Observable<boolean> {
		return this.globalStore.pipe(
			map((sub) => Object.keys(sub).map((key) => get(sub, key).loading as boolean)),
			map((allLoadingStates) => allLoadingStates.includes(true))
		);
	}

	public clear(): void {
		this.globalStore.dispatch(sharedActions.fromGlobal.ClearActionSet.DEFAULT({ payload: undefined }));
	}
}

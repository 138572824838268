/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { BaseType, select, Selection } from 'd3';

const HYPHEN = '\u2010';
const ELLIPSIS = '\u2026';

export const wrap = <Datum>(
	nodes: Selection<SVGTextElement, Datum, BaseType, unknown>,
	options: WrapOptions<Datum>
) => {
	nodes.each(function (datum) {
		const access = <Value>(accessor: Accessor<Datum, Value>): Value =>
			typeof accessor === 'function' ? accessor(datum) : accessor;

		const node = select(this);
		const fullText = node.text();
		const style = window.getComputedStyle(this);

		const lineHeight = 1.1;
		const verticalAlign = access(options.verticalAlign);
		const lines = determineLineBreaks(fullText, {
			ctx: options.ctx,
			fontFamily: style.fontFamily,
			fontSize: getValue(style.fontSize, 'px')!,
			lineHeight,
			width: access(options.width),
			height: access(options.height),
			lines: access(options.lines),
			breakStrategy: access(options.breakStrategy),
		});

		// hover text
		node.append('title').text(fullText);

		node.text('')
			.selectAll('tspan')
			.data(lines)
			.join('tspan')
			.attr('dy', (_, i) => (i > 0 ? `${lineHeight}em` : null))
			.attr('x', (_, i) => (i > 0 ? node.attr('x') || 0 : null))
			.text((line) => line);

		if (verticalAlign === WrapVerticalAlign.MIDDLE) {
			const originalDy = getValue(node.attr('dy'), 'em') || 0;
			node.attr('dy', `${originalDy - ((lines.length - 1) / 2) * lineHeight}em`);
		}
	});
};

const determineLineBreaks = (text: string, options: LineBreakOptions): string[] => {
	options = {
		...options,
		lines: getMaxLinesConsideringHeight(options),
		breakStrategy: options.breakStrategy ?? BreakStrategy.WORD,
	};
	options.ctx.font = `${options.fontSize}px ${options.fontFamily}`;

	const words = getWords(text).reverse();
	const lines: string[] = [];
	let activeLine = [];
	let activeText = '';

	const isLineTooLong = () => options.ctx.measureText(activeText).width > options.width;
	const nextLine = () => {
		lines.push(activeText);

		activeLine = [];
		activeText = '';
	};
	const isLastLine = () => options.lines !== undefined && lines.length + 1 >= options.lines;
	const lineEnd = (isWordBreak = false) => {
		if (isLastLine() && (words.length > 0 || isWordBreak)) {
			return ELLIPSIS;
		} else {
			return isWordBreak ? HYPHEN : '';
		}
	};

	while (words.length > 0) {
		// try to append the next word to the current line to see if it fits
		const word = words.pop()!;
		activeLine.push(word);
		activeText = activeLine.join(' ') + lineEnd();
		if (isLineTooLong()) {
			if (activeLine.length > 1 && options.breakStrategy === BreakStrategy.WORD) {
				// just start a new line and try to place the word there in the next iteration
				words.push(word);
				activeLine.pop();
				activeText = activeLine.join(' ') + lineEnd();
			} else {
				// The current word is too long for a single line. Break
				let breakPos = word.length;
				const lineStart =
					activeLine.length === 1 ? '' : activeLine.slice(0, activeLine.length - 1).join(' ') + ' ';

				for (; breakPos >= 1; breakPos -= 1) {
					const wordPart = word.substring(0, breakPos) + lineEnd(true);
					activeText = lineStart + wordPart;
					if (!isLineTooLong()) {
						break;
					}
				}

				words.push(word.substring(breakPos, word.length));
			}

			if (isLastLine()) {
				break;
			} else {
				nextLine();
			}
		}
	}

	nextLine();
	return lines;
};

export const getMaxLinesConsideringHeight = (options: LineBreakOptions): number | undefined => {
	if (options.height === undefined) {
		return options.lines;
	}

	return Math.min(
		options.lines || Number.POSITIVE_INFINITY,
		Math.max(1, Math.floor(options.height / (options.fontSize * options.lineHeight)))
	);
};

export const getWords = (text: string): string[] => text.trim().split(/\s+/g);

const getValue = (propertyString: string, expectedUnit: string): number | undefined => {
	const value = Number(propertyString.substring(0, propertyString.length - expectedUnit.length));
	if (Number.isNaN(value) || !propertyString.endsWith(expectedUnit)) {
		return undefined;
	}
	return value;
};

export interface WrapOptions<Datum = unknown> {
	ctx: CanvasRenderingContext2D;
	width: Accessor<Datum, number>;
	height?: Accessor<Datum, number>;
	lines?: Accessor<Datum, number>;
	verticalAlign?: Accessor<Datum, WrapVerticalAlign>;
	breakStrategy?: Accessor<Datum, BreakStrategy>;
}

interface LineBreakOptions {
	ctx: CanvasRenderingContext2D;
	fontFamily: string;
	fontSize: number;
	lineHeight: number;
	width: number;
	height?: number;
	lines?: number;
	breakStrategy?: BreakStrategy;
}

// eslint-disable-next-line @typescript-eslint/ban-types
type Accessor<Datum, Value> = Value extends Function ? never : Value | ((datum: Datum) => Value);

export enum WrapVerticalAlign {
	TOP,
	MIDDLE,
}

export enum BreakStrategy {
	WORD,
	CHARACTER,
}

import { Injectable } from '@angular/core';
import { EvasysState } from '../essential/redux/states/evasys.state';
import { Store } from '@ngrx/store';
import { sharedSelectors } from '../selectors';
import { EvasysNotificationModel } from '@evasys/globals/shared/models/evasys-notification.model';
import { ActivatedRoute } from '@angular/router';
import { NotificationEnum } from '@evasys/globals/shared/enums/component/notification.enum';
import { sharedActions } from '../actions';
import { Update } from '@ngrx/entity';
import { EvasysNotificationActionModel } from '@evasys/globals/shared/models/evasys-notification-action.model';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class NotificationService {
	constructor(
		private readonly notificationStore: Store<EvasysState<EvasysNotificationModel>>,
		private readonly activatedRoute: ActivatedRoute
	) {
		this.listenToQueryParams();
	}

	public getAll() {
		return this.notificationStore.select(sharedSelectors.fromNotifications.selectAll);
	}

	public getAllForCollection() {
		return this.notificationStore.select(sharedSelectors.fromNotifications.selectAll).pipe(
			map((notifications) => {
				return notifications.filter((notification) => {
					return notification.showInCollection;
				});
			})
		);
	}

	public createOne(notification: EvasysNotificationModel) {
		this.notificationStore.dispatch(
			sharedActions.fromNotifications.CreateOneActionSet.DEFAULT({
				payload: notification,
			})
		);
	}

	public createMany(notifications: EvasysNotificationModel[]) {
		this.notificationStore.dispatch(
			sharedActions.fromNotifications.CreateManyActionSet.DEFAULT({
				payload: notifications,
			})
		);
	}

	public updateOne(notificationUpdate: Update<EvasysNotificationModel>) {
		this.notificationStore.dispatch(
			sharedActions.fromNotifications.UpdateOneActionSet.DEFAULT({
				payload: notificationUpdate,
			})
		);
	}

	public addNotification(
		severity: NotificationEnum,
		message: string,
		id: string,
		showInCollection?: boolean,
		action?: EvasysNotificationActionModel
	) {
		this.createOne({ severity, message, id, displayed: false, showInCollection, action });
	}

	public deleteOne(id: string) {
		this.notificationStore.dispatch(
			sharedActions.fromNotifications.DeleteOneActionSet.DEFAULT({
				payload: id,
			})
		);
	}

	public clear() {
		this.notificationStore.dispatch(sharedActions.fromNotifications.ClearActionSet.DEFAULT(undefined));
	}

	private listenToQueryParams() {
		this.activatedRoute.queryParams.subscribe((params) => {
			if (
				params?.messageType &&
				params?.message &&
				Object.keys(NotificationEnum).includes(params.messageType) &&
				typeof params.message === 'string'
			) {
				this.addNotification(NotificationEnum[params.messageType], params.message, 'queryParam');
			}
		});
	}
}

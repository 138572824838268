import { Directive, HostListener, Input } from '@angular/core';

@Directive({
	selector: '[evasysNumbersOnly]',
})
export class NumbersOnlyDirective {
	@Input()
	public evasysNumbersOnly: boolean;

	@HostListener('keydown', ['$event'])
	onKeyDown(event: KeyboardEvent) {
		if (
			this.evasysNumbersOnly &&
			![
				'Delete',
				'Backspace',
				'Tab',
				'Escape',
				'Enter',
				'.',
				',',
				'End',
				'Home',
				'ArrowLeft',
				'ArrowUp',
				'ArrowRight',
				'ArrowDown',
			].includes(event.key) &&
			// We want to allow copy, paste, cut & select all
			!(['a', 'c', 'v', 'x'].includes(event.key) && (event.ctrlKey || event.metaKey)) &&
			!event.key.match('\\d')
		) {
			event.preventDefault();
		}
	}

	@HostListener('paste', ['$event'])
	onPaste(event: ClipboardEvent) {
		if (this.evasysNumbersOnly && event.clipboardData.getData('text').match(/[^\d.,]/)) {
			event.preventDefault();
		}
	}
}

import { sharedActions } from '../actions';
import { Action, ActionReducer, createReducer, MetaReducer, on } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { EvasysTokenModel } from '@evasys/globals/shared/models/general/evasys-token.model';
import { EvasysEntityAdapter } from '../essential/redux/adapter/evasys-entity.adapter';
import { defaultEvasysState, EvasysState } from '../essential/redux/states/evasys.state';
import { EvasysReducerCaseFactory } from '../essential/redux/reducers/evasys-reducer-case.factory';
import { getWindowVariable } from '@evasys/globals/shared/helper/window';

export const authAdapter = new EvasysEntityAdapter<EvasysTokenModel>();
export const authInitialState: EvasysState<EvasysTokenModel> = authAdapter.entity.getInitialState(defaultEvasysState());
const authReducerCaseFactory = new EvasysReducerCaseFactory<EvasysTokenModel>(authAdapter, sharedActions.fromAuth);

const { reduceClearActionSet, reduceLoadActionSet } = authReducerCaseFactory.reduceEvasysActionSets();

export const authReducer = createReducer(
	authInitialState,
	...reduceClearActionSet,
	...reduceLoadActionSet,
	//#region reduceLogin
	on(sharedActions.fromAuth.LoginActionSet.DEFAULT, (state: EvasysState<EvasysTokenModel>) =>
		authAdapter.load(authAdapter.entity.removeAll(state))
	),
	on(sharedActions.fromAuth.LoginActionSet.SUCCESS, (state: EvasysState<EvasysTokenModel>, { payload }) =>
		authAdapter.loadOneSucceed(payload, state)
	),
	on(sharedActions.fromAuth.LoginActionSet.FAILURE, (state: EvasysState<EvasysTokenModel>, { error }) => ({
		...state,
		loading: false,
		error,
	})),
	//#endregion reduceLogin
	//#region reduceRefresh
	on(sharedActions.fromAuth.RefreshActionSet.DEFAULT, (state: EvasysState<EvasysTokenModel>) =>
		authAdapter.load(state)
	),
	on(sharedActions.fromAuth.RefreshActionSet.SUCCESS, (state: EvasysState<EvasysTokenModel>, { payload }) =>
		authAdapter.loadOneSucceed(payload, state)
	),
	on(sharedActions.fromAuth.RefreshActionSet.FAILURE, (state: EvasysState<EvasysTokenModel>, { error }) => ({
		...state,
		error,
		loading: false,
	})),
	//#endregion reduceRefresh
	//#region reduceLogout
	on(sharedActions.fromAuth.LogoutActionSet.DEFAULT, (state: EvasysState<EvasysTokenModel>) => ({
		...state,
		loading: true,
	})),
	on(sharedActions.fromAuth.LogoutActionSet.SUCCESS, (state: EvasysState<EvasysTokenModel>) =>
		authAdapter.entity.removeAll({ ...state, loading: false })
	),
	on(sharedActions.fromAuth.LogoutActionSet.FAILURE, (state: EvasysState<EvasysTokenModel>, { error }) => ({
		...state,
		error,
		loading: false,
	}))
	//#endregion reduceLogout
);

export function reducer(state: EvasysState<EvasysTokenModel> | undefined, action: Action) {
	return authReducer(state, action);
}

//MetaReducer to store the config in localStorage

function localStorageSyncReducer(
	reducer: ActionReducer<EvasysState<EvasysTokenModel>>
): ActionReducer<EvasysState<EvasysTokenModel>> {
	return localStorageSync({
		keys: ['ids', 'entities'],
		rehydrate: true,
		storageKeySerializer: (key) =>
			`${getWindowVariable<string>('base-href') ? getWindowVariable<string>('base-href') + '_' : ''}auth_${key}`,
	})(reducer);
}

export const metaReducers: Array<MetaReducer<EvasysState<EvasysTokenModel>>> = [localStorageSyncReducer];

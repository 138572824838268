import { Component, Input } from '@angular/core';
import { Required } from '@evasys/globals/shared/decorators/decorators';
import { ValidationErrorModel } from '@evasys/globals/evasys/models/component/validation-error.model';

@Component({
	selector: 'evasys-error',
	templateUrl: './error.component.html',
})
export class ErrorComponent {
	//region Input & Output
	@Input()
	@Required()
	id?: string;

	@Input()
	errors: ValidationErrorModel[] = [];

	//endregion

	//region Variables

	//#endregion

	//region Events

	//#endregion
}

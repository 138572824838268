import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { endpoints } from '@evasys/globals/evainsights/api/endpoints';
import { KeyValueModel } from '@evasys/globals/evainsights/models/common/key-value.model';
import { Page } from '@evasys/globals/evainsights/models/pagination/page.model';
import { ProgrammeSearchRequest } from '@evasys/globals/evainsights/models/search/ProgrammeSearchRequest';

@Injectable({
	providedIn: 'root',
})
export class ProgrammeService {
	constructor(private http: HttpClient) {}

	getProgrammes(filter: ProgrammeSearchRequest = {}): Observable<Page<KeyValueModel>> {
		return this.http.get<Page<KeyValueModel>>(endpoints.getProgrammes.url(), {
			params: { ...filter },
		});
	}
}

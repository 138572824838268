import { schemeTableau10 } from 'd3';
import {
	Color,
	ColorScaleStop,
	ColorScheme,
	ColorSchemeType,
	ContinuousQuantitativeScheme,
	QualitativeScheme,
	QuantitativeColorSchemeSymmetry,
	Theme,
	ThemeVariant,
} from '../models/report-item';
import { pickBy } from 'lodash';
import { Language } from './types';

const evenlySpacedStops = (colors: Color[]): ColorScaleStop[] =>
	colors.map((color, index) => ({ color, position: index / (colors.length - 1) }));

const defaultTheme: Theme = {
	id: 1,
	name: 'Evainsights',
	forcedVariant: ThemeVariant.LIGHT,
	parent: null,
};

export const qual: QualitativeScheme = {
	id: 1,
	type: ColorSchemeType.QUALITATIVE,
	name: [
		{ lang: Language.DE, value: 'Qualitativ A' },
		{ lang: Language.EN, value: 'Qualitative A' },
		{ lang: Language.FR, value: 'Qualitative A' },
	],
	scales: [
		{
			themeId: defaultTheme.id,
			variant: ThemeVariant.LIGHT,
			colors: [
				'#095A5A',
				'#2877B0',
				'#CC4643',
				'#8F8000',
				'#856E91',
				'#38787A',
				'#7A7351',
				'#073834',
				'#CC1B00',
				'#1145BA',
				'#A85716',
			],
		},
	],
};

const tableau: QualitativeScheme = {
	id: 2,
	type: ColorSchemeType.QUALITATIVE,
	name: [
		{ lang: Language.DE, value: 'Qualitativ B' },
		{ lang: Language.EN, value: 'Qualitative B' },
		{ lang: Language.FR, value: 'Qualitative B' },
	],
	scales: [
		{
			themeId: defaultTheme.id,
			variant: ThemeVariant.LIGHT,
			colors: [...schemeTableau10],
		},
	],
};

// lightness-corrected and bezier interpolated 7-point sequential color scale between
// #bde8eb, #00a3ae, #095a5a
const blGn: ContinuousQuantitativeScheme = {
	id: 3,
	type: ColorSchemeType.QUANTITATIVE,
	symmetry: QuantitativeColorSchemeSymmetry.SEQUENTIAL,
	name: [
		{ lang: Language.DE, value: 'Sequentiell' },
		{ lang: Language.EN, value: 'Sequential' },
		{ lang: Language.FR, value: 'Séquentiel' },
	],
	scales: [
		{
			themeId: defaultTheme.id,
			variant: ThemeVariant.LIGHT,
			stops: evenlySpacedStops(['#bde8eb', '#94d1d6', '#6eb9bf', '#4ba2a8', '#2d8a8f', '#147275', '#095a5a']),
		},
	],
};

// lightness-corrected and bezier interpolated 9-point diverging color scale between
// #e86b3f, #fac64f, #77c051
const rdYlGn: ContinuousQuantitativeScheme = {
	id: 4,
	type: ColorSchemeType.QUANTITATIVE,
	symmetry: QuantitativeColorSchemeSymmetry.DIVERGING,
	name: [
		{ lang: Language.DE, value: 'Divergierend: Schlecht - Gut' },
		{ lang: Language.EN, value: 'Diverging: Bad - Good' },
		{ lang: Language.FR, value: 'Divergent : Mauvais - Bon' },
	],
	scales: [
		{
			themeId: defaultTheme.id,
			variant: ThemeVariant.LIGHT,
			// 9-step interpolation between
			stops: evenlySpacedStops([
				'#e86b3f',
				'#ee8343',
				'#f29a47',
				'#f7b04b',
				'#fac64f',
				'#dcc64f',
				'#bdc550',
				'#9cc350',
				'#77c051',
			]),
		},
	],
};

// lightness-corrected and bezier interpolated 11-point diverging color scale between
// #095a5a, #c7ced2, #2877b0
const gnGrBl: ContinuousQuantitativeScheme = {
	id: 5,
	type: ColorSchemeType.QUANTITATIVE,
	symmetry: QuantitativeColorSchemeSymmetry.DIVERGING,
	name: [
		{ lang: Language.DE, value: 'Divergierend: Zustimmung - Ablehnung' },
		{ lang: Language.EN, value: 'Diverging: Agree - Disagree' },
		{ lang: Language.FR, value: "Divergent : D'accord - Pas d'accord" },
	],
	scales: [
		{
			themeId: defaultTheme.id,
			variant: ThemeVariant.LIGHT,
			stops: evenlySpacedStops([
				'#095a5a',
				'#3b7070',
				'#5e8788',
				'#819ea0',
				'#a4b6b9',
				'#c7ced2',
				'#acbccb',
				'#91aac5',
				'#7499be',
				'#5488b7',
				'#2877b0',
			]),
		},
	],
};

export const colorSchemes = { qual, tableau, blGn, rdYlGn, gnGrBl } satisfies { [k: string]: ColorScheme };

export const isSchemeQualitative = (scheme: ColorScheme): scheme is QualitativeScheme =>
	scheme.type === ColorSchemeType.QUALITATIVE;

type ColorSchemes = typeof colorSchemes;
type QualitativeColorSchemes = {
	[S in keyof ColorSchemes as ColorSchemes[S] extends QualitativeScheme ? S : never]: ColorSchemes[S];
};

export const qualitativeColorSchemes = pickBy(colorSchemes, isSchemeQualitative) as QualitativeColorSchemes;

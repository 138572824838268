import { Directive, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { fromEvent, Observable, SubscriptionLike } from 'rxjs';

@Directive({
	selector: '[evasysClickAway]',
})
export class ClickAwayDirective implements OnInit, OnDestroy {
	// region Input & output

	@Input()
	public set clickAwayActive(active: boolean) {
		this.unsubscribeClickOutside();
		if (active) {
			this.clickOutsideSubscription = this.clickOutside$.subscribe((e) => this.onClickOutside(e));
		}
		this.active = active;
	}

	@Input()
	toggleElement: HTMLElement;

	@Output()
	public evasysClickAway = new EventEmitter<void>();

	// endregion Input & output

	// region Variables

	private active = true;
	private clickOutside$: Observable<Event> | null = null;
	private clickOutsideSubscription: SubscriptionLike = null;

	// endregion Variables

	public constructor(@Inject(DOCUMENT) private readonly document: Document, private readonly host: ElementRef) {}

	// region Events
	public ngOnInit(): void {
		this.clickOutside$ = fromEvent(this.document, 'mouseup');
		this.clickAwayActive = this.active;
	}

	public ngOnDestroy(): void {
		this.unsubscribeClickOutside();
	}

	public onClickOutside(event: Event): void {
		if (
			this.active &&
			!this.host.nativeElement.contains(event.target) &&
			!this.toggleElement?.contains(event.target as Node)
		) {
			this.evasysClickAway.emit();
		}
	}
	// endregion Events

	// region Methods
	private unsubscribeClickOutside(): void {
		if (this.clickOutsideSubscription) {
			this.clickOutsideSubscription.unsubscribe();
			this.clickOutsideSubscription = null;
		}
	}
	// endregion Methods
}

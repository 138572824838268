import { eSeverityLevel } from '@microsoft/applicationinsights-web';

export enum Level {
	DEBUG = eSeverityLevel.Verbose,
	INFO = eSeverityLevel.Information,
	WARN = eSeverityLevel.Warning,
	ERROR = eSeverityLevel.Error,
	FATAL = eSeverityLevel.Critical,
}

export const consoleLoggerByLevel: Record<Level, (...data: any[]) => void> = {
	[Level.DEBUG]: console.debug,
	[Level.INFO]: console.info,
	[Level.WARN]: console.warn,
	[Level.ERROR]: console.error,
	[Level.FATAL]: console.error,
};

import { inject, NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, RouterModule, Routes } from '@angular/router';
import { HealthComponent } from './health/health.component';
import { paths } from '@evasys/globals/evainsights/constants/paths';
import { AuthGuard } from '@evasys/shared/feature';
import { UserRightsEnum } from '@evasys/globals/shared/enums/business/user-rights.enum';
import { ReportType } from '@evasys/globals/evainsights/constants/types';
import { IsNotAuthenticatedGuard, IsTenantRegisteredGuard } from '@evasys/evainsights/shared/feature';

const loadEvainsightsReportItemCreatorFeatureModule = () =>
	import('@evasys/evainsights/report-item-creator/feature').then((m) => m.EvainsightsReportItemCreatorFeatureModule);
const loadEvainsightsReportItemsBatchCreatorFeatureModule = () =>
	import('@evasys/evainsights/report-items-batch-creator/feature').then(
		(m) => m.EvainsightsReportItemsBatchCreatorFeatureModule
	);
const loadEvainsightsReportCreatorFeatureModule = () =>
	import('@evasys/evainsights/report-creator/feature').then((m) => m.EvainsightsReportCreatorFeatureModule);
const loadEvainsightsReportTemplateCreatorFeatureModule = () =>
	import('@evasys/evainsights/reporttemplate-creator/feature').then(
		(m) => m.EvainsightsReportTemplateCreatorFeatureModule
	);
const loadEvainsightsReportFeatureModule = () =>
	import('@evasys/evainsights/report/feature').then((m) => m.EvainsightsReportFeatureModule);
const loadEvainsightsTopicModelFeatureModule = () =>
	import('@evasys/evainsights/topic-model/feature').then((m) => m.EvainsightsTopicModelFeatureModule);

const loadEvainsightsTopicFeatureModule = () =>
	import('@evasys/evainsights/topic/feature').then((m) => m.EvainsightsTopicFeatureModule);

const loadEvainsightsReportTemplateFeatureModule = () =>
	import('@evasys/evainsights/reporttemplate/feature').then((m) => m.EvainsightsReportTemplateFeatureModule);
const loadEvainsightsFormSelectorFeatureModule = () =>
	import('@evasys/evainsights/form-selector/feature').then((m) => m.EvainsightsFormSelectorFeatureModule);
const loadEvainsightsSurveySelectorFeatureModule = () =>
	import('@evasys/evainsights/survey-selector/feature').then((m) => m.EvainsightsSurveySelectorFeatureModule);

const routes: Routes = [
	{
		path: paths.getDefault.path,
		loadChildren: () =>
			import('@evasys/evainsights/authentication/feature').then((m) => m.EvainsightsAuthenticationFeatureModule),
		canActivate: [
			() => inject(IsTenantRegisteredGuard).canActivate(),
			(route: ActivatedRouteSnapshot) => inject(IsNotAuthenticatedGuard).canActivate(route),
		],
	},
	{
		path: paths.getReports.path,
		loadChildren: () =>
			import('@evasys/evainsights/report-overview/feature').then((m) => m.EvainsightsReportOverviewModule),
		canActivate: [AuthGuard],
		data: {
			rights: Object.values(UserRightsEnum),
			addRedirectAndSessionUrlParams: true,
		},
	},
	{
		path: paths.getReportTemplates.path,
		loadChildren: () =>
			import('@evasys/evainsights/reporttemplate-overview/feature').then(
				(m) => m.EvainsightsReportTemplateOverviewFeatureModule
			),
		canActivate: [AuthGuard],
		data: {
			rights: Object.values(UserRightsEnum),
			reportType: ReportType.TEMPLATE,
			addRedirectAndSessionUrlParams: true,
		},
	},
	{
		path: paths.getSurveySelector.path,
		loadChildren: loadEvainsightsSurveySelectorFeatureModule,
		canActivate: [AuthGuard],
		data: {
			rights: Object.values(UserRightsEnum),
			addRedirectAndSessionUrlParams: true,
		},
	},
	{
		path: paths.getFormSelector.path,
		loadChildren: loadEvainsightsFormSelectorFeatureModule,
		canActivate: [AuthGuard],
		data: {
			rights: Object.values(UserRightsEnum),
			addRedirectAndSessionUrlParams: true,
		},
	},
	{
		path: paths.getReport.path,
		loadChildren: loadEvainsightsReportFeatureModule,
		canActivate: [AuthGuard],
		data: {
			rights: Object.values(UserRightsEnum),
			reportType: ReportType.REPORT,
			addRedirectAndSessionUrlParams: true,
		},
	},
	{
		path: paths.getTextAnalysis.path,
		loadChildren: loadEvainsightsTopicModelFeatureModule,
		canActivate: [AuthGuard],
		data: {
			rights: Object.values(UserRightsEnum),
			addRedirectAndSessionUrlParams: true,
		},
	},
	{
		path: paths.getTopic.path,
		loadChildren: loadEvainsightsTopicFeatureModule,
		canActivate: [AuthGuard],
		data: {
			rights: Object.values(UserRightsEnum),
			addRedirectAndSessionUrlParams: true,
		},
	},
	{
		path: paths.getReportReader.path,
		loadChildren: loadEvainsightsReportFeatureModule,
		data: {
			showMainContentOnly: true,
		},
	},
	{
		path: paths.getReportCreator.path,
		loadChildren: loadEvainsightsReportCreatorFeatureModule,
		canActivate: [AuthGuard],
		data: {
			rights: Object.values(UserRightsEnum),
			reportType: ReportType.REPORT,
			addRedirectAndSessionUrlParams: true,
		},
	},
	{
		path: paths.getReportCreatorReport.path,
		loadChildren: loadEvainsightsReportCreatorFeatureModule,
		canActivate: [AuthGuard],
		data: {
			rights: Object.values(UserRightsEnum),
			reportType: ReportType.REPORT,
			addRedirectAndSessionUrlParams: true,
		},
	},
	{
		path: paths.getReportCreatorReportItem.path,
		loadChildren: loadEvainsightsReportItemCreatorFeatureModule,
		canActivate: [AuthGuard],
		data: {
			rights: Object.values(UserRightsEnum),
			reportType: ReportType.REPORT,
			addRedirectAndSessionUrlParams: true,
		},
	},
	{
		path: paths.getReportCreatorReportItemsBatchCreator.path,
		loadChildren: loadEvainsightsReportItemsBatchCreatorFeatureModule,
		canActivate: [AuthGuard],
		data: {
			rights: Object.values(UserRightsEnum),
			reportType: ReportType.REPORT,
			addRedirectAndSessionUrlParams: true,
		},
	},
	{
		path: paths.getReportCreatorReportItemCreator.path,
		loadChildren: loadEvainsightsReportItemCreatorFeatureModule,
		canActivate: [AuthGuard],
		data: {
			rights: Object.values(UserRightsEnum),
			reportType: ReportType.REPORT,
			addRedirectAndSessionUrlParams: true,
		},
	},
	{
		path: paths.getReportTemplate.path,
		loadChildren: loadEvainsightsReportTemplateFeatureModule,
		canActivate: [AuthGuard],
		data: {
			rights: Object.values(UserRightsEnum),
			reportType: ReportType.TEMPLATE,
			addRedirectAndSessionUrlParams: true,
		},
	},
	{
		path: paths.getReportTemplateCreator.path,
		loadChildren: loadEvainsightsReportTemplateCreatorFeatureModule,
		canActivate: [AuthGuard],
		data: {
			rights: Object.values(UserRightsEnum),
			reportType: ReportType.TEMPLATE,
			addRedirectAndSessionUrlParams: true,
		},
	},
	{
		path: paths.getReportTemplateCreatorReportTemplate.path,
		loadChildren: loadEvainsightsReportTemplateCreatorFeatureModule,
		canActivate: [AuthGuard],
		data: {
			rights: Object.values(UserRightsEnum),
			reportType: ReportType.TEMPLATE,
			addRedirectAndSessionUrlParams: true,
		},
	},
	{
		path: paths.getReportTemplateCreatorReportTemplateItem.path,
		loadChildren: loadEvainsightsReportItemCreatorFeatureModule,
		canActivate: [AuthGuard],
		data: {
			rights: Object.values(UserRightsEnum),
			reportType: ReportType.TEMPLATE,
			addRedirectAndSessionUrlParams: true,
		},
	},
	{
		path: paths.getReportTemplateCreatorReportTemplateItemsBatchCreator.path,
		loadChildren: loadEvainsightsReportItemsBatchCreatorFeatureModule,
		canActivate: [AuthGuard],
		data: {
			rights: Object.values(UserRightsEnum),
			reportType: ReportType.TEMPLATE,
			addRedirectAndSessionUrlParams: true,
		},
	},
	{
		path: paths.getReportTemplateCreatorReportTemplateItemCreator.path,
		loadChildren: loadEvainsightsReportItemCreatorFeatureModule,
		canActivate: [AuthGuard],
		data: {
			rights: Object.values(UserRightsEnum),
			reportType: ReportType.TEMPLATE,
			addRedirectAndSessionUrlParams: true,
		},
	},
	{
		// /health should always be reachable -> before catch all redirect
		path: paths.getHealth.path,
		component: HealthComponent,
	},
	{
		path: paths.getCatchRest.path,
		redirectTo: paths.getReports.path,
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}

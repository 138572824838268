import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableAreaComponent } from './table-area/table-area.component';
import { TableAreaListDirective } from './table-area-list/table-area-list.directive';
import { TableAreaNestedElementDirective } from './table-area-register/table-area-nested-element.directive';
import { LoadingOverlayModule } from '../../dynamic-components/loading-overlay/loading-overlay.module';

@NgModule({
	declarations: [TableAreaComponent, TableAreaListDirective, TableAreaNestedElementDirective],
	exports: [TableAreaComponent, TableAreaListDirective, TableAreaNestedElementDirective],
	imports: [CommonModule, LoadingOverlayModule],
})
export class TableAreaModule {}

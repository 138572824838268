import { FormArray, ValidatorFn } from '@angular/forms';

export function minSelectedCheckboxes(min = 1) {
	const validator: ValidatorFn = (formArray: FormArray) => {
		return getTotalSelected(formArray) >= min ? null : { required: true };
	};
	return validator;
}

export function maxSelectedCheckboxes(max = 1) {
	const validator: ValidatorFn = (formArray: FormArray) => {
		return getTotalSelected(formArray) <= max ? null : { required: true };
	};
	return validator;
}

function getTotalSelected(formArray: FormArray) {
	return formArray.controls.map((control) => control.value).reduce((prev, next) => (next ? prev + next : prev), 0);
}

import { Injectable } from '@angular/core';
import { firstValueFrom, from, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { EvasysState } from '../essential/redux/states/evasys.state';
import { sharedSelectors } from '../selectors/index';
import { map, switchMap } from 'rxjs/operators';
import { UserModelClass } from '../model-classes/user-model.class';
import { EvasysUserModel } from '@evasys/globals/shared/models/general/evasys-user.model';
import { sharedActions } from '../actions';
import { dispatchResult } from '@evasys/shared/util';
import { Actions } from '@ngrx/effects';
import { ExternalIdUserModel } from '@evasys/globals/evasys/models/business/external-id-user.model';

@Injectable({
	providedIn: 'root',
})
export class AuthUserService {
	//region Getter & Setter
	public get authenticatedUserObservable(): Observable<UserModelClass> {
		return this.userStore.select(sharedSelectors.fromAuthUser.selectAuthenticatedUser).pipe(
			map((user) => {
				if (user) {
					return new UserModelClass(user, this.userStore);
				} else {
					return null;
				}
			})
		);
	}

	public get authenticatedUserPromise(): Promise<UserModelClass> {
		return firstValueFrom(this.authenticatedUserObservable);
	}
	//endregion

	constructor(private readonly userStore: Store<EvasysState<EvasysUserModel>>, private readonly actions: Actions) {}

	//region Methods

	public authenticateUser(userId: string): Observable<EvasysUserModel> {
		const result = from(dispatchResult(this.actions, sharedActions.fromAuthUser.AuthenticateUserActionSet)).pipe(
			switchMap(() => this.userStore.select(sharedSelectors.fromAuthUser.selectAuthenticatedUser))
		);

		this.userStore.dispatch(sharedActions.fromAuthUser.AuthenticateUserActionSet.DEFAULT({ payload: userId }));
		return result;
	}

	public unauthenticateUser(): void {
		this.userStore.dispatch(sharedActions.fromAuthUser.UnauthenticateUserActionSet.DEFAULT(null));
	}

	public getUserForSwitch(externalId: number): Promise<ExternalIdUserModel[] | undefined> {
		const userForSwitch = dispatchResult(this.actions, sharedActions.fromAuthUser.GetUserSwitchDataActionSet).then(
			(result) => {
				if (result?.entities !== undefined && Array.isArray(result.entities)) {
					return result.entities;
				} else {
					return undefined;
				}
			}
		);

		this.userStore.dispatch(
			sharedActions.fromAuthUser.GetUserSwitchDataActionSet.DEFAULT({
				payload: externalId,
			})
		);

		return userForSwitch;
	}
	//endregion
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ReportTemplateEffects } from '../effects/reporttemplate.effects';
import { reportTemplateReducer } from '../reducers/reporttemplate.reducer';

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		EffectsModule.forFeature([ReportTemplateEffects]),
		StoreModule.forFeature('reportTemplates', reportTemplateReducer),
	],
})
export class ReportTemplatesModule {}

import { EvasysEntityAdapter } from '../essential/redux/adapter/evasys-entity.adapter';
import { defaultEvasysState, EvasysState } from '../essential/redux/states/evasys.state';
import { EvasysReducerCaseFactory } from '../essential/redux/reducers/evasys-reducer-case.factory';
import { Action, ActionReducer, createReducer, MetaReducer, on } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { EvasysTranslationModel } from '@evasys/globals/evasys/models/general/evasys-translation.model';
import { sharedActions } from '../actions/index';
import { getWindowVariable } from '@evasys/globals/shared/helper/window';

export const translationAdapter = new EvasysEntityAdapter<EvasysTranslationModel>();
export const translationInitialState: EvasysState<EvasysTranslationModel> = translationAdapter.entity.getInitialState(
	defaultEvasysState()
);
const translationReducerCaseFactory = new EvasysReducerCaseFactory<EvasysTranslationModel>(
	translationAdapter,
	sharedActions.fromTranslate
);

const { reduceClearActionSet, reduceLoadActionSet } = translationReducerCaseFactory.reduceEvasysActionSets();

export const translateReducer = createReducer(
	translationInitialState,
	...reduceClearActionSet,
	...reduceLoadActionSet,
	//#region reduceTranslate
	on(sharedActions.fromTranslate.LoadTranslationSet.DEFAULT, (state: EvasysState<EvasysTranslationModel>) => {
		return translationAdapter.load(state);
	}),
	on(
		sharedActions.fromTranslate.LoadTranslationSet.SUCCESS,
		(state: EvasysState<EvasysTranslationModel>, { payload }) => {
			return translationAdapter.loadManySucceed(payload, state);
		}
	),
	on(
		sharedActions.fromTranslate.LoadTranslationSet.FAILURE,
		(state: EvasysState<EvasysTranslationModel>, { error }) => ({
			...state,
			error,
			loading: false,
		})
	)
	//#endregion reduceTranslate
);

export function reducer(state: EvasysState<EvasysTranslationModel> | undefined, action: Action) {
	return translateReducer(state, action);
}

//MetaReducer to store the config in localStorage

function localStorageSyncReducer(
	reducer: ActionReducer<EvasysState<EvasysTranslationModel>>
): ActionReducer<EvasysState<EvasysTranslationModel>> {
	return localStorageSync({
		keys: ['ids', 'entities', 'pageConfig', 'loadedPages'],
		rehydrate: true,
		storageKeySerializer: (key) =>
			`${
				getWindowVariable<string>('base-href') ? getWindowVariable<string>('base-href') + '_' : ''
			}translation_${key}`,
	})(reducer);
}

export const metaReducers: MetaReducer<EvasysState<EvasysTranslationModel>>[] = [localStorageSyncReducer];

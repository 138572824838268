import { createFeatureSelector } from '@ngrx/store';
import { EvasysState } from '../essential/redux/states/evasys.state';
import { translationAdapter } from '../reducers/translate.reducer';
import { EvasysTranslationModel } from '@evasys/globals/evasys/models/general/evasys-translation.model';

export const getTranslateState = createFeatureSelector<EvasysState<EvasysTranslationModel>>('translate');

export const { selectLoading, selectPageConfig, selectPage, selectLoadedPages, selectEntity, selectMany } =
	translationAdapter.getSelectors(getTranslateState);
export const { selectIds, selectEntities, selectAll, selectTotal } =
	translationAdapter.entity.getSelectors(getTranslateState);

export enum NotificationEnum {
	ERROR = 'stop',
	WARNING = 'warning',
	SUCCESS = 'okay',
}

export const NotificationEnumMap = {
	0: NotificationEnum.ERROR,
	1: NotificationEnum.WARNING,
	2: NotificationEnum.WARNING,
};

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EvasysTokenModel } from '@evasys/globals/shared/models/general/evasys-token.model';
import { EvasysState } from '../essential/redux/states/evasys.state';
import { authAdapter } from '../reducers/auth.reducer';
import { EvasysNotificationModel } from '@evasys/globals/shared/models/evasys-notification.model';

export const getAuthState = createFeatureSelector<EvasysState<EvasysTokenModel>>('auth');

export const {
	selectLoading,
	selectPageConfig,
	selectPage,
	selectLoadedPages,
	selectEntity,
	selectMany,
	selectError,
	selectHasError,
} = authAdapter.getSelectors(getAuthState);
export const { selectIds, selectEntities, selectAll, selectTotal } = authAdapter.entity.getSelectors(getAuthState);

export const selectToken = createSelector(getAuthState, (state: EvasysState<EvasysTokenModel>): EvasysTokenModel => {
	return Object.values(state.entities)[0];
});

export const selectHasValidToken = createSelector(getAuthState, (state: EvasysState<EvasysTokenModel>): boolean => {
	return (
		state.ids &&
		state.ids.length > 0 &&
		Number(Object.values(state.entities)[0].expire_time) > new Date().getTime() &&
		!state.error
	);
});

export const selectHasValidTokenOrError = createSelector(
	getAuthState,
	(state: EvasysState<EvasysTokenModel>): boolean => {
		return (
			(state.ids &&
				state.ids.length > 0 &&
				Number(Object.values(state.entities)[0]?.expire_time) > new Date().getTime()) ||
			!!state.error
		);
	}
);

export const selectLoginMessages = createSelector(
	getAuthState,
	(state: EvasysState<EvasysTokenModel>): EvasysNotificationModel[] => {
		return Object.values(state.entities)[0]?.loginAdditionalData?.loginMessages;
	}
);

export const selectStartPage = createSelector(getAuthState, (state: EvasysState<EvasysTokenModel>): string => {
	return Object.values(state.entities)[0]?.loginAdditionalData?.userStartPage;
});

export const selectCompleteStartPage = createSelector(getAuthState, (state: EvasysState<EvasysTokenModel>): string => {
	return Object.values(state.entities)[0]?.loginAdditionalData?.userStartPageComplete;
});

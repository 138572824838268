import { detectOverflow, Modifier, Options } from '@popperjs/core';

export const maxSize: Modifier<'maxSize', Partial<Options>> = {
	name: 'maxSize',
	enabled: true,
	phase: 'main',
	requiresIfExists: ['offset', 'preventOverflow', 'flip'],
	fn({ state, name, options }) {
		const overflow = detectOverflow(state, options);
		const { x, y } = state.modifiersData.preventOverflow || { x: 0, y: 0 };
		const { width, height } = state.rects.popper;
		const [basePlacement] = state.placement.split('-');
		const mainAxis = ['top', 'bottom'].indexOf(basePlacement) >= 0 ? 'x' : 'y';

		const widthProp = basePlacement === 'left' ? 'left' : 'right';
		const heightProp = basePlacement === 'top' ? 'top' : 'bottom';

		if (mainAxis === 'x') {
			state.modifiersData[name] = {
				width: width - overflow.left - overflow.right,
				height: height - overflow[heightProp] - y,
			};
		} else {
			state.modifiersData[name] = {
				width: width - overflow[widthProp] - x,
				height: height - overflow.top - overflow.bottom,
			};
		}
	},
};

import { reportActions } from '../actions';
import { Action, createReducer } from '@ngrx/store';
import { Report } from '@evasys/globals/evainsights/models/report/report-reportTemplate.model';
import { defaultEvasysState, EvasysEntityAdapter, EvasysReducerCaseFactory, EvasysState } from '@evasys/shared/core';

export const reportAdapter = new EvasysEntityAdapter<Report>();
export const reportInitialState: EvasysState<Report> = reportAdapter.entity.getInitialState(defaultEvasysState());
const reportReducerCaseFactory = new EvasysReducerCaseFactory<Report>(reportAdapter, reportActions.fromReports);

const {
	reduceCreateOneActionSet,
	reduceCreateOneLocalActionSet,
	reduceEditOneActionSet,
	reduceEditOneLocalActionSet,
	reduceDeleteOneLocalActionSet,
	reduceLoadActionSet,
} = reportReducerCaseFactory.reduceEvasysActionSets();

export const reportReducer = createReducer(
	reportInitialState,
	...reduceCreateOneActionSet,
	...reduceCreateOneLocalActionSet,
	...reduceEditOneActionSet,
	...reduceEditOneLocalActionSet,
	...reduceDeleteOneLocalActionSet,
	...reduceLoadActionSet
);

export function reducer(state: EvasysState<Report> | undefined, action: Action) {
	return reportReducer(state, action);
}

import { Injectable } from '@angular/core';
import { ApiService, EvasysEffects } from '@evasys/shared/core';
import { Actions } from '@ngrx/effects';
import { EvasysMenuModel } from '@evasys/globals/evasys/models/general/evasys-menu.model';
import { evasysActions } from '../actions';
import { EvasysRequestMethodEnum } from '@evasys/globals/shared/enums/general/evasys-request-method.enum';
import { EvasysApiRequestModel } from '@evasys/globals/shared/models/general/evasys-api-request.model';
import { MenuitemModel } from '@evasys/globals/evasys/models/component/menu-item.model';
import { EndpointsEnum } from '@evasys/globals/evasys/api/endpoints.enum';

@Injectable()
export class MenuEffects extends EvasysEffects<EvasysMenuModel> {
	private API_PATH = EndpointsEnum.MENUS;

	constructor(protected override actions$: Actions, protected readonly apiService: ApiService) {
		super(evasysActions.fromMenu, 'menus', actions$);
	}

	loadAll$ = this.createEffect(
		evasysActions.fromMenu.LoadAllActionSet,
		() =>
			({
				apiPath: this.API_PATH,
				many: true,
				requestMethod: EvasysRequestMethodEnum.GET,
			} as EvasysApiRequestModel)
	);

	protected mapToBackend(menu: EvasysMenuModel): any {
		const menuForBackend = {
			...menu,
		} as any;

		delete menuForBackend.id;
		return menuForBackend;
	}

	protected mapToFrontend(data: any): EvasysMenuModel {
		let menus = {
			...data,
			id: data.menuid,
			submenus: data.submenus?.results?.map((menu: any) => ({
				...menu,
				id: menu.menuid,
				menuitems: menu.submenus?.results?.map(
					(menuitem: any) =>
						({
							id: menuitem.menuid,
							title: menuitem.title,
							route: menuitem.link,
							icon: menuitem.icon,
							multiPage: menuitem.link?.includes('TOKEN') || menuitem.link?.startsWith('http'),
						} as MenuitemModel)
				),
			})),
		};

		delete menus.menuid;
		delete menus.results;

		menus.submenus?.results?.forEach((submenu: any) => {
			delete submenu.results;
		});

		return menus;
	}
}

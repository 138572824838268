import { Matrix } from '@evasys/globals/evainsights/models/report-item';

export const matrixAt = (matrix: Matrix, position: number[]): number => {
	if (position.length === 0) {
		if (typeof matrix !== 'number') {
			throw Error('Matrix has more dimensions than the requested position');
		}
		return matrix;
	} else {
		if (!Array.isArray(matrix)) {
			throw Error('Matrix has fewer dimensions than the requested position');
		}

		const submatrix = matrix[position[0]];
		if (submatrix === undefined) {
			throw Error('Requested invalid position');
		}

		return matrixAt(submatrix, position.slice(1));
	}
};

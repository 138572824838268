import { Injectable } from '@angular/core';
import { TableHeadTranslationModel } from '@evasys/globals/shared/models/translation/table-head-translation.model';

@Injectable({
	providedIn: 'root',
})
export abstract class TableConfiguration {
	translations?: TableTranslations;
}

export interface TableTranslations {
	tableHead: TableHeadTranslationModel;
}

import { Matrix } from '@evasys/globals/evainsights/models/report-item';
import { mapIterable, returnFromIterable, zipIterableShortest } from '@evasys/globals/shared/helper/iterable';
import { matrixShape } from './../common';
import { matrixFullBy } from './../creation';
import { matrixValues } from './values';

export const matrixMultiply = (a: Matrix, b: Matrix): Matrix => {
	const values = mapIterable(zipIterableShortest(matrixValues(a), matrixValues(b)), ([a, b]) => a * b);
	return matrixFullBy(matrixShape(a), returnFromIterable(values));
};

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class LegalNoticeService {
	private _isVisible = false;
	private _isScantronObservable: Observable<boolean>;

	public set isScantronObservable(isScantronObservable: Observable<boolean>) {
		this._isScantronObservable = isScantronObservable;
	}

	public get isScantronObservable() {
		return this._isScantronObservable;
	}

	public set isVisible(visible: boolean) {
		this._isVisible = visible;
	}
	public get isVisible(): boolean {
		return this._isVisible;
	}
}

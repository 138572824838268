import {
	Directive,
	effect,
	EnvironmentInjector,
	inject,
	Input,
	OnInit,
	runInInjectionContext,
	TemplateRef,
	ViewContainerRef,
} from '@angular/core';
import { DropdownComponent } from '../dropdown/dropdown.component';
import { KeyValue } from '@angular/common';
import { isMap } from 'lodash';

@Directive({
	selector: '[evasysDropdownValue]',
})
export class DropdownValueDirective implements OnInit {
	//region Inputs & Outputs
	_mapping?: KeyValue<unknown, unknown>[] | Map<unknown, unknown>;
	@Input('evasysDropdownValueMapping')
	set mapping(mapping: KeyValue<unknown, unknown>[] | Map<unknown, unknown> | undefined | null) {
		this._mapping = mapping;
	}

	//endregion

	//region Injections
	private dropdownComponent = inject(DropdownComponent);
	private environmentInjector = inject(EnvironmentInjector);
	private templateRef = inject(TemplateRef<unknown>);
	private viewContainerRef = inject(ViewContainerRef);
	//endregion

	//region Variables
	private context = {
		value: null,
	};
	//endregion

	//region Events
	ngOnInit() {
		this.viewContainerRef.clear();
		const embeddedViewRef = this.viewContainerRef.createEmbeddedView(this.templateRef, this.context);
		embeddedViewRef.rootNodes[0].setAttribute('evasysDropdownValue', '');
		runInInjectionContext(this.environmentInjector, () => {
			effect(this.onValueChanged);
		});
	}

	private onValueChanged = () => {
		const mappedValue = isMap(this._mapping)
			? this._mapping.get(this.dropdownComponent._value())
			: this._mapping?.find((keyValue) => keyValue.key === this.dropdownComponent._value())?.value;
		this.context.value = mappedValue ?? this.dropdownComponent._value();
	};
	//endregion
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateEffects } from '../effects/translate.effects';
import { translationInitialState, translateReducer, metaReducers } from '../reducers/translate.reducer';

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		EffectsModule.forFeature([TranslateEffects]),
		StoreModule.forFeature('translate', translateReducer, { metaReducers, initialState: translationInitialState }),
	],
})
export class TranslateModule {}

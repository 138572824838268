import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CalendarComponent } from './calendar.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { TimePickerComponent } from './time-picker/time-picker.component';
import { TimeSelectComponent } from './time-picker/time-select/time-select.component';
import { CalenderTranslationService } from './services/calender-translation.service';
import { FormsModule } from '@angular/forms';
import { TranslationPipe } from './pipes/translation.pipe';
import { SelectedItemPipe } from './pipes/selected-item.pipe';
import { MeridiemPipe } from './pipes/meridiem.pipe';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    CalendarComponent,
    DatePickerComponent,
    TimeSelectComponent,
    TimePickerComponent,
    TranslationPipe,
    SelectedItemPipe,
    MeridiemPipe,
  ],
  imports: [CommonModule, NgbModule, FormsModule],
  providers: [CalenderTranslationService],
  exports: [CalendarComponent],
})
export class CalendarCommonModule {}

import { bytesToHex as toHex } from '@noble/hashes/utils';
import { sha256 } from '@noble/hashes/sha256';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

export function Required() {
	return function (target: Object, propertyKey: PropertyKey) {
		Object.defineProperty(target, propertyKey, {
			get() {
				throw new Error(`Attribute ${String(propertyKey)} is required`);
			},
			set(value) {
				Object.defineProperty(this, propertyKey, {
					value,
					writable: true,
				});
			},
		});
	};
}

export interface CachePropertyDescriptor<T, R> extends PropertyDescriptor {
	get?: (this: T) => R;
}

export function Cache<T, R>(ms?: number) {
	const cache: Map<string, { data: T; timestamp: number }> = new Map();

	return function (target: any, propertyKey: string, descriptor: PropertyDescriptor | CachePropertyDescriptor<T, R>) {
		const getter = descriptor.get;

		if (getter) {
			descriptor.get = function (this: T) {
				const value = getter.call(this);

				Object.defineProperty(this, propertyKey, {
					configurable: descriptor.configurable,
					enumerable: descriptor.enumerable,
					writable: false,
					value,
				});

				return value;
			};
		} else {
			const originalMethod = descriptor.value;
			const prefixKey = `${target.constructor.name}_${propertyKey}_`;

			descriptor.value = function (...args: any[]) {
				const key = prefixKey + toHex(sha256(JSON.stringify(args)));
				const cachedEntry = cache.get(key);

				if (cachedEntry !== undefined && (!ms || Date.now() - cachedEntry.timestamp < ms)) {
					return cachedEntry.data;
				}

				const result = originalMethod.apply(this, args);

				cache.set(key, {
					data: result,
					timestamp: Date.now(),
				});

				return result;
			};
		}
	};
}

export function LocalStorageForm(key: string): any {
	return function (target: any, propertyKey: string) {
		const originalNgOnInit = target.ngOnInit;
		const originalngOnDestroy = target.ngOnDestroy;
		let subscription: Subscription;

		target.ngOnInit = function (this: any) {
			if (originalNgOnInit) {
				originalNgOnInit.apply(this);
			}

			if (!key) {
				throw new Error('Form name is required for syncing with localStorage');
			}
			key = 'LocalStorageForm_' + key;
			const form: FormGroup = this[propertyKey];

			const storedData = localStorage.getItem(key);
			if (storedData) {
				const parsedData = JSON.parse(storedData);
				form.patchValue(parsedData);
			}

			subscription = form.valueChanges.subscribe((value) => {
				localStorage.setItem(key, JSON.stringify(value));
			});
		};
		target.ngOnDestroy = function (this: any) {
			if (originalngOnDestroy) {
				originalngOnDestroy.apply(this);
			}
			subscription?.unsubscribe();
		};
	};
}

import { Injectable } from '@angular/core';
import { EvasysEffects } from '../essential/redux/effects/evasys.effects';
import { Actions } from '@ngrx/effects';
import { EvasysTranslationModel } from '@evasys/globals/evasys/models/general/evasys-translation.model';
import { EvasysRequestMethodEnum } from '@evasys/globals/shared/enums/general/evasys-request-method.enum';
import { EvasysApiRequestModel } from '@evasys/globals/shared/models/general/evasys-api-request.model';
import { sharedActions } from '../actions/index';
import { SharedCoreConfiguration } from '../shared-core.configuration';
import { ApiService } from '../essential/api/services/api.service';

@Injectable()
export class TranslateEffects extends EvasysEffects<EvasysTranslationModel> {
	private API_PATH = this.sharedCoreConfiguration.api?.translation;

	constructor(
		protected override actions$: Actions,
		protected readonly apiService: ApiService,
		private readonly sharedCoreConfiguration: SharedCoreConfiguration
	) {
		super(sharedActions.fromTranslate, sharedCoreConfiguration.api?.translation, actions$);
	}

	loadPage$ = this.createEffect(sharedActions.fromTranslate.LoadTranslationSet, (action: any) => {
		let filter = 'language eq ' + action.payload.language;
		if (action.payload.prefix.length > 0) {
			const identifierCondition = action.payload.prefix
				?.map((prefix: string) => 'identifier co ' + prefix)
				.join(' or ')
				.toString();
			filter = filter + ' and (' + identifierCondition + ' or identifier sw ' + action.payload.language + ')';
		} else {
			filter = filter + ' and identifier sw ' + action.payload.language;
		}
		return {
			apiPath: this.API_PATH,
			many: true,
			requestMethod: EvasysRequestMethodEnum.GET,
			filter,
			view: 'detail',
			pageSize: -1,
		} as EvasysApiRequestModel;
	});

	protected mapToFrontend(data: any): EvasysTranslationModel {
		const translationMap = {
			...data,
			id: data.identifier,
		} as EvasysTranslationModel;

		//region Maping the Placeholder in translation from {x} to transloco common {{x}}
		const regex = /{\d+}/g;
		const values = Object.values(translationMap.translations).map((translation: string | undefined) =>
			translation?.replace(regex, '{$&}')
		);
		const keys = Object.keys(translationMap.translations);
		//endregion

		translationMap.translations = Object.assign({}, ...keys.map((key, index) => ({ [key]: values[index] })));
		return translationMap;
	}

	protected mapToBackend(): any {
		return '';
	}
}

<div
	*ngIf="_loading()"
	[class.flex-column]="_size() === loadingIconSize.lg || _size() === loadingIconSize.xl"
	[ngClass]="_size() === loadingIconSize.lg || _size() === loadingIconSize.xl ? 'gap-3' : 'gap-1'"
	[attr.data-cy]="'loading-' + id"
	class="d-inline-flex py-3 justify-content-center align-items-center"
>
	<div class="evasysSvg">
		<svg
			version="1.1"
			[style.width]="_size()"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 170 101.49"
			xml:space="preserve"
		>
			<path
				class="evasysSvgLeft"
				d="M49.33,97.77c-25.31,0-45.91-20.59-45.91-45.91c0-25.31,20.59-45.91,45.91-45.91c5.05,0,10.02,0.82,14.77,2.43
	c1.63,0.55,2.95,1.71,3.71,3.26c0.76,1.55,0.88,3.3,0.32,4.93c-0.89,2.62-3.34,4.38-6.11,4.38c-0.7,0-1.4-0.12-2.08-0.35
	c-3.41-1.16-6.98-1.75-10.62-1.75c-18.2,0-33,14.8-33,33c0,18.2,14.8,33,33,33c9.19,0,17.72-3.69,24.03-10.4l0.14-0.14l32.12-32.1
	c1.22-1.22,2.84-1.89,4.56-1.89c1.72,0,3.35,0.67,4.56,1.89c1.22,1.22,1.89,2.84,1.89,4.56c0,1.72-0.67,3.34-1.89,4.56L82.7,83.37
	C74.04,92.52,61.88,97.77,49.33,97.77z"
			/>
			<path
				class="evasysSvgRight"
				d="M118.66,97.77c-5.25,0-10.39-0.88-15.3-2.62c-3.35-1.19-5.12-4.88-3.93-8.24c0.91-2.57,3.36-4.3,6.08-4.3
	c0.73,0,1.45,0.13,2.15,0.37c3.52,1.25,7.22,1.88,11,1.88c18.2,0,33-14.8,33-33c0-18.2-14.8-33-33-33c-9.19,0-17.72,3.69-24.03,10.4
	l-0.14,0.15L58.78,65.09c-1.22,1.22-2.84,1.89-4.56,1.89c-1.72,0-3.34-0.67-4.56-1.89L34.41,49.86c-1.22-1.22-1.89-2.84-1.89-4.56
	c0-1.72,0.67-3.34,1.89-4.56c1.22-1.22,2.84-1.89,4.56-1.89s3.34,0.67,4.56,1.89l10.68,10.67l18.56-18.56
	c0.05-0.04,0.08-0.07,0.12-0.11l12.38-12.37c8.66-9.15,20.82-14.4,33.38-14.4c25.31,0,45.91,20.59,45.91,45.91
	C164.56,77.18,143.97,97.77,118.66,97.77z"
			/>
		</svg>
	</div>
	<div class="text-primary ms-2"><ng-content></ng-content></div>
</div>

import { createFeatureSelector } from '@ngrx/store';
import { EvasysState } from '@evasys/shared/core';
import { SystemInfoModel } from '@evasys/globals/evasys/models/business/system-info.model';
import { systemInfoAdapter } from '../reducers/system-info.reducer';

export const getSystemInfoState = createFeatureSelector<EvasysState<SystemInfoModel>>('systemInfos');

export const { selectLoading, selectPageConfig, selectPage, selectLoadedPages, selectEntity, selectMany, selectError } =
	systemInfoAdapter.getSelectors(getSystemInfoState);
export const { selectIds, selectEntities, selectAll, selectTotal } =
	systemInfoAdapter.entity.getSelectors(getSystemInfoState);

import { findLastIndex, sum } from 'lodash';
import { Cache } from '@evasys/globals/shared/decorators/decorators';

export type Statistics = NominalStatistics | OrdinalStatistics;

export interface NominalStatistics {
	sampleSize: number;
}

export interface OrdinalStatistics extends NominalStatistics {
	min: number;
	max: number;
	arithmeticMean: number;
	median: number;
	correctedSampleStandardDeviation: number;
	firstQuartile: number;
	thirdQuartile: number;
}

export class OrdinalStatisticsFromCounts implements OrdinalStatistics {
	constructor(private readonly counts: number[]) {
		if (this.sampleSize === 0) {
			throw Error('Cannot compute ordinal statistics from zero-counts');
		}
	}

	@Cache()
	get sampleSize() {
		return sum(this.counts);
	}

	@Cache()
	get min() {
		return this.counts.findIndex((count) => count > 0) + 1;
	}

	@Cache()
	get max() {
		return findLastIndex(this.counts, (count) => count > 0) + 1;
	}

	@Cache()
	get arithmeticMean() {
		return (1 / this.sampleSize) * sum(this.counts.map((count, index) => count * (index + 1)));
	}

	@Cache()
	get correctedSampleStandardDeviation() {
		if (this.sampleSize === 1) {
			return 0;
		}

		return Math.sqrt(
			(1 / (this.sampleSize - 1)) *
				sum(this.counts.map((count, index) => count * Math.pow(index + 1 - this.arithmeticMean, 2)))
		);
	}

	@Cache()
	get median() {
		return quantile(this.counts, 0.5);
	}

	@Cache()
	get firstQuartile() {
		return quantile(this.counts, 0.25);
	}

	@Cache()
	get thirdQuartile() {
		return quantile(this.counts, 0.75);
	}
}

/**
 * Compute Weibull quantile (type 6 in R) from per-option binned counts.
 * The same quantile type is used in evasys reports.
 * @param counts The binned counts. A counts of [1, 3, 0, 2] will compute the quantile over the values [1, 2, 2, 2, 4, 4]
 * @param alpha The quantile position to compute, e.g, 0.25 for the first quartile
 */
export const quantile = (counts: number[], alpha: number): number => {
	const n = sum(counts);
	if (n === 0) {
		return Number.NaN;
	}

	// real valued target index h
	const h = alpha * (n + 1);

	if (h < 1) {
		// first value
		return counts.findIndex((v) => v > 0) + 1;
	} else if (h > n) {
		// last value
		return counts.length - [...counts].reverse().findIndex((v) => v > 0);
	}

	const k = Math.floor(h);
	const w = h - k;

	// the rest of the function interpolates between xLeft=x[k] and xRight=x[k+1]
	// as xLeft + w * (xRight - xLeft)
	// where x is the sorted array of responses denoted by the counts variable

	// determine xLeft by scanning through the counts
	let xLeft = 0;
	let cumsum = 0;
	while (cumsum < k) {
		cumsum += counts[xLeft];
		xLeft += 1;
	}

	if (cumsum > k || w == 0) {
		// the target index h is within the bin of xLeft

		return xLeft;
	} else {
		// k is right at the border between different response values and we need to interpolate

		let xRight = xLeft + 1;
		// handle zero-values in counts
		// if we have an xLeft of 3 and the counts of values 4 and 5 are zero while 6 has a count of at least 1,
		// xRight should be 6, not just 3+1=4
		while (counts[xRight - 1] == 0) {
			xRight += 1;
		}

		return xLeft + w * (xRight - xLeft);
	}
};

import { HttpClient } from '@angular/common/http';
import {
	Translation,
	TRANSLOCO_CONFIG,
	TRANSLOCO_LOADER,
	translocoConfig,
	TranslocoLoader,
	TranslocoModule,
} from '@ngneat/transloco';
import { Injectable, NgModule } from '@angular/core';
import { environment } from '@evasys/globals/evainsights/environments/environment';
import { TRANSLOCO_PERSIST_LANG_STORAGE, TranslocoPersistLangModule } from '@ngneat/transloco-persist-lang';
import { combineLatest, map } from 'rxjs';
import { merge } from 'lodash';
import { provideTranslocoEvasysMessageformat } from './evasys-messageformat.providers';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
	constructor(private http: HttpClient) {}

	getTranslation(lang: string) {
		const [baseLang, contextLang] = lang.split('_');
		return combineLatest([
			this.http.get<Translation>(`/assets/i18n/${baseLang}.json`),
			this.http.get<Translation>(`/assets/i18n/${contextLang}/${baseLang}.json`),
		]).pipe(map(([baseTranslation, contextTranslation]) => merge(baseTranslation, contextTranslation)));
	}
}

export const TRANSLATION_KEY = 'translation_key';

@NgModule({
	imports: [
		TranslocoPersistLangModule.forRoot({
			storageKey: TRANSLATION_KEY,
			storage: {
				provide: TRANSLOCO_PERSIST_LANG_STORAGE,
				useValue: localStorage,
			},
		}),
	],
	exports: [TranslocoModule],
	providers: [
		provideTranslocoEvasysMessageformat(),
		{
			provide: TRANSLOCO_CONFIG,
			useValue: translocoConfig({
				availableLangs: ['de', 'en', 'fr'].flatMap((userLanguageCode) =>
					['edu', 'com', 'qmc'].map((contextLanguageCode) => `${userLanguageCode}_${contextLanguageCode}`)
				),
				defaultLang: 'de_edu',
				fallbackLang: 'de_edu',
				// Remove this option if your application doesn't support changing language in runtime.
				reRenderOnLangChange: true,
				prodMode: environment.production,
			}),
		},
		{ provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader },
	],
})
export class TranslocoRootModule {}

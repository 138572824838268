import { EvasysActionsFactory } from '../essential/redux/actions/evasys-actions.factory';
import { ConfigModel } from '@evasys/globals/evasys/models/business/config.model';
import { EvasysCollectionModel } from '@evasys/globals/shared/models/general/evasys-collection.model';

const actionsFactory = new EvasysActionsFactory<ConfigModel>('CONFIG');

export const { LoadPageActionSet, LoadManyActionSet, EditOneActionSet, LoadActionSet } = actionsFactory.createActions();

export const LoadOneWithConfigName = actionsFactory.createCustomActionSet<string, EvasysCollectionModel<ConfigModel>>(
	'LoadOneWithConfigName'
);

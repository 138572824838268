import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class SharedFeatureConfiguration {
	clientSecret: string;
	catchAllHttpErrors?: boolean;
	isApiUrl: (url: string) => boolean;
}

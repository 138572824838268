import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AuthEffects } from '../effects/auth.effects';
import { authInitialState, metaReducers, authReducer } from '../reducers/auth.reducer';

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		EffectsModule.forFeature([AuthEffects]),
		StoreModule.forFeature('auth', authReducer, { metaReducers, initialState: authInitialState }),
	],
})
export class AuthModule {}

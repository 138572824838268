import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { endpoints } from '@evasys/globals/evainsights/api/endpoints';

@Injectable({
	providedIn: 'root',
})
export class RespondentFilterService {
	constructor(readonly http: HttpClient) {}

	createNewRespondentFilterItemOptionByReportIdAndItemOptionIds(reportId: number, itemOptionIds: number[]) {
		return this.http.post(
			endpoints.createNewRespondentFilterItemForReportIdAndItemOptionIds.url({ reportId: reportId }),
			itemOptionIds
		);
	}

	deleteRespondentFilterItemOptionForReportIdAndItemId(reportId: number, itemId: number) {
		return this.http.delete(
			endpoints.deleteRespondentFilterItemOptionsForReportIdAndItemId.url({ reportId: reportId, itemId: itemId })
		);
	}
}

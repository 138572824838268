import {
	computed,
	Directive,
	effect,
	ElementRef,
	EnvironmentInjector,
	inject,
	Input,
	OnInit,
	runInInjectionContext,
} from '@angular/core';
import { DropdownComponent } from '../dropdown/dropdown.component';
import { createPopper, Instance, Modifier, OptionsGeneric } from '@popperjs/core';
import { fromEvent } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Directive({
	selector: '[evasysDropdownToggle]',
})
export class DropdownToggleDirective implements OnInit {
	//region Inputs & Outputs
	@Input()
	public eventType: 'click' | 'focus' | 'mouseover' = 'click';
	//endregion

	//region Injections
	private dropdownComponent = inject(DropdownComponent);
	private environmentInjector = inject(EnvironmentInjector);
	private elementRef = inject(ElementRef<HTMLElement>);
	//endregion

	//region Variables
	private popperInstance: Instance;
	private popperOptions = computed<Partial<OptionsGeneric<Partial<Modifier<unknown, unknown>>>>>(() => ({
		placement: this.dropdownComponent._menuPlacement(),
	}));

	//endregion

	//region Events
	ngOnInit() {
		this.elementRef.nativeElement.setAttribute('data-cy', 'dropdown-toggle-' + this.dropdownComponent.id);
		runInInjectionContext(this.environmentInjector, () => {
			effect(this.onDropdownMenuChanged);
			fromEvent(this.elementRef.nativeElement, this.eventType).pipe(takeUntilDestroyed()).subscribe(this.onEvent);
		});
	}

	onDropdownMenuChanged = () => {
		if (this.dropdownComponent.dropdownMenuComponent()) {
			this.popperInstance?.destroy();
			if (this.dropdownComponent.dropdownMenuComponent() && this.dropdownComponent._menuPlacement())
				this.popperInstance = createPopper(
					this.elementRef.nativeElement,
					this.dropdownComponent.dropdownMenuComponent(),
					this.popperOptions()
				);
		}
	};

	onEvent = () => {
		if (!this.dropdownComponent._isDisabled()) {
			this.dropdownComponent.isOpen.update((isOpen) => !isOpen);
		}
	};

	//endregion
}

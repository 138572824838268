import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InlineTranslocoPipe } from './inline-transloco/inline-transloco.pipe';

@NgModule({
	declarations: [InlineTranslocoPipe],
	exports: [InlineTranslocoPipe],
	imports: [CommonModule],
})
export class InlineTranslocoModule {}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModifyPipe } from './modify/modify.pipe';

@NgModule({
	declarations: [ModifyPipe],
	imports: [CommonModule],
	exports: [ModifyPipe],
})
export class ModifyModule {}

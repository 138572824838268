import { Component, Input } from '@angular/core';
import { ButtonDesignEnum } from '@evasys/globals/shared/enums/component/button-design.enum';
import { Required } from '@evasys/globals/shared/decorators/decorators';

@Component({
	selector: 'evasys-button',
	templateUrl: './button.component.html',
})
export class ButtonComponent {
	//#region Input & Output
	@Input()
	@Required()
	id: string | undefined;

	@Input()
	type = 'button';

	@Input()
	design = ButtonDesignEnum.PRIMARY;

	@Input()
	isDisabled = false;

	@Input()
	class?: string;

	//#endregion Input & Output

	//region Methods

	//endregion
}

import { Component } from '@angular/core';

@Component({
	selector: 'evainsights-health',
	template: '',
})
export class HealthComponent {
	constructor() {
		// This is intentional
	}
}

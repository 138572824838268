import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EvasysState } from '@evasys/shared/core';
import { WizardModel } from '@evasys/globals/shared/models/component/wizard/wizard.model';
import { wizardAdapter } from '../reducers/wizard.reducer';

export const getWizardState = createFeatureSelector<EvasysState<WizardModel>>('wizard');

export const { selectLoading, selectPageConfig, selectPage, selectLoadedPages, selectEntity, selectMany, selectError } =
	wizardAdapter.getSelectors(getWizardState);
export const { selectIds, selectEntities, selectAll, selectTotal } = wizardAdapter.entity.getSelectors(getWizardState);

export const selectShowWizard = (id: string) =>
	createSelector(getWizardState, (state: EvasysState<WizardModel>) => state?.entities[id]?.show);

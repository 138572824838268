import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const securePasswordValidator = (): ValidatorFn => {
	return (control: AbstractControl): ValidationErrors | null => {
		let validationResult = null;

		const value: string = control.value;
		if (!value || value === '') {
			return null;
		}

		//custom validation
		if (validationResult === null) {
			const characterRegex: RegExp = /(?=.*[A-Z])(?=.*[a-z])(?=.*\d).+/;
			const repetitionRegex: RegExp = /(.)\1{2}/;

			validationResult =
				characterRegex.test(value) && !repetitionRegex.test(value) ? null : { securePassword: true };
		}

		return validationResult;
	};
};

import { Directive, ElementRef, inject, OnInit } from '@angular/core';

@Directive({
	standalone: true,
	selector: '[evainsightsInputAutoFillOff]',
})
export class InputAutoFillOffDirective implements OnInit {
	private _el = inject(ElementRef);

	ngOnInit(): void {
		this._el.nativeElement.setAttribute('autocomplete', 'off');
		this._el.nativeElement.setAttribute('autocorrect', 'off');
		this._el.nativeElement.setAttribute('autocapitalize', 'off');
		this._el.nativeElement.setAttribute('spellcheck', 'false');
	}
}

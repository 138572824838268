import {
	AfterContentInit,
	Component,
	ContentChildren,
	EventEmitter,
	Input,
	OnDestroy,
	Output,
	QueryList,
} from '@angular/core';
import { Required } from '@evasys/globals/shared/decorators/decorators';
import { EvasysSortingModel } from '@evasys/globals/shared/models/general/evasys-sorting.model';
import { TableHeadComponent } from '../table-head/table-head.component';
import { Subscription } from 'rxjs';

@Component({
	selector: 'evasys-table',
	templateUrl: './table.component.html',
	styles: [':host{display: block}'],
})
export class TableComponent implements AfterContentInit, OnDestroy {
	//region ContentChildren
	@ContentChildren(TableHeadComponent, { descendants: true })
	public columnQuery?: QueryList<TableHeadComponent>;
	//endregion

	//region Input & Output
	@Input()
	@Required()
	id?: string;

	@Input()
	fixedLayout = false;

	@Input()
	description: string;

	@Output()
	sortAction: EventEmitter<EvasysSortingModel> = new EventEmitter<EvasysSortingModel>();

	//endregion

	//region Variables

	public get tableHeads(): TableHeadComponent[] {
		return this._tableHeads;
	}

	private set tableHeads(tableHeads: TableHeadComponent[]) {
		this.clearTableHeadSubs();

		this._tableHeads = [];
		for (const [index, tableHead] of tableHeads.entries()) {
			this.tableHeadSubs.push(
				tableHead.sortingChange.subscribe((sorting) => {
					if (sorting) {
						this.onSorting(index);
					}
				})
			);
			this._tableHeads.push(tableHead);
		}
	}

	private _tableHeads: TableHeadComponent[] = [];

	private subscriptions: Subscription[] = [];

	private tableHeadSubs: Subscription[] = [];

	private sortingSubs: Subscription[] = [];
	//endregion

	//region events
	ngAfterContentInit() {
		this.tableHeads = this.columnQuery.toArray();
		this.findAndActivateDefaultSort();

		this.subscriptions.push(
			this.columnQuery.changes.subscribe((tableHeads) => {
				this.tableHeads = tableHeads.toArray();
			})
		);
	}

	public ngOnDestroy(): void {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
		this.clearTableHeadSubs();
		this.clearSortingSubs();
	}

	public onSorting(sortingIndex: number): void {
		if (sortingIndex in this.tableHeads) {
			const sortingTableHead = this.tableHeads[sortingIndex];
			if (sortingTableHead.sorting) {
				for (const [index, tableHead] of this.tableHeads.entries()) {
					if (sortingIndex !== index) {
						tableHead.sorting = false;
					}
				}

				this.sortAction.emit({
					columnField: sortingTableHead.columnField,
					sortOrderAscending: sortingTableHead.sortOrderAscending,
				});

				this.clearSortingSubs();
				this.sortingSubs.push(
					sortingTableHead.sortOrderAscendingChange.subscribe(() =>
						this.sortAction.emit({
							columnField: sortingTableHead.columnField,
							sortOrderAscending: sortingTableHead.sortOrderAscending,
						})
					)
				);
			}
		}
	}
	//endregion

	//region methods

	public findAndActivateDefaultSort(): void {
		for (const [index, tableHead] of this.tableHeads.entries()) {
			if (tableHead.sortByDefault) {
				tableHead.sorting = true;
				this.onSorting(index);
				break;
			}
		}
	}

	public clearTableHeadSubs(): void {
		this.tableHeadSubs.forEach((sub) => sub.unsubscribe());
	}

	public clearSortingSubs(): void {
		this.sortingSubs.forEach((sub) => sub.unsubscribe());
	}
	//endregion
}

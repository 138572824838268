import { FormService } from '../form/form.service';
import { ParticipationEventService } from '../participation/participation-event.service';
import { ProgrammeService } from '../organization/programme.service';
import { PeriodService } from '../organization/period.service';
import { StaffService } from '../staff/staff.service';
import { UnitService } from '../organization/unit.service';
import { KeyValueModel } from '@evasys/globals/evainsights/models/common/key-value.model';
import { Injectable } from '@angular/core';
import {
	TypeaheadItemPageRequest,
	TypeaheadItemSearch,
} from '@evasys/globals/shared/models/component/typeahead/typeahead.model';
import {
	FilterAreaControlWriteUrl,
	LoadFunction,
} from '@evasys/globals/evainsights/models/filter/filter-area-control-write-url.model';
import { Observable, of } from 'rxjs';
import { Page } from '@evasys/globals/evainsights/models/pagination/page.model';
import { MultiLangService, pageContent } from '@evasys/evainsights/shared/util';
import { Form } from '@evasys/globals/evainsights/models/form/form.model';
import { SurveyService } from '../survey/survey.service';
import { Survey } from '@evasys/globals/evainsights/models/survey/survey.entity';
import { ItemService } from '../item/item.service';
import { Item } from '@evasys/globals/evainsights/models/item/item.model';
import { TopicModelInfo } from '@evasys/globals/evainsights/models/topic-modeling/topic-model-info.model';
import { TopicModelService } from '../topic-modeling/topic-model.service';
import { TranslocoService } from '@ngneat/transloco';
import { Sentiment } from '@evasys/globals/evainsights/constants/types';
import { PagedItemSearchRequest } from '@evasys/globals/evainsights/models/search/ItemSearchRequest';
import { LevelEnum } from '@evasys/globals/shared/enums/business/level.enum';

@Injectable({
	providedIn: 'root',
})
export class FilterService {
	constructor(
		private participationEventService: ParticipationEventService,
		private periodService: PeriodService,
		private formService: FormService,
		private programmeService: ProgrammeService,
		private staffService: StaffService,
		private readonly unitService: UnitService,
		private readonly surveyService: SurveyService,
		private readonly itemService: ItemService,
		private readonly multiLangService: MultiLangService,
		private translocoService: TranslocoService,
		private readonly topicModelService: TopicModelService
	) {}

	programmes = {
		id: 'programmes' as const,
		...this.buildItemSearchAndLoad(({ text, ...rest }) =>
			this.programmeService.getProgrammes({
				name: text,
				...rest,
			})
		),
		icon: { dark: 'book_blue.svg', light: 'book_deactivated.svg' },
		name: 'filterArea.filter.programme',
		identifier: 'key',
		formatter: 'value',
		emptyResultsText: 'filterArea.filter.programme.error.noneFound',
		queryParameterName: 'programmeId',
	} satisfies FilterAreaControlWriteUrl<KeyValueModel>;

	units = {
		id: 'units' as const,
		...this.buildItemSearchAndLoad(({ text, ...rest }) =>
			this.unitService.getUnits({
				name: text,
				...rest,
			})
		),
		icon: { dark: 'home.svg', light: 'home_deactivated.svg' },
		name: 'filterArea.filter.unit',
		identifier: 'key',
		formatter: 'value',
		emptyResultsText: 'filterArea.filter.unit.error.noneFound',
		queryParameterName: 'unitId',
	} satisfies FilterAreaControlWriteUrl<KeyValueModel>;

	periods = {
		id: 'periods' as const,
		...this.buildItemSearchAndLoad(({ text, ...rest }) =>
			this.periodService.getPeriods({
				name: text,
				...rest,
			})
		),
		icon: { dark: 'calendar.svg', light: 'calendar_deactivated.svg' },
		name: 'filterArea.filter.period',
		identifier: 'key',
		formatter: 'value',
		emptyResultsText: 'filterArea.filter.period.error.noneFound',
		queryParameterName: 'periodId',
	} satisfies FilterAreaControlWriteUrl<KeyValueModel>;

	forms = {
		id: 'forms' as const,
		...this.buildItemSearchAndLoad(({ text, ...rest }) =>
			this.formService.getSearchResult({
				name: text,
				...rest,
			})
		),
		icon: { dark: 'desktop.svg', light: 'desktop_deactivated.svg' },
		name: 'filterArea.filter.form',
		identifier: 'id',
		formatter: 'shortName',
		emptyResultsText: 'filterArea.filter.form.error.noneFound',
		queryParameterName: 'formId',
	} satisfies FilterAreaControlWriteUrl<Form>;

	participationEvents = {
		id: 'participationEvents' as const,
		...this.buildItemSearchAndLoad(({ text, ...rest }) =>
			this.participationEventService.getSearchResult({
				eventName: text,
				...rest,
			})
		),
		icon: { dark: 'index.svg', light: 'index_deactivated.svg' },
		name: 'filterArea.filter.participationEvent',
		identifier: 'key',
		formatter: 'value',
		emptyResultsText: 'filterArea.filter.participationEvent.error.noneFound',
		queryParameterName: 'participationEventId',
	} satisfies FilterAreaControlWriteUrl<KeyValueModel>;

	leaders = {
		id: 'leaders' as const,
		...this.buildItemSearchAndLoad(({ text, ...rest }) =>
			this.staffService.getStaffs({
				name: text,
				roles: [LevelEnum.INSTRUCTOR, LevelEnum.DEAN],
				...rest,
			})
		),
		icon: { dark: 'ep_user1_blue.svg', light: 'nutzer_deaktiv.svg' },
		name: 'filterArea.filter.leader',
		identifier: 'key',
		formatter: 'value',
		emptyResultsText: 'filterArea.filter.leader.error.noneFound',
		queryParameterName: 'leaderId',
	} satisfies FilterAreaControlWriteUrl<KeyValueModel>;

	surveys = {
		id: 'surveys' as const,
		...this.buildItemSearchAndLoad(({ text, ...rest }) =>
			this.surveyService.getSearchResult({
				description: text,
				...rest,
			})
		),
		icon: { dark: 'survey.svg', light: 'survey_deactivated.svg' },
		name: 'domain.survey',
		identifier: 'id',
		formatter: 'description',
		emptyResultsText: 'filterArea.filter.survey.error.noneFound',
		queryParameterName: 'surveyId',
	} satisfies FilterAreaControlWriteUrl<Survey>;

	items = {
		id: 'items' as const,
		...this.buildItemSearchAndLoad(({ text, ...rest }) =>
			this.itemService.getSearchResult({
				itemTextName: text,
				...rest,
			} satisfies PagedItemSearchRequest)
		),
		icon: { dark: 'question.svg', light: 'question.svg' },
		name: 'item.item',
		identifier: 'id',
		formatter: (item: Item) => this.multiLangService.translate(item.text, ''),
		emptyResultsText: 'filterArea.filter.item.error.noneFound',
		queryParameterName: 'itemId',
	} satisfies FilterAreaControlWriteUrl<Item>;

	topicModels = {
		id: 'topicModels' as const,
		...this.buildItemSearchAndLoad(({ text, ...rest }) =>
			this.topicModelService.getSearchResult({
				title: text,
				...rest,
			})
		),
		icon: { dark: 'question.svg', light: 'question.svg' },
		name: 'topicModel.topicModel',
		identifier: 'id',
		formatter: (topicModel: TopicModelInfo) => topicModel.title,
		emptyResultsText: 'filterArea.filter.item.error.noneFound',
		queryParameterName: 'topicModelId',
	} satisfies FilterAreaControlWriteUrl<TopicModelInfo>;

	sentiments = {
		id: 'sentiments' as const,
		items: [Sentiment.NEUTRAL, Sentiment.POSITIVE, Sentiment.NEGATIVE],
		load: (ids: string[] | number[]) => of(ids as Sentiment[]),
		icon: { dark: 'question.svg', light: 'question.svg' },
		name: 'filterArea.filter.sentiment',
		identifier: (sentiment: Sentiment) => sentiment,
		formatter: (sentiment) => this.translocoService.selectTranslate('sentiment.' + sentiment),
		emptyResultsText: 'filterArea.filter.sentiment.error.noneFound',
		queryParameterName: 'sentimentId',
	} satisfies FilterAreaControlWriteUrl<Sentiment>;

	buildItemSearchAndLoad<T>(searchFn: (filter: ApiSearchParams) => Observable<Page<T>>): {
		items: TypeaheadItemSearch<T>;
		load: LoadFunction<T>;
	} {
		return {
			items: (text, filter) => searchFn({ text, ...filter }),
			load: (ids: number[] | string[]) =>
				searchFn({ ids: ids as number[], page: 0, size: ids.length, text: '' }).pipe(pageContent()),
		};
	}
}

interface ApiSearchParams extends TypeaheadItemPageRequest {
	text: string;
	ids?: number[];
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'reduce',
})
export class ReducePipe<T> implements PipeTransform {
	transform(
		arr: T[],
		callbackfn: (previousValue: T, currentValue: T, currentIndex: number, array: T[]) => T,
		initialValue?: T
	): T {
		return arr.reduce(callbackfn, initialValue);
	}
}

import { createFeatureSelector } from '@ngrx/store';
import { EvasysState } from '../essential/redux/states/evasys.state';
import { EvasysNotificationModel } from '@evasys/globals/shared/models/evasys-notification.model';
import { notificationAdapter } from '../reducers/notification.reducer';

export const getNotificationState = createFeatureSelector<EvasysState<EvasysNotificationModel>>('notification');

export const { selectEntity, selectMany } = notificationAdapter.getSelectors(getNotificationState);

export const { selectIds, selectEntities, selectAll, selectTotal } =
	notificationAdapter.entity.getSelectors(getNotificationState);

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { userAdapter } from '../reducers/auth-user.reducer';
import { EvasysState } from '../essential/redux/states/evasys.state';
import { EvasysUserModel } from '@evasys/globals/shared/models/general/evasys-user.model';

export const getUserState = createFeatureSelector<EvasysState<EvasysUserModel>>('authUser');

export const { selectLoading, selectPageConfig, selectPage, selectLoadedPages, selectEntity, selectMany, selectError } =
	userAdapter.getSelectors(getUserState);
export const { selectIds, selectEntities, selectAll, selectTotal } = userAdapter.entity.getSelectors(getUserState);

export const selectAuthenticatedUser = createSelector(
	getUserState,
	(state: EvasysState<EvasysUserModel>): EvasysUserModel | null => {
		return state.ids?.length > 0 ? state.entities[state.ids[0]] : null;
	}
);

export const selectHasAuthenticatedUserOrError = createSelector(
	getUserState,
	(state: EvasysState<EvasysUserModel>): boolean => {
		return (state.ids && state.ids.length > 0) || !!state.error;
	}
);

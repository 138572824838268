import { Action, ActionReducer, createReducer, MetaReducer, on } from '@ngrx/store';
import { sharedActions } from '../actions/index';
import { Update } from '@ngrx/entity';
import { localStorageSync } from 'ngrx-store-localstorage';
import { EvasysUserModel } from '@evasys/globals/shared/models/general/evasys-user.model';
import { EvasysEntityAdapter } from '../essential/redux/adapter/evasys-entity.adapter';
import { defaultEvasysState, EvasysState } from '../essential/redux/states/evasys.state';
import { EvasysReducerCaseFactory } from '../essential/redux/reducers/evasys-reducer-case.factory';
import { getWindowVariable } from '@evasys/globals/shared/helper/window';

export const userAdapter = new EvasysEntityAdapter<EvasysUserModel>();

export const userInitialState: EvasysState<EvasysUserModel> = userAdapter.entity.getInitialState(defaultEvasysState());

const authUserReducerCaseFactory = new EvasysReducerCaseFactory<EvasysUserModel>(
	userAdapter,
	sharedActions.fromAuthUser
);

const { reduceLoadActionSet } = authUserReducerCaseFactory.reduceEvasysActionSets();

export const authUserReducer = createReducer(
	userInitialState,
	...reduceLoadActionSet,
	on(
		sharedActions.fromAuthUser.ChangeActiveRoleActionSet.DEFAULT,
		(state: EvasysState<EvasysUserModel>, { payload: { id, activeRole } }) => {
			const update: Update<EvasysUserModel> = {
				id,
				changes: { activeRight: activeRole },
			};
			return userAdapter.entity.updateOne(update, state);
		}
	),
	on(sharedActions.fromAuthUser.AuthenticateUserActionSet.DEFAULT, (state: EvasysState<EvasysUserModel>) =>
		userAdapter.load(state)
	),
	on(
		sharedActions.fromAuthUser.AuthenticateUserActionSet.SUCCESS,
		(state: EvasysState<EvasysUserModel>, { payload }) => {
			const update = userAdapter.loadOneSucceed(payload, userAdapter.entity.removeAll(state));
			return update;
		}
	),
	on(
		sharedActions.fromAuthUser.AuthenticateUserActionSet.FAILURE,
		(state: EvasysState<EvasysUserModel>, { error }) => ({ ...state, error, loading: false })
	),
	on(sharedActions.fromAuthUser.UnauthenticateUserActionSet.DEFAULT, (state: EvasysState<EvasysUserModel>) =>
		userAdapter.entity.removeAll(state)
	)
);

export function reducer(state: EvasysState<EvasysUserModel> | undefined, action: Action) {
	return authUserReducer(state, action);
}

//MetaReducer to store the authenticated user in localStorage

function localStorageSyncReducer(reducer: ActionReducer<EvasysState<EvasysUserModel>>) {
	return localStorageSync({
		keys: ['ids', 'entities'],
		rehydrate: true,
		storageKeySerializer: (key) =>
			`${
				getWindowVariable<string>('base-href') ? getWindowVariable<string>('base-href') + '_' : ''
			}authedUser_${key}`,
	})(reducer);
}

export const metaReducers: Array<MetaReducer<EvasysState<EvasysUserModel>>> = [localStorageSyncReducer];

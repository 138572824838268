import {
	Color,
	ColorScale,
	ColorScaleStop,
	ContinuousQuantitativeScale,
	DiscreteQuantitativeScale,
	QualitativeScale,
	QuantitativeScale,
} from '../../models/report-item';
import { full } from '../../../shared/helper/array';
import { interpolateLab, quantize, scaleLinear } from 'd3';
import { range } from 'lodash';

export const defaultColor = '#095a5a';
export const defaultPresets = ['#095a5a', '#1ec6a5', '#073834', '#3aa9fb', '#ff5855'];

export const getScaleColors = (scale: ColorScale, count: number): Color[] =>
	isScaleQualitative(scale) ? getQualitativeScaleColors(scale, count) : getQuantitativeScaleColors(scale, count);

export const getQualitativeScaleColors = (scale: QualitativeScale, count: number): Color[] =>
	range(count).map((i) => scale.colors[i % scale.colors.length]);

export const getQuantitativeScaleColors = (scale: QuantitativeScale, count: number): Color[] =>
	'colors' in scale
		? getDiscreteQuantitativeScaleColors(scale, count)
		: getContinuousQuantitativeScaleColors(scale, count);

export const getDiscreteQuantitativeScaleColors = (scale: DiscreteQuantitativeScale, count: number): Color[] => {
	const matchingColorList = scale.colors.find((set) => set.length === count);
	if (!matchingColorList) {
		console.warn(`Could not find color sequence of length ${count} in discrete quantitative scale`);
		return full(count, defaultColor);
	}

	return matchingColorList;
};

export const getContinuousQuantitativeScaleColors = (scale: ContinuousQuantitativeScale, count: number): Color[] => {
	const interpolator = getColorScaleStopInterpolator(scale.stops);
	return count === 1 ? [interpolator(0.5)] : quantize(interpolator, count);
};

export const getColorScaleStopInterpolator = (stops: ColorScaleStop[]): ((t: number) => Color) =>
	scaleLinear<string>()
		.domain(stops.map((stop) => stop.position))
		.range(stops.map((stop) => stop.color))
		.interpolate(interpolateLab);

const isScaleQualitative = (scale: ColorScale): scale is QualitativeScale =>
	'colors' in scale && typeof scale.colors[0] === 'string';

<div
	[id]="dropdownComponent.id"
	[class.border-danger]="isInvalid"
	class="d-flex flex-nowrap flex-row align-items-center justify-content-between m-0 w-100 p-1 minh-38px border rounded position-relative"
	[class.filter-greyscale]="dropdownComponent._isDisabled()"
>
	<div *ngIf="dropdownComponent._value() === null"></div>
	<div
		[ngClass]="{
			'h6 top-0 translate-middle-y eva-topmenu-bg-color': dropdownComponent._value() !== null,
			'h-input': dropdownComponent._value() === null
		}"
		class="position-absolute px-1 transition-all"
		[class.text-muted]="dropdownComponent._isDisabled()"
	>
		<ng-content select="[evasysDropdownLabel]"></ng-content>
	</div>

	<span
		*ngIf="dropdownComponent._value() !== null"
		[attr.data-cy]="'dropdown-value-' + dropdownComponent.id"
		class="me-auto dropdown-value d-block py-0 my-0 px-2"
		[class.text-muted]="dropdownComponent._isDisabled()"
		evasysTextOverflow
	>
		<ng-content select="[value]"></ng-content>
	</span>

	<div class="ms-1 me-2">
		<ng-content select="evasys-note"></ng-content>
		<img
			evasysImage
			class="c-w-20px c-h-20px rotate-90 ms-auto px-1 user-select-none"
			[id]="'expand'"
			[isIcon]="true"
			src="./assets/images/icons/navigate_right.svg"
		/>
	</div>
</div>
<ng-content></ng-content>

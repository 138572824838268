<div [clickAwayActive]="!isMenuCollapsed" (evasysClickAway)="onClickOutside()">
	<div class="ms-auto me-0 eva-topmenu-bg-color row">
		<div class="col-auto col-lg-12 order-1 d-flex flex-row border-0 p-2 p-lg-0 overflow-hidden">
			<button
				class="btn c-hover-gray c-border-radius-0 c-h-55px text-nowrap px-xl-3 c-z-index-1 flex-lg-grow-1 eva-search-width"
				(click)="onButtonClick(MenuStateEnum.SEARCHING)"
				[class.shadow]="menuState === MenuStateEnum.SEARCHING"
				[attr.data-cy]="'searchHelp-button-search'"
			>
				<img
					evasysImage
					[src]="'assets/images/icons/find.svg'"
					[id]="'searchHelp-button-search'"
					[isIcon]="true"
					[class]="'c-w-20px c-h-20px'"
				/>
				<span class="m-0 ms-1 ms-xl-2 h6 eva-topmenu-color">{{
					config?.translations?.searchHelp.searchMenuButtonText | transloco
				}}</span>
			</button>
			<button
				class="btn c-hover-gray c-border-radius-0 c-h-55px text-nowrap px-xl-3 c-z-index-1 flex-lg-grow-1 eva-help-width"
				(click)="onButtonClick(MenuStateEnum.HELPING)"
				[class.shadow]="menuState === MenuStateEnum.HELPING"
				[attr.data-cy]="'searchHelp-button-help'"
			>
				<img
					evasysImage
					[src]="'assets/images/icons/help2.svg'"
					[id]="'searchHelp-button-help'"
					[isIcon]="true"
					[class]="'c-w-20px c-h-20px'"
				/>
				<span class="m-0 ms-1 ms-xl-2 h6 eva-topmenu-color">{{
					config?.translations?.searchHelp.helpMenuButtonText | transloco
				}}</span>
			</button>
		</div>
	</div>
	<div
		#collapse="ngbCollapse"
		[(ngbCollapse)]="isMenuCollapsed"
		[class.collapse-horizontal]="(isLargeScreenObservable | async) === false"
		class="position-relative my-auto c-transition-all eva-topmenu-bg-color eva-search-help-width"
		[class.c-shadow-no-top]="
			(isActive(MenuStateEnum.SEARCHING) || isActive(MenuStateEnum.HELPING)) && (isLargeScreenObservable | async)
		"
	>
		<form [formGroup]="searchForm" class="p-3 d-flex flex-row justify-content-center">
			<evasys-textbox formControlName="searching" class="me-2 me-lg-0" [id]="'searchHelp-searchingInput'">{{
				menuState === MenuStateEnum.SEARCHING
					? (config?.translations?.searchHelp.searchTextboxLabel | transloco)
					: (config?.translations.searchHelp.helpTextboxLabel | transloco)
			}}</evasys-textbox>
			<evasys-button
				[design]="searchButtonDesign"
				[attr.class]="'m-0 ms-1 p-0 flex-grow-1 align-self-stretch d-block'"
				[class]="'h-100'"
				(click)="onSearchSubmit()"
				(keyup.enter)="onSearchSubmit()"
				[id]="'searchHelp-submitSearch'"
				[isDisabled]="!searchForm.valid"
				[type]="'submit'"
			>
				<img
					evasysImage
					[src]="'assets/images/icons/arrow_right.svg'"
					[id]="'searchHelp-submitSearch'"
					[alt]="config?.translations?.searchHelp.searchSubmitButtonAltText | transloco"
					[isIcon]="true"
					[class]="'c-w-20px c-h-20px'"
					class="h-100 d-flex justify-content-center align-content-center"
				/>
			</evasys-button>
		</form>
	</div>
</div>

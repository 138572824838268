import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { endpoints } from '@evasys/globals/evainsights/api/endpoints';
import { Page } from '@evasys/globals/evainsights/models/pagination/page.model';
import { ItemCollection } from '@evasys/globals/evainsights/models/item/item.model';
import { PaginatedItemCollectionSearchRequest } from '@evasys/globals/evainsights/models/search/PaginatedItemCollectionSearchRequest';

@Injectable({
	providedIn: 'root',
})
export class ItemCollectionService {
	private http = inject(HttpClient);

	searchItemCollections(
		filter: PaginatedItemCollectionSearchRequest = { page: 0, size: 20 }
	): Observable<Page<ItemCollection>> {
		return this.http.post<Page<ItemCollection>>(endpoints.postItemCollectionsSearch.url(), filter);
	}
}

import { animate, style, transition, trigger } from '@angular/animations';

export const fadeInLeftAnimation = () => {
	return trigger('fadeInLeftAnimation', [
		transition('void => *', [
			style({
				opacity: 0,
				transform: 'translateX(25px)',
			}),
			animate(
				'400ms',
				style({
					opacity: 1,
					transform: 'translateX(0px)',
				})
			),
		]),

		transition('* => void', [
			animate(
				'400ms',
				style({
					opacity: 0,
					transform: 'translateX(25px)',
				})
			),
		]),
	]);
};

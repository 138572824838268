import { inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PaginatedSearchRequest } from '@evasys/globals/evainsights/models/search/PaginatedSearchRequest';
import { isEqual } from '@evasys/globals/shared/helper/object';

@Injectable({
	providedIn: 'root',
})
export class SearchRequestParamService {
	private router = inject(Router);
	private activatedRoute = inject(ActivatedRoute);

	updateParams(update: Partial<PaginatedSearchRequest> & { [k: string]: unknown }) {
		const currentParams = this.activatedRoute.snapshot.queryParams;
		const { page, size, ...queryParamsUpdate } = update;

		if (size !== undefined) {
			queryParamsUpdate['pageSize'] = size;
		}

		if (page !== undefined || (this.areParamsChanged(queryParamsUpdate) && 'page' in currentParams)) {
			queryParamsUpdate['page'] = page ?? 1;
		}

		this.router.navigate([], {
			queryParams: queryParamsUpdate,
			queryParamsHandling: 'merge',
		});
	}

	private areParamsChanged(queryParamsUpdate: { [k: string]: unknown }) {
		return Object.entries(queryParamsUpdate).some(([name, newValue]) => {
			const currentValue = this.activatedRoute.snapshot.queryParams[name];
			return !isEqual(this.asValuesArray(newValue), this.asValuesArray(currentValue));
		});
	}

	/**
	 * Map the different Params value formats to a string array.
	 */
	private asValuesArray(value: unknown): string[] {
		if (value === undefined || value === null) {
			return [];
		} else if (Array.isArray(value)) {
			return value.map(String);
		} else {
			return [String(value)];
		}
	}
}

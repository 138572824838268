import { Injectable } from '@angular/core';
import { ReportFacadeService } from './report-facade.service';
import { ReportTemplateFacadeService } from './reporttemplate-facade.service';
import { ReportType } from '@evasys/globals/evainsights/constants/types';
import { EvasysLoadingStrategiesEnum } from '@evasys/globals/shared/enums/general/evasys-loadingStrategies.enum';
import { Update } from '@ngrx/entity';
import { Observable } from 'rxjs';
import { Report, ReportTemplate } from '@evasys/globals/evainsights/models/report/report-reportTemplate.model';
import { endpoints } from '@evasys/globals/evainsights/api/endpoints';
import { HttpClient, HttpResponse } from '@angular/common/http';

@Injectable({
	providedIn: 'root',
})
export class ReportAndReportTemplateFacadeService {
	constructor(
		private http: HttpClient,
		private reportTemplateFacadeService: ReportTemplateFacadeService,
		private reportFacadeService: ReportFacadeService
	) {}

	get(
		id: number,
		reportType: ReportType,
		evasysLoadingStrategiesEnum: EvasysLoadingStrategiesEnum
	): Observable<Report | ReportTemplate> {
		return reportType === ReportType.REPORT
			? this.reportFacadeService.get<Report>({
					id: id,
					loadingStrategy: evasysLoadingStrategiesEnum,
			  })
			: this.reportTemplateFacadeService.get<ReportTemplate>({
					id: id,
					loadingStrategy: evasysLoadingStrategiesEnum,
			  });
	}

	updateOneLocal(reportType: ReportType, reportOrReportTemplate: Report | ReportTemplate) {
		const update: Update<Report | ReportTemplate> = {
			id: reportOrReportTemplate.id,
			changes: reportOrReportTemplate,
		};
		reportType === ReportType.REPORT
			? this.reportFacadeService.updateOneLocal(update)
			: this.reportTemplateFacadeService.updateOneLocal(update);
	}

	updateOne(
		reportType: ReportType,
		reportOrReportTemplate: Report | ReportTemplate
	): Promise<Report | ReportTemplate> {
		const update: Update<Report | ReportTemplate> = {
			id: reportOrReportTemplate.id,
			changes: reportOrReportTemplate,
		};
		return reportType === ReportType.REPORT
			? this.reportFacadeService.updateOne(update)
			: this.reportTemplateFacadeService.updateOne(update);
	}

	patchTitle(reportType: ReportType, id: number, title: string): Observable<HttpResponse<unknown>> {
		const url =
			reportType === ReportType.REPORT
				? endpoints.report.url({ reportId: id })
				: endpoints.reportTemplate.url({ reportTemplateId: id });
		return this.http.patch(
			url,
			{ title },
			{
				observe: 'response',
			}
		);
	}
}

import {
	BehaviorSubject,
	combineLatest,
	distinctUntilChanged,
	filter,
	first,
	map,
	Observable,
	shareReplay,
	skip,
	Subject,
	switchAll,
	switchMap,
} from 'rxjs';
import { inject, Injectable } from '@angular/core';
import { UiConfig } from '@evasys/globals/evainsights/models/common/ui-config.model';
import { HttpClient } from '@angular/common/http';
import { endpoints } from '@evasys/globals/evainsights/api/endpoints';
import { TranslocoService } from '@ngneat/transloco';
import { DateTime } from '../../../../../../../shared/ui/src/lib/components/control-components/calendar/src/app/calendar/lib/utils/date';
import { AuthUserServiceHelperService } from '../auth/auth-user-service-helper.service';
import { runtimeEnvironment } from '@evasys/globals/evainsights/environments/runtime-environment';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { isNotNullish } from '@evasys/globals/evainsights/typeguards/common';

@Injectable({
	providedIn: 'root',
})
export class UiConfigService {
	//region Injections
	private readonly http = inject(HttpClient);
	private readonly authUserServiceHelperService = inject(AuthUserServiceHelperService);
	private readonly translocoService = inject(TranslocoService);
	//endregion

	//region Variables
	private static readonly DEFAULT_LOGO_SRC = '/assets/logos/evasys.svg';
	private readonly _uiConfigSource$ = new Subject<Observable<UiConfig>>();
	private readonly _uiConfig$ = new BehaviorSubject<UiConfig>({
		logoSrc: UiConfigService.DEFAULT_LOGO_SRC,
		decimalFormat: ',',
		dateFormat: 'd.m.Y',
		language: 'de_edu',
		timeFormat: 'H:i:s',
	});

	//endregion
	public readonly isInitialized = this._uiConfig$.pipe(
		skip(1),
		first(),
		map(() => true),
		shareReplay()
	);

	constructor() {
		this._uiConfigSource$
			.pipe(switchAll(), map(this.applyDefaults), takeUntilDestroyed())
			.subscribe(this._uiConfig$);
	}

	//region Getter & Setter

	public getUiConfig(): Observable<UiConfig> {
		return this._uiConfig$.asObservable();
	}

	public set uiConfigLanguage(language: string) {
		this.isInitialized.subscribe(() => {
			this._uiConfig$.next({ ...this._uiConfig$.value, language });
		});
	}

	public getTimeDateFormat() {
		return combineLatest([this._uiConfig$, this.translocoService.selectTranslate('common.at')]).pipe(
			map(
				([config, atTranslation]) =>
					`${DateTime.toISOFormat(config.dateFormat)} '${atTranslation}' ${DateTime.toISOFormat(
						config.timeFormat
					)}`
			)
		);
	}
	//endregion

	//region Methods
	public keepUiConfigUpToDate(useSystemUiConfig = false) {
		this.switchUiConfigSource(useSystemUiConfig ? this.getSystemUiConfig() : this.getUserUiConfig());
	}

	public switchUiConfigSource(uiConfigSource: Observable<UiConfig>) {
		this._uiConfigSource$.next(uiConfigSource);
	}

	private applyDefaults(uiConfig: UiConfig) {
		return {
			...uiConfig,
			logoSrc:
				uiConfig.logoSrc != null && uiConfig.logoSrc.trim() !== ''
					? uiConfig.logoSrc
					: UiConfigService.DEFAULT_LOGO_SRC,
		};
	}

	private getSystemUiConfig() {
		return this.http.get<UiConfig>(
			endpoints.getSystemUiConfig.url({
				tenantSubdomain: runtimeEnvironment.tenantSubdomain,
			})
		);
	}

	/**
	 * Returns the user UI config whenever the logged-in user changes, determined by the current auth token
	 */
	private getUserUiConfig() {
		// We have to determine whether to make a request based on the `EvasysTokenModel` auth token instead of the
		// `EvasysUserModel` given by `.getAuthenticatedUser()` because the auth token will be `undefined` as soon as we log out
		// while the user model is still present until the next user logs in.

		return this.authUserServiceHelperService.getToken().pipe(
			map((token) => token?.userid),
			filter(isNotNullish),
			distinctUntilChanged(),
			switchMap(() => this.http.get<UiConfig>(endpoints.getUserUiConfig.url()))
		);
	}

	//endregion
}

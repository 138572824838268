import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegulationClickDirective } from './regulation-click/regulation-click.directive';

@NgModule({
	declarations: [RegulationClickDirective],
	exports: [RegulationClickDirective],
	imports: [CommonModule],
})
export class RegulationClickModule {}

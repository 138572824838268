import { inject, Pipe, PipeTransform } from '@angular/core';
import { KeyValue, KeyValuePipe } from '@angular/common';

@Pipe({
	name: 'keepMapOrder',
})
export class KeepMapOrderPipe implements PipeTransform {
	keyValuePipe = inject(KeyValuePipe);
	transform<K, V>(input: ReadonlyMap<K, V>): Array<KeyValue<K, V>> {
		return this.keyValuePipe.transform(input, () => 0);
	}
}

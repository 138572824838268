import { GridsterComponent, GridsterItem, GridsterItemComponentInterface, GridsterPush } from 'angular-gridster2';
import { range } from 'lodash';
import { assertNotNullish } from '@evasys/globals/evainsights/typeguards/common';

// This file provides modifications to Gridster2's inner workings, allowing us to change the behaviour beyond what is
// currently possible with the GridsterConfig. They opt out of TypeScript checking with `any` as they have to access
// and/or replace private methods and variables. See [1] for a TypeScript feature proposal that would allow us to
// instead remove the private access control modifiers, allowing for better typesafety than with the current `any`.
// [1] https://github.com/microsoft/TypeScript/issues/22677

export function disableGridsterPushBackSwappingItems() {
	// A modified version of the Gridster2's checkPushedItem function. The original implementation often swaps items
	// into a different order while pushing, sometimes completely reordering the grid even with only compact north
	// and push south enabled. This implementation forces gridster to retain the original ordering.
	// https://github.com/tiberiuzuld/angular-gridster2/blob/13d48e374833c759c7d1009e186862136224bba3/projects/angular-gridster2/src/lib/gridsterPush.service.ts#L353
	(GridsterPush.prototype as any).checkPushedItem = function (
		pushedItem: GridsterItemComponentInterface,
		i: number
	): boolean {
		const path = this.pushedItemsPath[i];
		let j = path.length - 2;
		let lastPosition;
		let x;
		let y;
		let change = false;
		for (; j > -1; j--) {
			lastPosition = path[j];
			x = pushedItem.$item.x;
			y = pushedItem.$item.y;
			pushedItem.$item.x = lastPosition.x;
			pushedItem.$item.y = lastPosition.y;
			const collidingItems: GridsterItemComponentInterface[] = this.gridster.findItemsWithItem(pushedItem.$item);
			if (collidingItems.length === 0) {
				pushedItem.setSize();
				path.splice(j + 1, path.length - j - 1);
				change = true;
			} else {
				pushedItem.$item.x = x;
				pushedItem.$item.y = y;

				// This here is the main addition to the Gridster2's implementation. The original always tried to
				// revert back to any one of the prior steps, never doing a return here. This modified
				// implementation only ever undoes push sequences up to the point where they collide with another
				// item (apart from the item that is currently moving)
				if (collidingItems.length > 1 || collidingItems[0] !== this.gridsterItem) {
					return change;
				}
			}
		}

		if (path.length < 2) {
			this.removeFromPushed(i);
		}

		return change;
	};
}

export function makeGridsterSwapCompact() {
	// Override how gridster pushes items away while dragging or resizing. The default behaviour inserts many empty
	// cells while swapping items of different sizes. This implementation minimizes the empty spaces.
	// See https://github.com/tiberiuzuld/angular-gridster2/issues/432 for the related Gridster issue asking for
	// this behaviour to be implemented in gridster itself.
	(GridsterPush.prototype as any).addToPushed = function (gridsterItem: GridsterItemComponentInterface) {
		if (this.pushedItems.indexOf(gridsterItem) < 0) {
			this.pushedItems.push(gridsterItem);

			const newPath = pathBetween(gridsterItem.item, gridsterItem.$item);

			this.pushedItemsPath.push(newPath);
		} else {
			const i = this.pushedItems.indexOf(gridsterItem);
			const currentPath = this.pushedItemsPath[i];
			const lastPath = currentPath[currentPath.length - 1];

			const newPath = pathBetween(lastPath, gridsterItem.$item);

			this.pushedItemsPath[i].push(...newPath);
		}

		function pathBetween(start: GridsterItem, end: GridsterItem) {
			return [
				...inclusiveRange(start.x, end.x).map((x) => ({ x, y: start.y })),
				...inclusiveRange(start.y, end.y).map((y) => ({ x: end.x, y })),
			];
		}
	};
}

export function freezeGridsterRowHeight(gridster: GridsterComponent) {
	// Some grid types like `scrollVertical` ignore the `fixedRowHeight` option and set the row height dynamically in
	// order to e.g. maintain a specific cell width-to-height ratio.
	// This method ensures that gridster will always use the `fixedRowHeight` value.

	Object.defineProperty(gridster, 'curRowHeight', {
		get: () => {
			const height = gridster.options.fixedRowHeight;
			assertNotNullish(height);
			return height;
		},
		set: () => {
			// this is intentional
		},
	});
}

const inclusiveRange = (start: number, end: number) => {
	return start <= end ? range(start, end + 1) : range(start, end - 1);
};

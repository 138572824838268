import { defaultEvasysState, EvasysEntityAdapter, EvasysReducerCaseFactory, EvasysState } from '@evasys/shared/core';
import { evasysActions } from '../actions';
import { Action, createReducer, on } from '@ngrx/store';
import { WizardModel } from '@evasys/globals/shared/models/component/wizard/wizard.model';

export const wizardAdapter = new EvasysEntityAdapter<WizardModel>();
export const wizardInitialState: EvasysState<WizardModel> = wizardAdapter.entity.getInitialState(defaultEvasysState());
const wizardReducerCaseFactory = new EvasysReducerCaseFactory<WizardModel>(wizardAdapter, evasysActions.fromWizard);

const { reduceLoadOneActionSet, reduceLoadOneWithParamsActionSet, reduceLoadActionSet } =
	wizardReducerCaseFactory.reduceEvasysActionSets();

export const wizardReducer = createReducer(
	wizardInitialState,
	...reduceLoadOneActionSet,
	...reduceLoadOneWithParamsActionSet,
	...reduceLoadActionSet,
	on(evasysActions.fromWizard.ToggleWizard.DEFAULT, (state: EvasysState<WizardModel>, { payload }) => {
		if (state?.entities && state.entities[payload.id]?.show !== undefined) {
			state.entities[payload.id].show = payload.show;
		}
		return state;
	})
);

export function reducer(state: EvasysState<WizardModel> | undefined, action: Action) {
	return wizardReducer(state, action);
}

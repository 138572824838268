export enum EvasysActionsEnum {
	LOAD = 'Load',
	LOADONE = 'LoadOne',
	LOADONEWITHPARAMS = 'LoadOneWithParams',
	LOADWITHPARAMS = 'LoadWithParams',
	LOADMANY = 'LoadMany',
	LOADALL = 'LoadAll',
	LOADMANYWITHPARAMS = 'LoadManyWithParams',
	LOADPAGE = 'LoadPage',
	LOADPAGEWITHPARAMS = 'LoadPageWithParams',
	LOADPAGEWITHFILTER = 'LoadPageWithFilter',
	CREATEONE = 'CreateOne',
	CREATEONELOCAL = 'CreateOneLocal',
	CREATEMANY = 'CreateMany',
	UPLOADFILE = 'UploadFile',
	EDITONE = 'EditOne',
	EDITONELOCAL = 'EditOneLocal',
	EDITMANY = 'EditMany',
	DELETEONE = 'DeleteOne',
	DELETEONELOCAL = 'DeleteOneLocal',
	DELETEMANY = 'DeleteMany',
	CLEAR = 'Clear',
	CUSTOM = 'Custom',
}

import { Observable, of } from 'rxjs';

export type PropertyFormatter<T> = keyof T;
export type StringFormatter<T> = (item: T) => string;
export type ObservableFormatter<T> = (item: T) => Observable<string>;
export type Formatter<T> = PropertyFormatter<T> | StringFormatter<T> | ObservableFormatter<T>;

export function toObservableFormatter<T>(formatter: Formatter<T>): ObservableFormatter<T> {
	return (item: T) => {
		if (typeof formatter === 'function') {
			const formatted = formatter(item);
			return typeof formatted === 'string' ? of(formatted) : formatted;
		} else {
			return of(item[formatter] as string);
		}
	};
}

export function raisingFormatter(): never {
	throw Error('Missing formatter');
}

import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableComponent } from './table/table.component';
import { TableHeadComponent } from './table-head/table-head.component';
import { TranslocoModule } from '@ngneat/transloco';
import { TableConfiguration } from './table.configuration';
import { ImageModule } from '../../../directives/image/image.module';
import { TextOverflowModule } from '../../../directives/text-overflow/text-overflow.module';
import { TableColumnComponent } from './table-column/table-column.component';

@NgModule({
	declarations: [TableComponent, TableHeadComponent, TableColumnComponent],
	imports: [CommonModule, TranslocoModule, ImageModule, TextOverflowModule],
	exports: [TableComponent, TableHeadComponent, TableColumnComponent],
})
export class TableModule {
	static forRoot(config: TableConfiguration): ModuleWithProviders<any> {
		return {
			ngModule: TableModule,
			providers: [
				{
					provide: TableConfiguration,
					useValue: config,
				},
			],
		};
	}
}

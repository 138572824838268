import { Component, ContentChildren, inject, QueryList, ViewEncapsulation } from '@angular/core';
import { DropdownComponent } from '../../dropdown/dropdown.component';
import { ErrorComponent } from '../../../../dynamic-components/error/error/error.component';

@Component({
	selector: 'evasys-dropdown-primary',
	templateUrl: './dropdown-primary.component.html',
	styleUrls: ['./dropdown-primary.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class DropdownPrimaryComponent {
	//region ContentChildren
	@ContentChildren(ErrorComponent)
	errors: QueryList<ErrorComponent>;
	//endregion
	//region Injections
	public dropdownComponent = inject(DropdownComponent);

	//endregion

	//region Getter & Setter
	public get isInvalid() {
		return !!this.errors.find((errors) => !!errors.errors.find((error) => error.trigger === true));
	}

	//endregion
}

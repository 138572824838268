import { Report } from '@evasys/globals/evainsights/models/report/report-reportTemplate.model';
import { EvasysActionsEnum } from '@evasys/globals/shared/enums/general/evasys-actions.enum';
import { Update } from '@ngrx/entity';
import { createActionSet } from './createActionSet';

const storeKey = '[REPORTS]';

export const LoadActionSet = createActionSet<number, Report>(storeKey, EvasysActionsEnum.LOAD);
export const CreateOneActionSet = createActionSet<Report, Report>(storeKey, EvasysActionsEnum.CREATEONE);
export const CreateOneLocalActionSet = createActionSet<Report, void>(storeKey, EvasysActionsEnum.CREATEONELOCAL);
export const EditOneActionSet = createActionSet<Update<Report>, Report>(storeKey, EvasysActionsEnum.EDITONE);
export const EditOneLocalActionSet = createActionSet<Update<Report>, void>(storeKey, EvasysActionsEnum.EDITONELOCAL);
export const DeleteOneLocalActionSet = createActionSet<number, void>(storeKey, EvasysActionsEnum.DELETEONELOCAL);

import { animate, state, style, transition, trigger } from '@angular/animations';

export const marginLeftAnimation = (from: string, to: string, time: string) => {
	return trigger('marginLeftAnimation', [
		state(
			'false',
			style({
				marginLeft: from,
			})
		),
		state(
			'true',
			style({
				marginLeft: to,
			})
		),
		transition('true <=> false', [animate(time + ' ease-out')]),
		transition(':enter', [
			style({
				marginLeft: from,
			}),
			animate(
				time + ' ease-out',
				style({
					marginLeft: to,
				})
			),
		]),
		transition(':leave', [
			animate(
				time + ' ease-out',
				style({
					marginLeft: from,
				})
			),
		]),
	]);
};

import * as fromTranslate from './translate.actions';
import * as fromConfigs from './config.actions';
import * as fromAuth from './auth.actions';
import * as fromNotifications from './notification.actions';
import * as fromAuthUser from './auth-user.actions';
import * as fromGlobal from './global.actions';

export const sharedActions = {
	fromTranslate,
	fromConfigs,
	fromAuth,
	fromAuthUser,
	fromGlobal,
	fromNotifications,
};

<button
	#toggleElement
	id="notificationCollection"
	class="btn h-100 d-block position-relative m-0 p-0"
	(click)="onCollapseToggle()"
	[attr.data-cy]="'notificationCollection-toggle'"
>
	<evasys-mark [marked]="notifications.length > 0" [id]="'notificationAvailable'">
		<img
			evasysImage
			[src]="'./assets/images/icons/preferences_gear.svg'"
			[id]="'notificationCollection'"
			[alt]="config.translations.notificationCollection.notificationIconTitle | transloco"
			[class]="'c-w-20px c-h-20px'"
			[isIcon]="true"
			altTextAsLabel="true"
		/>
	</evasys-mark>
</button>

<div
	[clickAwayActive]="!collapsed"
	(evasysClickAway)="collapsed = true"
	[toggleElement]="toggleElement"
	@nestedAnimations
	class="h-100 p-0 position-relative"
>
	<div
		*ngIf="!collapsed"
		@yGrowAnimation
		class="position-absolute eva-topmenu-bg-color c-w-500px c-translate-x-95-invert start-0 eva-topmenu-bg-color eva-topmenu-color shadow-sm c-transition-all animate-hidden"
	>
		<div
			*ngIf="notifications.length === 0"
			[attr.data-cy]="'notificationCollection-empty'"
			class="h-auto my-0 p-3 d-flex justify-content-between"
		>
			{{ 'S_NO_MESSAGES_AVAILABLE' | transloco }}
		</div>
		<div
			*ngFor="let notification of notifications; last as isLast"
			class="h-auto my-0 p-3 d-flex flex-column align-content-start"
			[ngClass]="{ 'border-bottom': !isLast }"
		>
			<div class="d-flex">
				<p
					class="my-1 text-break"
					[attr.data-cy]="'notificationCollection-notification-' + notification.id"
					[innerHTML]="notification.message"
				></p>
				<button
					class="btn ms-auto"
					[attr.data-cy]="'button-deleteNotification-' + notification.id"
					(click)="onDeleteAction(notification.id)"
				>
					<img
						evasysImage
						[src]="'./assets/images/icons/delete2.svg'"
						[id]="'deleteNotification-' + notification.id"
						[class]="'c-w-20px'"
						[isIcon]="true"
					/>
				</button>
			</div>
			<evasys-button
				*ngIf="!!notification.action"
				[id]="'apiCall-' + notification.id"
				(click)="onApiCallAction(notification.action); onDeleteAction(notification.id)"
			>
				{{ notification.action?.text }}
			</evasys-button>
		</div>
	</div>
</div>

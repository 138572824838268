import {
	AfterContentChecked,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EnvironmentInjector,
	inject,
	OnDestroy,
	OnInit,
	runInInjectionContext,
} from '@angular/core';
import { distinctUntilChanged, filter, first, map, Observable, of, Subscription } from 'rxjs';
import { paths } from '@evasys/globals/evainsights/constants/paths';
import { AuthService, AuthUserService, NotificationService } from '@evasys/shared/core';
import { UserRightDesignation } from '@evasys/globals/shared/enums/business/user-rights.enum';
import { TranslocoService } from '@ngneat/transloco';
import { Tabitem } from '@evasys/shared/ui';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { EvasysNotificationModel } from '@evasys/globals/shared/models/evasys-notification.model';
import { getUserLanguageCode, LoggerService } from '@evasys/evainsights/shared/util';
import { runtimeEnvironment } from '@evasys/globals/evainsights/environments/runtime-environment';
import { AuthUserServiceHelperService, EndpointsService, UiConfigService } from '@evasys/evainsights/shared/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
	selector: 'evainsights-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy, AfterContentChecked, OnInit {
	private readonly translocoService = inject(TranslocoService);
	private readonly environmentInjector = inject(EnvironmentInjector);
	private readonly notificationService = inject(NotificationService);
	private readonly authService = inject(AuthService);
	private readonly authUserService = inject(AuthUserService);
	private readonly authUserServiceHelperService = inject(AuthUserServiceHelperService);
	private readonly uiConfigService = inject(UiConfigService);
	private readonly logger = inject(LoggerService);
	private readonly router = inject(Router);
	private readonly rootRoute = inject(ActivatedRoute);
	private readonly endpointsService = inject(EndpointsService);
	private readonly elementRef = inject(ElementRef);
	private cdref = inject(ChangeDetectorRef);

	title = 'evainsights';
	menuTabItems: Tabitem<string>[];
	userRightDesignation = UserRightDesignation;
	authenticated$ = this.authService.hasToken();
	user = this.authUserService.authenticatedUserObservable;
	translocoMenuSubscription: Subscription;
	showMainContentOnly: Observable<boolean>;
	notifications: Observable<EvasysNotificationModel[]>;
	logoAnchorPath: string;
	logo$ = this.uiConfigService.getUiConfig().pipe(map((config) => config.logoSrc));

	constructor() {
		this.authUserServiceHelperService.getToken().subscribe((token) => {
			this.logger.context = {
				tenantSubdomain: runtimeEnvironment.tenantSubdomain,
				userId: token?.userid ?? null,
			};
		});

		this.translocoMenuSubscription = this.translocoService
			.selectTranslateObject('domain')
			.subscribe((menuTranslation) => {
				this.menuTabItems = [
					{
						id: 'reports',
						title: menuTranslation.reports,
						href: of(paths.getReports.build()),
						queryParamsHandling: 'preserve',
					},
					{
						id: 'reportTemplates',
						title: menuTranslation.reportTemplates,
						href: of(paths.getReportTemplates.build()),
						queryParamsHandling: 'preserve',
					},
					{
						id: 'textAnalysis',
						title: menuTranslation.textAnalysis,
						href: of(paths.getTextAnalysis.build()),
						queryParamsHandling: 'preserve',
					},
				];
			});

		this.showMainContentOnly = this.router.events.pipe(
			filter((e: NavigationEnd): e is NavigationEnd => e instanceof NavigationEnd),
			map(() => this.rootRoute.children.every((child) => child.snapshot.data.showMainContentOnly ?? false))
		);
		this.notifications = this.notificationService.getAllForCollection();
		this.logoAnchorPath = paths.getReports.build();
	}

	ngOnInit() {
		runInInjectionContext(this.environmentInjector, () => {
			this.translocoService.langChanges$.pipe(takeUntilDestroyed()).subscribe(this.onLanguageChange);
			this.router.events
				.pipe(
					filter((e) => e instanceof NavigationEnd),
					map(() => this.rootRoute.firstChild?.snapshot.data['showMainContentOnly']),
					distinctUntilChanged(),
					takeUntilDestroyed()
				)
				.subscribe((showMainContentOnly) => {
					this.uiConfigService.keepUiConfigUpToDate(showMainContentOnly);
				});
		});
	}

	onLanguageChange = () => {
		const languageCode = getUserLanguageCode(this.translocoService.getActiveLang());
		document.documentElement.lang = languageCode;
	};

	onUserRightChange(_event: Event) {
		// implement user right change mechanism
	}

	onLogout() {
		this.authService
			.logout()
			.then(() => {
				this.endpointsService
					.getEvasysEndpoint()
					.pipe(first())
					.subscribe((endpoint) => {
						const loginUrl = endpoint.value + 'public/ui';
						window.location.href = loginUrl;
					});
			})
			.catch((err) => console.error('Can not Logout on event: ', err));
	}

	ngOnDestroy(): void {
		this.translocoMenuSubscription.unsubscribe();
	}

	ngAfterContentChecked() {
		this.cdref.detectChanges();
	}
}

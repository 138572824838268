import { inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable({ providedIn: 'root' })
export class TitleService {
	private title = inject(Title);

	setTitle(titleText: string) {
		this.title.setTitle(titleText + ' - evasys/insights');
	}
}

import { sharedActions } from '../actions';
import { Action, ActionReducer, createReducer, MetaReducer, on } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { EvasysNotificationModel } from '@evasys/globals/shared/models/evasys-notification.model';
import { EvasysEntityAdapter } from '../essential/redux/adapter/evasys-entity.adapter';
import { defaultEvasysState, EvasysState } from '../essential/redux/states/evasys.state';
import { EvasysReducerCaseFactory } from '../essential/redux/reducers/evasys-reducer-case.factory';
import { getWindowVariable } from '@evasys/globals/shared/helper/window';

export const notificationAdapter = new EvasysEntityAdapter<EvasysNotificationModel>();
export const notificationInitialState: EvasysState<EvasysNotificationModel> =
	notificationAdapter.entity.getInitialState(defaultEvasysState());
const notificationReducerCaseFactory = new EvasysReducerCaseFactory<EvasysNotificationModel>(
	notificationAdapter,
	sharedActions.fromNotifications
);

const { reduceClearActionSet, reduceLoadActionSet } = notificationReducerCaseFactory.reduceEvasysActionSets();

export const notificationReducer = createReducer(
	notificationInitialState,
	...reduceClearActionSet,
	...reduceLoadActionSet,
	on(
		sharedActions.fromNotifications.CreateOneActionSet.DEFAULT,
		(state: EvasysState<EvasysNotificationModel>, { payload }) => notificationAdapter.entity.addOne(payload, state)
	),
	on(
		sharedActions.fromNotifications.CreateManyActionSet.DEFAULT,
		(state: EvasysState<EvasysNotificationModel>, { payload }) => notificationAdapter.entity.addMany(payload, state)
	),
	on(
		sharedActions.fromNotifications.DeleteOneActionSet.DEFAULT,
		(state: EvasysState<EvasysNotificationModel>, { payload }) =>
			notificationAdapter.entity.removeOne(payload, state)
	),
	on(
		sharedActions.fromNotifications.UpdateOneActionSet.DEFAULT,
		(state: EvasysState<EvasysNotificationModel>, { payload }) =>
			notificationAdapter.entity.updateOne(payload, state)
	)
);

export function reducer(state: EvasysState<EvasysNotificationModel> | undefined, action: Action) {
	return notificationReducer(state, action);
}

//MetaReducer to store the config in localStorage

function localStorageSyncReducer(
	syncReducer: ActionReducer<EvasysState<EvasysNotificationModel>>
): ActionReducer<EvasysState<EvasysNotificationModel>> {
	return localStorageSync({
		keys: ['ids', 'entities'],
		rehydrate: true,
		storageKeySerializer: (key) =>
			`${
				getWindowVariable<string>('base-href') ? getWindowVariable<string>('base-href') + '_' : ''
			}notification_${key}`,
	})(syncReducer);
}

export const metaReducers: Array<MetaReducer<EvasysState<EvasysNotificationModel>>> = [localStorageSyncReducer];

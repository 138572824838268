<div
	class="d-flex flex-row flex-nowrap"
	(click)="onClick()"
	[ngClass]="['justify-content-' + position]"
	[class.c-cursor-pointer]="sortable"
	[class.fw-bold]="sortable"
>
	<div evasysTextOverflow [text]="overflowText">
		<ng-content></ng-content>
	</div>
	<div [class.d-none]="!sortable || !sorting">
		<img
			evasysImage
			[id]="'table-sortIcon-ascending-' + imageId"
			[src]="'assets/images/icons/down.svg'"
			[isIcon]="true"
			[class]="'c-w-20px c-h-20px'"
			[class.d-none]="!sortOrderAscending"
			[alt]="config.translations.tableHead.sortDescending | transloco"
			altTextAsLabel="true"
		/>
		<img
			evasysImage
			[id]="'table-sortIcon-descending-' + imageId"
			[src]="'assets/images/icons/up.svg'"
			[isIcon]="true"
			[class]="'c-w-20px c-h-20px'"
			[class.d-none]="sortOrderAscending"
			[alt]="config.translations.tableHead.sortAscending | transloco"
			altTextAsLabel="true"
		/>
	</div>
</div>

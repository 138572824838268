<ul
	#nav="ngbNav"
	(mouseleave)="openSubMenuId = null"
	[(activeId)]="active"
	[class.border-bottom-0]="!underline"
	[class.justify-content-center]="centerItems"
	[orientation]="orientation"
	class="nav-tabs"
	ngbNav
>
	<li
		(click)="openSupMenu(tabitem.id)"
		*ngFor="let tabitem of tabitems; let id = index"
		[class.border-bottom]="orientation === 'vertical'"
		[ngbNavItem]="id"
		class="nav-li"
	>
		<button
			(click)="onItemClicked(tabitem.id, id)"
			*ngIf="!tabitem.href; else link"
			[attr.data-cy]="'tabs-' + tabitem.id"
			[class.active]="active === id"
			[class.px-xl-3]="orientation === 'horizontal'"
			class="py-3 px-2 nav-link"
			ngbNavLink
		>
			{{ tabitem.title | toObservable | async | uppercase }}
		</button>

		<div
			*ngIf="tabitem.id === openSubMenuId"
			class="bg-white fa-w-16 h6 sub-menu"
			[attr.data-cy]="'tabSubMenu-' + tabitem.id"
		>
			<a
				(click)="onSubItemClicked($event, subItem.id, subId, id)"
				*ngFor="let subItem of tabitem.subItems; let subId = index"
				[attr.data-cy]="'tabSubItem-' + subItem.id"
				[routerLink]="subItem.href | async"
				[routerLinkActive]="'active'"
				[queryParamsHandling]="subItem.queryParamsHandling"
				class="dropdown-item p-3 sub-nav-link"
			>
				<div *ngIf="subItem.isTitleImage !== true">{{ subItem.title | toObservable | async | uppercase }}</div>
				<img
					evasysImage
					[alt]="'subItem-titleImage'"
					[class]="'c-w-200px c-h-18px'"
					[id]="'subItem-titleImage-' + subItem.id"
					*ngIf="subItem.isTitleImage"
					[src]="subItem.title | toObservable | async"
				/>
			</a>
		</div>

		<ng-template #link>
			<a
				(click)="activeSub = 0; onItemClicked(tabitem.id, id)"
				*ngIf="!tabitem.isMultiPage; else multiPage"
				[attr.data-cy]="'tabs-' + tabitem.id"
				[class.px-xl-3]="orientation === 'horizontal'"
				[routerLink]="tabitem.href | async"
				class="py-3 px-2"
				ngbNavLink
				>{{ tabitem.title | toObservable | async | uppercase }}</a
			>
		</ng-template>

		<ng-template #multiPage>
			<a
				[attr.data-cy]="'tabs-' + tabitem.id"
				[class.px-xl-3]="orientation === 'horizontal'"
				[href]="tabitem.href | async"
				class="py-3 px-2 nav-link"
			>
				<evasys-mark [marked]="tabitem.mark" [id]="tabitem.id">
					{{ tabitem.title | toObservable | async | uppercase }}
				</evasys-mark>
			</a>
		</ng-template>
	</li>
</ul>

<div class="position-relative c-h-50px c-w-50px">
	<div
		[clickAwayActive]="!isCollapsed"
		[class.c-w-50px-i]="isCollapsed"
		(evasysClickAway)="isCollapsed = true"
		class="eva-usermenu-width p-0 position-absolute c-absolute-right h-100 c-transition-all eva-topmenu-bg-color"
	>
		<button
			class="btn h-100 m-0 mx-auto d-block p-0 text-right"
			(click)="onCollapseToggle()"
			[attr.data-cy]="'usermenu-toggle'"
		>
			<img
				evasysImage
				[alt]="config.translations.usermenu.userMenuIconTitle | transloco"
				[src]="'./assets/images/icons/users3.svg'"
				[id]="'usermenu'"
				[class]="'c-w-20px c-h-20px'"
				altTextAsLabel="true"
			/>
		</button>

		<div
			#growPanel
			class="c-transition-all c-z-index-2 eva-topmenu-bg-color eva-topmenu-color animate-hidden"
			[class.shadow-sm]="!isCollapsed"
			@yGrowAnimation
			*ngIf="!isCollapsed"
		>
			<div class="p-3">
				<div class="p-0 mb-4 col-12 c-cursor-def">
					<p #usernameRef class="m-0 p-0 h5 text-nowrap fw-bold" [attr.data-cy]="'usermenu-userName'">
						{{ username }}
					</p>
					<p class="m-0 p-0 text-nowrap fw-normal h5" [attr.data-cy]="'usermenu-userRole'">{{ role }}</p>
				</div>
				<evasys-dropdown
					class="m-0 p-0 mb-3 col-12"
					*ngIf="roles?.size >= 1"
					[id]="'roleDropdown'"
					(valueChange)="respond($event)"
					[menuPlacement]="'bottom-end'"
					[formControls]="[roleControl]"
				>
					<evasys-dropdown-primary evasysDropdownToggle>
						<span evasysDropdownLabel>{{
							config?.translations?.usermenu.changeRoleDropdownText | transloco
						}}</span>
						<span value *evasysDropdownValue="let value = value; mapping: roles">{{ value }}</span>
						<div *evasysDropdownMenu>
							<evasys-selector [forceSelection]="true" [formControl]="roleControl" [id]="'roleDropdown'">
								<evasys-selector-item *ngFor="let role of roles | keepMapOrder" [value]="role.key">
									{{ role.value }}
								</evasys-selector-item>
							</evasys-selector>
						</div>
					</evasys-dropdown-primary>
				</evasys-dropdown>

				<div class="text-nowrap pb-2">
					<evasys-button
						[id]="'logout'"
						(evasysRegulationClick)="logoutResponse.emit()"
						class="d-inline-block"
						>{{ config?.translations?.usermenu.logoutButtonText | transloco }}
					</evasys-button>

					<evasys-button
						*ngIf="canSwitchUser"
						[id]="'switchUser'"
						(click)="openUserSwitch.emit()"
						[class]="'d-inline-block ms-1'"
						>{{ config?.translations?.usermenu.switchUserText | transloco }}
					</evasys-button>
				</div>

				<evasys-system-usage
					*ngIf="showSystemUsage"
					[system]="system"
					[activeSystemUser]="activeSystemUser"
					[activeOnlineUser]="activeOnlineUser"
					(systemUsageCall)="systemUsageCall.emit()"
				>
				</evasys-system-usage>
			</div>
		</div>
	</div>
</div>

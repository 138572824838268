import { Injectable } from '@angular/core';
import { firstValueFrom, from, Observable } from 'rxjs';
import { sharedActions } from '../actions';
import { Store } from '@ngrx/store';
import { catchError, map } from 'rxjs/operators';
import { sharedSelectors } from '../selectors';
import { AuthUserService } from './auth-user.service';
import { EvasysTokenModel } from '@evasys/globals/shared/models/general/evasys-token.model';
import { FacadeService } from '../essential/redux/services/facade.service';
import { EvasysState } from '../essential/redux/states/evasys.state';
import { LoginModel } from '@evasys/globals/evasys/models/business/login.model';
import { EvasysNotificationModel } from '@evasys/globals/shared/models/evasys-notification.model';
import { GlobalService } from './global.service';
import { Actions } from '@ngrx/effects';
import { dispatchResult } from '@evasys/shared/util';
import { SharedCoreConfiguration } from '../shared-core.configuration';
import { EvasysRevocationRequestModel } from '@evasys/globals/shared/models/general/evasys-revocation-request.model';
import { EvasysRevokeTokenTypeEnum } from '@evasys/globals/shared/models/general/evasys-revoke-token-type.enum';

@Injectable({
	providedIn: 'root',
})
export class AuthService extends FacadeService<EvasysTokenModel> {
	constructor(
		private readonly tokenStore: Store<EvasysState<EvasysTokenModel>>,
		private readonly globalService: GlobalService,
		private readonly userService: AuthUserService,
		protected override actions: Actions,
		private readonly sharedCoreConfiguration: SharedCoreConfiguration
	) {
		super(tokenStore, sharedActions.fromAuth, sharedSelectors.fromAuth, actions);
	}

	get token(): Observable<EvasysTokenModel> {
		return this.tokenStore.select(sharedSelectors.fromAuth.selectToken);
	}

	public login(loginModel: LoginModel): Promise<EvasysTokenModel> {
		this.requestCounter += 1;
		const result = dispatchResult(this.actions, sharedActions.fromAuth.LoginActionSet).then((token) => {
			return firstValueFrom(this.userService.authenticateUser(token.userid)).then(() => {
				return token;
			});
		});

		this.tokenStore.dispatch(
			sharedActions.fromAuth.LoginActionSet.DEFAULT({
				payload: {
					...loginModel,
				},
				requestId: this.requestCounter,
			})
		);

		return result;
	}

	public async logout() {
		this.requestCounter += 1;
		try {
			const token = await firstValueFrom(this.tokenStore.select(sharedSelectors.fromAuth.selectToken));
			if (token && token.access_token) {
				const logoutResult = dispatchResult(this.actions, sharedActions.fromAuth.LogoutActionSet).finally(
					() => {
						this.userService.unauthenticateUser();
						this.globalService.clear();
						//remove localStorage of LocalStorageForm Decorator
						Object.keys(localStorage)
							.filter((x) => x.startsWith('LocalStorageForm_'))
							.forEach((x) => localStorage.removeItem(x));
						return true;
					}
				);

				const payload: EvasysRevocationRequestModel =
					this.sharedCoreConfiguration.api.revokeTokenType === EvasysRevokeTokenTypeEnum.REFRESH_TOKEN
						? { token: token.refresh_token, token_type_hint: EvasysRevokeTokenTypeEnum.REFRESH_TOKEN }
						: { token: token.access_token, token_type_hint: EvasysRevokeTokenTypeEnum.ACCESS_TOKEN };

				this.tokenStore.dispatch(
					sharedActions.fromAuth.LogoutActionSet.DEFAULT({
						payload,
						requestId: this.requestCounter,
					})
				);

				return logoutResult;
			} else {
				return Promise.resolve(true);
			}
		} catch (err) {
			console.error('Can not logout: ', err);
			return Promise.resolve(false);
		}
	}

	public refreshLogin(): Observable<EvasysTokenModel | null> {
		return from(
			firstValueFrom(this.tokenStore.select(sharedSelectors.fromAuth.selectToken)).then((token) => {
				if (token) {
					this.requestCounter += 1;
					const result = dispatchResult(this.actions, sharedActions.fromAuth.RefreshActionSet);
					this.tokenStore.dispatch(
						sharedActions.fromAuth.RefreshActionSet.DEFAULT({
							payload: token.refresh_token,
							requestId: this.requestCounter,
						})
					);
					return result;
				} else {
					return null;
				}
			})
		).pipe(
			catchError((err) => {
				console.error('Can not refresh the token: ', err);
				throw err;
			})
		);
	}

	public hasValidToken(): Observable<boolean> {
		return this.tokenStore.select(sharedSelectors.fromAuth.selectHasValidToken);
	}

	public hasToken(): Observable<boolean> {
		return this.tokenStore.select(sharedSelectors.fromAuth.selectToken).pipe(map((token) => !!token));
	}

	public getLoginMessages(): Observable<EvasysNotificationModel[]> {
		return this.tokenStore.select(sharedSelectors.fromAuth.selectLoginMessages);
	}

	public getStartPage(): Observable<string> {
		return this.tokenStore.select(sharedSelectors.fromAuth.selectStartPage);
	}

	public getCompleteStartPage(): Observable<string> {
		return this.tokenStore.select(sharedSelectors.fromAuth.selectCompleteStartPage);
	}

	public clear(): void {
		this.tokenStore.dispatch(
			sharedActions.fromAuth.ClearActionSet.DEFAULT({
				payload: undefined,
			})
		);
	}
}

import { Component, EventEmitter, Input, Output, signal } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ChangeEventFunc } from '@evasys/globals/shared/types/select.types';

@Component({
	selector: 'evasys-selector',
	templateUrl: './selector.component.html',
	styles: [':host {display: flex}'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: SelectorComponent,
			multi: true,
		},
	],
})
export class SelectorComponent<T> implements ControlValueAccessor {
	//region Input & Output
	@Input({ required: true })
	public id?: string;

	@Input()
	public formControlName?: string;

	@Input()
	public allowMultiSelect = false;

	@Input()
	public isDisabled = false;

	@Input()
	forceSelection = false;

	@Output()
	selectionChange = new EventEmitter<T>();

	@Output()
	onlyNewSelectionChange = new EventEmitter<T>();
	//#endregion

	//region Variables
	public value = signal<T | T[]>(null);
	public options = signal<T[]>([]);
	public isMouseDown = signal<boolean>(false);

	//endregion

	//region Events

	public _onTouched: any = () => {
		//default
	};
	public _onChange: any = () => {
		//default
	};

	onValueChange(value: T) {
		this.value.set(value);
		this._onChange(this.value());
		this._onTouched();
		this.selectionChange.emit(value);
	}

	onMouseDown() {
		this.isMouseDown.set(true);
	}

	onMouseUp() {
		this.isMouseDown.set(false);
	}

	onMouseLeave() {
		this.isMouseDown.set(false);
	}

	//endregion

	//region Methods
	public selectBetween(value: T) {
		const input = this.value() as [];

		const fromValue = input[input.length - 1];
		const toValue = value;

		let found = false;
		const selectedValues = this.options().filter((value) => {
			if (value === toValue || value === fromValue) {
				found = !found;
				if (!found) {
					return true;
				}
			}
			return found;
		});

		selectedValues.forEach((selectedValue) => this.onlyNewSelectionChange.emit(selectedValue));

		const newSelectedValues = Array.from(new Set([...input, ...selectedValues]));
		this.value.set(newSelectedValues);
	}
	//endregion

	//region ControlValueAccessor

	public registerOnChange(fn: ChangeEventFunc): void {
		this._onChange = fn;
	}

	public registerOnTouched(fn: any): void {
		this._onTouched = fn;
	}

	public setDisabledState(disabled: boolean): void {
		this.isDisabled = disabled;
	}

	public writeValue(value: T) {
		this.value.set(value);
	}

	//endregion
}

import { Component, Input } from '@angular/core';
import { Required } from '@evasys/globals/shared/decorators/decorators';

@Component({
	selector: 'evasys-header',
	templateUrl: './header.component.html',
})
export class HeaderComponent {
	//region Input & Output

	@Input()
	@Required()
	id: string;

	@Input()
	@Required()
	headerLevel: number;

	@Input()
	class? = '';

	@Input()
	text?: string;
	//endregion

	//region Events

	//endregion
}

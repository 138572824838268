import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ConfigEffects } from '../effects/config.effects';
import { configInitialState, configReducer, metaReducers } from '../reducers/config.reducer';

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		EffectsModule.forFeature([ConfigEffects]),
		StoreModule.forFeature('configs', configReducer, { metaReducers, initialState: configInitialState }),
	],
})
export class ConfigsModule {}

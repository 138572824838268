<div
	class="d-flex flex-column flex-nowrap gap-1 p-3 c-overflow-y-auto mh-100 minw-100"
	[attr.data-cy]="'selector-' + id"
	[attr.isDisabled]="isDisabled"
	[attr.multiSelect]="allowMultiSelect"
	[attr.forceSelection]="forceSelection"
	[class.opacity-50]="isDisabled"
	(mouseleave)="onMouseLeave()"
	(mousedown)="onMouseDown()"
	(mouseup)="onMouseUp()"
>
	<ng-content></ng-content>
</div>

import { NgModule } from '@angular/core';
import { ProductMenuComponent } from './product-menu.component';
import { TranslocoModule } from '@ngneat/transloco';
import { ProductMenuItemComponent } from './product-menu-item/product-menu-item.component';
import { ImageModule } from '../../../../../directives/image/image.module';
import { CommonModule } from '@angular/common';

@NgModule({
	declarations: [ProductMenuComponent, ProductMenuItemComponent],
	imports: [CommonModule, TranslocoModule, ImageModule],
	exports: [ProductMenuComponent, ProductMenuItemComponent],
})
export class ProductMenuModule {}

import { createAction, props } from '@ngrx/store';
import { EvasysResponseStateEnum } from '@evasys/globals/shared/enums/general/evasys-response-state.enum';
import { HttpErrorResponse } from '@angular/common/http';
import { EvasysActionsEnum } from '@evasys/globals/shared/enums/general/evasys-actions.enum';

export function createActionSet<IN, OUT>(key: string, evasysActionsEnum: EvasysActionsEnum) {
	return {
		DEFAULT: createAction([key, evasysActionsEnum].join(' '), props<{ payload: IN; requestId?: number }>()),
		SUCCESS: createAction(
			[key, evasysActionsEnum, EvasysResponseStateEnum.SUCCESS].join(' '),
			props<{ payload: OUT; requestId?: number }>()
		),
		FAILURE: createAction(
			[key, evasysActionsEnum, EvasysResponseStateEnum.FAILURE].join(' '),
			props<{ error: HttpErrorResponse; requestId?: number }>()
		),
	};
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlSerializer, UrlTree } from '@angular/router';
import { from, Observable, of } from 'rxjs';
import { UserRightsEnum } from '@evasys/globals/shared/enums/business/user-rights.enum';
import { switchMap } from 'rxjs/operators';
import { AuthService, AuthUserService } from '@evasys/shared/core';
import { omit } from 'lodash';

@Injectable({
	providedIn: 'root',
})
export class AuthGuard {
	constructor(
		private readonly router: Router,
		private readonly authService: AuthService,
		private readonly authUserService: AuthUserService,
		private readonly urlSerializer: UrlSerializer
	) {}

	canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
		const userRights = route.data.rights;
		const userLevels = route.data.levels;

		return this.authUserService.authenticatedUserObservable.pipe(
			switchMap((user) => {
				if (route.data.addRedirectAndSessionUrlParams && route.queryParams['forwardinfo']) {
					const redirectUrl = this.urlSerializer.serialize(
						this.router.createUrlTree(
							route.url.map((segment) => segment.path),
							{
								queryParams: omit(route.queryParams, 'forwardinfo'),
							}
						)
					);
					const loginUrl = this.urlSerializer.serialize(
						this.router.createUrlTree([], {
							queryParams: {
								redirectUrl,
								forwardinfo: route.queryParams['forwardinfo'],
							},
						})
					);
					return from(
						this.authService
							.logout()
							.then(() => this.router.parseUrl(loginUrl))
							.catch(() => {
								return this.router.parseUrl('');
							})
					);
				} else if (user && (userRights === null || user.isAuthorized(userRights))) {
					return of(true);
				} else if (
					(user && !!userRights.find((userRight) => user.userRights?.includes(userRight))) ||
					(user && !!userLevels.includes(user.level))
				) {
					const newActiveRole = userRights.find(
						(userRight) => userRight !== UserRightsEnum.FIRSTADMIN && user.userRights.includes(userRight)
					);
					user.changeActiveRole(newActiveRole);
					return of(true);
				} else {
					return from(
						this.authService
							.logout()
							.then(() => this.router.parseUrl(''))
							.catch(() => {
								return this.router.parseUrl('');
							})
					);
				}
			})
		);
	}
}

import { FormMultiLang } from '../common/multi-lang';
import { ItemOption } from './item-value-position.model';

export interface Item {
	id: number;
	typeId: ItemTypeId;
	info?: ItemInfo | null;
	code: string;
	text: FormMultiLang<string>;
	itemOptions: ItemOption[];
	position: number;
}
export enum ItemTypeId {
	SCALA = 0,
	OPTIONAL_SCALA = 1,
	OPEN_QUESTION = 2,
	OPTIONAL_OPEN_QUESTION = 3,
	GRADE_VALUE = 4,
	OPTIONAL_GRADE_VALUE = 5,
	SINGLE_CHOICE = 6,
	OPTIONAL_SINGLE_CHOICE = 7,
	MULTIPLE_CHOICE = 8,
	OPTIONAL_MULTIPLE_CHOICE = 9,
	MONSTER = 10,
	OPTIONAL_MONSTER = 11,
	MATRIX = 14,
	SCORING = 16,
	TABLE_MATRIX = 20,
	COMMENT = 21,
	LINE_HORIZONTAL = 22,
	PDF_PAGE_BREAK = 33,
	RANKING = 999,
}

export enum ItemInfoType {
	CHOICE = 'choice',
	OPEN_QUESTION = 'openQuestion',
	SCALA = 'scala',
}

// Do not export - only for modeling
interface ItemInfoBase<Type extends ItemInfoType> {
	type: Type;
}

export interface ChoiceItemInfo extends ItemInfoBase<ItemInfoType.CHOICE> {
	itemOptionCount: number;
}

export interface OpenQuestionItemInfo extends ItemInfoBase<ItemInfoType.OPEN_QUESTION> {
	responseCount: number;
}

export interface ScalaItemInfo extends ItemInfoBase<ItemInfoType.SCALA> {
	itemOptionCount: number;
	leftPoleText: FormMultiLang<string>;
	rightPoleText: FormMultiLang<string>;
}

export interface ItemCollection {
	id: number;
	text: FormMultiLang<string>;
	formShortName: string;
	items: Item[];
}

export type ItemInfo = ChoiceItemInfo | OpenQuestionItemInfo | ScalaItemInfo;

import { Component, Input } from '@angular/core';
import { LegalNoticeService } from './legal-notice.service';
import { SharedUiConfiguration } from '../../../shared-ui.configuration';
import { of } from 'rxjs';
import { Required } from '@evasys/globals/shared/decorators/decorators';

@Component({
	selector: 'evasys-legal-notice',
	templateUrl: './legal-notice.component.html',
})
export class LegalNoticeComponent {
	@Required()
	@Input()
	id: string;
	public get text() {
		return this.config.translations.legalNotice.text;
	}

	public get link() {
		return this.config.translations.legalNotice.link;
	}

	public get isScantronLicense() {
		return this.legalNoticeService.isScantronObservable ?? of(false);
	}

	constructor(
		public readonly legalNoticeService: LegalNoticeService,
		public readonly config: SharedUiConfiguration
	) {}
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
	imports: [CommonModule, FormsModule, TranslocoModule],
	declarations: [],
	exports: [],
})
export class EvainsightsSharedCoreModule {}

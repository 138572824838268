<ng-template #content let-modal>
	<div [evasysIfContent]="1" class="modal-header">
		<ng-content select="[header]"></ng-content>

		<evasys-button
			*ngIf="closeable"
			[id]="'modal-close'"
			[design]="buttonDesign.LINK"
			(click)="modal.dismiss('Cross click')"
			class="col-auto"
		>
			<img
				evasysImage
				[class]="'c-w-20px c-h-20px'"
				[id]="'modal-close'"
				[isIcon]="true"
				[src]="'assets/images/icons/window-close.svg'"
			/>
		</evasys-button>
	</div>
	<div class="modal-body py-3" [attr.data-cy]="'modal-body-' + id">
		<ng-content></ng-content>
	</div>
	<div evasysIfContent class="modal-footer">
		<ng-content select="[footer]"></ng-content>
	</div>
</ng-template>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HasOnlyPipe } from './has-only/has-only.pipe';

@NgModule({
	declarations: [HasOnlyPipe],
	exports: [HasOnlyPipe],
	imports: [CommonModule],
})
export class HasOnlyModule {}

import { MessageFormatTranspiler } from '@ngneat/transloco-messageformat';
import { Injectable } from '@angular/core';
import { getUserLanguageCode } from '@evasys/evainsights/shared/util';

@Injectable()
export class EvasysMessageformatTranspiler extends MessageFormatTranspiler {
	onLangChanged(lang: string) {
		// transform evasys language codes like de_edu into the locale codes expected by messageformat / Intl
		super.onLangChanged(getUserLanguageCode(lang));
	}
}

export class DateTime extends Date {
  public static dateIsValid(date: Date): boolean {
    return !Number.isNaN(new Date(date).getTime());
  }

  public static toISOFormat(value: string | undefined): string {
    if (value != undefined) {
      value = value?.replace('d', 'dd');
      value = value?.replace('M', 'MMM');
      value = value?.replace('m', 'MM');
      value = value?.replace('y', 'yy');
      value = value?.replace('Y', 'yyyy');

      value = value?.replace('h', 'hh');
      value = value?.replace('H', 'HH');
      value = value?.replace('i', 'mm');
      value = value?.replace('s', 'ss');
      value = value?.replace('a', 'aaa');
      value = value?.replace('A', 'a');

      return value;
    }

    return 'dd.MM.yyyy hh:mm';
  }

  public static getDefaultChar(value: string): string {
    if (value.includes('.')) {
      return '.';
    }

    if (value.includes('-')) {
      return '-';
    }

    if (value.includes('/')) {
      return '/';
    }

    return '.';
  }

  public static createDatePattern(initFormat: string, defaultChar: string): string {
    const dateTimeFormatSpilt: string[] = initFormat.split(' ');
    const dateFormatSplit: string[] = dateTimeFormatSpilt[0].split(defaultChar);
    const timeFormatSplit: string[] = dateTimeFormatSpilt[1].split(':');
    let datePattern: string = '';

    for (let i = 0; i < dateFormatSplit.length; i++) {
      if (dateFormatSplit[i] == 'dd') {
        datePattern += '(\\d{2})';
      }

      if (dateFormatSplit[i] == 'MMM' || dateFormatSplit[i] == 'MM') {
        datePattern += '(\\d{2})';
      }

      if (dateFormatSplit[i] == 'yy' || dateFormatSplit[i] == 'yyyy') {
        datePattern += '(\\d{4})';
      }
      if (i <= 1) {
        datePattern += defaultChar;
      }
    }

    datePattern += '\\s';

    if (timeFormatSplit !== undefined) {
      if (timeFormatSplit[0] !== undefined) {
        datePattern += '(\\d{2})';
      }

      datePattern += ':';

      if (timeFormatSplit[1] !== undefined) {
        datePattern += '(\\d{2})';
      }

      datePattern += ':';

      if (timeFormatSplit[2] !== undefined) {
        datePattern += '(\\d{2})';
      }
    }

    return datePattern;
  }
}

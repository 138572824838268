import { popAnimation } from './pop.animation';
import { x3DRotateAnimation } from './3d-rotate/x-3d-rotate.animation';
import { xGrowAnimation } from './grow/x-grow.animation';
import { fadeInLeftAnimation } from './fade/fade-in-left.animation';
import { listAnimation } from './list.animation';
import { paginationAnimation } from './pagination.animation';
import { y3DRotateAnimation } from './3d-rotate/y-3d-rotate.animation';
import { z3DRotateAnimation } from './3d-rotate/z-3d-rotate.animation';
import { yGrowAnimation } from './grow/y-grow.animation';
import { nestedAnimations } from './nested.animation';
import { fadeOutTopAnimation } from './fade/fade-out-top.animation';
import { opacityAnimation } from './opacity.animation';

export class Animations {
	public static popAnimation = popAnimation();
	public static x3DRotateAnimation = x3DRotateAnimation();
	public static y3DRotateAnimation = y3DRotateAnimation();
	public static z3DRotateAnimation = z3DRotateAnimation();
	public static listAnimation = listAnimation();
	public static paginationAnimation = paginationAnimation();
	public static opacityAnimation = opacityAnimation();

	public static xGrowAnimation = (from: string = '0', to: string = '*', ms: number = 250) =>
		xGrowAnimation(from, to, ms);

	public static yGrowAnimation = (from: string = '0', to: string = '*', ms: number = 250) =>
		yGrowAnimation(from, to, ms);

	public static fadeInLeftAnimation = (ms: number = 400) => fadeInLeftAnimation(ms);

	public static fadeOutTopAnimation = (ms: number = 400) => fadeOutTopAnimation(ms);

	public static nestedAnimations = (child: string = '*') => nestedAnimations(child);
}

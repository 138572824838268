import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { TenantService } from '@evasys/evainsights/shared/core';
import { NavigateService } from '@evasys/evasys/shared/feature';
import { ExternalLink } from '@evasys/globals/evainsights/constants/paths';

@Injectable({
	providedIn: 'root',
})
export class IsTenantRegisteredGuard {
	public constructor(
		private readonly tenantService: TenantService,
		private readonly navigationService: NavigateService
	) {}

	public canActivate(): Promise<boolean> {
		return firstValueFrom(this.tenantService.getTenantRegistered()).then((isTenantRegistered) => {
			if (!isTenantRegistered) {
				this.navigationService.navigateToExternWebsite(this.getExternalWebsite());
			}
			return isTenantRegistered;
		});
	}

	private getExternalWebsite() {
		if (window.location.hostname.endsWith('.de')) {
			return ExternalLink.EVASYS_DE;
		}
		return ExternalLink.EVASYS_EN;
	}
}

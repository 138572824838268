import { animate, query, stagger, style, transition, trigger } from '@angular/animations';

export const listAnimation = () => {
	return trigger('listAnimation', [
		transition('* <=> *', [
			query(
				':enter',
				[
					style({
						opacity: 0,
						transform: 'translateX(-15px)',
					}),
					stagger(
						'50ms',
						animate(
							'550ms ease-out',
							style({
								opacity: 1,
								transform: 'translateX(0px)',
							})
						)
					),
				],
				{ optional: true }
			),
			query(
				':leave',
				animate(
					'50ms',
					style({
						opacity: 0,
					})
				),
				{
					optional: true,
				}
			),
		]),
	]);
};

import { BaseType, range, ScaleOrdinal, Selection } from 'd3';
import { BreakStrategy, getWords, wrap, WrapOptions } from './text';
import { sum } from 'lodash';
import { fitTexts } from '@evasys/evainsights/shared/util';
import { fontFamily, legendFontSize } from '@evasys/globals/evainsights/helper/charts/defaults';
import { measureTexts } from './text/measure';

export interface LegendOptions {
	ctx: CanvasRenderingContext2D;
	scale: ScaleOrdinal<number, string>;
	labels: { [entry: number]: string }; // TODO: Decide whether to keep this type or string[] instead
	width: number;
}

export function drawLegend(
	legendGroup: Selection<SVGGElement, unknown, BaseType, unknown>,
	{ ctx, scale, labels, width }: LegendOptions
) {
	const swatchSize = 15;
	const swatchTextPadding = 5;
	const fontSize = legendFontSize;
	const betweenRowPadding = 5;
	const betweenEntryPadding = 10;

	const legendTexts = range(scale.domain().length).map((i) => getWords(labels[i]).join(' '));
	const legendTextLengths = measureTexts(legendTexts, ctx, { fontFamily, fontSize });

	const legendEntryPlacements = determineLegendEntryPlacements(
		legendTextLengths,
		width,
		swatchSize + swatchTextPadding,
		betweenEntryPadding
	);
	const nLegendRows = Math.max(...legendEntryPlacements.map((placement) => placement.row)) + 1;

	legendGroup
		.selectAll('g')
		.data(range(scale.domain().length))
		.join('g')
		.attr('data-cy', 'axisChartLegend')
		.call((g) =>
			g
				.data(legendEntryPlacements)
				.attr('transform', (d) => `translate(${d.x},${d.row * (swatchSize + betweenRowPadding)})`)
		)
		.call((g) =>
			g
				.append('rect')
				.attr('fill', (d) => scale(d))
				.attr('x', 0)
				.attr('y', 0)
				.attr('width', swatchSize)
				.attr('height', swatchSize)
				.attr('rx', swatchSize / 4)
		)
		.call((g) =>
			g
				.data(legendEntryPlacements)
				.append('text')
				.attr('x', swatchSize + 5)
				.attr('y', swatchSize / 2)
				.attr('dominant-baseline', 'central')
				.attr('font-family', fontFamily)
				.attr('font-size', fontSize)
				.text((d) => legendTexts[d.item])
				.call(wrap, {
					ctx,
					lines: 1,
					width: (d) => d.textLength,
					breakStrategy: BreakStrategy.CHARACTER,
				} satisfies WrapOptions<LegendEntryPlacement>)
		);

	return {
		height: nLegendRows * swatchSize + (nLegendRows - 1) * betweenRowPadding,
	};
}

function determineLegendEntryPlacements(
	textLengths: number[],
	maxWidth: number,
	paddingEntry: number,
	paddingInner: number
): LegendEntryPlacement[] {
	// determine how to split the legend entries into upto two lines
	const availableWidthInRow = (nItems: number) => maxWidth - nItems * paddingEntry - (nItems - 1) * paddingInner;
	const rawItems = textLengths.map((textLength, index) => ({ textLength, index }));
	const middleItem = Math.ceil(textLengths.length / 2);
	const rowsItems =
		availableWidthInRow(rawItems.length) > sum(textLengths)
			? [rawItems]
			: [rawItems.slice(0, middleItem), rawItems.slice(middleItem)];

	return rowsItems.flatMap((rowItems, rowIndex) => {
		const shortenedTextLengths = fitTexts(
			rowItems.map((item) => item.textLength),
			availableWidthInRow(rowItems.length)
		);

		let x = 0;
		const placements: LegendEntryPlacement[] = [];
		for (let i = 0; i < rowItems.length; i++) {
			if (i > 0) {
				x += paddingInner;
			}

			placements.push({ item: rowItems[i].index, row: rowIndex, x, textLength: shortenedTextLengths[i] });

			x += shortenedTextLengths[i] + paddingEntry;
		}

		const rowPadding = (maxWidth - x) / 2;
		return placements.map((placement) => ({ ...placement, x: placement.x + rowPadding }));
	});
}

interface LegendEntryPlacement {
	item: number;
	row: number;
	x: number;
	textLength: number;
}

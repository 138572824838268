import { Component, Input, signal } from '@angular/core';
import { first, Subject, takeUntil, timer } from 'rxjs';

export enum LoadingIconSize {
	sm = '20px',
	md = '50px',
	lg = '150px',
	xl = '300px',
}

@Component({
	selector: 'evasys-loading',
	templateUrl: './loading.component.html',
	styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent {
	@Input({ required: true })
	id: string;

	@Input()
	set loading(loading: boolean) {
		if (loading) {
			timer(120)
				.pipe(takeUntil(this.destroy$), first())
				.subscribe(() => {
					this._loading.set(loading);
				});
		} else if (this._loading() === true) {
			this._loading.set(loading);
		} else {
			this.destroy$.next();
		}
	}

	@Input()
	set size(size: LoadingIconSize) {
		this._size.set(size);
	}

	_loading = signal(false);
	_size = signal(LoadingIconSize.md);
	loadingIconSize = LoadingIconSize;
	private destroy$ = new Subject<void>();
}

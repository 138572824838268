import { axisFontSize } from '@evasys/globals/evainsights/helper/charts/defaults';

export interface Position {
	x: number;
	y: number;
}

export interface Size {
	width: number;
	height: number;
}

export enum AxisLabelPosition {
	BESIDES = 'BESIDES',
	ABOVE = 'ABOVE',
}

export interface FrameConfig {
	legendHeight: number | undefined;
	yAxisLabelPosition: AxisLabelPosition | undefined;
	hasXAxisLabel: boolean;
	isVertical: boolean;
	isValueAxisNumeric: boolean;
	visualStatisticsSize: number | undefined;
	textualStatisticsSize: number | undefined;
}

/* eslint-disable @typescript-eslint/member-ordering */
export class Frame {
	numericTickLabelSize: Size = { width: 40, height: 13 };
	textualTickLabelSize: Size = { width: 63, height: 25 };

	constructor(public size: Size, public config: FrameConfig) {}

	private buildYRect = (args: HorizontalRectArgs) =>
		buildRect({
			...args,
			top: () => this.body.top,
			bottom: () => this.body.bottom,
		});

	private buildXRect = (args: VerticalRectArgs) =>
		buildRect({
			...args,
			left: () => this.body.left,
			right: () => this.body.right,
		});

	drawRegion = buildRect({
		top: () => 3,
		bottom: () => this.size.height - 3,
		left: () => 5,
		right: () => this.size.width - 10,
	});

	body = buildRect({
		top: () => {
			if (this.config.isVertical) {
				if (this.visualStatistics) {
					return this.visualStatistics.bottom + 10;
				} else if (this.textualStatistics) {
					return this.textualStatistics.bottom + 5;
				}
			}

			if (this.legend) {
				return this.legend.bottom + 10;
			}
			const offsetForYTickLabel = (this.yAxisTickLabelSize.height ?? 0) / 2;

			if (this.config.yAxisLabelPosition === AxisLabelPosition.ABOVE && this.yLabel) {
				return this.yLabel.bottom + offsetForYTickLabel + 5;
			} else {
				return offsetForYTickLabel;
			}
		},
		bottom: () => this.xAxis.top,
		left: () => this.yAxis.right,
		// depending on the config, the right-most x-axis tick gets placed at right edge of the x-axis, centered horizontally
		// => add some space to the right of the x-axis
		right: () => {
			const limit = this.drawRegion.right - (this.xAxisTickLabelSize.width ?? 0) / 2;

			if (!this.config.isVertical) {
				if (this.visualStatistics) {
					return Math.min(limit, this.visualStatistics.left - 10);
				} else if (this.textualStatistics) {
					return Math.min(limit, this.textualStatistics.left - 14);
				}
			}

			return limit;
		},
	});

	get legend() {
		const legendHeight = this.config.legendHeight;
		if (legendHeight === undefined) {
			return undefined;
		}

		return this.buildXRect({
			top: () => this.drawRegion.top,
			bottom: () => this.drawRegion.top + legendHeight,
		});
	}

	get yLabel() {
		if (this.config.yAxisLabelPosition === undefined) {
			return undefined;
		} else if (this.config.yAxisLabelPosition === AxisLabelPosition.ABOVE) {
			return buildRect({
				top: () => this.drawRegion.top,
				height: () => axisFontSize,
				left: () => this.drawRegion.left,
				width: () => 2 * (this.yAxis.right - this.drawRegion.left),
			});
		} else {
			return this.buildYRect({
				left: () => this.drawRegion.left,
				width: () => axisFontSize,
			});
		}
	}

	yAxis = this.buildYRect({
		left: () => {
			if (this.config.yAxisLabelPosition === AxisLabelPosition.BESIDES && this.yLabel !== undefined) {
				return this.yLabel.right + 5;
			}
			return this.drawRegion.left;
		},
		width: () => {
			return 13 + this.yAxisTickLabelSize.width;
		},
	});

	get xLabel() {
		if (!this.config.hasXAxisLabel) {
			return undefined;
		}

		return this.buildXRect({
			bottom: () => this.drawRegion.bottom,
			height: () => axisFontSize,
		});
	}

	xAxis = this.buildXRect({
		height: () => this.xAxisTickLabelSize.height + 10,
		bottom: () =>
			this.xLabel
				? this.xLabel.top - 5
				: Math.min(this.drawRegion.bottom, this.size.height - 30 + this.xAxis.height),
	});

	private get valueAxisTickLabelSize(): Size {
		return this.config.isValueAxisNumeric ? this.numericTickLabelSize : this.textualTickLabelSize;
	}

	get yAxisTickLabelSize() {
		return this.config.isVertical
			? this.valueAxisTickLabelSize
			: { width: this.textualTickLabelSize.width, height: undefined };
	}

	get xAxisTickLabelSize() {
		return this.config.isVertical
			? { width: undefined, height: this.textualTickLabelSize.height }
			: this.valueAxisTickLabelSize;
	}

	get textualStatistics() {
		const size = this.config.textualStatisticsSize;
		if (size === undefined) {
			return undefined;
		}

		if (this.config.isVertical) {
			return this.buildXRect({
				top: () => (this.legend ? this.legend.bottom + 9 : this.drawRegion.top),
				height: () => size,
			});
		} else {
			return this.buildYRect({
				right: () => this.drawRegion.right,
				width: () => size,
			});
		}
	}

	get visualStatistics() {
		const size = this.config.visualStatisticsSize;
		if (size === undefined) {
			return undefined;
		}

		if (this.config.isVertical) {
			return this.buildXRect({
				top: () =>
					this.textualStatistics
						? this.textualStatistics.bottom + 9
						: this.legend
						? this.legend.bottom + 9
						: this.drawRegion.top,
				height: () => size,
			});
		} else {
			return this.buildYRect({
				right: () => (this.textualStatistics ? this.textualStatistics.left - 13 : this.drawRegion.right),
				width: () => size,
			});
		}
	}
}

export interface Rect {
	top: number;
	bottom: number;
	left: number;
	right: number;
	width: number;
	height: number;
	center: Position;
}

type NFn = () => number;
type HorizontalRectArgs = { left: NFn; right: NFn } | { left: NFn; width: NFn } | { right: NFn; width: NFn };
type VerticalRectArgs = { top: NFn; bottom: NFn } | { top: NFn; height: NFn } | { bottom: NFn; height: NFn };
type RectArgs = HorizontalRectArgs & VerticalRectArgs;

const buildRect = (args: RectArgs): Rect => {
	const rect: Rect = {
		get top() {
			return 'top' in args ? args.top() : args.bottom() - args.height();
		},
		get bottom() {
			return 'bottom' in args ? args.bottom() : args.top() + args.height();
		},
		get left() {
			return 'left' in args ? args.left() : args.right() - args.width();
		},
		get right() {
			return 'right' in args ? args.right() : args.left() + args.width();
		},
		get width() {
			return 'width' in args ? args.width() : args.right() - args.left();
		},
		get height() {
			return 'height' in args ? args.height() : args.bottom() - args.top();
		},
		center: {
			get x() {
				return (rect.left + rect.right) / 2;
			},
			get y() {
				return (rect.top + rect.bottom) / 2;
			},
		},
	};

	return rect;
};

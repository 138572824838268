import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	Renderer2,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ValidationErrorModel } from '@evasys/globals/evasys/models/component/validation-error.model';
import { Subject, Subscription } from 'rxjs';
import { LabelPositionEnum } from '@evasys/globals/shared/enums/component/label-position.enum';
import { Required } from '@evasys/globals/shared/decorators/decorators';

@Component({
	selector: 'evasys-checkbox',
	templateUrl: './checkbox.component.html',
	styleUrls: ['./checkbox.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: CheckboxComponent,
			multi: true,
		},
	],
})
export class CheckboxComponent implements ControlValueAccessor, OnChanges, AfterViewInit, OnDestroy, OnInit {
	//region ViewChilds
	@ViewChild('labelElement')
	labelElement: ElementRef<HTMLLabelElement>;

	@ViewChild('input') inputElem: ElementRef;
	//endregion

	//#region Input & Output
	@Input()
	@Required()
	id: string | undefined;

	@Input()
	name: string | undefined;

	@Input()
	errors: ValidationErrorModel[] = [];

	@Input()
	labelPosition: LabelPositionEnum = LabelPositionEnum.RIGHT;

	@Input()
	hiddenLabel = false;

	@Input()
	set isDisabled(disabled: boolean) {
		this.setDisabledState(disabled);
	}

	@Input()
	value: string | number | null = null;

	@Input()
	public set checked(checked: boolean) {
		let value;
		if (checked) {
			value = this.value ?? checked;
		} else {
			value = this.value === null || this.value === undefined ? checked : null;
		}
		this.input.setValue(value);

		this._checked = checked;
	}

	@Input()
	public arrange: 'start' | 'center' = 'start';

	@Input()
	public role: 'checkbox' | 'switch' = 'checkbox';

	@Output()
	checkedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

	@Output()
	clearAction: EventEmitter<void> = new EventEmitter<void>();

	@Input()
	indeterminate = false;

	//#endregion Input & Output

	//#region Variables
	private subscriptions: Subscription[] = [];

	public _isDisabled = false;
	public _checked = false;
	public input = new FormControl();
	public _labelPosition = LabelPositionEnum;
	private changeEvent: Subject<boolean> = new Subject<boolean>();
	//#endregion Input & Output

	//#region Ctor
	constructor(private readonly changeDetectionRef: ChangeDetectorRef, private renderer: Renderer2) {}

	//#endregion Ctor

	//#region Events
	ngOnChanges(changes: SimpleChanges) {
		if (changes.indeterminate && this.inputElem) {
			this.renderer.setProperty(
				this.inputElem.nativeElement,
				'indeterminate',
				changes.indeterminate.currentValue
			);
		}
	}

	ngAfterViewInit() {
		if (this.indeterminate) {
			this.renderer.setProperty(this.inputElem.nativeElement, 'indeterminate', this.indeterminate);
		}
	}

	_onChange: any = () => {
		//default
	};
	_onTouched: any = () => {
		//default
	};

	ngOnInit() {
		this.listenToChangeEvent();
	}

	ngOnDestroy() {
		this.subscriptions.forEach((subscription) => subscription.unsubscribe());
	}

	onChecked(): void {
		this.changeEvent.next(!this._checked);
	}

	//#endregion Events

	//#region Methods
	public isInvalid(): boolean {
		return !!this.errors.find((error) => error.trigger);
	}

	public clear() {
		this._checked = false;
		this.input.setValue(this.value ? null : false);
		this.clearAction.emit();
	}

	private listenToChangeEvent() {
		this.subscriptions.push(
			this.changeEvent.subscribe((change) => {
				this.checked = change;
				this._onChange(change ? this.input.value : false);
				this.checkedChange.emit(change);
			})
		);
	}

	//endregion Methods

	//#region ControlValueAccessor

	registerOnChange(fn: (_: any) => void): void {
		this._onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this._onTouched = fn;
	}

	setDisabledState(isDisabled: boolean): void {
		this._isDisabled = isDisabled;
		isDisabled ? this.input.disable() : this.input.enable();
	}

	writeValue(value: any): void {
		if (value === null) {
			this.input.reset();
		}
		this.input.setValue(value);
		this._checked = !!value;
	}

	//#endregion ControlValueAccessor
}

<ng-template #defaultResultTemplate let-text let-term="term">
	<ngb-highlight
		[result]="text | modify : (formatter$ | async) | async"
		[term]="term"
		class="w-100"
		evasysTextOverflow
	>
	</ngb-highlight>
</ng-template>

<div #inputContainer class="form-floating w-100">
	<input
		#input
		evainsightsInputAutoFillOff
		[attr.data-cy]="'typeahead-' + id + '-input'"
		class="form-control"
		[placeholder]="placeholder"
		type="text"
		triggers="manual"
		[autoClose]="false"
		(click)="open()"
		(focus)="open()"
		(mousedown)="onInputMouseDown($event)"
		(keydown.escape)="onEscapeKeydown($event)"
		(keydown.arrowDown)="onArrowDownKeydown($event)"
		(keydown.arrowUp)="onArrowUpKeydown($event)"
		(keydown.enter)="onConfirmKeydown($event)"
		(keydown.tab)="onConfirmKeydown($event)"
		[ngModel]="searchText$.value"
		(ngModelChange)="onSearchTextChange($event)"
		[ngbPopover]="popoverContent"
		[popperOptions]="popoverOption"
		[container]="popoverContainer"
		[popoverClass]="'typeahead-popover c-w-min-content typeahead-' + id + '-popover ' + popoverClass"
		[placement]="placement"
		[animation]="false"
		#popover="ngbPopover"
	/>
	<label
		[for]="id"
		[ngClass]="[
			'typeahead-label text-truncate mw-100 h-auto',
			disabled ? 'disabled-class w-100 rounded' : 'c-w-unset'
		]"
	>
		<ng-content></ng-content>
	</label>
	<evasys-error [errors]="errors" [id]="'typeahead-' + id"></evasys-error>
	<ng-template #popoverContent>
		<div
			(mousedown)="onPopoverMouseDown($event)"
			*ngIf="results$ | async; let results"
			infiniteScroll
			[infiniteScrollThrottle]="0"
			[infiniteScrollContainer]="'.typeahead-' + id + '-popover .popover-body'"
			[fromRoot]="true"
			(scrolled)="onScrolled()"
		>
			<ng-container *ngTemplateOutlet="header; context: { $implicit: results }"> </ng-container>
			<div
				class="dropdown-menu typeahead-dropdown-menu overflow-auto border-0 show position-relative w-100 m-0 c-z-index-0"
				[class.c-h-4rem]="!results.latestResults"
				[attr.data-cy]="'typeahead-' + id + '-results'"
			>
				<div *ngIf="results.error" class="invalid-feedback px-3 my-2">
					{{ searchFailedText }}
				</div>
				<ng-container *ngIf="results.latestResults">
					<ng-container *ngFor="let entity of results.latestResults.entities; let i = index">
						<button
							(click)="onItemClick(entity)"
							[disabled]="disabled"
							(mouseenter)="onItemMouseenter(i, -1)"
							[attr.data-cy]="'button-typeahead-' + id + '-result-' + (entity | modify : getIdentifier)"
							class="d-block cursor-pointer text-truncate w-100 border-0 dropdown-item"
							[class.active]="i === activeIndex && activeChildIndex < 0 && hasInputFocus"
						>
							<ng-container *ngLet="entity | modify : (formatter$ | async) | async; let text">
								<ng-container
									*ngTemplateOutlet="
										resultTemplate || defaultResultTemplate;
										context: { $implicit: entity, term: results.latestResults.text, text }
									"
								>
								</ng-container>
							</ng-container>
						</button>
						<button
							class="ps-5 d-block cursor-pointer text-truncate w-100 border-0 dropdown-item"
							*ngFor="let child of entity[childItemIdentifier]; let ci = index"
							(click)="onItemClick(child)"
							[disabled]="disabled"
							(mouseenter)="onItemMouseenter(i, ci)"
							[attr.data-cy]="'button-typeahead-' + id + '-child-' + (child | modify : getIdentifier)"
							[class.active]="i === activeIndex && ci === activeChildIndex && hasInputFocus"
						>
							<ng-container *ngLet="child | modify : (formatter$ | async) | async; let ctext">
								<ng-container
									*ngTemplateOutlet="
										resultTemplate || defaultResultTemplate;
										context: { $implicit: child, term: results.latestResults.text, text: ctext }
									"
								>
								</ng-container>
							</ng-container>
						</button>
					</ng-container>
				</ng-container>
				<div
					*ngIf="results.isLoadingMore"
					class="text-center text-primary"
					[attr.data-cy]="'typeahead-' + id + '-loadingMoreText'"
				>
					{{ config.translations.typeahead.loading | transloco }}
				</div>
				<div
					*ngIf="results.latestResults?.entities.length === 0"
					class="text-center my-3"
					[attr.data-cy]="'typeahead-' + id + '-emptyResultsText'"
				>
					{{ emptyResultsText }}
				</div>
				<evasys-loading-overlay
					[loading]="results.isLoadingNewQuery"
					backdropColorClass="c-bg"
				></evasys-loading-overlay>
			</div>
		</div>
	</ng-template>
</div>

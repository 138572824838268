import { zipObject } from 'lodash';

export type Nested<T> = Array<Nested<T>> | T;

// the index path and corresponding value
export type NestedPath<T> = [number[], T];
export type DimensionPath<Dimension extends string | number | symbol, T> = [Record<Dimension, number>, T];

/**
 * Returns all values in a nested array of arbitrary depth along with the index path to the value
 */
export const nestedPositions = <T>(value: Nested<T>): NestedPath<T>[] => {
	if (Array.isArray(value)) {
		return value.flatMap((subarray, index) =>
			nestedPositions(subarray).map(
				([nestedPath, nestedVal]) => <NestedPath<T>>[[index, ...nestedPath], nestedVal]
			)
		);
	} else {
		return [[[], value]];
	}
};

export const namedNestedPositions = <T, Dimension extends string | number | symbol>(
	value: Nested<T>,
	dimensions: Dimension[]
): DimensionPath<Dimension, T>[] =>
	nestedPositions(value).map(([path, v]) => [zipObject(dimensions, path) as Record<Dimension, number>, v]);

/**
 * Returns true if arr is a numeric sequence with a constant step size, i.e., all values.
 * The term "range" here refers to the function name d3.range, not the output range of a scale.
 * true: [0, 1, 2, 3] and [3, 2, 1, 0] and [3, 4, 5] and [0, 2, 4] and [0.1, 0.2, 0.3]
 * false: [0, 2, 3] and [1, 0, 2]
 */
export const isRange = (arr: number[]): boolean => {
	if (arr.length <= 1) {
		return true;
	}

	const expectedStep = arr[1] - arr[0];
	for (let i = 1; i < arr.length; i++) {
		if (arr[i] !== arr[i - 1] + expectedStep) {
			return false;
		}
	}

	return true;
};

import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Animations } from '@evasys/globals/shared/animations/angular/animations';
import { SharedUiConfiguration } from '../../../shared-ui.configuration';
import { FormControl } from '@angular/forms';

@Component({
	selector: 'evasys-usermenu',
	templateUrl: './usermenu.component.html',
	styleUrls: ['./usermenu.component.scss'],
	animations: [Animations.z3DRotateAnimation, Animations.yGrowAnimation('0px', '*'), Animations.nestedAnimations()],
})
export class UsermenuComponent {
	// region Inputs & Outputs
	@Input()
	roles: Map<any, string> = new Map<any, string>();

	@Input()
	username = 'empty';

	@Input()
	system: 'evasys' | 'evaexam';

	@Input()
	role = 'empty';

	@Input()
	canSwitchUser = false;

	@Input()
	showSystemUsage: boolean;

	@Input()
	activeSystemUser: number;

	@Input()
	activeOnlineUser: number;

	@Output()
	roleResponse = new EventEmitter<any>();

	@Output()
	logoutResponse = new EventEmitter<void>();

	@Output()
	openUserSwitch = new EventEmitter<void>();

	@Output()
	systemUsageCall = new EventEmitter<void>();

	@Output()
	userMenuOpened = new EventEmitter<void>();
	// endregion Inputs & Outputs

	// region ViewChilds & ViewChildren

	@ViewChild('growPanel')
	growPanel: any;

	@ViewChild('usernameRef')
	usernameRef: any;

	// endregion ViewChilds & ViewChildren

	// region Variables

	public isCollapsed = true;
	public roleControl = new FormControl();

	// endregion Variables

	constructor(public readonly config: SharedUiConfiguration) {}

	// region Events

	respond(value: string) {
		if (value !== null) {
			this.roleResponse.emit(value);
		}
	}

	public onCollapseToggle(): void {
		this.isCollapsed = !this.isCollapsed;

		if (!this.isCollapsed) {
			this.userMenuOpened.emit();
		}
	}

	// endregion Events
}

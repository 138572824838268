import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'namesByIds',
})
export class NamesByIdsPipe implements PipeTransform {
	transform(ids: (string | number)[], list: { id?: string | number; name: string }[] | null): string[] {
		if (list == null) {
			return [];
		}
		return list.filter((item) => ids.includes(item.id ?? item.name)).map((item) => item.name);
	}
}

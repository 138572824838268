export interface Font {
	family: string;
	weight: number;
}

export function isAvailable(font: Font): boolean {
	return document.fonts.check(`${font.weight} 12px ${font.family}`);
}

export function fallback(candidates: Font[], warn = true): Font {
	candidates = candidates.slice();

	const primary = candidates[0];
	const found = candidates.find(isAvailable);

	if (found === undefined) {
		throw Error('None of the font candidates are available');
	} else if (found !== primary && warn) {
		console.warn(
			`Primary font family "${primary.family}" at weight ${primary.weight} is not available. Falling back to "${found.family}" at weight ${found.weight}`
		);
	}

	return found;
}

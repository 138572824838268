import { animate, style, transition, trigger } from '@angular/animations';

export const popAnimation = () => {
	return trigger('popAnimation', [
		transition('void => *', [
			style({
				height: 0,
				opacity: 0,
				transform: 'scale(0.85)',
				marginBottom: 0,
				paddingTop: 0,
				paddingRight: 0,
				paddingBottom: 0,
				paddingLeft: 0,
			}),
			animate(
				'50ms',
				style({
					height: '*',
					marginBottom: '*',
					paddingTop: '*',
					paddingRight: '*',
					paddingBottom: '*',
					paddingLeft: '*',
				})
			),
			animate(68),
		]),

		transition('* => void', [
			animate(
				50,
				style({
					transform: 'scale(1.05)',
				})
			),
			animate(
				50,
				style({
					transform: 'scale(1)',
					opacity: 0.75,
				})
			),
			animate(
				'120ms ease-out',
				style({
					transform: 'scale(0.68)',
					opacity: 0,
				})
			),
			animate(
				'150ms ease-out',
				style({
					opacity: 0,
					height: 0,
					paddingTop: 0,
					paddingRight: 0,
					paddingBottom: 0,
					paddingLeft: 0,
					marginBottom: 0,
				})
			),
		]),
	]);
};

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropdownModule, SharedUiModule } from '@evasys/shared/ui';
import { TranslocoModule } from '@ngneat/transloco';
import { EvainsightsSharedUtilModule, TranslateMultiLangPipe } from '@evasys/evainsights/shared/util';
import {
	NgbAlert,
	NgbCollapseModule,
	NgbDatepickerModule,
	NgbNavModule,
	NgbPaginationModule,
	NgbPopoverModule,
	NgbTimepickerModule,
	NgbTypeaheadModule,
} from '@ng-bootstrap/ng-bootstrap';
import { EvainsightsSharedCoreModule } from '@evasys/evainsights/shared/core';
import { ReportGridCreatorComponent } from './components/report/report-grid-creator/report-grid-creator.component';
import { HttpErrorInterceptor } from './auth/http-error-interceptor';
import { ConfigurationMenuComponent } from './components/configuration-menu/configuration-menu.component';
import { RespondentFilterComponent } from './components/report/respondent-filter/respondent-filter.component';
import { ReportTemplateListComponent } from './components/report/report-template-list/report-template-list.component';
import { ReportSurveyListComponent } from './components/report/report-survey-list/report-survey-list.component';
import { ClickSidebarComponent } from './components/click-sidebar/click-sidebar.component';
import { RespondentFilterModalComponent } from './components/report/respondent-filter-modal/respondent-filter-modal.component';
import { ReportBatchAddItemsComponent } from './components/report/report-batch-add-items/report-batch-add-items.component';
import { EvainsightsSharedUiModule } from '@evasys/evainsights/shared/ui';
import { ReportTemplateFormComponent } from './components/report/report-template-form-list/report-template-form.component';
import { ReportCreatorPageComponent } from './components/report/report-page/report-creator-page.component';
import { ReportReaderMenuComponent } from './components/report/report-reader-menu/report-reader-menu.component';
import { VisualizationCardMenuComponent } from './components/visualization-card-menu/visualization-card-menu.component';
import { ReportMetadataComponent } from './components/report/report-metadata/report-metadata.component';
import { ReportPublishModalComponent } from './components/report/report-publish-modal/report-publish-modal.component';
import { IconPopoverComponent } from './components/icon-popover/icon-popover.component';
import { ButtonResetSearchSelectionComponent } from './components/search/buton-reset-search-selection/button-reset-search-selection.component';
import { CardSelectComponent } from './components/card-select/card-select.component';
import { SidebarCommonComponent } from './components/sidebar-common/sidebar-common.component';
import { DeleteModalComponent } from './components/delete-modal/delete-modal.component';
import { ColorPickerComponent } from './components/color-picker/color-picker.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { FilterTypeaheadComponent } from './components/filter-typeahead/filter-typeahead.component';
import { FilterAreaWriteUrlComponent } from './components/filter-area-write-url/filter-area-write-url.component';
import { SharedUtilModule } from '@evasys/shared/util';
import { ItemsSearchComponent } from './components/items-search/items-search.component';
import { ReportExportMenuComponent } from './components/report/report-export-menu/report-export-menu.component';
import { ReportGridComponent } from './components/report-grid/report-grid.component';
import { GridsterModule } from 'angular-gridster2';
import { TopicsSearchComponent } from './components/topics-search/topics-search.component';
import { KeepMapOrderModule } from '../../../../../shared/util/src/lib/pipes/keep-map-order/keep-map-order.module';
import { AnchorModule } from '../../../../../shared/ui/src/lib/components/static-components/anchor/anchor.module';
import { CalendarCommonModule } from '../../../../../shared/ui/src/lib/components/control-components/calendar/src/app/calendar/calendar-common.module';
import { NgLetModule } from 'ng-let';
import { ReportLanguageDropdownComponent } from './components/report/report-language-dropdown/report-language-dropdown.component';
import { ReportSurveyListModalComponent } from './components/report/report-survey-list-modal/report-survey-list-modal.component';
import { ValueByMapModule } from '../../../../../shared/util/src/lib/pipes/value-by-map/value-by-map.module';
import { SurveyFilteredRespondentCountComponent } from '../../../ui/src/lib/components/survey-filtered-respondent-count/survey-filtered-respondent-count.component';

@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		SharedUiModule,
		FormsModule,
		TranslocoModule,
		EvainsightsSharedCoreModule,
		EvainsightsSharedUtilModule,
		EvainsightsSharedUiModule,
		NgbNavModule,
		NgbCollapseModule,
		NgbPaginationModule,
		NgbDatepickerModule,
		NgbTimepickerModule,
		ColorPickerModule,
		NgbTypeaheadModule,
		NgbPopoverModule,
		SharedUtilModule,
		GridsterModule,
		AnchorModule,
		DropdownModule,
		KeepMapOrderModule,
		NgLetModule,
		ValueByMapModule,
		NgbAlert,
		CalendarCommonModule,
		SurveyFilteredRespondentCountComponent,
	],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpErrorInterceptor,
			multi: true,
		},
		TranslateMultiLangPipe,
	],
	declarations: [
		ReportGridCreatorComponent,
		ConfigurationMenuComponent,
		RespondentFilterComponent,
		ReportTemplateListComponent,
		ReportSurveyListComponent,
		ReportTemplateListComponent,
		ClickSidebarComponent,
		RespondentFilterModalComponent,
		ReportBatchAddItemsComponent,
		ReportTemplateFormComponent,
		ReportCreatorPageComponent,
		ReportReaderMenuComponent,
		VisualizationCardMenuComponent,
		ReportMetadataComponent,
		ReportPublishModalComponent,
		IconPopoverComponent,
		ButtonResetSearchSelectionComponent,
		CardSelectComponent,
		SidebarCommonComponent,
		ColorPickerComponent,
		DeleteModalComponent,
		FilterTypeaheadComponent,
		FilterAreaWriteUrlComponent,
		ItemsSearchComponent,
		ReportExportMenuComponent,
		ReportGridComponent,
		TopicsSearchComponent,
		ReportSurveyListModalComponent,
		ReportLanguageDropdownComponent,
	],
	exports: [
		ReportGridCreatorComponent,
		ConfigurationMenuComponent,
		RespondentFilterComponent,
		ReportSurveyListComponent,
		ClickSidebarComponent,
		ReportTemplateFormComponent,
		ReportCreatorPageComponent,
		ReportReaderMenuComponent,
		ReportBatchAddItemsComponent,
		VisualizationCardMenuComponent,
		SidebarCommonComponent,
		IconPopoverComponent,
		CardSelectComponent,
		DeleteModalComponent,
		ColorPickerComponent,
		FilterTypeaheadComponent,
		FilterAreaWriteUrlComponent,
		ItemsSearchComponent,
		ReportMetadataComponent,
		ReportGridComponent,
		TopicsSearchComponent,
	],
})
export class EvainsightsSharedFeatureModule {}

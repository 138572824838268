import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { Tabitem } from '@evasys/shared/ui';

@Component({
	selector: 'evasys-tabs',
	templateUrl: './tabs.component.html',
	styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent<T> implements OnInit {
	//#region Input/Output
	@Input()
	tabitems: Tabitem<T>[] = [];

	@Input()
	underline = true;

	@Input()
	centerItems = false;

	@Input()
	active = 0;

	@Input()
	activeSub = 0;

	@Input()
	responsive = false;

	@Output()
	response = new EventEmitter<T>();
	//#endregion Input/Output

	//#region Variables
	orientation: 'horizontal' | 'vertical' = 'horizontal';

	openSubMenuId: T | null = null;
	//#endregion Variables

	//#region Events

	ngOnInit(): void {
		this.determineOrientation();
	}

	onItemClicked(id: any, counterId: number) {
		this.active = counterId;
		this.response.emit(id);
	}

	onSubItemClicked(event: MouseEvent, id: any, subCounterId: number, counterId: number) {
		event.stopPropagation();
		this.openSubMenuId = null;
		this.active = counterId;
		this.activeSub = subCounterId;
		this.response.emit(id);
	}

	openSupMenu(id: string) {
		const activeSubMenu = this.tabitems.find((x) => x.id === id);
		const subItemData = activeSubMenu?.subItems || [];
		this.openSubMenuId = subItemData.length > 0 ? activeSubMenu.id : null;
	}

	@HostListener('window:resize')
	private determineOrientation(): void {
		if (this.responsive) {
			this.orientation = window.innerWidth <= 991 ? 'vertical' : 'horizontal';
		}
	}

	//#endregion Events
}

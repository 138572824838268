import { animate, state, style, transition, trigger } from '@angular/animations';

export enum PaginationAnimationEnum {
	TO_RIGHT = 'toRight',
	TO_LEFT = 'toLeft',
	BACK = 'back',
}

export const paginationAnimation = () => {
	return trigger('paginationAnimation', [
		state(
			PaginationAnimationEnum.TO_LEFT,
			style({
				transform: 'translateX(-150%)',
				opacity: 0,
			})
		),
		state(
			PaginationAnimationEnum.TO_RIGHT,
			style({
				transform: 'translateX(150%)',
				opacity: 0,
			})
		),
		state(
			PaginationAnimationEnum.BACK,
			style({
				transform: 'translateX(0%)',
				opacity: 1,
			})
		),
		transition('* => ' + PaginationAnimationEnum.TO_RIGHT, [animate('400ms ease-in-out')]),
		transition('* => ' + PaginationAnimationEnum.TO_LEFT, [animate('400ms ease-in-out')]),
		transition('* => ' + PaginationAnimationEnum.BACK, [animate('400ms ease-in-out')]),
	]);
};

import { Component, inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UiConfigService } from '@evasys/evainsights/shared/core';
import {
	getContextLanguageCode,
	getLanguageCode,
	getUserLanguageCode,
	validOrDisabledRawFormControlValueChanges,
} from '@evasys/evainsights/shared/util';
import { map, Subject, take, takeUntil } from 'rxjs';
import { enumValues } from '@evasys/globals/shared/helper/enum';
import { Language } from '@evasys/globals/evainsights/constants/types';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UiConfig } from '@evasys/globals/evainsights/models/common/ui-config.model';

@Component({
	selector: 'evainsights-area-ui-config',
	templateUrl: './area-ui-config.component.html',
})
export class AreaUiConfigComponent {
	fb = inject(FormBuilder);
	uiConfigService = inject(UiConfigService);

	form = this.fb.group({
		logoSrc: [''],
		decimalFormat: ['.', Validators.required],
		dateFormat: ['d.m.Y', Validators.required],
		timeFormat: ['H:i:s', Validators.required],
		language: this.fb.group({
			user: ['de', Validators.required],
			context: ['edu', Validators.required],
		}),
	});

	decimalFormatOptions = ['.', ','];
	userLanguageOptions = enumValues(Language);
	contextLanguageOptions = ['edu', 'com', 'qmc'];

	constructor() {
		const enabled$ = new Subject<void>();

		this.uiConfigService
			.getUiConfig()
			.pipe(takeUntil(enabled$), takeUntilDestroyed())
			.subscribe(this.setFormValueFromUiConfig);

		this.form.valueChanges.pipe(take(1), takeUntilDestroyed()).subscribe(() => {
			enabled$.next();
			this.switchToDevUiConfig();
		});
	}

	setFormValueFromUiConfig = (uiConfig: UiConfig) => {
		this.form.setValue(
			{
				...uiConfig,
				language: {
					user: getUserLanguageCode(uiConfig.language),
					context: getContextLanguageCode(uiConfig.language),
				},
			},
			{ emitEvent: false }
		);
	};

	switchToDevUiConfig = () => {
		this.uiConfigService.switchUiConfigSource(
			validOrDisabledRawFormControlValueChanges(this.form).pipe(
				map(({ language, ...rest }) => ({
					...rest,
					language: getLanguageCode(language.user, language.context),
				}))
			)
		);
	};
}

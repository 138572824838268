import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { Required } from '@evasys/globals/shared/decorators/decorators';

@Component({
	selector: 'evasys-chip',
	templateUrl: './chip.component.html',
})
export class ChipComponent {
	@HostBinding('class')
	hostClass = 'card p-0 bg-primary inline-flex mw-100 text-truncate h-100';

	@Input()
	@Required()
	id: string;

	@Input()
	@Required()
	text: string;

	@Input()
	imageSrc?: string;

	@Input()
	imageAlt?: string;

	@Input()
	isDisabled = false;

	@Output()
	removeAction: EventEmitter<void> = new EventEmitter();

	_removeAction() {
		if (!this.isDisabled) {
			this.removeAction.emit();
		}
	}
}

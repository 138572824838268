<ng-template #content>
	<div class="offcanvas-header">
		<h4 class="offcanvas-title">Developer Tools</h4>
	</div>

	<div class="offcanvas-body">
		<evainsights-area-developer-login></evainsights-area-developer-login>

		<hr />

		<evainsights-area-ui-config></evainsights-area-ui-config>
	</div>
</ng-template>

import { createSelector } from '@ngrx/store';
import { EvasysPageConfigModel } from '@evasys/globals/shared/models/general/evasys-page-config.model';
import { EvasysManyInPagesModel } from '@evasys/globals/evasys/models/general/evasys-many-in-pages.model';
import { HttpErrorResponse } from '@angular/common/http';

export function loadingSelector(state: any) {
	return createSelector(state, (loadingState: any) => {
		return loadingState.loading as boolean;
	});
}

export function selectWithFilterSelector<T>(state: any, filter: (entity: T) => boolean) {
	return createSelector(state, (entitiesState: any) => {
		const entities = Object.values(entitiesState.entities);
		return filter ? entities.filter((entity: T) => filter(entity)) : entities;
	});
}

export function loadedPagesSelector(state: any) {
	return createSelector(state, (loadedPagesState: any) => {
		return loadedPagesState.loadedPages as number[];
	});
}

export function pageConfigSelector(state: any) {
	return createSelector(state, (pageConfigState: any) => {
		return pageConfigState.pageConfig as EvasysPageConfigModel;
	});
}

export function pageSelector(state: any) {
	return createSelector(state, (pageState, props) => {
		if (pageState && props?.page) {
			const toPage = props.page;
			let loadedPages = pageState.loadedPages as number[];
			loadedPages = [...loadedPages].sort((a, b) => a - b).slice(0, toPage);

			const start = (loadedPages.length - 1) * pageState.pageConfig?.pageSize;
			const end = loadedPages.length * pageState.pageConfig?.pageSize;

			const entities = (pageState.ids as any[])?.map((id) => pageState.entities[id]) as any[];
			return pageState.pageConfig?.pageSize === -1 ? entities : entities?.slice(start, end);
		} else {
			return undefined;
		}
	});
}

export function entitySelector(state: any) {
	return createSelector(state, (entityState: any, entityProps: any) => {
		return entityState.entities[entityProps.id];
	});
}

export function responseSelector(state: any) {
	return createSelector(state, (responseState: any) => {
		return { error: responseState.error, entities: responseState.entities };
	});
}

export function errorSelector(state: any) {
	return createSelector(state, (errorState: any) => {
		return errorState.error as HttpErrorResponse;
	});
}

export function hasErrorSelector(state: any) {
	return createSelector(state, (hasErrorState: any) => {
		return hasErrorState.error ? true : false;
	});
}

export function manyEntitiesSelector(state: any) {
	return createSelector(state, (manyEntitiesState: any, manyEntitiesProps: EvasysManyInPagesModel) => {
		const toPage = manyEntitiesProps?.page;
		const pageSize = manyEntitiesProps?.pageSize;
		const ids = manyEntitiesProps?.ids;

		const start = (toPage - 1) * pageSize;
		const end = toPage * pageSize;

		if (toPage && ids) {
			if (manyEntitiesState.ids) {
				const entities = manyEntitiesState.ids
					.filter((key) => manyEntitiesProps.ids.includes(key))
					.map((key) => manyEntitiesState.entities[key]);
				//-1 is no pagination
				return pageSize !== -1 ? entities.splice(start, end) : entities;
			} else {
				console.warn('ids are undefined');
				return [];
			}
		} else {
			console.error('no given props in many selector!');
			return [];
		}
	});
}

export function manyEntitiesWithFilterSelector<T>(state: any) {
	return createSelector(
		state,
		(
			manyWithFilterState: any,
			manyWithFilterProps: {
				page: number;
				filter: (entity: T) => boolean;
				pageSize?: number;
			}
		) => {
			const toPage = manyWithFilterProps?.page;
			const filter = manyWithFilterProps?.filter;

			const start = (toPage - 1) * (manyWithFilterProps.pageSize ?? manyWithFilterState.pageConfig?.pageSize);
			const end = toPage * (manyWithFilterProps.pageSize ?? manyWithFilterState.pageConfig?.pageSize);

			if (toPage && filter) {
				const result = Object.values(manyWithFilterState.entities).filter((entity: T) => filter(entity));

				return manyWithFilterProps.pageSize === -1 ? result : result.splice(start, end);
			} else {
				console.error('Can not select the entities with filter: No filter or page is set.');
				return [];
			}
		}
	);
}

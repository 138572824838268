import { ErrorHandler, Injectable } from '@angular/core';
import { LoggerService } from './logger.service';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class ErrorHandlerService implements ErrorHandler {
	constructor(private readonly logger: LoggerService) {
		// this is intentional
	}

	handleError(error: unknown) {
		if (error instanceof HttpErrorResponse) {
			// any http requests (including errors) are automatically logged as dependencies and don't need to be considered here
			return;
		}

		if (!(error instanceof Error)) {
			throw Error('Cannot handle errors that do not inherit from the Error class');
		}

		this.logger.error(error);
	}
}

<div
	@noInitialAnimation
	class="user-select-none rounded px-1 d-flex flex-row flex-nowrap align-items-center"
	[class.c-cursor-pointer]="(isSelected() && allowDeselection) || !isSelected()"
	[class.text-primary]="isSelected()"
	(mousedown)="onSelect($event)"
	(mouseenter)="onMouseEnter()"
	[attr.data-cy]="'selector-' + selectorComponent?.id + '-item-' + value + '-event'"
	[attr.isSelected]="isSelected()"
>
	<img
		@xGrowAnimation
		*ngIf="isSelected()"
		evasysImage
		class="c-h-20px c-z-index-1"
		[id]="'check-' + selectorComponent?.id"
		[isIcon]="true"
		[src]="checkIcon"
	/>
	<div
		@nestedAnimations
		class="ms-2"
		[class.w-100]="stretchItem"
		[attr.data-cy]="'selector-' + selectorComponent?.id + '-item-' + value"
	>
		<ng-content></ng-content>
	</div>
</div>

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TableConfiguration } from '../table.configuration';

@Component({
	selector: 'th[evasysTableHead]',
	templateUrl: './table-head.component.html',
})
export class TableHeadComponent {
	// region static variables

	private static imageIdCounter = 1;

	// endregion static variables

	//region Input & Output

	@Input()
	overflowText?: string;

	@Input()
	public columnField?: string;

	@Input()
	public sortable = false;

	@Input()
	sortOrderAscending = true;

	@Input()
	sortByDefault = false;

	@Input()
	public position: 'start' | 'end' | 'center' = 'center';

	@Output()
	sortingChange = new EventEmitter<boolean>();

	@Output()
	sortOrderAscendingChange = new EventEmitter<boolean>();

	//endregion

	//region Variables
	public sorting = false;

	public imageId;

	//endregion

	public constructor(public readonly config: TableConfiguration) {
		this.imageId = TableHeadComponent.imageIdCounter++;
	}

	//region Events

	public onClick(): void {
		if (this.sortable) {
			if (this.sorting) {
				this.sortOrderAscending = !this.sortOrderAscending;
				this.sortOrderAscendingChange.emit(this.sortOrderAscending);
			} else {
				this.sorting = true;
				this.sortOrderAscending = true;
				this.sortingChange.emit(this.sorting);
			}
		}
	}

	public setSorting(sortOrderAscending: boolean) {
		if (this.sortable) {
			this.sorting = true;
			this.sortingChange.emit(this.sorting);
			this.sortOrderAscending = sortOrderAscending;
			this.sortOrderAscendingChange.emit(this.sortOrderAscending);
		}
	}

	//endregion
}

import { Observable, Subject } from 'rxjs';

export abstract class WebWorker<T> {
	worker: Worker;
	WORKER_ENABLED = typeof Worker !== 'undefined';
	private onMessage = new Subject<MessageEvent>();
	private onError = new Subject<ErrorEvent>();

	constructor(worker?: Worker) {
		if (this.WORKER_ENABLED) {
			if (!this.worker && worker) {
				this.worker = worker;
			}

			if (this.worker) {
				this.worker.onmessage = (data) => {
					this.onMessage.next(data);
				};

				this.worker.onerror = (data) => {
					this.onError.next(data);
				};
			}
		} else {
			console.warn('WebWorker is not enabled');
		}
	}

	postMessage(data: T) {
		if (this.WORKER_ENABLED && this.worker) {
			this.worker.postMessage(data);
		} else {
			console.warn('WebWorker is not enabled or registered');
			this.run(data);
		}
	}

	onmessage(): Observable<MessageEvent> {
		return this.onMessage.asObservable();
	}

	onerror(): Observable<ErrorEvent> {
		return this.onError.asObservable();
	}

	terminate() {
		if (this.worker) {
			this.worker.terminate();
			this.worker = undefined;
		}
	}

	public abstract run(
		data?: T,
		postMessage?: (message: any, targetOrigin: string, transfer?: Transferable[]) => void
	);
}

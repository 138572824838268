import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'evasys-top-menu',
	templateUrl: './top-menu.component.html',
})
export class TopMenuComponent {
	//region Input & Output
	@Input()
	public logo: string;

	@Input()
	public logoAlt?: string;

	@Output()
	public logoClickedAction = new EventEmitter<void>();
	//endregion

	//region Variables
	public isMobileMenuCollapsed = true;
	//#endregion
}

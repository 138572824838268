import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { Logger } from '@evasys/globals/evainsights/logging/logger';
import { Level } from '@evasys/globals/evainsights/logging/level.enum';
import { LogProperties } from '@evasys/globals/evainsights/logging/log-properties.model';
import { setDefaultLogger } from '@evasys/globals/evainsights/logging/default-logger';
import { isNotServicePathPattern } from '@evasys/globals/evainsights/api/endpoints';
import { runtimeEnvironment } from '@evasys/globals/evainsights/environments/runtime-environment';

@Injectable({
	providedIn: 'root',
})
export class LoggerService extends Logger {
	public context: Readonly<{ [key: string]: unknown }> = {};

	private applicationInsights?: ApplicationInsights;

	constructor(router: Router) {
		super();

		if (runtimeEnvironment.applicationInsightsConnectionString) {
			const angularPlugin = new AngularPlugin();

			this.applicationInsights = new ApplicationInsights({
				config: {
					connectionString: runtimeEnvironment.applicationInsightsConnectionString,
					extensions: [angularPlugin],
					extensionConfig: {
						[angularPlugin.identifier]: { router },
					},
					enableCorsCorrelation: true,
					correlationHeaderExcludePatterns: [isNotServicePathPattern],
				},
			});

			this.applicationInsights.addTelemetryInitializer((item) => {
				item.data = {
					...this.context,
					...item.data,
				};
			});

			this.applicationInsights.loadAppInsights();
		}

		setDefaultLogger(this);
	}

	protected override logPreparedProperties(level: Level, data: string | Error, props?: LogProperties) {
		if (!this.applicationInsights) {
			return;
		}

		if (typeof data === 'string') {
			this.applicationInsights.trackTrace({
				message: data,
				severityLevel: level,
				properties: props,
			});
		} else {
			this.applicationInsights.trackException({
				exception: data,
				severityLevel: level,
				properties: props,
			});
		}
	}
}

<span
	*ngIf="countConfig?.keyParts"
	[title]="
		'report.surveyRespondentCount.' + countConfig.keyParts.coarse + '.' + countConfig.keyParts.fine + '.hover'
			| transloco : countConfig.params
	"
	[attr.data-cy]="
		'chip-selectedTypeaheadValue-reportSurveys-' +
		survey.id +
		'-responseCount-' +
		countConfig.keyParts.coarse +
		'-' +
		countConfig.keyParts.fine
	"
	>{{ 'report.surveyRespondentCount.' + countConfig.keyParts.coarse | transloco : countConfig.params }}</span
>

import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { endpoints } from '@evasys/globals/evainsights/api/endpoints';
import { Page } from '@evasys/globals/evainsights/models/pagination/page.model';
import { Item } from '@evasys/globals/evainsights/models/item/item.model';
import { PagedItemSearchRequest } from '@evasys/globals/evainsights/models/search/ItemSearchRequest';

@Injectable({
	providedIn: 'root',
})
export class ItemService {
	private http = inject(HttpClient);

	getSearchResult(filter: PagedItemSearchRequest = { page: 0, size: 10 }): Observable<Page<Item>> {
		return this.http.post<Page<Item>>(endpoints.postItemsSearch.url(), filter);
	}
}

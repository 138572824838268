<button
	[attr.class]="'btn text-nowrap ' + (!!class ? class : '')"
	[type]="type"
	[ngClass]="design ? 'btn-' + design : ''"
	[id]="'button-' + id"
	[attr.data-cy]="'button-' + id"
	[disabled]="isDisabled"
>
	<ng-content></ng-content>
</button>

import { ReportMultiLang } from '../../../../common/multi-lang';

export enum DataIndexDimensionType {
	ITEM = 'ITEM',
	ITEM_OPTION = 'ITEM_OPTION',
	PERIOD = 'PERIOD',
	TOPIC = 'TOPIC',
}

export type IndexDimension = ItemDimension | ItemOptionDimension | PeriodDimension | TopicDimension;

export interface ItemDimension {
	type: DataIndexDimensionType.ITEM;
	domain: Array<{
		name: ReportMultiLang<string>;
		itemIds: number[];
		exclude: boolean;
	}>;
}

export interface ItemOptionDimension {
	type: DataIndexDimensionType.ITEM_OPTION;
	domain: Array<{
		name: ReportMultiLang<string>; // maybe switch on constructed name types
		itemOptionIds: number[];
		exclude: boolean;
		hide: boolean;
	}>;
}

export interface PeriodDimension {
	type: DataIndexDimensionType.PERIOD;
	domain: PeriodDimensionDomain;
}

export type PeriodDimensionDomain =
	| { type: PeriodDomainType.FULL | PeriodDomainType.PRESENT } // auto
	| { type: PeriodDomainType.CUSTOM; periodIds: number[] };

export interface TopicDimension {
	type: DataIndexDimensionType.TOPIC;
	domain: Array<{
		name: ReportMultiLang<string>;
		itemIds: number[];
		topicIds: string[];
		exclude: boolean;
	}>;
}

export enum PeriodDomainType {
	FULL = 'FULL', // all periods starting from the first occurrence to the last
	PRESENT = 'PRESENT', // no periods where all series are null. E.g. if the question was asked in summer terms, show only those
	CUSTOM = 'CUSTOM', // only the periods explicitly enabled
}

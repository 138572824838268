// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	baseHref: window['base-href'],
	oldWorldHref: window['base-href']?.split('/public/ui')[0],
	apiHref: window['base-href']?.split('/ui')[0] + '/api/v1/',
	authApiHref: window['base-href']?.split('/ui')[0] + '/api/v1/oauth',
	clientId: '578944590d74a3e34cf97ff19069012d',
	clientSecret: null,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related dynamic-notification stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an dynamic-notification is thrown.
 */
// import 'zone.js/dist/zone-dynamic-notification';  // Included with Angular CLI.

import { animate, state, style, transition, trigger } from '@angular/animations';

export const xGrowAnimation = (from: string, to: string, ms: number) => {
	return trigger('xGrowAnimation', [
		state(
			'false',
			style({
				width: from,
				overflow: 'hidden',
			})
		),
		state(
			'true',
			style({
				width: to,
				opacity: '*',
				overflow: 'hidden',
			})
		),
		transition('true <=> false', [animate(ms + 'ms ease-in-out')]),
		transition(':enter', [
			style({
				width: from,
				overflow: 'hidden',
			}),
			animate(
				ms + 'ms',
				style({
					width: to,
					opacity: '*',
					overflow: 'hidden',
				})
			),
		]),
		transition(':leave', [
			animate(
				ms + 'ms',
				style({
					height: from,
					overflow: 'hidden',
				})
			),
		]),
	]);
};

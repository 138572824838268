import {
	Component,
	effect,
	EnvironmentInjector,
	EventEmitter,
	inject,
	Input,
	OnInit,
	Output,
	runInInjectionContext,
	signal,
} from '@angular/core';
import { AbstractControl, FormControlStatus } from '@angular/forms';
import { Placement } from '@popperjs/core';
import { startWith, Subscription } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
	selector: 'evasys-dropdown',
	templateUrl: './dropdown.component.html',
	styles: [':host {display: flex}'],
})
export class DropdownComponent<T> implements OnInit {
	//region Input & Output
	@Input({ required: true })
	id?: string;

	@Input()
	set isDisabled(isDisabled: boolean) {
		if (isDisabled) {
			this.controls().forEach((control) => control.disable());
		} else {
			this.controls().forEach((control) => control.enable());
		}
		this._isDisabled.set(isDisabled);
	}

	@Input()
	set value(value: T) {
		this.controls().forEach((control) => control.setValue(value));
	}

	@Input()
	set formControls(formControls: AbstractControl[]) {
		this.controls.set(formControls);
	}

	@Input()
	set menuPlacement(menuPlacement: Placement) {
		this._menuPlacement.set(menuPlacement);
	}
	_menuPlacement = signal<Placement>('bottom-start');

	@Input()
	splitControlValues = false;

	@Output()
	valueChange = new EventEmitter<T | T[]>();

	//endregion;

	//region Injections
	private environmentInjector = inject(EnvironmentInjector);
	//endregion

	//region Variables
	public _value = signal<T | T[]>(null);
	public isOpen = signal<boolean>(false);
	public _isDisabled = signal<boolean>(false);
	public _isInvalid = signal<boolean>(false);
	public dropdownMenuComponent = signal<HTMLElement | null>(null);
	public controls = signal<AbstractControl[]>([]);
	private subscriptions: Subscription[] = [];

	//endregion

	//region Events
	ngOnInit() {
		runInInjectionContext(this.environmentInjector, () => {
			effect(this.onControlsChanged, { allowSignalWrites: true });
			effect(() => {
				this.valueChange.emit(this._value());
			});
		});
	}

	onControlsChanged = () => {
		this.subscriptions.forEach((subscription) => subscription.unsubscribe());
		this.subscriptions = [];
		this.controls().forEach((control, index) => {
			runInInjectionContext(this.environmentInjector, () => {
				this.subscriptions.push(
					control.valueChanges
						.pipe(startWith(control.value), takeUntilDestroyed())
						.subscribe((value) => this.onValueChanged(value, index))
				);
				this.subscriptions.push(
					control.statusChanges.pipe(takeUntilDestroyed()).subscribe(this.onStatusChanged)
				);
			});
		});
	};

	onValueChanged = (value: T, index: number) => {
		if (this.splitControlValues) {
			this._value.update((currentValue) => {
				if (!Array.isArray(currentValue)) {
					currentValue = [];
				}
				currentValue[index] = value;
				return currentValue;
			});
		} else if (this._value() !== value) {
			this._value.set(value);
		}
	};

	onStatusChanged = (status: FormControlStatus) => {
		switch (status) {
			case 'DISABLED':
				this._isDisabled.set(true);
				break;
			case 'INVALID':
				this._isInvalid.set(true);
				break;
			case 'VALID':
				this._isInvalid.set(false);
				this._isDisabled.set(false);
				break;
		}
	};

	onClickAway = () => {
		if (this.isOpen()) {
			this.isOpen.set(false);
		}
	};

	//endregion
}

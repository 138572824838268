<h1 *ngIf="headerLevel === 1" [attr.class]="class + ' h1'" [attr.id]="id" [attr.data-cy]="'header-' + id">
	{{ text }}
</h1>
<h2 *ngIf="headerLevel === 2" [attr.class]="class + ' h2'" [attr.id]="id" [attr.data-cy]="'header-' + id">
	{{ text }}
</h2>
<h3 *ngIf="headerLevel === 3" [attr.class]="class + ' h3'" [attr.id]="id" [attr.data-cy]="'header-' + id">
	{{ text }}
</h3>
<h4 *ngIf="headerLevel === 4" [attr.class]="class + ' h4'" [attr.id]="id" [attr.data-cy]="'header-' + id">
	{{ text }}
</h4>
<h5 *ngIf="headerLevel === 5" [attr.class]="class + ' h5'" [attr.id]="id" [attr.data-cy]="'header-' + id">
	{{ text }}
</h5>
<h6 *ngIf="headerLevel === 6" [attr.class]="class + ' h6'" [attr.id]="id" [attr.data-cy]="'header-' + id">
	{{ text }}
</h6>

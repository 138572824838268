import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EvasysState } from '../essential/redux/states/evasys.state';
import { configAdapter } from '../reducers/config.reducer';
import { ConfigModel } from '@evasys/globals/evasys/models/business/config.model';

export const getConfigState = createFeatureSelector<EvasysState<ConfigModel>>('configs');

export const {
	selectLoading,
	selectPageConfig,
	selectPage,
	selectLoadedPages,
	selectEntity,
	selectMany,
	selectError,
	selectHasError,
	selectManyWithFilter,
	selectResponse,
} = configAdapter.getSelectors(getConfigState);
export const { selectIds, selectEntities, selectAll, selectTotal } = configAdapter.entity.getSelectors(getConfigState);

export function selectConfigWithName(name: string) {
	return createSelector(getConfigState, (state: any) => {
		const config: ConfigModel = Object.values<ConfigModel>(state.entities).filter(
			(entity: ConfigModel) => entity.name === name
		)[0];
		return config;
	});
}

export function selectConfigsWithName(names: string[]) {
	return createSelector(getConfigState, (state: any) => {
		const configs: ConfigModel[] = Object.values<ConfigModel>(state.entities).filter((entity: ConfigModel) =>
			names.includes(entity.name)
		);
		return configs;
	});
}

import { inject, Injectable } from '@angular/core';
import { Item } from '@evasys/globals/evainsights/models/item/item.model';
import { map, Observable } from 'rxjs';
import { FormElementDescriptorService, FormElementTranslate } from '@evasys/evainsights/shared/util';
import { TranslocoService } from '@ngneat/transloco';
import { hasText } from '@evasys/globals/shared/helper/string';

// TODO SDI-3158: Fix circular import
@Injectable({
	providedIn: 'root',
})
export class UiFormElementDescriptorService {
	protected readonly translocoService = inject(TranslocoService);
	protected readonly formElementDescriptorService = inject(FormElementDescriptorService);

	public readonly selectItemDescriptor = (item: Item): Observable<string> => {
		return this.getTranslate().pipe(
			map((translate) => this.formElementDescriptorService.getItemDescriptor(item, translate))
		);
	};

	private readonly getTranslate = (): Observable<FormElementTranslate> => {
		// TODO SDI-3158: Provide MultiLangService-based implementation after initial fix deployment
		return this.translocoService.langChanges$.pipe(
			map((lang) => ({
				key: (key) => this.translocoService.translate(key, undefined, lang),
				form: (formMultiLang) => {
					const value = formMultiLang[0].value;
					return hasText(value) ? value : null;
				},
			}))
		);
	};
}

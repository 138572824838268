import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StepFilterComponent } from './step-filter/step-filter.component';
import { StepFilterItemComponent } from './step-filter-item/step-filter-item.component';
import { ButtonModule } from '../../static-components/button/button.module';
import { TranslocoModule } from '@ngneat/transloco';
import { RequiredStepDirective } from './required-step/required-step.directive';
import { StepFilterConfig } from './step-filter.config';

@NgModule({
	declarations: [StepFilterComponent, StepFilterItemComponent, RequiredStepDirective],
	imports: [CommonModule, ButtonModule, TranslocoModule],
	exports: [StepFilterComponent, StepFilterItemComponent, RequiredStepDirective],
})
export class StepFilterModule {
	static forRoot(config: StepFilterConfig): ModuleWithProviders<any> {
		return {
			ngModule: StepFilterModule,
			providers: [
				{
					provide: StepFilterConfig,
					useValue: config,
				},
			],
		};
	}
}

import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, scan, shareReplay } from 'rxjs';
import { endpoints } from '@evasys/globals/evainsights/api/endpoints';
import { AnalysisType, VisualizationType } from '@evasys/globals/evainsights/constants/types';
import { map } from 'rxjs/operators';
import { pick } from 'lodash';
import {
	AggregationType,
	AxisChartDataRequestDimension,
	AxisChartIndexDimension,
	BarChartConfig,
	BarChartContent,
	CentralTendencyLineChartConfig,
	CentralTendencyLineChartContent,
	CentralTendencyLineChartData,
	CentralTendencyLineChartDataRequest,
	CountAxisChartDataRequest,
	CountLineChartConfig,
	CountLineChartContent,
	DataIndexDimensionType,
	DimensionMappingConfig,
	NoDataRequest,
	ProfileLineChartConfig,
	ProfileLineChartContent,
	ReportItemContent,
	ReportItemDataContext,
	ResponseCountAxisChartData,
	ResponsesDataRequest,
	RichTextConfig,
	RichTextContent,
	TopicWordcloudConfig,
	TopicWordcloudContent,
	TopicWordcloudData,
	TopicWordcloudDataRequest,
	TopicWordcloudResponsesConfig,
	TopicWordcloudResponsesContent,
	TopicWordcloudResponsesData,
	TopicWordcloudResponsesDataRequest,
} from '@evasys/globals/evainsights/models/report-item';
import {
	ResponsesConfig,
	ResponsesContent,
	ResponsesData,
} from '@evasys/globals/evainsights/models/report-item/content/responses-report-item-content.model';
import {
	WordFrequencyWordcloudConfig,
	WordFrequencyWordcloudContent,
	WordFrequencyWordcloudData,
} from '@evasys/globals/evainsights/models/report-item/content/word-frequency-wordcloud-report-item-content.model';
import { isEqual } from '@evasys/globals/shared/helper/object';
import {
	DividerConfig,
	DividerContent,
} from '@evasys/globals/evainsights/models/report-item/content/divider-report-item-content.model';

@Injectable({
	providedIn: 'root',
})
export class ChartService {
	private readonly http = inject(HttpClient);

	countAxisChartLoader: DataLoader<BarChartContent | CountLineChartContent, CountAxisChartDataRequest> = {
		matches: (config): config is BarChartConfig | CountLineChartConfig =>
			config.visualizationType === VisualizationType.BAR_CHART ||
			(config.visualizationType === VisualizationType.LINE_CHART &&
				config.aggregation.type === AggregationType.RESPONSE_COUNT),
		getParams: (config, context) => ({
			...context,
			dataDimensions: config.dimensionMappings.map(this.toDataRequestDimension),
		}),
		getData: (params) => this.http.post<ResponseCountAxisChartData>(endpoints.postAxisChartData.url(), params),
	};

	centralTendencyLineChartLoader: DataLoader<CentralTendencyLineChartContent, CentralTendencyLineChartDataRequest> = {
		matches: (config): config is CentralTendencyLineChartConfig =>
			config.visualizationType === VisualizationType.LINE_CHART &&
			config.aggregation.type === AggregationType.CENTRAL_TENDENCY,
		getParams: (config, context) => ({
			...context,
			dataDimensions: config.dimensionMappings.map(this.toDataRequestDimension),
			aggregation: {
				metric: config.aggregation.metric,
				sources: config.aggregation.sources.map(({ itemOptionIds }) => ({ itemOptionIds })),
			},
		}),
		getData: (params) => this.http.post<CentralTendencyLineChartData>(endpoints.postAxisChartData.url(), params),
	};

	wordFrequencyWordcloudLoader: DataLoader<WordFrequencyWordcloudContent, ResponsesDataRequest> = {
		matches: (config): config is WordFrequencyWordcloudConfig =>
			config.visualizationType === VisualizationType.WORDCLOUD &&
			config.analysisType == AnalysisType.WORD_FREQUENCY,
		getParams: (config, context) => ({ ...context, itemIds: config.itemIds }),
		getData: (params) =>
			this.http.post<WordFrequencyWordcloudData>(endpoints.postWordFrequencyWordcloudData.url(), params),
	};

	topicWordcloudResponsesLoader: DataLoader<TopicWordcloudResponsesContent, TopicWordcloudResponsesDataRequest> = {
		matches: (config): config is TopicWordcloudResponsesConfig =>
			config.visualizationType === VisualizationType.WORDCLOUD_RESPONSES,
		getParams: ({ topicId, itemIds }, context) => ({ ...context, topicId, itemIds }),
		getData: (params) =>
			this.http.post<TopicWordcloudResponsesData>(endpoints.postTopicWordcloudResponsesData.url(), params),
	};

	topicWordcloudLoader: DataLoader<TopicWordcloudContent, TopicWordcloudDataRequest> = {
		matches: (config): config is TopicWordcloudConfig =>
			config.visualizationType === VisualizationType.WORDCLOUD && config.analysisType === AnalysisType.TOPIC,
		getParams: ({ topicId, itemIds }, context) => ({ ...context, topicId, itemIds }),
		getData: (params) => this.http.post<TopicWordcloudData>(endpoints.postTopicWordcloudData.url(), params),
	};

	noDataLoader: DataLoader<RichTextContent | DividerContent | ProfileLineChartContent, NoDataRequest> = {
		matches: (config): config is RichTextConfig | DividerConfig | ProfileLineChartConfig =>
			[VisualizationType.RICH_TEXT, VisualizationType.DIVIDER, VisualizationType.PROFILE_LINE_CHART].includes(
				config.visualizationType
			),
		getParams: () => ({}),
		getData: () => of(null),
	};

	responsesLoader: DataLoader<ResponsesContent, ResponsesDataRequest> = {
		matches: (config): config is ResponsesConfig => config.visualizationType === VisualizationType.RESPONSES,
		getParams: ({ itemIds }, context) => ({ ...context, itemIds }),
		getData: (params) => this.http.post<ResponsesData>(endpoints.postResponsesData.url(), params),
	};

	toDataRequestDimension = (
		mapping: DimensionMappingConfig<AxisChartIndexDimension, unknown>
	): AxisChartDataRequestDimension => {
		const dataDimension = mapping.data;

		if (dataDimension.type === DataIndexDimensionType.ITEM) {
			return {
				type: DataIndexDimensionType.ITEM,
				domain: this.toDataRequestDomain(dataDimension.domain, ['itemIds']),
			};
		} else if (dataDimension.type === DataIndexDimensionType.ITEM_OPTION) {
			return {
				type: DataIndexDimensionType.ITEM_OPTION,
				domain: this.toDataRequestDomain(dataDimension.domain, ['itemOptionIds']),
			};
		} else if (dataDimension.type === DataIndexDimensionType.PERIOD) {
			return {
				type: DataIndexDimensionType.PERIOD,
				domain: dataDimension.domain,
			};
		} else {
			return {
				type: DataIndexDimensionType.TOPIC,
				domain: this.toDataRequestDomain(dataDimension.domain, ['itemIds', 'topicIds']),
			};
		}
	};

	toDataRequestDomain = <Point extends { exclude: boolean }, Property extends keyof Point>(
		domain: Point[],
		properties: Property[]
	) => {
		return domain.filter((point) => !point.exclude).map((point) => pick(point, ...properties));
	};

	/**
	 * A Rxjs operator that build complete report item contents including the data from configs.
	 * Whenever possible, it reuses the previous data to prevent unnecessary network requests.
	 */
	getReportItemContentFromConfig(context: ReportItemDataContext) {
		return (
			configObservable: Observable<ReportItemContent['config']>
		): Observable<Observable<ReportItemContent>> => {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			return configObservable.pipe(
				scan<ReportItemContent['config'], ScanAccumulation>(
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					({ cacheKey: prevCacheKey, data: prevData }, config) => {
						const cacheQuery = this.makeDataCacheQuery(config, context);
						// if the cache key is the same as for the previous observable, reuse the shared data
						// otherwise compute the data and make it shareable for the next report item
						const dataObservable = isEqual(cacheQuery.key, prevCacheKey)
							? prevData
							: cacheQuery.compute().pipe(shareReplay(1));

						return {
							cacheKey: cacheQuery.key,
							data: dataObservable,
							content: dataObservable?.pipe(map((data) => ({ config, data }))),
						};
					},
					{ cacheKey: null, data: null, content: null }
				),
				map(({ content }) => content)
			);
		};
	}

	private makeDataCacheQuery(config: ReportItemContent['config'], context: ReportItemDataContext): CacheQuery {
		if (this.countAxisChartLoader.matches(config)) {
			return this.makeDataCacheQueryWithLoader(config, context, this.countAxisChartLoader);
		} else if (this.centralTendencyLineChartLoader.matches(config)) {
			return this.makeDataCacheQueryWithLoader(config, context, this.centralTendencyLineChartLoader);
		} else if (this.wordFrequencyWordcloudLoader.matches(config)) {
			return this.makeDataCacheQueryWithLoader(config, context, this.wordFrequencyWordcloudLoader);
		} else if (this.topicWordcloudResponsesLoader.matches(config)) {
			return this.makeDataCacheQueryWithLoader(config, context, this.topicWordcloudResponsesLoader);
		} else if (this.topicWordcloudLoader.matches(config)) {
			return this.makeDataCacheQueryWithLoader(config, context, this.topicWordcloudLoader);
		} else if (this.noDataLoader.matches(config)) {
			return this.makeDataCacheQueryWithLoader(config, context, this.noDataLoader);
		} else if (this.responsesLoader.matches(config)) {
			return this.makeDataCacheQueryWithLoader(config, context, this.responsesLoader);
		} else {
			throw new Error('Unsupported chart');
		}
	}

	private makeDataCacheQueryWithLoader<Content extends ReportItemContent, Params>(
		config: Content['config'],
		context: ReportItemDataContext,
		loader: DataLoader<Content, Params>
	): CacheQuery {
		const params = loader.getParams(config, context);

		return {
			key: [loader.getData, params],
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			compute: () => loader.getData(params),
		};
	}
}

// Define the loader for each possible data type in three steps
// this allows us to decide if we can reuse a previous content request:
// does the same data loader still match and did the request params stay the same? Then reuse, otherwise request
interface DataLoader<Content extends ReportItemContent, Params> {
	// should this loader handle the request?
	matches: (config: ReportItemContent['config']) => config is Content['config'];
	// which parts of the options are actually used when building the content?
	getParams: (config: Content['config'], context: ReportItemDataContext) => Params;
	// build the content given the parameters
	getData: (params: Params) => Observable<Content['data']> | null;
}

interface CacheQuery {
	key: unknown;
	compute: () => Observable<ReportItemContent['data']>;
}

interface ScanAccumulation {
	cacheKey: unknown | null;
	data: Observable<ReportItemContent['data']> | null;
	content: Observable<ReportItemContent> | null;
}

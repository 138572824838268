import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FoldableDirective } from './foldable/foldable.directive';

@NgModule({
	declarations: [FoldableDirective],
	imports: [CommonModule],
	exports: [FoldableDirective],
})
export class FoldableModule {}

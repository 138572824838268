import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ApiService } from '../../api/services/api.service';
import { EvasysApiRequestModel } from '@evasys/globals/shared/models/general/evasys-api-request.model';
import { EvasysRequestMethodEnum } from '@evasys/globals/shared/enums/general/evasys-request-method.enum';

/**
 * @typeParam M - The type of the expected frontend model
 *
 * @template M - The type of the expected frontend model
 */
export abstract class EvasysEffects<M> {
	protected abstract readonly apiService: ApiService;

	protected constructor(
		protected readonly fromActions: any,
		protected readonly apiPath: string,
		protected readonly actions$: Actions
	) {}

	protected abstract mapToFrontend(data: any): M;

	protected abstract mapToBackend(subunit: M): any;

	/**
	 *@param mapFunction A callback function to map the response data of the api to the frontend model data structure
	 */

	private createSuccessAction(
		request: EvasysApiRequestModel,
		response: any,
		action: any,
		actionSet: any,
		requestId?: number
	) {
		switch (request.requestMethod) {
			case EvasysRequestMethodEnum.GET:
				return actionSet.SUCCESS({ payload: response, requestId });
			case EvasysRequestMethodEnum.POST:
				return actionSet.SUCCESS({ payload: response, requestId });
			case EvasysRequestMethodEnum.DELETE:
				return actionSet.SUCCESS({ payload: action.payload, requestId });
			case EvasysRequestMethodEnum.PUT:
				return actionSet.SUCCESS({ payload: response, requestId });
			case EvasysRequestMethodEnum.PATCH:
				return actionSet.SUCCESS({ payload: response, requestId });
		}
	}

	/**
	 * @param actionSet The Action-Type of the Action. You get the Action-Type via the self defined enum in the Actions.
	 * @param requestFunction A callback function to config the api request
	 */
	protected createEffect<IN>(actionSet: any, requestFunction: (action: any) => EvasysApiRequestModel) {
		return createEffect(() => {
			try {
				return this.actions$.pipe(
					ofType(actionSet.DEFAULT),
					mergeMap((action) => {
						const request = requestFunction(action);
						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						// @ts-ignore: Object is possibly 'undefined'.
						return this.apiService.request<M>(request, this.mapToFrontend, this.mapToBackend).pipe(
							map((response) => {
								return this.createSuccessAction(request, response, action, actionSet, action.requestId);
							}),
							catchError((error) => {
								return of(actionSet.FAILURE({ error, requestId: actionSet.DEFAULT.requestId }));
							})
						);
					})
				);
			} catch (err) {
				throw new Error(
					'Can not handle effect: Maybe you forgot to add the action set (LoadActionSet)! ' + err
				);
			}
		});
	}

	//General Effects

	load$ = this.createEffect(
		this.fromActions.LoadActionSet,
		(action: any) =>
			({
				apiPath:
					action?.payload?.id && !Array.isArray(action.payload.id)
						? this.apiPath + '/' + action?.payload?.id
						: this.apiPath,
				many: Array.isArray(action.payload.id) || action.payload.id === undefined,
				requestMethod: EvasysRequestMethodEnum.GET,
				page: action.payload?.page,
				order: action.payload?.order,
				pageSize: action.payload?.pageSize,
				params: action.payload?.params,
				fields: action.payload?.fields,
				view: action.payload?.view,
			} as EvasysApiRequestModel)
	);
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ReportEffects } from '../effects/report.effects';
import { reportReducer } from '../reducers/report.reducer';

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		EffectsModule.forFeature([ReportEffects]),
		StoreModule.forFeature('reports', reportReducer),
	],
})
export class ReportsModule {}

import { animate, state, style, transition, trigger } from '@angular/animations';

export const yGrowAnimation = (from: string, to: string, ms: number) => {
	return trigger('yGrowAnimation', [
		state(
			'false',
			style({
				height: from,
			})
		),
		state(
			'true',
			style({
				height: to,
				opacity: '*',
			})
		),
		transition('true <=> false', [animate(ms + 'ms')]),
		transition(':enter', [
			style({
				height: from,
			}),
			animate(
				ms + 'ms',
				style({
					height: to,
					opacity: '*',
				})
			),
		]),
		transition(':leave', [
			animate(
				ms + 'ms',
				style({
					height: from,
				})
			),
		]),
	]);
};

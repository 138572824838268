import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

export const maxLengthAxisLabel = 35;
export const maxLengthTitle = 300;
export const maxLengthReportTitle = 500;
export const maxLengthTopicName = 100;

export const validatorTextColor = Validators.pattern('^#(?:[0-9a-fA-F]{6}){1,2}$');

export const minMapSize = (min: number): ValidatorFn => {
	return (control: AbstractControl): ValidationErrors | null => {
		return control.value.size >= min ? null : { minMapSize: true };
	};
};

export const expiredTimeDateValidator = (): ValidatorFn => {
	return (control: AbstractControl): ValidationErrors | null => {
		if (new Date(control.value) < new Date()) {
			return { expired: true };
		}
		return null;
	};
};

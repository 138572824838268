import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { endpoints } from '@evasys/globals/evainsights/api/endpoints';
import { Page } from '@evasys/globals/evainsights/models/pagination/page.model';
import { ReportSearchRequest } from '@evasys/globals/evainsights/models/search/ReportSearchRequest';
import { KeyValueModel } from '@evasys/globals/evainsights/models/common/key-value.model';
import { ReportTemplate } from '@evasys/globals/evainsights/models/report/report-reportTemplate.model';
import { ReportBaseService } from './reportBase.service';

@Injectable({
	providedIn: 'root',
})
export class ReportTemplateService implements ReportBaseService<ReportTemplate, ReportSearchRequest> {
	readonly http = inject(HttpClient);

	getSearchResult(filter: ReportSearchRequest = {}): Observable<Page<ReportTemplate>> {
		return this.http.get<Page<ReportTemplate>>(endpoints.reportTemplates.url(), { params: { ...filter } });
	}

	getById(reportTemplateId: number): Observable<ReportTemplate> {
		return this.http.get<ReportTemplate>(endpoints.reportTemplate.url({ reportTemplateId }));
	}

	saveNew(reportTemplate: ReportTemplate): Observable<ReportTemplate> {
		return this.http.post<ReportTemplate>(endpoints.reportTemplates.url(), reportTemplate);
	}

	update(reportTemplate: ReportTemplate): Observable<ReportTemplate> {
		return this.http.put<ReportTemplate>(
			endpoints.reportTemplate.url({ reportTemplateId: reportTemplate.id }),
			reportTemplate
		);
	}

	delete(reportTemplate: ReportTemplate): Observable<unknown> {
		return this.http.delete<ReportTemplate>(endpoints.reportTemplate.url({ reportTemplateId: reportTemplate.id }), {
			observe: 'response',
		});
	}

	getForms(reportTemplateId: number): Observable<KeyValueModel> {
		return this.http.get<KeyValueModel>(endpoints.getReportTemplateForms.url({ reportTemplateId }));
	}
}

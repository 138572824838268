import { Element, Text } from 'slate';
import { RichTextBody } from '@evasys/globals/evainsights/models/report-item';

export const richTextIsEmpty = (richText: RichTextBody): boolean => {
	return richText.length === 0 || richText.every(elementIsEmpty);
};

const elementIsEmpty = (element: Element): boolean => {
	if (element.children.length === 0) {
		return true;
	} else if (element.children.length > 1) {
		return false;
	}

	const first = element.children[0];
	return Text.isText(first) && first.text.trim() === '';
};

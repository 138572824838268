import { EvasysActionsEnum } from '@evasys/globals/shared/enums/general/evasys-actions.enum';
import { EvasysResponseStateEnum } from '@evasys/globals/shared/enums/general/evasys-response-state.enum';
import { EvasysManyInPagesModel } from '@evasys/globals/evasys/models/general/evasys-many-in-pages.model';
import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { EvasysCollectionModel } from '@evasys/globals/shared/models/general/evasys-collection.model';
import { HttpErrorResponse } from '@angular/common/http';
import { EvasysActionSet } from '@evasys/globals/shared/models/general/evasys-action-set.models';
import { EvasysFacadeGetModel } from '@evasys/globals/evasys/models/general/evasys-facade-get.model';

export class EvasysActionsFactory<M> {
	private readonly _token = '[' + this.token?.toUpperCase() + ']';

	constructor(private readonly token: string) {}

	//#region Methods

	/**
	 *
	 * @typeParam IN - The type of the Payload for the Effect
	 * @typeParam OUT - The type of the Response
	 *
	 * @template IN - The type of the Payload for the Effect
	 * @template OUT - The type of the Response
	 * @param type
	 * @param apiRequest
	 */
	private createActionSet<IN, OUT>(type: string, apiRequest: boolean = true): EvasysActionSet<IN, OUT> {
		if (apiRequest) {
			return {
				DEFAULT: createAction([this._token, type].join(' '), props<{ payload: IN }>()),
				SUCCESS: createAction(
					[this._token, type, EvasysResponseStateEnum.SUCCESS].join(' '),
					props<{ payload: OUT }>()
				),
				FAILURE: createAction(
					[this._token, type, EvasysResponseStateEnum.FAILURE].join(' '),
					props<{ error: HttpErrorResponse }>()
				),
			};
		} else {
			return {
				DEFAULT: createAction([this._token, EvasysActionsEnum.DELETEMANY].join(' '), props<{ payload: IN }>()),
			};
		}
	}

	/**
	 * @param actionType - type of action (for example: COMPARE, LOAD, REFRESH)
	 * @param apiRequest
	 *
	 * @typeParam IN - The type of the Payload for the Effect
	 * @typeParam OUT - The type of the Response
	 *
	 * @template IN - The type of the Payload for the Effect
	 * @template OUT - The type of the Response
	 */
	public createCustomActionSet = <IN, OUT>(actionType: string, apiRequest: boolean = true) =>
		this.createActionSet<IN, OUT>(
			apiRequest
				? [EvasysActionsEnum.CUSTOM, actionType].join(' ')
				: [EvasysActionsEnum.CUSTOM, actionType, 'NoResponse'].join(' ')
		);

	public createActions() {
		return {
			LoadActionSet: this.createActionSet<EvasysFacadeGetModel<M>, EvasysCollectionModel<M> | M>(
				EvasysActionsEnum.LOAD
			),
			LoadPageActionSet: this.createActionSet<number, EvasysCollectionModel<M>>(
				EvasysActionsEnum.LOADPAGEWITHPARAMS
			),
			LoadOneActionSet: this.createActionSet<string | number, M>(EvasysActionsEnum.LOADONE),
			LoadAllActionSet: this.createActionSet<void, EvasysCollectionModel<M>>(EvasysActionsEnum.LOADALL),
			LoadOneWithParamsActionSet: this.createActionSet<{ id: string | number; params: [string, string][] }, M>(
				EvasysActionsEnum.LOADONEWITHPARAMS
			),
			LoadWithParamsActionSet: this.createActionSet<{ params: [string, string][] }, EvasysCollectionModel<M>>(
				EvasysActionsEnum.LOADWITHPARAMS
			),
			LoadManyActionSet: this.createActionSet<EvasysManyInPagesModel, EvasysCollectionModel<M>>(
				EvasysActionsEnum.LOADMANY
			),
			LoadPageWithFilterActionSet: this.createActionSet<
				{ page: number; filter: string; pageSize: number },
				EvasysCollectionModel<M>
			>(EvasysActionsEnum.LOADPAGEWITHFILTER),
			CreateOneActionSet: this.createActionSet<M, M>(EvasysActionsEnum.CREATEONE),
			CreateOneLocalActionSet: this.createActionSet<M, void>(EvasysActionsEnum.CREATEONELOCAL, false),
			UploadFileActionSet: this.createActionSet<{ file: File; id?: number }, Partial<M>>(
				EvasysActionsEnum.UPLOADFILE
			),
			EditOneActionSet: this.createActionSet<Update<M>, M>(EvasysActionsEnum.EDITONE),
			EditOneLocalActionSet: this.createActionSet<Update<M>, M>(EvasysActionsEnum.EDITONELOCAL, false),
			EditManyActionSet: this.createCustomActionSet<Update<M>[], EvasysCollectionModel<M>>(
				EvasysActionsEnum.EDITMANY
			),
			DeleteOneActionSet: this.createActionSet<string | number, M>(EvasysActionsEnum.DELETEONE),
			DeleteOneLocalActionSet: this.createActionSet<string | number, void>(
				EvasysActionsEnum.DELETEONELOCAL,
				false
			),
			DeleteManyActionSet: this.createActionSet<string[] | number[], string[]>(EvasysActionsEnum.DELETEMANY),
			ClearActionSet: this.createCustomActionSet<void, void>(EvasysActionsEnum.CLEAR, false),
		};
	}

	//#endregion Methods
}

import * as fromSystemInfo from './system-info.actions';
import * as fromMenu from './menu.actions';
import * as fromWizard from './wizard.actions';
import * as fromStatus from './status.actions';

export const evasysActions = {
	fromSystemInfo,
	fromMenu,
	fromWizard,
	fromStatus,
};

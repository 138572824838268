import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipDirective } from './tooltip/tooltip.directive';

@NgModule({
	declarations: [TooltipDirective],
	exports: [TooltipDirective],
	imports: [CommonModule],
})
export class TooltipModule {}

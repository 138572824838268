import { createReducer, on } from '@ngrx/store';
import { EvasysStatusModel } from '@evasys/globals/evasys/models/general/evasys-status.model';
import { defaultEvasysState, EvasysEntityAdapter, EvasysReducerCaseFactory, EvasysState } from '@evasys/shared/core';
import { evasysActions } from '../actions';

export const statusAdapter = new EvasysEntityAdapter<EvasysStatusModel>();
export const statusInitialState: EvasysState<EvasysStatusModel> = statusAdapter.entity.getInitialState(
	defaultEvasysState()
);

const statusReducerCaseFactory = new EvasysReducerCaseFactory<EvasysStatusModel>(
	statusAdapter,
	evasysActions.fromStatus
);

const { reduceLoadAllActionSet, reduceLoadOneActionSet, reduceLoadActionSet } =
	statusReducerCaseFactory.reduceEvasysActionSets();

export const statusReducer = createReducer(
	statusInitialState,
	...reduceLoadAllActionSet,
	...reduceLoadOneActionSet,
	...reduceLoadActionSet,
	//region deleteOneStatusActionSet
	on(
		evasysActions.fromStatus.deleteOneStatusActionSet.DEFAULT,
		(state: EvasysState<EvasysStatusModel>, { payload }) => statusAdapter.entity.removeOne(payload, state)
	),
	//endregion
	//region createOneStatusActionSet
	on(
		evasysActions.fromStatus.createOneStatusActionSet.DEFAULT,
		(state: EvasysState<EvasysStatusModel>, { payload }) => statusAdapter.loadOneSucceed(payload, state)
	),
	//endregion
	//region eulaAccepted
	on(evasysActions.fromStatus.EulaAcceptedActionSet.DEFAULT, (state: EvasysState<EvasysStatusModel>) =>
		statusAdapter.load(state)
	),
	on(evasysActions.fromStatus.EulaAcceptedActionSet.SUCCESS, (state: EvasysState<EvasysStatusModel>) => ({
		...state,
		loading: false,
	})),
	on(evasysActions.fromStatus.EulaAcceptedActionSet.FAILURE, (state: EvasysState<EvasysStatusModel>, { error }) => ({
		...state,
		error,
		loading: false,
	}))
	//endregion
);

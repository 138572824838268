import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { NoteComponent } from './note/note.component';

@NgModule({
	declarations: [NoteComponent],
	imports: [CommonModule, NgbPopoverModule],
	exports: [NoteComponent],
})
export class NoteModule {}

import { Injectable } from '@angular/core';
import { Observable, pairwise, shareReplay, startWith } from 'rxjs';
import { Event, NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class UrlService {
	public previousUrl$: Observable<string | null>;

	constructor(router: Router) {
		this.previousUrl$ = router.events.pipe(
			filter(UrlService.isNavigationEnd),
			pairwise(),
			map(([previous]) => previous.url),
			startWith(null),
			shareReplay(1)
		);
	}

	private static isNavigationEnd(event: Event): event is NavigationEnd {
		return event instanceof NavigationEnd;
	}

	initialize() {
		// trigger the URL tracking
		this.previousUrl$
			.subscribe(() => {
				/* this is intentional */
			})
			.unsubscribe();
	}
}

import { Code } from './code.model';

export class CodedError extends Error {
	constructor(readonly code: Readonly<Code>, message: string) {
		super(message);
	}
}

export const getErrorCode = (error: unknown): Code | null => {
	// Try to extract the error code on a best-effort basis.
	// Main challenge is when we throw a CodedError that gets caught in a try-catch, with the catch block throwing a
	// new error, there is no clear way to tell if the original source of the error was a CodedError.
	// This method tries to unpack the error to get the root source

	if (error instanceof CodedError) {
		return error.code;
	} else if (error && typeof error === 'object' && 'rejection' in error && error.rejection instanceof Error) {
		// Occurs if the CodedError occurs somewhere in a promise.
		// The error message logged to the console starts with "Error: Uncaught (in promise)"
		return getErrorCode(error.rejection);
	} else {
		return null;
	}
};

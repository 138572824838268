import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ScreenService, ScreenSizes } from '../../../../../../util/src/lib/screen/services/screen.service';
import { Observable } from 'rxjs';
import { FormBuilder } from '@angular/forms';
import { Validations } from '@evasys/globals/shared/validations/validations';
import { ButtonDesignEnum } from '@evasys/globals/shared/enums/component/button-design.enum';
import { SharedUiConfiguration } from '../../../shared-ui.configuration';

enum MenuState {
	NONE,
	SEARCHING,
	HELPING,
}

@Component({
	selector: 'evasys-search-help',
	templateUrl: './search-help.component.html',
})
export class SearchHelpComponent implements OnInit {
	//#region Input & Output
	@Input()
	searchText: string;

	@Output()
	helpAction = new EventEmitter<string>();

	@Output()
	searchAction = new EventEmitter<string>();

	//#endregion Input & Output

	//#region Variables
	public MenuStateEnum = MenuState;
	public menuState = MenuState.NONE;
	public isMenuCollapsed = true;
	public isLargeScreenObservable: Observable<boolean> | null = null;
	public searchButtonDesign = ButtonDesignEnum.OUTLINE_LINK;
	//#endregion Variables

	//#region Form
	public searchForm = this.formBuilder.group({
		searching: ['', [Validations.required]],
	});
	//#endregion Form

	constructor(
		private readonly screenService: ScreenService,
		private readonly formBuilder: FormBuilder,
		public readonly config: SharedUiConfiguration
	) {}

	//#region events
	ngOnInit(): void {
		this.isLargeScreenObservable = this.screenService.isSizeBiggerThanObservable(ScreenSizes.M);
	}

	onButtonClick(menuState: MenuState): void {
		if (this.isActive(menuState)) {
			this.menuState = MenuState.NONE;
		} else {
			this.menuState = menuState;
			this.searchForm.get('searching').setValue(menuState === MenuState.HELPING ? this.searchText : '');
		}
		this.isMenuCollapsed = !(this.isActive(MenuState.SEARCHING) || this.isActive(MenuState.HELPING));
	}

	onSearchSubmit() {
		if (this.menuState === MenuState.HELPING) {
			this.helpAction.emit(this.searchForm.controls.searching.value);
		} else if (this.menuState === MenuState.SEARCHING) {
			this.searchAction.emit(this.searchForm.controls.searching.value);
		}
	}

	public onClickOutside(): void {
		this.menuState = MenuState.NONE;
		this.isMenuCollapsed = true;
	}

	//#endregion events

	//#region methods
	isActive(menuState: MenuState): boolean {
		return menuState === this.menuState;
	}

	//#endregion methods
}

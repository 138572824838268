import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EvasysState } from '@evasys/shared/core';
import { EvasysMenuModel } from '@evasys/globals/evasys/models/general/evasys-menu.model';
import { MenuState } from '@evasys/globals/evasys/models/states/menu.state';
import { menuAdapter } from '../reducers/menu.reducer';

export const getMenuState = createFeatureSelector<EvasysState<EvasysMenuModel, MenuState>>('menu');

export const { selectLoading, selectPageConfig, selectPage, selectLoadedPages, selectEntity, selectMany, selectError } =
	menuAdapter.getSelectors(getMenuState);

export const { selectIds, selectEntities, selectAll, selectTotal } = menuAdapter.entity.getSelectors(getMenuState);

export const selectPermanentOpenState = createSelector(
	getMenuState,
	(state: EvasysState<EvasysMenuModel, MenuState>) => state?.supplement?.permanentlyOpen
);

export const selectSearchTextState = createSelector(
	getMenuState,
	(state: EvasysState<EvasysMenuModel, MenuState>) => state?.supplement?.searchText
);

import { inject, Injectable } from '@angular/core';
import { memoize, once, sumBy } from 'lodash';
import { appendElementHidden } from '@evasys/globals/evainsights/helper/dom';
import { CANVAS_RENDERING_CONTEXT_2D } from './canvas-rendering-context-2d.token';

@Injectable({
	providedIn: 'root',
})
export class TextMeasurementService {
	private ctx = inject(CANVAS_RENDERING_CONTEXT_2D);

	private getComputeStyleElement = once(() => {
		const element = document.createElement('div');
		appendElementHidden(element);
		return element;
	});

	private getStyleFromCss = memoize((className: string): TextMeasurementStyle => {
		const elt = this.getComputeStyleElement();
		elt.className = className;

		const style = window.getComputedStyle(elt);

		return {
			font: style.getPropertyValue('font'),
			padding: sumBy(['padding-left', 'padding-right'], (property) => parseInt(style.getPropertyValue(property))),
		};
	});

	/**
	 * Efficiently approximate the offsetWidth of an HTML element with text content
	 * Occasionally over-estimates the offset width by one pixel.
	 * @param text The text content of the element
	 * @param className The CSS class name of the element
	 */
	getOffsetWidth = (text: string, className: string): number => {
		const style = this.getStyleFromCss(className);
		return Math.ceil(this.getTextWidth(text, style.font) + style.padding);
	};

	private getTextWidth(text: string, font: string): number {
		this.ctx.font = font;
		return this.ctx.measureText(text).width;
	}
}

interface TextMeasurementStyle {
	font: string;
	padding: number; // in x-direction
}

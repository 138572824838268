import { Injectable } from '@angular/core';
import { EvasysEffects } from '../essential/redux/effects/evasys.effects';
import { Actions } from '@ngrx/effects';
import { EvasysRequestMethodEnum } from '@evasys/globals/shared/enums/general/evasys-request-method.enum';
import { EvasysApiRequestModel } from '@evasys/globals/shared/models/general/evasys-api-request.model';
import { sharedActions } from '../actions/index';
import { EvasysGrantEnum } from '@evasys/globals/shared/enums/general/evasys-grant.enum';
import { EvasysTokenModel } from '@evasys/globals/shared/models/general/evasys-token.model';
import { SharedCoreConfiguration } from '../shared-core.configuration';
import { ApiService } from '../essential/api/services/api.service';

@Injectable()
export class AuthEffects extends EvasysEffects<EvasysTokenModel> {
	private API_PATH = this.sharedCoreConfiguration.api.auth;

	constructor(
		protected override actions$: Actions,
		protected readonly apiService: ApiService,
		private readonly sharedCoreConfiguration: SharedCoreConfiguration
	) {
		super(sharedActions.fromAuth, sharedCoreConfiguration.api.auth, actions$);
	}

	login$ = this.createEffect(sharedActions.fromAuth.LoginActionSet, (action) => {
		const clientSecret = this.sharedCoreConfiguration.auth.clientSecret;

		return {
			apiPath: this.API_PATH,
			many: false,
			requestMethod: EvasysRequestMethodEnum.POST,
			body: {
				username: action.payload.sso ? ' ' : action.payload.username,
				password: action.payload.sso ? ' ' : action.payload.password,
				grant_type: EvasysGrantEnum.PASSWORD,
				client_id: this.sharedCoreConfiguration.auth.clientId,
				...(clientSecret != null && { client_secret: clientSecret }),
				chosenUserId: action.payload.userId,
				captchaResetKey: action.payload.captchaResetKey,
				captchaInputString: action.payload.captchaInputString,
				forwardinfo: action.payload.sso,
				session: action.payload.sso,
				tenant_subdomain: action.payload.tenantSubdomain,
			},
		};
	});

	refresh$ = this.createEffect(sharedActions.fromAuth.RefreshActionSet, (action) => {
		const clientSecret = this.sharedCoreConfiguration.auth.clientSecret;

		return {
			apiPath: this.API_PATH,
			many: false,
			requestMethod: EvasysRequestMethodEnum.POST,
			body: {
				refresh_token: action.payload,
				grant_type: EvasysGrantEnum.REFRESH_TOKEN,
				client_id: this.sharedCoreConfiguration.auth.clientId,
				...(clientSecret != null && { client_secret: clientSecret }),
			},
		};
	});

	logout$ = this.createEffect(
		sharedActions.fromAuth.LogoutActionSet,
		(action: any) =>
			({
				apiPath: this.API_PATH + '/revoke',
				many: false,
				requestMethod: EvasysRequestMethodEnum.POST,
				body: action.payload,
			} as EvasysApiRequestModel)
	);

	protected mapToFrontend(data: any): EvasysTokenModel {
		if (data === null) {
			return null;
		}
		const expire_time = new Date();
		let detail;
		if (data.detail) {
			detail = JSON.parse(data.detail);
		}

		expire_time?.setSeconds(expire_time?.getSeconds() + data?.expires_in);
		const token: any = {
			...data,
			id: data.userid ? data.userid : '-1',
			expire_time: expire_time?.getTime()?.toString(),
			multipleExternalIds: !!data.mainUserFullName,
			detail,
		} as EvasysTokenModel;

		delete token.users;
		delete token.mainUserFullName;

		return token;
	}

	protected mapToBackend(config: EvasysTokenModel): any {
		return {
			...config,
		};
	}
}

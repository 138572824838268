import { fill, range, zipObject } from 'lodash';

export function full<T>(size: number, value: T): T[] {
	return fill(new Array(size), value);
}

export function fullBy<T>(size: number, value: (index: number) => T): T[] {
	const arr = new Array<T>(size);
	for (let i = 0; i < arr.length; i++) {
		arr[i] = value(i);
	}
	return arr;
}

type ZipItem<T extends any[][]> = T extends [Array<infer HeadItem>, ...infer TailArrays]
	? TailArrays extends any[]
		? [HeadItem, ...ZipItem<TailArrays>]
		: never
	: [];

export function zipShortest<T extends any[][]>(...arrays: T) {
	return range(Math.min(...arrays.map((arr) => arr.length))).map((i) => arrays.map((arr) => arr[i]) as ZipItem<T>);
}

type NamedZipItem<T extends { [k: PropertyKey]: any[] }> = { [k in keyof T]: T[k] extends Array<infer V> ? V : never };
export function namedZipShortest<O extends { [k: PropertyKey]: any[] }>(obj: O) {
	const entries = Object.entries(obj);
	const keys = entries.map(([k]) => k);
	const values = entries.map(([, v]) => v);

	return zipShortest(...values).map((zipItem) => zipObject(keys, zipItem) as unknown as NamedZipItem<O>);
}

export function findIndices<T>(arr: T[], predicate: (value: T) => boolean): number[] {
	return arr
		.map((value, index) => [value, index] as const)
		.filter(([value]) => predicate(value))
		.map(([, index]) => index);
}

export function combinations<T>(arrays: Array<T[]>): Array<T[]> {
	if (arrays.length === 0) {
		return [];
	} else if (arrays.length === 1) {
		return arrays[0].map((value) => [value]);
	} else {
		const restCombinations = combinations(arrays.slice(1));
		return arrays[0].flatMap((value) => restCombinations.map((restCombination) => [value, ...restCombination]));
	}
}

/**
 * Like Array.prototype.indexOf but ensures that the value exists at most once
 */
export function onlyIndexOf<T>(arr: T[], value: T): number {
	const firstOccurrence = arr.indexOf(value);
	if (firstOccurrence === -1) {
		return -1;
	}

	const secondOccurrence = arr.indexOf(value, firstOccurrence + 1);
	if (secondOccurrence !== -1) {
		throw Error('Value exists twice');
	}

	return firstOccurrence;
}

export function hasDuplicates(arr: unknown[]) {
	return new Set(arr).size !== arr.length;
}

import * as fromReports from './report.actions';
import * as fromReportTemplates from './reporttemplate.actions';

export const reportActions = {
	fromReports,
};

export const reportTemplateActions = {
	fromReportTemplates,
};

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PermissionPipe } from './Pipes/permission.pipe';
import { PermissionDirective } from './directives/permission.directive';

@NgModule({
	declarations: [PermissionPipe, PermissionDirective],
	exports: [PermissionPipe, PermissionDirective],
	imports: [CommonModule],
})
export class PermissionsModule {}

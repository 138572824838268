import { Component, Input } from '@angular/core';

@Component({
	selector: 'evasys-product-menu-item',
	templateUrl: './product-menu-item.component.html',
})
export class ProductMenuItemComponent {
	@Input()
	id: string;

	@Input()
	name: string;

	@Input()
	logo: string;

	@Input()
	url: URL | string;

	get href() {
		return this.url instanceof URL ? this.url.href : this.url;
	}
}

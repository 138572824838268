import {
	ChangeDetectorRef,
	Component,
	forwardRef,
	inject,
	Input,
	OnChanges,
	SimpleChanges,
	TemplateRef,
	ViewChild,
} from '@angular/core';
import { first, map, Observable } from 'rxjs';
import { ObservableFormatter, raisingFormatter, toObservableFormatter } from '@evasys/shared/util';
import { ValidationErrorModel } from '@evasys/globals/evasys/models/component/validation-error.model';
import { ChipTypeaheadDesignEnum } from '@evasys/globals/shared/enums/component/chip-typeahead-design.enum';
import { ButtonDesignEnum } from '@evasys/globals/shared/enums/component/button-design.enum';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ResultTemplateContext, TypeaheadComponent } from '../typeahead/typeahead.component';
import { Router } from '@angular/router';
import { SharedUiConfiguration } from '../../../../../shared-ui.configuration';
import {
	TypeaheadIdentifierValue,
	TypeaheadItemIdentifier,
	TypeaheadItemPageRequest,
	TypeaheadItemSearchPage,
} from '@evasys/globals/shared/models/component/typeahead/typeahead.model';
import { getTypeaheadIdentifierValue } from '@evasys/globals/shared/helper/typeahead';
import { InputValueReplaceStrategy } from '@evasys/globals/shared/enums/component/typeahead.enum';
import { Required } from '@evasys/globals/shared/decorators/decorators';

@Component({
	selector: 'evasys-chip-typeahead',
	templateUrl: './chip-typeahead.component.html',
	styleUrls: ['./chip-typeahead.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => ChipTypeaheadComponent),
			multi: true,
		},
	],
})
export class ChipTypeaheadComponent<T> implements ControlValueAccessor, OnChanges {
	private cd = inject(ChangeDetectorRef);
	protected router = inject(Router);
	protected config = inject(SharedUiConfiguration);

	@Input()
	@Required()
	id: string;

	@Input()
	@Required()
	items: T[] | ((query: string, pageRequest: TypeaheadItemPageRequest) => Observable<TypeaheadItemSearchPage<T>>);

	@Input()
	emptyResultsText: string;

	@Input()
	searchFailedText?: string;

	@Input()
	set formatter(formatter: ((item: T) => string) | ((item: T) => Observable<string>)) {
		this._formatter = toObservableFormatter(formatter);
	}

	@Input()
	header: TemplateRef<Record<string, never>>;

	@Input()
	resultTemplate: TemplateRef<ResultTemplateContext<T>>;

	@Input()
	disabled = false;

	@Input()
	autoFocus: boolean;

	@Input()
	placement = 'bottom-start bottom-end top-start top-end';

	@Input()
	itemIdentifier: TypeaheadItemIdentifier<T> = <TypeaheadItemIdentifier<T>>'id';

	@Input()
	design = ChipTypeaheadDesignEnum.DEFAULT;

	@Input()
	errors: ValidationErrorModel[] = [];

	@Input()
	popoverClass: string;

	@Input()
	showSelectAll = false;

	@Input()
	maxTotalSelectedElements?: number;

	@Input()
	popoverContainer?: 'body' = undefined;

	@Input()
	chipContentTemplate: TemplateRef<T>;

	@Input()
	childItemIdentifier?: string;

	@Input()
	chipVisibleSize?: number;

	@ViewChild('typeahead')
	typeahead: TypeaheadComponent<T>;

	// used to clear the typeahead
	typeaheadInputValue: string | null = '';
	chips: Array<T> = [];
	_formatter: ObservableFormatter<T> = raisingFormatter;
	iconStyle = ButtonDesignEnum.LINK;
	chipTypeaheadDesign = ChipTypeaheadDesignEnum;
	unselectedItems:
		| T[]
		| ((query: string, pageRequest: TypeaheadItemPageRequest) => Observable<TypeaheadItemSearchPage<T>>);
	buttonStyle = ButtonDesignEnum;
	inputValueReplaceStrategy = InputValueReplaceStrategy;
	canSelectAllElements = true;

	ngOnChanges(changes: SimpleChanges) {
		if (changes.items) {
			this.updateUnselectedItems();
		}
	}

	_onChange: (value: T[]) => void = () => {
		// this is intentional
	};

	_onTouched: any = () => {
		//default
	};

	registerOnChange(fn: any): void {
		this._onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this._onTouched = fn;
	}

	writeValue(value: T[] | null) {
		this.chips = value ?? [];
		this.updateUnselectedItems();
	}

	filterUnselected = (items: T[]): T[] => {
		return items.filter((item) => !this.isSelected(item));
	};

	updateUnselectedItems() {
		if (Array.isArray(this.items)) {
			this.unselectedItems = this.filterUnselected(this.items);
		} else {
			const search = this.items;
			this.unselectedItems = (query, pageRequest) =>
				search(query, pageRequest).pipe(
					map((unselected) => ({
						...unselected,
						content: this.filterUnselected(unselected.content),
					}))
				);
		}
	}

	selectAllResults(event: MouseEvent, text: string, canSelectAll: boolean) {
		if (canSelectAll) {
			if (Array.isArray(this.unselectedItems)) {
				this.setChips(this.chips.concat(this.unselectedItems));
			} else {
				this.unselectedItems(text, { size: 1000, page: 0 })
					.pipe(first())
					.subscribe((items) => {
						this.setChips(this.chips.concat(items.content));
					});
			}
			this.typeahead.close();
			event.preventDefault();
		}
	}

	isSelected = (value: T): boolean => {
		if (typeof value === 'object' && this.childItemIdentifier in value) {
			return value[this.childItemIdentifier].every((child) =>
				this.chips.some((chip) => this.getIdentifier(chip) === this.getIdentifier(child))
			);
		} else {
			return this.chips.some((chip) => this.getIdentifier(chip) === this.getIdentifier(value));
		}
	};

	isPartSelected = (value: T): boolean => {
		if (typeof value === 'object' && this.childItemIdentifier in value) {
			const matchedCount = value[this.childItemIdentifier].filter((child) =>
				this.chips.some((chip) => this.getIdentifier(chip) === this.getIdentifier(child))
			).length;
			return matchedCount > 0 && matchedCount < value[this.childItemIdentifier].length;
		}
		return false;
	};

	toggleChip(value: T) {
		const hasChildItem = typeof value === 'object' && this.childItemIdentifier && this.childItemIdentifier in value;
		if (!this.isSelected(value)) {
			const chipsToAdd = hasChildItem
				? value[this.childItemIdentifier].filter((child) => !this.isSelected(child))
				: [value];
			this.setChips([...this.chips, ...chipsToAdd]);
			this.cd.detectChanges();
		} else {
			const chipsToRemove = hasChildItem ? value[this.childItemIdentifier] : [value];
			chipsToRemove.forEach((child) => this.removeChip(child));
		}
	}

	removeChip(value: T) {
		if (this.disabled) return;
		this.setChips(this.chips.filter((chip) => this.getIdentifier(chip) !== this.getIdentifier(value)));
	}

	setChips(chips: T[]) {
		this.chips = chips;
		this.updateUnselectedItems();
		this._onChange(this.chips);
	}

	getIdentifier = (item: T): TypeaheadIdentifierValue => {
		return getTypeaheadIdentifierValue(item, this.itemIdentifier);
	};
}

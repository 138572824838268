import {
	ApplicationRef,
	ChangeDetectorRef,
	Directive,
	ElementRef,
	Inject,
	Injector,
	Input,
	NgZone,
	OnDestroy,
	OnInit,
	Renderer2,
	ViewContainerRef,
} from '@angular/core';
import { NgbTooltip, NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { DOCUMENT } from '@angular/common';
import { debounceTime, Subject, Subscription } from 'rxjs';

@Directive({
	selector: '[evasysTextOverflow]',
	providers: [NgbTooltipConfig],
})
export class TextOverflowDirective extends NgbTooltip implements OnInit, OnDestroy {
	//region Input & Output
	@Input()
	set minWidth(minWidth: string) {
		if (minWidth) {
			this.nativeElement.style.minWidth = minWidth;
		}
	}

	@Input()
	set active(isActive: boolean) {
		this.defaultStyle(isActive);
	}

	@Input()
	set text(value: string) {
		this.ngbTooltip = value;
	}
	//endregion

	//region Variables
	private observer: ResizeObserver;
	private resizeSubject: Subject<void> = new Subject<void>();
	private DEFAULT_STYLES = ['text-truncate'];

	//hint: NOT only get cleared on destroy!
	private subscriptions: Subscription[] = [];

	//endregion
	private nativeElement;

	constructor(
		private elementRef: ElementRef,
		private renderer: Renderer2,
		injector: Injector,
		viewContainerRef: ViewContainerRef,
		config: NgbTooltipConfig,
		_ngZone: NgZone,
		@Inject(DOCUMENT) _document: any,
		applicationRef: ApplicationRef,
		_changeDetector: ChangeDetectorRef
	) {
		super(
			elementRef,
			renderer,
			injector,
			viewContainerRef,
			config,
			_ngZone,
			_document,
			_changeDetector,
			applicationRef
		);
	}

	//endregion

	//region Events
	override ngOnInit() {
		super.ngOnInit();
		this.nativeElement = this.elementRef.nativeElement;
		this.defaultStyle(true);
		this.addTooltipConfigs();
		this.observeElementResize();
		this.listenOnResize();
	}

	override ngOnDestroy() {
		super.ngOnDestroy();
		this.renderer.destroy();
		this.observer.unobserve(this.nativeElement);
		this.observer.disconnect();
		this.subscriptions.forEach((sub) => sub.unsubscribe());
		this.observer = undefined;
		this.resizeSubject?.complete();
		this.resizeSubject = undefined;
		this.subscriptions = undefined;
	}

	//endregion

	//region Methods

	defaultStyle(set: boolean) {
		this.DEFAULT_STYLES.forEach((cssClass) => {
			if (set) {
				this.nativeElement.classList.add(cssClass);
			} else {
				this.nativeElement.classList.remove(cssClass);
			}
		});
	}

	private observeElementResize() {
		this.observer = new ResizeObserver(() => this.resizeSubject.next());
		this.observer.observe(this.nativeElement);
	}

	private addTooltipConfigs() {
		this.disableTooltip = false;
		this.openDelay = 300;
		this.closeDelay = 200;
		this.placement = 'bottom';
		this.container = 'body';
		this.listenOnResize();
	}

	private listenOnResize() {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
		this.subscriptions.push(
			this.resizeSubject.pipe(debounceTime(300)).subscribe(() => {
				this.disableTooltip = this.nativeElement.offsetWidth >= this.nativeElement.scrollWidth;
			})
		);
	}

	//endregion
}

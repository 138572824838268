import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { Observable, of } from 'rxjs';

export const phoneValidator = (): AsyncValidatorFn => {
	return (control: AbstractControl): Observable<ValidationErrors | null> => {
		const value: string = control.value;
		if (!value || value === '') {
			return of(null);
		}
		const phoneRegex = new RegExp('^\\+?\\d+$');
		return phoneRegex.test(value) ? of(null) : of({ phone: true });
	};
};

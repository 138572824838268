import { matrixShape } from '../common';
import { matrixValues } from './values';
import { matrixFullBy } from '../creation';
import { matrixProduct } from './product';
import { returnFromIterable } from '@evasys/globals/shared/helper/iterable';
import { Matrix } from '@evasys/globals/evainsights/models/report-item';

export const matrixReshape = (matrix: Matrix, shape: number[]): Matrix => {
	return matrixFullBy(determineOutputShape(matrixShape(matrix), shape), returnFromIterable(matrixValues(matrix)));
};

/**
 * verify the output shape compatibility with the input shape and resolve the auto dimension if present
 */
const determineOutputShape = (inputShape: number[], outputShape: number[]): number[] => {
	const inputSize = matrixProduct(inputShape);
	const autoDimensionIndex = outputShape.indexOf(-1);

	if (autoDimensionIndex === -1) {
		// All dimensions are fully specified. Only check if they result in a matrix of the correct size.
		if (inputSize !== matrixProduct(outputShape)) {
			throw Error('The output size does not match the input size');
		}

		return outputShape;
	} else {
		// One dimension is set to -1 => determine its value automatically
		const secondAutoDimensionIndex = outputShape.indexOf(-1, autoDimensionIndex + 1);
		if (secondAutoDimensionIndex !== -1) {
			throw Error('The output shape contains two auto-dimensions');
		}

		const sizeFactor = matrixProduct(outputShape.filter((value) => value !== -1));
		if (inputSize % sizeFactor !== 0) {
			throw Error('Cannot determine the size of the auto dimension');
		}

		const autoDimensionSize = inputSize / sizeFactor;

		return outputShape.map((value, index) => (index === autoDimensionIndex ? autoDimensionSize : value));
	}
};

import { NgModule } from '@angular/core';
import { CommonModule, KeyValuePipe } from '@angular/common';
import { KeepMapOrderPipe } from './keep-map-order/keep-map-order.pipe';

@NgModule({
	declarations: [KeepMapOrderPipe],
	imports: [CommonModule],
	providers: [KeyValuePipe],
	exports: [KeepMapOrderPipe],
})
export class KeepMapOrderModule {}

import { Component, Input } from '@angular/core';
import { PlacementArray } from '@ng-bootstrap/ng-bootstrap/util/positioning';

@Component({
	selector: 'evasys-note',
	templateUrl: './note.component.html',
})
export class NoteComponent {
	@Input()
	note?: string;

	@Input()
	icon = 'about';

	@Input()
	class = 'c-h-20px c-w-20px';

	@Input()
	asInnerHTML = false;

	@Input()
	placement: PlacementArray = 'left';
}

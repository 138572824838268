import { EvasysActionsFactory } from '@evasys/shared/core';
import { WizardModel } from '@evasys/globals/shared/models/component/wizard/wizard.model';

const actionsFactory = new EvasysActionsFactory<WizardModel>('WIZARD');

export const { LoadPageActionSet, LoadOneActionSet, LoadManyActionSet, LoadOneWithParamsActionSet, LoadActionSet } =
	actionsFactory.createActions();

export const ToggleWizard = actionsFactory.createCustomActionSet<{ id: string; show: boolean }, void>(
	'ToggleWizard',
	false
);

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { endpoints } from '@evasys/globals/evainsights/api/endpoints';
import { KeyValueModel } from '@evasys/globals/evainsights/models/common/key-value.model';
import { Page } from '@evasys/globals/evainsights/models/pagination/page.model';
import { PeriodSearchRequest } from '@evasys/globals/evainsights/models/search/PeriodSearchRequest';

@Injectable({
	providedIn: 'root',
})
export class PeriodService {
	constructor(private http: HttpClient) {}

	getPeriods(filter: PeriodSearchRequest = {}): Observable<Page<KeyValueModel>> {
		return this.http.get<Page<KeyValueModel>>(endpoints.getPeriods.url(), {
			params: { ...filter },
		});
	}
}

import { Injectable } from '@angular/core';
import { Item } from '@evasys/globals/evainsights/models/item/item.model';
import { FormMultiLang } from '@evasys/globals/evainsights/models/common/multi-lang';
import {
	getLeftmostItemOption,
	getRightmostItemOption,
	isChoiceItem,
	isNotAbstention,
	isScalaItem,
} from '@evasys/globals/evainsights/helper/item';
import { nonNullish } from '@evasys/globals/evainsights/typeguards/common';

/**
 * Constructs element descriptors for use-cases where a concise textual description is required, such as a multiselect chip.
 *
 * The methods include suitable fallback texts to cover those cases in which the primary text is empty.
 */
@Injectable({ providedIn: 'root' })
export class FormElementDescriptorService {
	private static readonly SAMPLE_ITEM_OPTION_COUNT = 3;

	public readonly getItemDescriptor = (item: Item, translate: FormElementTranslate): string => {
		return (
			translate.form(item.text) ??
			this.getItemDescriptorFromOptions(item, translate) ??
			translate.key('item.withoutText')
		);
	};

	private readonly getItemDescriptorFromOptions = (item: Item, translate: FormElementTranslate): string | null => {
		if (isScalaItem(item)) {
			const left = translate.form(getLeftmostItemOption(item.itemOptions).text);
			const right = translate.form(getRightmostItemOption(item.itemOptions).text);
			if (left === null && right === null) {
				return null;
			} else {
				return `${left} - ${right}`.trim();
			}
		} else if (isChoiceItem(item)) {
			const nonAbstentionOptions = item.itemOptions.filter(isNotAbstention);
			const sampleOptions = nonAbstentionOptions.slice(0, FormElementDescriptorService.SAMPLE_ITEM_OPTION_COUNT);
			// TODO SDI-3158: Check
			// the evasys form editor strictly requires a non-empty text for all added item options
			const sampleOptionTexts = sampleOptions.map((itemOption) => nonNullish(translate.form(itemOption.text)));

			if (nonAbstentionOptions.length > sampleOptions.length) {
				sampleOptionTexts.push('…');
			}

			return sampleOptionTexts.join(', ');
		} else {
			return null;
		}
	};
}

export interface FormElementTranslate {
	key: (key: string) => string;
	form: (formMultiLang: FormMultiLang<string>) => string | null;
}

import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
	selector: '[evasysIfContent]',
})
export class IfContentDirective implements AfterViewInit {
	@Input()
	evasysIfContent: number = 0;

	constructor(private readonly elementRef: ElementRef) {}

	ngAfterViewInit(): void {
		if (this.elementRef.nativeElement.childNodes.length <= this.evasysIfContent) {
			this.elementRef.nativeElement.remove();
		}
	}
}

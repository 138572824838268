import { EvasysActionsFactory } from '../essential/redux/actions/evasys-actions.factory';
import { EvasysTranslationModel } from '@evasys/globals/evasys/models/general/evasys-translation.model';
import { EvasysCollectionModel } from '@evasys/globals/shared/models/general/evasys-collection.model';

const actionsFactory = new EvasysActionsFactory<EvasysTranslationModel>('TRANSLATE');
export const { ClearActionSet, LoadActionSet } = actionsFactory.createActions();

export const LoadTranslationSet = actionsFactory.createCustomActionSet<
	{ language: string; prefix: string[]; page: number },
	EvasysCollectionModel<EvasysTranslationModel>
>('LoadTranslation');

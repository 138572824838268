import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Animations } from '@evasys/globals/shared/animations/angular/animations';
import { NotificationEnum } from '@evasys/globals/shared/enums/component/notification.enum';
import { NgbAlertConfig } from '@ng-bootstrap/ng-bootstrap';
import { ButtonDesignEnum } from '@evasys/globals/shared/enums/component/button-design.enum';
import { FacadeService, NotificationService } from '@evasys/shared/core';
import { EvasysNotificationModel } from '@evasys/globals/shared/models/evasys-notification.model';
import { Update } from '@ngrx/entity';
import { ServiceInjectorMapper } from '@evasys/globals/evasys/mapper/general/service-injector-mapper';
import { TranslocoService } from '@ngneat/transloco';
import { EndpointsEnum } from '@evasys/globals/evasys/api/endpoints.enum';
import {
	UpdateErrorMessageMapper,
	UpdateSuccessMessageMapper,
} from '@evasys/globals/evasys/api/mapper/update-message.mapper';

@Component({
	selector: 'evasys-notification',
	templateUrl: './dynamic-notification.component.html',
	styleUrls: ['./dynamic-notification.component.scss'],
	providers: [NgbAlertConfig],
	animations: [Animations.popAnimation],
})
export class DynamicNotificationComponent implements OnInit, OnDestroy {
	//region Variables
	public notifications: EvasysNotificationModel[] = [];
	public buttonDesign = ButtonDesignEnum;
	private notificationsSubscription: Subscription;

	//endregion

	constructor(
		private readonly notificationService: NotificationService,
		private readonly alertConfig: NgbAlertConfig,
		private readonly injector: Injector,
		private readonly translateService: TranslocoService
	) {}

	//region events

	ngOnInit(): void {
		this.alertConfig.dismissible = false;
		this.handleNotifications();
	}

	ngOnDestroy(): void {
		this.notificationsSubscription.unsubscribe();
	}

	onClosed(notification: EvasysNotificationModel) {
		const index = this.notifications.indexOf(notification);
		if (index !== -1) {
			this.notifications.splice(index, 1);
			if (!notification.showInCollection) {
				this.notificationService.deleteOne(notification.id);
			}
		}
	}

	onApiCallAction(notification: EvasysNotificationModel) {
		const service = this.injector.get<FacadeService<any>>(ServiceInjectorMapper[notification.action.apiRoute]);

		let update: Update<any> = {
			id: notification.action.endpoint,
			changes: notification.action.queryParams,
		};

		service
			.updateOne(update)
			.then((response) => {
				this.notificationService.addNotification(
					NotificationEnum.SUCCESS,
					this.translateService.translate(
						UpdateSuccessMessageMapper.get(notification.action.apiRoute as EndpointsEnum)
					),
					response.configid,
					false
				);
			})
			.catch(() => {
				this.notificationService.addNotification(
					NotificationEnum.WARNING,
					this.translateService.translate(
						UpdateErrorMessageMapper.get(notification.action.apiRoute as EndpointsEnum)
					),
					'update_error',
					false
				);
			});
		this.notificationService.deleteOne(notification.id);
	}

	//endregion

	//region methods

	asCssClass(type: NotificationEnum) {
		const keys = Object.keys(NotificationEnum).filter((x) => NotificationEnum[x] == type);
		return keys.length > 0 ? keys[0] : null;
	}

	private handleNotifications() {
		this.notificationsSubscription = this.notificationService.getAll().subscribe((notifications) => {
			notifications
				.filter((notification) => !notification?.displayed)
				.forEach((notification) => {
					if (!!notification) {
						// set a flag to the state of the notification as it was displayed to the user.
						// interactionLink is added as Mock-data, as it will be added to the model in a later story
						const update: Update<EvasysNotificationModel> = {
							id: notification.id,
							changes: {
								displayed: true,
							},
						};
						this.notificationService.updateOne(update);
						// min: 5 sec, max: 10 sec, from 1 to 5: one second per element
						const duration =
							5000 + (this.notifications.length <= 5 ? this.notifications.length * 1000 : 5000);
						new Promise((resolve) => setTimeout(resolve, duration)).then(() => this.onClosed(notification));
						this.notifications.push(notification);
					}
				});
		});
	}

	//endregion
}

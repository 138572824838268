export enum LevelEnum {
	INSTRUCTOR = 1,
	DEAN = 2,
	ADMINISTRATOR = 3,
	DEAN_OF_STUDIES = 4,
	DATA_COLLECTOR = 5,
	SERIES_PRINT = 7,
	REPORT_CREATOR = 8,
	WASTE_PAPER_BIN = 9,
	ONLINE_SURVEY_USER = 10, // Not really used, here for completeness
	WEB_VERIFIER = 11,
	REPORTING_MASK = 12,
	SUBSECTION_ADMINISTRATOR = 13,
	MODUL = 14,
	SOAP = 15,
	ONLINE_SURVEY_PARTICIPANTS = 16,
	ARCHIVED = 21,
}

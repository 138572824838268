import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
	HttpStatusCode,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { NotificationService } from '@evasys/shared/core';
import { NotificationEnum } from '@evasys/globals/shared/enums/component/notification.enum';
import { TranslocoService } from '@ngneat/transloco';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
	constructor(
		private readonly notificationService: NotificationService,
		private translocoService: TranslocoService
	) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			tap({
				error: (error) => {
					if (
						error instanceof HttpErrorResponse &&
						error.status !== HttpStatusCode.Unauthorized &&
						error.status !== HttpStatusCode.Forbidden
					) {
						this.notificationService.clear();
						this.notificationService.addNotification(
							NotificationEnum.ERROR,
							this.translocoService.translate('error.occurred'),
							String(error.status)
						);
					}
				},
			})
		);
	}
}

import { WebWorker } from '../../web-worker/web-worker';
import { Injectable } from '@angular/core';
import { utilWorkerInstance } from './util.worker.factory';

export interface SortWorkerParams<T> {
	sortField: keyof T;
	entities: T[];
	sortDirectionAscending: boolean;
}
export interface SearchWorkerParams<T> {
	entities: T[];
	searchValue: string;
	keys?: (keyof T)[];
}

@Injectable({
	providedIn: 'root',
	useFactory: () => new UtilWorker(utilWorkerInstance()),
})
export class UtilWorker<T> extends WebWorker<SortWorkerParams<T> | SearchWorkerParams<T>> {
	constructor(worker?: Worker) {
		super(worker);
	}

	public run(data: SearchWorkerParams<T>, postMessage);
	public run(data: SortWorkerParams<T>, postMessage);
	public run(data: SortWorkerParams<T> | SearchWorkerParams<T>, postMessage) {
		if ('searchValue' in data) {
			this.handleSearch(data, postMessage);
		} else if ('sortField' in data && 'sortDirectionAscending' in data) {
			this.handleSort(data, postMessage);
		}
	}

	private handleSearch(data: SearchWorkerParams<T>, postMessage) {
		if (!data.entities || data.entities?.length === 0) {
			postMessage({ result: [] });
		}
		if (!data.keys) {
			const keys = Object.keys(data.entities[0]).filter((key) => key !== 'id');
			data.keys = keys as (keyof T)[];
		}

		const foundEntities = data.entities.filter((entity) => {
			return data.keys.some((key) => {
				return (
					(typeof entity[key] === 'string' && (entity[key] as string).includes(data.searchValue)) ||
					(typeof entity[key] === 'number' && (entity[key] as number).toString().includes(data.searchValue))
				);
			});
		});

		postMessage({ result: foundEntities });
	}

	private handleSort(data: SortWorkerParams<T>, postMessage) {
		if (
			data &&
			data.sortField !== undefined &&
			data.entities !== undefined &&
			data.sortDirectionAscending !== undefined
		) {
			const sortEntities = data.entities;

			const sortedEntities = [...sortEntities].sort((entityA, entityB) => {
				const sortValueA = entityA[data.sortField];
				const sortValueB = entityB[data.sortField];

				if (typeof sortValueA === 'string' && typeof sortValueB === 'string') {
					return data.sortDirectionAscending
						? sortValueA.localeCompare(sortValueB)
						: sortValueB.localeCompare(sortValueA);
				} else if (typeof sortValueA === 'number' && typeof sortValueB === 'number') {
					return data.sortDirectionAscending ? sortValueA - sortValueB : sortValueB - sortValueA;
				} else {
					console.error('Can not determine sort type in util Worker (handleSort).');
					return 0;
				}
			});

			postMessage({ result: sortedEntities });
		}
	}
}

// @ts-ignore
if (typeof WorkerGlobalScope !== 'undefined' && self instanceof WorkerGlobalScope) {
	const sortWorker = new UtilWorker();
	addEventListener('message', ({ data }) => {
		sortWorker.run(data, postMessage);
	});
}

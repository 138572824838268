<evasys-modal #modal [id]="'modalConfirm-' + id" [closeable]="false">
	<ng-content></ng-content>
	<div footer>
		<evasys-button [id]="'acceptButton-' + id" (click)="accept.emit(); modal.close()" class="me-2">
			{{ 'S_BUTTON_OK' | transloco }}
		</evasys-button>
		<evasys-button [id]="'rejectButton-' + id" (click)="reject.emit(); modal.close()" [design]="designs.SECONDARY">
			{{ 'S_BUTTON_CANCEL' | transloco }}
		</evasys-button>
	</div>
</evasys-modal>

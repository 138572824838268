import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import {
	FormMultiLang,
	FormMultiLangEntry,
	ReportMultiLang,
	ReportMultiLangEntry,
} from '@evasys/globals/evainsights/models/common/multi-lang';
import { MultiLangService } from './multi-lang.service';
import { ReportLanguage } from '@evasys/globals/evainsights/models/report/report-reportTemplate.model';
import { findFormMultiLangEntryForReportLang, findReportMultiLangEntry } from './select';

@Injectable()
export class ReportMultiLangService extends MultiLangService {
	reportLanguage$ = new BehaviorSubject<ReportLanguage | undefined>(undefined);

	public setReportLanguage(reportLanguage: ReportLanguage) {
		this.reportLanguage$.next(reportLanguage);
	}

	protected override findFormMultiLangEntry<T>(
		formMultiLang: FormMultiLang<T>
	): Observable<FormMultiLangEntry<T> | undefined> {
		return this.reportLanguage$.pipe(
			map((reportLanguage) =>
				reportLanguage !== undefined
					? findFormMultiLangEntryForReportLang(formMultiLang, reportLanguage)
					: undefined
			)
		);
	}

	protected override findReportMultiLangEntry<T>(
		reportMultiLang: ReportMultiLang<T>
	): Observable<ReportMultiLangEntry<T> | undefined> {
		return this.reportLanguage$.pipe(
			map((reportLanguage) =>
				reportLanguage !== undefined ? findReportMultiLangEntry(reportMultiLang, reportLanguage.id) : undefined
			)
		);
	}
}

import { sharedActions } from '../actions';
import { ActionReducer } from '@ngrx/store';

export function globalReducer(reducer: ActionReducer<any>): ActionReducer<any> {
	return function (state: any, action: any) {
		if (action.type === sharedActions.fromGlobal.ClearActionSet.DEFAULT.type) {
			const expections = ['translate'];
			state = expections.reduce((clearedState, expectation) => {
				clearedState[expectation] = state[expectation];
				return clearedState;
			}, {});
		}

		return reducer(state, action);
	};
}
